<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser"></div>
<h2 mat-dialog-title>{{ formTitle }}</h2>

<mat-dialog-content>
  <form [formGroup]="form">
    <mat-horizontal-stepper [linear]="true" #stepper>
      <mat-step [stepControl]="processedFileForm">
        <div [formGroup]="processedFileForm">
          <ng-template matStepLabel>Mod Zip File</ng-template>
          <h3>Upload Processed Mod Zip File Named <i>{{ pointCloudFileName }}</i></h3>
          <div>
            <span>
              <button (click)="processedFileInput.click()">
                <mat-icon>library_add</mat-icon>
                <span>Select Processed Mod Zip File</span>
                <input #processedFileInput type="file" (change)="processedFileInputChange($event)"
                  accept=".zip, .rar, .7z" style="display: none" />
              </button>
            </span>

            <br><br>
          </div>
        </div>

        <div class="button-row">
          <button mat-raised-button aria-label="Close without saving" matTooltip="Close without saving"
            (click)="cancel()">
            Cancel
          </button>
          <button mat-raised-button aria-label="Back to previous step" matTooltip="Back to previous step"
            matStepperPrevious>
            Back
          </button>
          <div *ngIf="needSiteFile; then nextButton; else saveButton"></div>
          <ng-template #nextButton>
            <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" matStepperNext>
              Next
            </button>
          </ng-template>
          <ng-template #saveButton>
            <button mat-raised-button color="primary" aria-label="Save and close" matToolip="Save and close" #saveButton
              (click)="save(currentUser)" [disabled]="form.invalid">
              Save
            </button>
          </ng-template>
        </div>
      </mat-step>

      <div *ngIf="hasPanoMod">
        <mat-step [stepControl]="panoFileForm">
          <div [formGroup]="panoFileForm">
            <ng-template matStepLabel>Panoramic Mod</ng-template>
            <h3>Panoramic Mod Zip File Named <i>{{ panoFileName }}</i></h3>
            <div>
              <span>
                <button (click)="panoFileInput.click()">
                  <mat-icon>library_add</mat-icon>
                  <span>Select Processed Panoramic Mod Zip File</span>
                  <input #panoFileInput type="file" (change)="panoFileInputChange($event)" accept=".zip, .rar, .7z"
                    style="display: none" />
                </button>
              </span>

              <br><br>
            </div>
          </div>

          <div class="button-row">
            <button mat-raised-button aria-label="Close without saving" matTooltip="Close without saving"
              (click)="cancel()">
              Cancel
            </button>
            <button mat-raised-button aria-label="Back to previous step" matTooltip="Back to previous step"
              matStepperPrevious>
              Back
            </button>
            <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" matStepperNext>
              Next
            </button>
          </div>
        </mat-step>
      </div>

      <div *ngIf="needSiteFile">
        <mat-step [stepControl]="siteFileForm">
          <div [formGroup]="siteFileForm">
            <ng-template matStepLabel>Site JSON File</ng-template>
            <h3>Site JSON File Named <i>{{ siteFileName }}</i></h3>
            <div>
              <span>
                <button (click)="siteFileInput.click()">
                  <mat-icon>library_add</mat-icon>
                  <span>Select Site JSON File</span>
                  <input #siteFileInput type="file" (change)="siteFileInputChange($event)" accept=".json"
                    style="display: none" />
                </button>
              </span>

              <br><br>
            </div>
          </div>

          <div class="button-row">
            <button mat-raised-button aria-label="Close without saving" matTooltip="Close without saving"
              (click)="cancel()">
              Cancel
            </button>
            <button mat-raised-button aria-label="Back to previous step" matTooltip="Back to previous step"
              matStepperPrevious>
              Back
            </button>
            <button mat-raised-button color="primary" aria-label="Save and close" matToolip="Save and close" #saveButton
              (click)="save(currentUser)" [disabled]="form.invalid">
              Save
            </button>
          </div>
        </mat-step>
      </div>
    </mat-horizontal-stepper>
    <p *ngIf="errorMsg">{{ errorText }}</p>
  </form>
</mat-dialog-content>