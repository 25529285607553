<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser">
  <h3>{{ formTitle }}</h3>
  <div
    *ngIf="{
      viewFormat: viewFormat$ | async
    } as settings"
  >
    <mat-toolbar>
      <mat-button-toggle-group [formControl]="viewAsControl" aria-label="View Projects As">
        <mat-button-toggle
          mat-icon-button
          aria-label="View ships as cards"
          matTooltip="View ships as cards"
          value="cards"
          (change)="onViewFormatChange($event.value, currentUser)"
        >
          <mat-icon>view_module</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          mat-icon-button
          aria-label="View ships as list"
          matTooltip="View ships as list"
          value="list"
          (change)="onViewFormatChange($event.value, currentUser)"
        >
          <mat-icon>view_list</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </mat-toolbar>
    <div *ngIf="ships">
      <div class="grid" *ngIf="settings.viewFormat === 'cards'">
        <div *ngFor="let ship of ships">
          <mat-card appearance="outlined" class="ship-card mat-elevation-z10 {{ getShipDisplayClass(ship) }}">
            <mat-card-header (click)="selectShip(ship)">
              <mat-card-title>{{ ship.name }}</mat-card-title>
              <mat-card-subtitle>{{ getShipDesignation(ship) }}</mat-card-subtitle>
            </mat-card-header>
            <img
              mat-card-image
              src="{{ ship.imageUrl }}"
              alt="Image for {{ ship.name }}"
              (click)="selectShip(ship)"
              onerror="this.onerror=null; this.src='../../../../assets/img/SDAT.png';"
            />
            <mat-card-footer (click)="selectShip(ship)">
              <p style="text-align: right; font-style: italic; font-size: smaller; margin-right: 15px">
                Last Modified: {{ ship.updatedAt | date: 'short' }}
              </p>
            </mat-card-footer>
          </mat-card>
        </div>
      </div>
      <div *ngIf="settings.viewFormat === 'list'">
        <table
          mat-table
          [dataSource]="ships"
          matSort
          matSortActive="name"
          matSortDirection="asc"
          class="mat-elevation-z8"
        >
          <!-- name column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ship Name</th>
            <td mat-cell *matCellDef="let ship" style="cursor: pointer">
              <a aria-label="Click to select ship" matTooltip="Click to select ship" (click)="selectShip(ship)">
                {{ ship.name }}
              </a>
            </td>
          </ng-container>

          <!-- designationWithNullNumber column -->
          <ng-container matColumnDef="designationWithHullNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Designation</th>
            <td mat-cell *matCellDef="let ship">
              <a aria-label="Click to select ship" matTooltip="Click to select ship" (click)="selectShip(ship)">
                {{ ship.designationWithHullNumber }}
              </a>
            </td>
          </ng-container>

          <!-- updatedAt column -->
          <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Modified</th>
            <td mat-cell *matCellDef="let ship">
              <a aria-label="Click to select ship" matTooltip="Click to select ship" (click)="selectShip(ship)">
                {{ ship.updatedAt | date: 'short' }}
              </a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedShipColumns; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedShipColumns"
            [ngClass]="{ 'selected-row': selectedShipId == row._id }"
          ></tr>
        </table>
      </div>
    </div>
  </div>
</div>
