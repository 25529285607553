import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import keys from 'lodash-es/keys';
import { BehaviorSubject, combineLatest, fromEvent, noop, Observable, zip } from 'rxjs';
import { concatMap, distinctUntilChanged, exhaustMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { ImageDoc, Mod, Model3d, Project, Scan, Ship, User } from '@shared/models';
import {
  ErrorService,
  FileService,
  ModService,
  Model3dService,
  ProjectService,
  ScanService,
  SettingsService,
  ShipService,
  UserService,
} from '@shared/services';
import { FileObjectTypesEnum, DbCollectionsEnum, TagFileTypesEnum, TagTypesEnum, UserRolesEnum } from '@shared/enums';
import { createTag, getUpdates, removeUndefinedProps } from '@shared/utils';

import { environment } from '@environment';

@UntilDestroy()
@Component({
  selector: 'app-project-dialog',
  templateUrl: './project-dialog.component.html',
  styleUrls: ['./project-dialog.component.css'],
})
export class ProjectDialogComponent implements AfterViewInit {
  currentUser: User;
  errorMsg: boolean;
  errorText = 'Please select a file';
  imageFileObj: File;
  imageURL: string; //for previewing file
  form: UntypedFormGroup;
  fileForm: UntypedFormGroup;
  projectInfoForm: UntypedFormGroup;
  shipInfoForm: UntypedFormGroup;
  shipModelScanForm: UntypedFormGroup;
  project: Project;
  isNewProject = false;
  currentUser$: Observable<User>;
  isDebugging$: Observable<boolean>;
  ships$: Observable<Ship[]>;
  selectedShip$: Observable<Ship>;
  selectedShipMod$: Observable<Mod>;
  selectedShipModModel3d$: Observable<Model3d>;
  selectedShipModScan$: Observable<Scan>;
  selectedShipMods$: Observable<Mod[]>;
  selectedShipModels3d$: Observable<Model3d[]>;
  selectedShipScans$: Observable<Scan[]>;
  viewFormat$: Observable<string>;
  viewAsControl = new UntypedFormControl();

  shipModelScanErrorSubject = new BehaviorSubject<string>('A ship 3D model or scan is required.');
  shipModelScanHintSubject = new BehaviorSubject<string>('Selected Ship 3D Model / Scan:');
  shipModelScanError$: Observable<string> = this.shipModelScanErrorSubject.asObservable();
  shipModelScanHint$: Observable<string> = this.shipModelScanHintSubject.asObservable();;

  allowShipModels3d = true;
  allowShipScans = true;

  @ViewChild('saveButton', { static: true }) saveButton: ElementRef;

  @ViewChild('cancelButton', { static: true }) cancelButton: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ProjectDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    data: {
      currentUser: User;
      isNewProject: boolean;
      project: Project;
    },
    private errorService: ErrorService,
    private fileService: FileService,
    private modService: ModService,
    private model3dService: Model3dService,
    private projectService: ProjectService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private userService: UserService,
  ) {
    this.allowShipModels3d = this.shipService.getAllowShipModels3d();
    this.allowShipScans = this.shipService.getAllowShipScans();
    this.currentUser = data.currentUser;
    this.isNewProject = data.isNewProject;
    this.project = data.project;

    this.currentUser$ = this.userService.currentUser$;
    this.isDebugging$ = this.settingsService.isDebugging$;
    this.ships$ = this.shipService.shipsWithValidMods$;
    this.selectedShip$ = this.shipService.currentShip$;
    this.selectedShipMod$ = this.modService.currentShipMod$;
    this.selectedShipMods$ = this.modService.currentShipMods$;
    this.selectedShipModModel3d$ = this.model3dService.currentShipModel3d$;
    this.selectedShipModScan$ = this.scanService.currentShipScan$;
    this.selectedShipModels3d$ = this.model3dService.currentShipModels3dWithMods$;
    this.selectedShipScans$ = this.scanService.currentShipScansWithMods$;
    this.viewFormat$ = this.settingsService.viewFormat$;
    this.viewAsControl.setValue(this.settingsService.getViewFormat());

    zip(
      this.currentUser$,
      this.ships$,
      this.selectedShip$,
      this.selectedShipMods$,
      this.selectedShipMod$,
      this.selectedShipModModel3d$,
      this.selectedShipModScan$,
      this.selectedShipModels3d$,
      this.selectedShipScans$,
      this.viewFormat$
    )
      .pipe(untilDestroyed(this))
      .pipe(
        map(
          ([
            user,
            ships,
            selectedShip,
            selectedShipMods,
            selectedShipMod,
            selectedShipModModel3d,
            selectedShipModScan,
            selectedShipModels3d,
            selectedShipScans,
            viewFormat,
          ]) => ({
            user,
            ships,
            selectedShip,
            selectedShipMods,
            selectedShipMod,
            selectedShipModModel3d,
            selectedShipModScan,
            selectedShipModels3d,
            selectedShipScans,
            viewFormat,
          })
        )
      )
      .subscribe(
        ({
          user,
          ships,
          selectedShip,
          selectedShipMods,
          selectedShipMod,
          selectedShipModModel3d,
          selectedShipModScan,
          selectedShipModels3d,
          selectedShipScans,
          viewFormat,
        }) => {
          this.currentUser = user;

          this.fileForm = fb.group({
            editorId: [this.currentUser ? this.currentUser._id : null, Validators.required],
            imageFileName: new UntypedFormControl(this.project ? this.project.imageUrl : environment.defaultImageUrl),
            imageUrl: [this.project.imageUrl || environment.defaultImageUrl],
            imageBase64DataUrl: [''],
            imageThumbnailUrl: [this.project.imageThumbnailUrl || environment.defaultThumbnailImageUrl],
            avatar: [null],
          });

          this.projectInfoForm = fb.group({
            _id: [this.project._id, Validators.required],
            creatorId: [this.project.creatorId, Validators.required],
            description: [this.project.description],
            editorId: [this.currentUser ? this.currentUser._id : null, Validators.required],
            isModelAnalysisProject: [this.project.isModelAnalysisProject, Validators.required],
            name: [this.project.name, Validators.required],
          });

          this.shipInfoForm = fb.group({
            shipId: new UntypedFormControl(this.project.ship ? this.project.ship._id : null,
              Validators.required
            ),
          });

          this.shipModelScanForm = fb.group({
            shipPanoramicModId: new UntypedFormControl(selectedShipModScan && selectedShipModScan.panoramic?.modId || null),
            shipModId: new UntypedFormControl(selectedShipMod ? selectedShipMod._id : null, Validators.required),
            shipModel3dId: new UntypedFormControl(selectedShipModModel3d ? selectedShipModModel3d._id : null),
            shipModSourceCollection: new UntypedFormControl(selectedShipMod && selectedShipMod.modSource ? selectedShipMod.modSource.collection : null,
              Validators.required
            ),
            shipModSourceId: new UntypedFormControl(selectedShipMod && selectedShipMod.modSource ? selectedShipMod.modSource._id : null,
              Validators.required
            ),
            shipPanoramicScanId: new UntypedFormControl(selectedShipModScan && selectedShipModScan.panoramic?.scanId || null),
            shipScanId: new UntypedFormControl(selectedShipModScan ? selectedShipModScan._id : null),
          });

          this.form = fb.group({
            fileForm: this.fileForm,
            projectInfoForm: this.projectInfoForm,
            shipInfoForm: this.shipInfoForm,
            shipModelScanForm: this.shipModelScanForm,
          });
        }
      );

    combineLatest([
      this.selectedShip$,
      this.selectedShipModels3d$,
      this.selectedShipScans$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([ship, shipModels3d, shipScans]) => {
        if (ship && this.isNewProject) {
          this.shipInfoForm.controls.shipId.setValue(ship._id);

          if (this.isNewProject) {
            if (
              shipModels3d &&
              Array.isArray(shipModels3d) &&
              shipModels3d.length > 0 &&
              shipScans &&
              Array.isArray(shipScans) &&
              shipScans.length > 0
            ) {
              this.shipModelScanHintSubject.next('Select either a 3D model or a scan to use from this ship:');
              this.shipModelScanErrorSubject.next('A ship 3D model or scan is required.');
            } else if (shipModels3d && Array.isArray(shipModels3d) && shipModels3d.length > 0) {
              this.shipModelScanHintSubject.next('Select a 3D model to use from this ship:');
              this.shipModelScanErrorSubject.next('A ship 3D model is required.');
            } else if (shipScans && Array.isArray(shipScans) && shipScans.length > 0) {
              this.shipModelScanHintSubject.next('Select a scan to use from this ship:');
              this.shipModelScanErrorSubject.next('A ship scan is required.');
            } else {
              this.shipModelScanHintSubject.next('Error: Ship has no 3D models or scans.');
              this.shipModelScanErrorSubject.next('A ship 3D model or scan is required.');
            }
          } else {
            if (this.allowShipModels3d && this.allowShipScans) {
              this.shipModelScanHintSubject.next('Selected Ship 3D Model / Scan:');
              this.shipModelScanErrorSubject.next('A ship 3D model or scan is required.');
            } else if (this.allowShipModels3d) {
              this.shipModelScanHintSubject.next('Selected Ship 3D Model');
              this.shipModelScanErrorSubject.next('A ship 3D model is required.');
            } else {
              this.shipModelScanHintSubject.next('Selected Ship Scan');
              this.shipModelScanErrorSubject.next('A ship 3D model or scan is required.');
            }
          }
        }
      });
  }

  ngAfterViewInit() { }

  get existingShipTitle(): string {
    let returnValue = '';

    this.selectedShip$.subscribe((ship) => {
      if (ship) {
        returnValue = `Selected Ship: ${ship.name} | ${ship.designationWithHullNumber ? ship.designationWithHullNumber : ship.hullNumber.toString()
          }`;
      }
    });

    return returnValue;
  }

  get formTitle(): String {
    if (this.isNewProject) {
      return 'Create New Project';
    } else {
      return `Edit Project: ${this.project.name}`;
    }
  }

  get imageFileName(): string {
    return this.fileForm.get('imageFileName').value || '';
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get isExistingProject(): boolean {
    return !this.isNewProject;
  }

  get showShipModel3dSelect(): Boolean {
    let returnValue = false;

    this.selectedShipModels3d$.subscribe((models3d) => {
      if (models3d && models3d.length > 0 && this.allowShipModels3d) {
        if (this.isNewProject || this.shipModelScanForm.controls.shipModel3dId.value !== null) {
          returnValue = true;
        }
      }
    });

    return returnValue;
  }

  get showShipScanSelect(): Boolean {
    let returnValue = false;

    this.selectedShipScans$.subscribe((scans) => {
      if (scans && scans.length > 0 && this.allowShipScans) {
        if (this.isNewProject || this.shipModelScanForm.controls.shipScanId.value !== null) {
          returnValue = true;
        }
      }
    });

    return returnValue;
  }

  get showShipModelsAndScansHint(): Boolean {
    if (this.showShipModel3dSelect && this.showShipScanSelect) {
      return true;
    } else {
      return false;
    }
  }

  get saveButtonText(): String {
    if (this.isNewProject) {
      return 'Create';
    } else {
      return 'Save';
    }
  }

  cancel(): void {
    if (this.isNewProject) {
      this.settingsService.setIsLoading(true);
      const promises = [];
      promises.push(this.projectService.getProjectById(null, this.currentUser));
      promises.push(this.shipService.getShipById(null, this.currentUser));

      Promise.allSettled(promises).then((results) => {
        this.dialogRef.close();
      });
    } else {
      this.dialogRef.close();
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  async imageFileInputChange($event: Event): Promise<any> {
    const _this = this;
    _this.errorMsg = false;
    const FILE = ($event.target as HTMLInputElement).files[0];
    _this.imageFileObj = FILE;

    if (!_this.imageFileObj) {
      _this.errorMsg = true;
      _this.errorText = 'Please select an image file';
    } else {
      _this.settingsService.setIsLoading(true);
      const tags = [];
      tags.push(createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.IMAGE));

      _this.fileService
        .uploadFile(
          _this.imageFileObj,
          FileObjectTypesEnum.PROJECT,
          _this.project._id,
          tags,
          TagFileTypesEnum.IMAGE,
          this.currentUser
        )
        .then((results) => {
          //add image preview, see https://www.positronx.io/angular-8-show-image-preview-with-reactive-forms-tutorial/
          _this.fileForm.patchValue({
            avatar: FILE,
          });
          _this.fileForm.get('avatar').updateValueAndValidity();

          const reader = new FileReader();
          reader.onload = () => {
            try {
              _this.imageURL = reader.result as string;
            } catch (ex) {
              _this.errorService.handleError(`Error previewing image ${ex}`);
            }
          };
          reader.readAsDataURL(FILE);

          const imageDoc: ImageDoc = {
            _id: results._id,
            creatorId: _this.currentUser._id,
            displayThumbnailUrl: results.thumbnailDisplayUrl,
            displayUrl: results.locationDisplayUrl,
            isMainImage: true,
            isSnapshot: false,
            name: results.nameWithoutExtension,
            parent: {
              _id: _this.project._id,
              collection: DbCollectionsEnum.PROJECTS,
            },
            thumbnailUrl: results.thumbnailUrl || environment.defaultThumbnailImageUrl,
            url: results.locationUrl,
          };

          _this.projectService
            .addImage(imageDoc, _this.project, _this.currentUser)
            .then((projectResults) => {
              _this.errorText = '';
              //make sure the changes get picked up on the save - jane 5/15/2024
              _this.fileForm.controls.imageUrl.setValue(results.locationUrl);
              _this.fileForm.controls.imageUrl.markAsDirty();
              _this.fileForm.controls.imageBase64DataUrl.setValue(results.locationBase64DataUrl);
              _this.fileForm.controls.imageBase64DataUrl.markAsDirty();
              _this.fileForm.controls.imageFileName.setValue(this.imageFileObj.name);
              _this.fileForm.controls.imageFileName.markAsDirty();
              _this.fileForm.controls.imageThumbnailUrl.setValue(results.thumbnailUrl);
              _this.fileForm.controls.imageThumbnailUrl.markAsDirty();
            })
            .catch((projectError) => {
              const errMessage = `Error adding image ${imageDoc._id} to project ${_this.project._id}: ${projectError.message}`;
              _this.errorText = errMessage;
            })
            .finally(() => {
              _this.settingsService.setIsLoading(false);
              if (results.processing) {
                Swal.fire(
                  'File Upload in Process',
                  'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.',
                  'info'
                );
              }
            });
        })
        .catch((uploadError) => {
          _this.settingsService.setIsLoading(false);
          _this.errorMsg = true;
          _this.errorText = uploadError.message;
        });
    }
  }

  getShipDesignation(ship: Ship): String {
    let returnValue = '';

    if (ship) {
      returnValue = ship.designationWithHullNumber || ship.hullNumber.toString();
    }

    return returnValue;
  }

  onShipModel3dChange(value, currentUser: User) {
    this.settingsService.setIsLoading(true);
    const promises = [];
    let mod: Mod;

    zip(this.selectedShip$, this.selectedShipModels3d$)
      .pipe(
        map(([ship, models3d]) => ({
          ship,
          models3d,
        }))
      )
      .subscribe(
        ({ ship, models3d }) => {
          const matching = models3d.find((model3d) => model3d._id === value);
          promises.push(this.shipService.setShipModel3d(ship, matching, currentUser));
          promises.push(
            this.modService.getModById(
              matching ? matching.modId : null,
              DbCollectionsEnum.SHIPS,
              ship ? ship._id : null,
              this.currentUser
            )
          );
          
          Promise.allSettled(promises).then((results) => {
            const modResults = results[results.length - 1];

            if (modResults.status === 'fulfilled') {
              mod = modResults.value;
            }

            if (mod && mod.modSource && mod.modSource.collection && mod.modSource._id) {
              this.shipModelScanForm.controls.shipModId.setValue(mod._id);
              this.shipModelScanForm.controls.shipModel3dId.setValue(value);
              this.shipModelScanForm.controls.shipModSourceCollection.setValue(mod.modSource.collection);
              this.shipModelScanForm.controls.shipModSourceId.setValue(mod.modSource._id);

              //if they have already selected a scan for the ship, need to clear it
              if (this.showShipModelsAndScansHint) {
                if (this.shipModelScanForm.controls.shipScanId.value != null) {
                  this.shipModelScanForm.controls.shipScanId.setValue(null);
                }
              }

              this.settingsService.setIsLoading(false);
            } else {
              this.settingsService.setIsLoading(false);
              if (this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  `Error`,
                  `The seleceted 3D model does not have a valid mod.  Please select another one or email ${environment.techSupportEmail}.`,
                  'error'
                );
              }
            }
          });
        },
        (error) => {
          this.settingsService.setIsLoading(false);
          const errMessage = this.errorService.handleError(`Error getting ship models: ${error.message}`);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              'Error',
              `There was an error loading the ship 3D models.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        }
      );
  }

  onShipScanChange(value, currentUser: User) {
    this.settingsService.setIsLoading(true);
    const promises = [];
    let mod: Mod;

    zip(this.selectedShip$, this.selectedShipScans$)
      .pipe(
        map(([ship, scans]) => ({
          ship,
          scans,
        }))
      )
      .subscribe(
        ({ ship, scans }) => {
          const matching = scans ? scans.find((scan) => scan._id === value) : null;

          if (matching && matching.panoramic?.scanId) {
            this.shipModelScanForm.controls.shipPanoramicModId.setValue(matching.panoramic.modId);
            this.shipModelScanForm.controls.shipPanoramicScanId.setValue(matching.panoramic.scanId);
          }

          promises.push(this.shipService.setShipScan(ship, matching, currentUser));
          promises.push(
            this.modService.getModById(
              matching ? matching.modId : null,
              DbCollectionsEnum.SHIPS,
              ship ? ship._id : null,
              this.currentUser
            )
          );

          Promise.allSettled(promises).then((results) => {
            const modResults = results[results.length - 1];

            if (modResults.status === 'fulfilled') {
              mod = modResults.value;
            }

            if (mod && mod.modSource && mod.modSource.collection && mod.modSource._id) {
              this.shipModelScanForm.controls.shipScanId.setValue(value);
              this.shipModelScanForm.controls.shipModId.setValue(mod._id);
              this.shipModelScanForm.controls.shipModSourceCollection.setValue(mod.modSource.collection);
              this.shipModelScanForm.controls.shipModSourceId.setValue(mod.modSource._id);

              //if they have already selected a 3d model for the ship, need to clear it
              if (this.showShipModelsAndScansHint) {
                if (this.shipModelScanForm.controls.shipModel3dId.value != null) {
                  this.shipModelScanForm.controls.shipModel3dId.setValue(null);
                }
              }

              this.settingsService.setIsLoading(false);
            } else if (ship) {
              this.settingsService.setIsLoading(false);
              if (this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  `Error`,
                  `The selected scan does not have a valid mod.  Please select another one or email ${environment.techSupportEmail}.`,
                  'error'
                );
              }
            }
          });
        },
        (error) => {
          this.settingsService.setIsLoading(false);
          const errMessage = this.errorService.handleError(`Error getting ship scans: ${error.message}`);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              'Error',
              `There was an error loading the ship scans.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        }
      );
  }

  onViewFormatChange(value: string, currentUser: User) {
    this.userService.updateViewFormatPreference(currentUser._id, value);
  }

  save(currentUser): void {
    this.settingsService.setIsLoading(true);

    if (this.isNewProject) {
      const projectInfo = {
        ...this.fileForm.value,
        ...this.projectInfoForm.value,
      };
  
      projectInfo.ship = {
        _id: this.shipInfoForm.controls.shipId.value,
        modId: this.shipModelScanForm.controls.shipModId.value,
        model3dId: this.shipModelScanForm.controls.shipModel3dId.value,
        scanId: this.shipModelScanForm.controls.shipScanId.value,
      };

      if (this.shipModelScanForm.controls.shipPanoramicScanId.value) {
        projectInfo.ship.panoramic = {
          modId: this.shipModelScanForm.controls.shipPanoramicModId.value,
          scanId: this.shipModelScanForm.controls.shipPanoramicScanId.value
        };
      }

      this.projectService
        .createNewProject(projectInfo, currentUser)
        .then((newProject: Project) => {
          this.close();
          this.router.navigate([`/projects/${newProject._id}`]);
        })
        .catch((error) => {
          this.settingsService.setIsLoading(false);
          this.close();
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error`,
              `There was an error creating the project.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        });
    } else {
      let changes: any = {
        editorId: currentUser._id
      };
      let shipChanges: any = {};

      const fileChanges = getUpdates(this.fileForm, changes);
      const projectInfoChanges = getUpdates(this.projectInfoForm, changes);
      shipChanges = getUpdates(this.shipInfoForm, shipChanges);

      changes = removeUndefinedProps(changes);
      shipChanges = removeUndefinedProps(shipChanges);
      const changedShipKeys = keys(shipChanges);

      if (changedShipKeys.length > 0) {
        changes.ship = changedShipKeys;
      }

      const changedKeys = keys(changes);

      if (changedKeys.length > 1) {
        this.projectService
        .saveProject(this.projectInfoForm.controls._id.value, changes, this.currentUser)
        .then((updatedProject: Project) => {
          this.close();
          const navigationUrl = `/projects/${this.projectInfoForm.controls._id.value}`;
          this.router.navigateByUrl(navigationUrl);
        })
        .catch((error) => {
          this.settingsService.setIsLoading(false);
          this.close();
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error Saving Project`,
              `${error}.  Please email ${environment.techSupportEmail} with any questions.`,
              'error'
            );
          }
        });
      } else {
        this.settingsService.setIsLoading(false);
        this.close();
      }
    }
  }
}
