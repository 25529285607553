export enum DbCollectionsEnum {
  ACTIVITIES = 'activities',
  AREA_MEASUREMENTS = 'areameasurements',
  DATA_SOURCES = 'datasources',
  DOCUMENTS = 'documents',
  GEOTAGS = 'geotags',
  IMAGE_DOCS = 'imagedocs',
  KEYFRAMES = 'keyframes',
  MANUFACTURERS = 'manufacturers',
  MEASUREMENTS = 'measurements',
  MODELS3D = 'models3d',
  MODS = 'mods',
  NOTES = 'notes',
  PATHS_TRAVELED = 'pathstraveled',
  PROJECTS = 'projects',
  REPORTS = 'reports',
  SCANNERS = 'scanners',
  SCANS = 'scans',
  SHIP_CLASSES = 'shipclasses',
  SHIP_DESIGNATIONS = 'shipdesignations',
  SHIPS = 'ships',
  UNREAL_SERVERS = 'unrealservers',
  USERS = 'users',
  USER_STATUS_ACTIVITIES = 'userstatusactivities',
  VEHICLES = 'vehicles',
  VEHICLE_DESIGNATIONS = 'vehicledesignations',
  VEHICLE_MODELS = 'vehiclemodels',
  VEHICLE_PURPOSES = 'vehiclepurposes',
  VIDEOS = 'videos',
}
