export enum 
FileObjectTypesEnum {
  APP = 'app', 
  MOD = 'mod',
  MODEL3D = 'model3d',
  PROJECT = 'project',
  SHIP = 'ship',
  SITE = 'site',
  USER = 'user',
  VEHICLE = 'vehicle',
}
