import { Injectable, ResolvedReflectiveFactory } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of } from 'rxjs';

import { Note, User } from '@shared/models';
import { DbCollectionsEnum } from '@shared/enums';
import { getCustomHeaders } from '@shared/utils';

import { ErrorService, LogService, SettingsService, UserService } from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  private currentNoteSubject = new BehaviorSubject<Note>(null);
  private currentProjectNotesSubject = new BehaviorSubject<Note[]>(null);
  private currentShipNotesSubject = new BehaviorSubject<Note[]>(null);
  private currentVehicleNotesSubject = new BehaviorSubject<Note[]>(null);
  private noteErrorSubject = new BehaviorSubject<string>(null);
  currentNote$: Observable<Note> = this.currentNoteSubject.asObservable();
  currentProjectNotes$: Observable<Note[]> = this.currentProjectNotesSubject.asObservable();
  currentShipNotes$: Observable<Note[]> = this.currentShipNotesSubject.asObservable();
  currentVehicleNotes$: Observable<Note[]> = this.currentVehicleNotesSubject.asObservable();
  noteError$: Observable<string> = this.noteErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) {}

  async createNewNote(payload, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if(currentUser && parentCollection && parentId && payload) {
        const url = `${environment.baseAPIUrl}note/create?userId=${currentUser?._id}`;
        let notes: Note[];
        let returnValue: Note;
        let isValidParentCollection = true;
  
        switch (parentCollection) {
          case DbCollectionsEnum.PROJECTS:
            notes = _this.currentProjectNotesSubject.getValue();
            break;
          case DbCollectionsEnum.SHIPS:
            notes = _this.currentShipNotesSubject.getValue();
            break;
          case DbCollectionsEnum.VEHICLES:
            notes = _this.currentVehicleNotesSubject.getValue();
            break;
          default:
            isValidParentCollection = false;
            break;
        }
  
        if (isValidParentCollection) {
          const newNotes = notes.slice(0);
  
          //set position as last note
          if (payload.reportSectionId) {
            const sectionNotes = notes.filter((note) => note.reportSectionId === payload.reportSectionId);
            payload.position = sectionNotes.length + 1;
          } else if (payload.imageId) {
            const imageNotes = notes.filter((note) => note.imageId === payload.imageId);
            payload.position = imageNotes.length + 1;
          } else {
            payload.position = 1;
          }
  
          _this.httpClient
            .post(url, payload, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (newNote: Note) => {
                returnValue = newNote;
                newNotes.push(newNote);
  
                switch (parentCollection) {
                  case DbCollectionsEnum.PROJECTS:
                    _this.currentProjectNotesSubject.next(newNotes);
                    break;
                  case DbCollectionsEnum.SHIPS:
                    _this.currentShipNotesSubject.next(newNotes);
                    break;
                  case DbCollectionsEnum.VEHICLES:
                    _this.currentVehicleNotesSubject.next(newNotes);
                    break;
                }
  
                resolve(returnValue);
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.noteErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {
  
              }
            });
        } else {
          const errMessage = `${parentCollection} is not a valid note parent collection`;
          _this.errorService.handleError(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `payload, parentCollection, parentId and user are required to note`;
        _this.errorService.handleError(errMessage);
        _this.noteErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async deleteNote(noteId: string, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && noteId && parentCollection && parentId) {
        let returnValue: Note[];
        let isValidParentCollection = true;
  
        switch (parentCollection) {
          case DbCollectionsEnum.PROJECTS:
          case DbCollectionsEnum.SHIPS:
          case DbCollectionsEnum.VEHICLES:
            isValidParentCollection = true;
            break;
          default:
            isValidParentCollection = false;
            break;
        }
  
        if (isValidParentCollection) {
          if (noteId && parentId && currentUser) {
            const url = `${environment.baseAPIUrl}note/${noteId}?userId=${currentUser._id}`;
  
            _this.httpClient
              .delete(url, {
                headers: getCustomHeaders(true),
                responseType: 'json',
              })
              .subscribe({
                next: (deletedNote: Note) => {
                  console.log(`successfully deleted noteId ${noteId}`);
  
                  _this.refreshNotesByParent(parentCollection, parentId, currentUser)
                    .then((notes) => {
                      returnValue = notes;
                    })
                    .catch((notesError) => {
                      _this.noteErrorSubject.next(notesError.message);
                    })
                    .finally(() => {
                      switch (parentCollection) {
                        case DbCollectionsEnum.PROJECTS:
                          _this.currentProjectNotesSubject.next(returnValue);
                          break;
                        case DbCollectionsEnum.SHIPS:
                          _this.currentShipNotesSubject.next(returnValue);
                          break;
                        case DbCollectionsEnum.VEHICLES:
                          _this.currentVehicleNotesSubject.next(returnValue);
                          break;
                      }
  
                      resolve(returnValue);
                    });
                },
                error: (error: HttpErrorResponse) => {
                  const errMessage = _this.errorService.handleError(error);
                  _this.noteErrorSubject.next(errMessage);
                  reject(error);
                },
                complete: () => {
  
                }
              });
          } else {
            const errMessage = `noteId, parent and userId are required to delete a note`;
            _this.errorService.handleError(errMessage);
            _this.noteErrorSubject.next(errMessage);
            reject(errMessage);
          }
        } else {
          const errMessage = `${parentCollection} is not a valid note parent collection`;
          _this.errorService.handleError(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `noteId, parentCollection, parentId and user are required to delete a note`;
        _this.errorService.handleError(errMessage);
        _this.noteErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getCurrentNote() {
    return this.currentNoteSubject.getValue();
  }

  getCurrentProjectNotes() {
    return this.currentProjectNotesSubject.getValue();
  }

  getCurrentShipNotes() {
    return this.currentShipNotesSubject.getValue();
  }

  getCurrentVehicleNotes() {
    return this.currentVehicleNotesSubject.getValue();
  }

  async refreshNotesByParent(parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (parentCollection) {
        let returnValue: Note[];
        let isValidParentCollection = true;
  
        switch (parentCollection) {
          case DbCollectionsEnum.PROJECTS:
          case DbCollectionsEnum.SHIPS:
          case DbCollectionsEnum.VEHICLES:
            break;
          default:
            isValidParentCollection = false;
            break;
        }
  
        if (isValidParentCollection) {
          if (currentUser && parentId) {
            const url = `${environment.baseAPIUrl}note/${parentCollection}/${parentId}?userId=${currentUser?._id}`;
  
            _this.httpClient
              .get(url, {
                headers: getCustomHeaders(true),
                responseType: 'json',
              })
              .subscribe({
                next: (notes: Note[]) => {
                  returnValue = notes;
  
                  switch (parentCollection) {
                    case DbCollectionsEnum.PROJECTS:
                      _this.currentProjectNotesSubject.next(returnValue);
                      break;
                    case DbCollectionsEnum.SHIPS:
                      _this.currentShipNotesSubject.next(returnValue);
                      break;
                    case DbCollectionsEnum.VEHICLES:
                      _this.currentVehicleNotesSubject.next(returnValue);
                      break;
                  }
  
                  resolve(returnValue);
                },
                error: (error: HttpErrorResponse) => {
                  const errMessage = _this.errorService.handleError(error);
                  _this.noteErrorSubject.next(errMessage);
                  reject(errMessage);
                },
                complete: () => {
  
                }
              });
          } else {
            switch (parentCollection) {
              case DbCollectionsEnum.PROJECTS:
                _this.currentProjectNotesSubject.next(returnValue);
                break;
              case DbCollectionsEnum.SHIPS:
                _this.currentShipNotesSubject.next(returnValue);
                break;
              case DbCollectionsEnum.VEHICLES:
                _this.currentVehicleNotesSubject.next(returnValue);
                break;
            }
  
            resolve(returnValue);
          }
        } else {
          const errMessage = _this.errorService.handleError(`${parentCollection} is not a valid note parent collection`);
          _this.noteErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `parentCollection is required to refresh notes by parent`;
        _this.errorService.handleError(errMessage);
        _this.noteErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveNote(noteId: string, parentCollection: string, parentId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && noteId && parentCollection && parentId) {
        let notes: Note[];
        let returnValue: Note;
        let isValidParentCollection = true;
  
        switch (parentCollection) {
          case DbCollectionsEnum.PROJECTS:
            notes = _this.currentProjectNotesSubject.getValue();
            break;
          case DbCollectionsEnum.SHIPS:
            notes = _this.currentShipNotesSubject.getValue();
            break;
          case DbCollectionsEnum.VEHICLES:
            notes = _this.currentVehicleNotesSubject.getValue();
            break;
          default:
            isValidParentCollection = false;
            break;
        }
  
        if (isValidParentCollection) {
          const noteIndex = notes.findIndex((note) => note._id === noteId);
          const newNotes = notes.slice(0);
          const url = `${environment.baseAPIUrl}note/${noteId}?userId=${currentUser?._id}`;
  
          _this.httpClient
            .put(url, changes, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (updatedNote: Note) => {
                returnValue = updatedNote;
  
                newNotes[noteIndex] = {
                  ...notes[noteIndex],
                  ...returnValue,
                };
  
                switch (parentCollection) {
                  case DbCollectionsEnum.PROJECTS:
                    _this.currentProjectNotesSubject.next(newNotes);
                    break;
                  case DbCollectionsEnum.SHIPS:
                    _this.currentShipNotesSubject.next(newNotes);
                    break;
                  case DbCollectionsEnum.VEHICLES:
                    _this.currentVehicleNotesSubject.next(newNotes);
                    break;
                }
  
                resolve(returnValue);
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.noteErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {
  
              }
            });
        } else {
          const errMessage = _this.errorService.handleError(`${parentCollection} is not a valid note parent collection`);
          _this.errorService.handleError(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `changes, noteId, parentCollection, parentId and user are required to update a note`;
        _this.errorService.handleError(errMessage);
        _this.noteErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }
}
