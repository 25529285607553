import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';

import { ShipClass, User } from '@shared/models';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import {
  ErrorService,
  LogService,
  SettingsService,
  ShipDesignationService,
  UserService
} from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class ShipClassService {
  private currentShipClassSubject = new BehaviorSubject<ShipClass>(null);
  private shipClassesSubject = new BehaviorSubject<ShipClass[]>([]);
  private shipClassErrorSubject = new BehaviorSubject<string>(null);
  currentShipClass$: Observable<ShipClass> = this.currentShipClassSubject.asObservable();
  shipClasses$: Observable<ShipClass[]> = this.shipClassesSubject.asObservable();
  shipClassError$: Observable<string> = this.shipClassErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private shipDesignationService: ShipDesignationService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) {
  }

  async createNewShipClass(payload: ShipClass, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && payload) {
        _this.settingsService.setIsLoading(true);
        const shipClasses = _this.shipClassesSubject.getValue();
        const newShipClasses = shipClasses.slice(0);
        const url = `${environment.baseAPIUrl}shipClass/create?userId=${currentUser?._id}`;
        let returnValue: ShipClass;

        _this.httpClient
          .post(url, payload, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (newShipClass: ShipClass) => {
              returnValue = newShipClass;
              _this.currentShipClassSubject.next(newShipClass);
              newShipClasses.push(newShipClass);
              _this.shipClassesSubject.next(newShipClasses);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.shipClassErrorSubject.next(errMessage);
              _this.currentShipClassSubject.next(null);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        const errMessage = `payload and user are required to create a ship class`;
        _this.errorService.handleError(errMessage);
        _this.shipClassErrorSubject.next(errMessage);
        reject(errMessage)
      }
    });
  }

  async saveShipClass(shipClassId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && shipClassId) {
        _this.settingsService.setIsLoading(true);
        const shipClasses = _this.shipClassesSubject.getValue();
        const shipClassIndex = shipClasses.findIndex((shipClass) => shipClass._id === shipClassId);
        const newShipClasses = shipClasses.slice(0);
        const url = `${environment.baseAPIUrl}shipClass/${shipClassId}?userId=${currentUser?._id}`;
        let returnValue: ShipClass;

        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedShipClass: ShipClass) => {
              returnValue = updatedShipClass;
              _this.currentShipClassSubject.next(updatedShipClass);
              newShipClasses[shipClassIndex] = updatedShipClass;
              _this.shipClassesSubject.next(newShipClasses);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.shipClassErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        const errMessage = `changes, shipClassId and user are required to update a ship class`;
        _this.errorService.handleError(errMessage);
        _this.shipClassErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getShipClasses(currentUser: User): Observable<ShipClass[]> {
    const shipClassesHttp$ = createHttpObservable(`${environment.baseAPIUrl}shipClass?userId=${currentUser?._id}`, {}, true);

    shipClassesHttp$
      .pipe(
        catchError((err) => {
          console.error(`Error getting ship classes: ${err}`);
          return of([]);
        })
      )
      .subscribe((shipClasses: ShipClass[]) => {
        this.shipClassesSubject.next(shipClasses);
      });

    return shipClassesHttp$;
  }

  getCurrentShipClass() {
    return this.currentShipClassSubject.getValue();
  }

  getShipClassById(shipClassId: string, currentUser: User): any {
    if (currentUser && shipClassId) {
      return this.shipClasses$
        .pipe(
          map((shipClasses) => shipClasses.find((shipClass) => shipClass._id === shipClassId)),
          filter((shipClass) => !!shipClass)
        )
        .subscribe((shipClass: ShipClass) => {
          this.currentShipClassSubject.next(shipClass);
          this.shipDesignationService.refreshShipDesignationsByShipClass(shipClass ? shipClass._id : null);
        });
    } else {
      this.currentShipClassSubject.next(null);
    }
  }
}
