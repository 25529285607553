<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser">
  <h2 mat-dialog-title>{{ formTitle }}</h2>

  <mat-dialog-content>
    <form [formGroup]="form">
      <mat-horizontal-stepper [linear]="true" #stepper>
        <mat-step [stepControl]="scanForm">
          <ng-template matStepLabel>Scan Overview</ng-template>
          <div [formGroup]="scanForm">
            <input type="text" formControlName="creatorId" hidden ng-disabled="true" />
            <mat-form-field appearance="fill">
              <mat-label>Scan Name</mat-label>
              <input matInput placeholder="Scan Name" formControlName="name" />
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill">
              <mat-label>Organization</mat-label>
              <mat-select formControlName="dataSourceId">
                <mat-option *ngFor="let dataSource of dataSources$ | async as dataSources" [value]="dataSource._id">
                  {{ dataSource.name }}
                </mat-option>
              </mat-select>
              <button
                matSuffix
                mat-icon-button
                aria-label="Add Scan Data Source"
                matTooltip="Add Scan Data Source"
                *ngIf="isAdmin"
                (click)="addDataSource()"
              >
                <mat-icon>add</mat-icon>
              </button>
            </mat-form-field>

            <table style="width: 100%">
              <tr>
                <td style="width: 50%">
                  <mat-form-field>
                    <mat-label>Scan Date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="scanDate" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </td>
                <td style="width: 50%">
                  <mat-form-field>
                    <input
                      matInput
                      type="text"
                      placeholder="Name of Person Who Scanned"
                      formControlName="nameOfPersonScanning"
                    />
                  </mat-form-field>
                </td>
              </tr>
            </table>

            <br>

            <mat-radio-group name="classification" formControlName="classification"
              >Scan Classification:&nbsp;&nbsp;
              <mat-radio-button value="Unclass">Unclass</mat-radio-button>
              <!-- per Ivan, onlyb allow Unclass for now, jane 4/7/20201
                            <mat-radio-button value="FOUO">FOUO</mat-radio-button>
                            <mat-radio-button value="Secret">Secret</mat-radio-button>
                            <mat-radio-button value="TopSecret">Top Secret</mat-radio-button> -->
            </mat-radio-group>

            <br><br>

            <mat-form-field>
              <mat-label>Scan Uploader</mat-label>
              <input matInput placeholder="SCAN DATA UPLOADER" formControlName="scanUploader" ng-disabled="true" />
            </mat-form-field>

            <br>

            <mat-form-field>
              <textarea matInput rows="2" placeholder="Scan Description" formControlName="description"> </textarea>
            </mat-form-field>

            <br>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="close()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                color="primary"
                aria-label="Next step"
                matTooltip="Next step"
                *ngIf="scanForm.valid"
                matStepperNext
              >
                Next
              </button>
            </div>

            <div *ngIf="isDebugging$ | async">
              <pre>_id: {{ scanForm.get('_id').valid }}</pre>
              <pre>editorId: {{ scanForm.get('editorId').valid }}</pre>
              <pre>name: {{ scanForm.get('name').valid }}</pre>
              <pre>creatorId: {{ scanForm.get('creatorId').valid }}</pre>
              <pre>classification: {{ scanForm.get('classification').valid }}</pre>
              <pre>scanDate: {{ scanForm.get('scanDate').valid }}</pre>
              <pre>dataSourceId: {{ scanForm.get('dataSourceId').valid }}</pre>
              <pre>description: {{ scanForm.get('description').valid }}</pre>
              <pre>nameOfPersonScanning: {{ scanForm.get('nameOfPersonScanning').valid }}</pre>
              <pre>scanUploader: {{ scanForm.get('scanUploader').valid }}</pre>
              <pre>modId: {{ scanForm.get('modId').valid }}</pre>
              <pre>parent: {{ scanForm.get('parent').valid }}</pre>
            </div>
          </div>
        </mat-step>

        <mat-step [stepControl]="scannerForm" errorMessage="Scanner info is required.">
          <ng-template matStepLabel>Scanner Info</ng-template>
          <div [formGroup]="scannerForm">
            <mat-form-field appearance="fill">
              <mat-label>Scanner Manufacturer</mat-label>
              <mat-select formControlName="manufacturerId" (selectionChange)="onManufacturerChange(currentUser)">
                <mat-option
                  *ngFor="let manufacturer of manufacturers$ | async as manufacturers"
                  [value]="manufacturer._id"
                >
                  {{ manufacturer.name }}
                </mat-option>
              </mat-select>
              <button
                matSuffix
                mat-icon-button
                aria-label="Add Scanner Manufacturer"
                matTooltip="Add Scanner Manufacturer"
                *ngIf="isAdmin"
                (click)="addManufacturer()"
              >
                <mat-icon>add</mat-icon>
              </button>
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill">
              <mat-label>Scanner</mat-label>
              <mat-select formControlName="scannerId">
                <mat-option *ngFor="let scanner of scanners$ | async as scanners" [value]="scanner._id">
                  {{ scanner.name }} | {{ scanner.modelNumber }}
                </mat-option>
              </mat-select>
              <button
                matSuffix
                mat-icon-button
                aria-label="Add Scanner"
                matTooltip="Add Scanner"
                *ngIf="isAdmin"
                (click)="addScanner()"
              >
                <mat-icon>add</mat-icon>
              </button>
            </mat-form-field>

            <br>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="close()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Back to previous step"
                matTooltip="Back to previous step"
                matStepperPrevious
              >
                Back
              </button>
              <button
                mat-raised-button
                color="primary"
                aria-label="Next step"
                matTooltip="Next step"
                *ngIf="scannerForm.valid"
                matStepperNext
              >
                Next
              </button>
            </div>

            <div *ngIf="isDebugging$ | async">
              <pre>manufacturerId: {{ scannerForm.get('manufacturerId').valid }}</pre>
              <pre>scannerId: {{ scannerForm.get('scannerId').valid }}</pre>
            </div>
          </div>
        </mat-step>

        <mat-step [stepControl]="scanDetailsForm" errorMessage="Scan details are required.">
          <ng-template matStepLabel>Scan Details</ng-template>
          <div [formGroup]="scanDetailsForm">
            <mat-radio-group aria-label="Scan Type" name="scanType" formControlName="scanType"
              >Scan Type:&nbsp;&nbsp;
              <mat-radio-button value="Cleaned">Cleaned</mat-radio-button>
              <mat-radio-button value="Raw">Raw</mat-radio-button>
              <mat-radio-button value="Registered">Registered</mat-radio-button>
            </mat-radio-group>

            <br>

            <table style="width: 100%">
              <tr>
                <div [formGroup]="registrationErrorForm">
                  <td style="width: 15%">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        pattern="[0-9]*"
                        placeholder="Registration Error"
                        formControlName="value"
                      />
                    </mat-form-field>
                  </td>
                  <td style="width: 30%">
                    <mat-radio-group
                      aria-label="Scan Registration Error Units"
                      name="registrationErrorUnits"
                      formControlName="units"
                      >Units:&nbsp;&nbsp;
                      <mat-radio-button value="mm" readonly>mm</mat-radio-button>
                    </mat-radio-group>
                  </td>
                </div>
                <div [formGroup]="decimationLevelForm">
                  <td style="width: 15%">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        pattern="[0-9]*"
                        placeholder="Decimation Level"
                        formControlName="value"
                      />
                    </mat-form-field>
                  </td>
                  <td style="width: 30%">
                    <mat-radio-group
                      aria-label="Scan Decimation Level Units"
                      name="decimationLevelUnits"
                      formControlName="units"
                      >Units:&nbsp;&nbsp;
                      <mat-radio-button value="mm" readonly>mm</mat-radio-button>
                    </mat-radio-group>
                  </td>
                </div>
              </tr>
            </table>

            <br>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="close()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Back to previous step"
                matTooltip="Back to previous step"
                matStepperPrevious
              >
                Back
              </button>
              <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" matStepperNext>
                Next
              </button>
            </div>

            <div *ngIf="isDebugging$ | async">
              <pre>scanType: {{ scanDetailsForm.get('scanType').valid }}</pre>
              <pre>registrationErrorValue: {{ registrationErrorForm.get('value').valid }}</pre>
              <pre>registrationErrorUnits: {{ registrationErrorForm.get('units').valid }}</pre>
              <pre>decimationLevelValue: {{ decimationLevelForm.get('value').valid }}</pre>
              <pre>decimationLevelUnits: {{ decimationLevelForm.get('units').valid }}</pre>
            </div>
          </div>
        </mat-step>

        <mat-step [stepControl]="fileForm" *ngIf="isNewScan">
          <ng-template matStepLabel>Scan Point Cloud File</ng-template>
          <div [formGroup]="fileForm" *ngIf="isNewScan">
            <div>
              <span>
                <button (click)="scanFileInput.click()" [disabled]="scanForm.invalid">
                  <mat-icon>library_add</mat-icon>
                  <span>Select Scan File ({{ pointCloudFileExtensions }})</span>
                  <input
                    #scanFileInput
                    type="file"
                    (change)="scanFileInputChange($event)"
                    accept="{{ pointCloudFileExtensions }}"
                    style="display: none"
                  />
                </button>

                <br><br>

                <input
                  type="text"
                  formControlName="scanFileName"
                  readonly
                  *ngIf="hasScanFileName"
                  class="fileNameConfirmationInput"
                />
              </span>

              <br><br>

              <mat-checkbox class="example-margin" 
              formControlName="hasPanos"
              (change)="setHasPanos($event.checked)">Has Panoramic Images?</mat-checkbox>

              <span *ngIf="hasPanos">
                <br><br>
                <mat-checkbox class="example-margin" *ngIf="canUsePCFileForPanos"
                formControlName="usePointCloudFileForPanos"
                (change)="setUsePointCloudFileForPanos($event.checked)">Use Point Cloud Scan for Pano?</mat-checkbox>
                
                <br><br>

                <button (click)="panoFileInput.click()" [disabled]="scanForm.invalid" *ngIf="!usePointCloudFileForPanos">
                  <mat-icon>library_add</mat-icon>
                  <span>Select Panoramic File ({{ panoCloudFileExtensions }})</span>
                  <input
                    #panoFileInput
                    type="file"
                    (change)="panoFileInputChange($event)"
                    accept="{{ panoCloudFileExtensions }}"
                    style="display: none"
                  />
                </button>

                <br><br>

                <input
                  type="text"
                  formControlName="panoFileName"
                  readonly
                  *ngIf="hasPanoFileName"
                  class="fileNameConfirmationInput"
                />
              </span>
            </div>
            <br>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="close()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Back to previous step"
                matTooltip="Back to previous step"
                matStepperPrevious
              >
                Back
              </button>
              <button
                mat-raised-button
                color="primary"
                aria-label="Next step"
                matTooltip="Next step"
                matStepperNext
                *ngIf="fileForm.valid"
              >
                Next
              </button>
            </div>
          </div>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          <div class="button-row">
            <button
              mat-raised-button
              aria-label="Close without saving"
              matTooltip="Close without saving"
              (click)="close()"
            >
              Cancel
            </button>
            <button
              mat-raised-button
              color="primary"
              aria-label="Save and close"
              matTooltip="Save and close"
              *ngIf="getShowScanSaveButton(currentUser.role)"
              color="primary"
              (click)="saveScanChanges(currentUser)"
            >
              Save
            </button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </mat-dialog-content>
</div>
