<div class="block-container-with-toolbar" *ngIf="currentUser$ | async as currentUser">
  <ng-container>
    <div *ngIf="isAdmin">
      <div *ngIf="shipPointCloudModsInProcessing$ | async as shipPointCloudModsInProcessing">
        <h2>SHIPS THAT NEED PROCESSING</h2>
        <mat-divider></mat-divider>
        <app-mod-table
          [mods]="shipPointCloudModsInProcessing"
          [isVehicleMods]="false"
          [isModsNeedingAdjustment]="false"
          [showPaginator]="true"
          [showFilter]="true"
        ></app-mod-table>
      </div>
      <br><br><br>
      <div *ngIf="vehicleModsInProcessing$ | async as vehicleModsInProcessing">
        <h2>VEHICLES THAT NEED PROCESSING</h2>
        <mat-divider></mat-divider>
        <app-mod-table
          [mods]="vehicleModsInProcessing"
          [isVehicleMods]="true"
          [isModsNeedingAdjustment]="false"
          [showPaginator]="true"
          [showFilter]="true"
        ></app-mod-table>
      </div>
      <br><br><br>
      <div *ngIf="vehicleModsNeedingAdjustment$ | async as vehicleModsNeedingAdjustment">
        <h2>VEHICLES THAT NEED CALIBRATION</h2>
        <mat-divider></mat-divider>
        <app-mod-table
          [mods]="vehicleModsNeedingAdjustment"
          [isVehicleMods]="true"
          [isModsNeedingAdjustment]="true"
          [showPaginator]="true"
          [showFilter]="true"
        ></app-mod-table>
      </div>
    </div>
  </ng-container>
</div>
