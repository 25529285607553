import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, from, throwError, of, zip } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';

import {
  DataSource,
  Document,
  ImageDoc,
  Manufacturer,
  Mod,
  Model3d,
  Note,
  Scan,
  Scanner,
  User,
  Vehicle,
  VehicleDesignation,
  VehicleModel,
  VehiclePurpose,
  Video
} from '@shared/models';
import { DbCollectionsEnum, ModStatesEnum } from '@shared/enums';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import {
  DataSourceService,
  DocumentService,
  ErrorService,
  ImageDocService,
  LogService,
  ManufacturerService,
  Model3dService,
  ModService,
  NoteService,
  ScannerService,
  ScanService,
  SettingsService,
  UnrealServerService,
  UserService,
  VehicleDesignationService,
  VehicleModelService,
  VehiclePurposeService,
  VideoService
} from '@shared/services';

import { environment } from '@environment';

const ObjectID = require('bson-objectid');

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  private allowVehicleModels3dSubject = new BehaviorSubject<boolean>(null);
  private allowVehicleScansSubject = new BehaviorSubject<boolean>(null);
  private currentVehicleSubject = new BehaviorSubject<Vehicle>(null);
  private currentVehicleDesignationSubject = new BehaviorSubject<VehicleDesignation>(null);
  private currentVehicleImagesSubject = new BehaviorSubject<ImageDoc[]>(null);
  private currentVehicleModelSubject = new BehaviorSubject<VehicleModel>(null);
  private currentVehicleModel3dSubject = new BehaviorSubject<Model3d>(null);
  private currentVehicleModels3dSubject = new BehaviorSubject<Model3d[]>(null);
  private currentVehicleModels3dWithModsSubject = new BehaviorSubject<Model3d[]>(null);
  private currentVehicleModels3dWithValidModsSubject = new BehaviorSubject<Model3d[]>(null);
  private currentVehicleNotesSubject = new BehaviorSubject<Note[]>(null);
  private currentVehiclePurposeSubject = new BehaviorSubject<VehiclePurpose>(null);
  private currentVehicleScanSubject = new BehaviorSubject<Scan>(null);
  private currentVehicleScansSubject = new BehaviorSubject<Scan[]>(null);
  private currentVehicleScansWithModsSubject = new BehaviorSubject<Scan[]>(null);
  private currentVehicleScansWithValidModsSubject = new BehaviorSubject<Scan[]>(null);
  private currentVehicleVideosSubject = new BehaviorSubject<Video[]>(null);
  private dataSourcesSubject = new BehaviorSubject<DataSource[]>(null);
  private documentsSubject = new BehaviorSubject<Document[]>(null);
  private manufacturersSubject = new BehaviorSubject<Manufacturer[]>(null);
  private models3dSubject = new BehaviorSubject<Model3d[]>(null);
  private modsSubject = new BehaviorSubject<Mod[]>(null);
  private scannersSubject = new BehaviorSubject<Scanner[]>(null);
  private scansSubject = new BehaviorSubject<Scan[]>(null);
  private usersSubject = new BehaviorSubject<User[]>(null);
  private vehicleDesignationsSubject = new BehaviorSubject<VehicleDesignation[]>(null);
  private vehicleModelsSubject = new BehaviorSubject<VehicleModel[]>(null);
  private vehiclePurposesSubject = new BehaviorSubject<VehiclePurpose[]>(null);
  private vehiclesSubject = new BehaviorSubject<Vehicle[]>([]);
  private vehiclesWithModsSubject = new BehaviorSubject<Vehicle[]>(null);
  private vehiclesWithValidModsSubject = new BehaviorSubject<Vehicle[]>(null);
  private vehicleErrorSubject = new BehaviorSubject<string>(null);
  allowVehicleModel3ds$: Observable<boolean> = this.allowVehicleModels3dSubject.asObservable();
  allowVehicleScans$: Observable<boolean> = this.allowVehicleScansSubject.asObservable();
  currentVehicle$: Observable<Vehicle> = this.currentVehicleSubject.asObservable();
  currentVehicleDesignation$: Observable<VehicleDesignation> = this.currentVehicleDesignationSubject.asObservable();
  currentVehicleImages$: Observable<ImageDoc[]> = this.currentVehicleImagesSubject.asObservable();
  currentVehicleModel$: Observable<VehicleModel> = this.currentVehicleModelSubject.asObservable();
  currentVehicleModel3d$: Observable<Model3d> = this.currentVehicleModel3dSubject.asObservable();
  currentVehicleModels3d$: Observable<Model3d[]> = this.currentVehicleModels3dSubject.asObservable();
  currentVehicleModels3dWithMods$: Observable<Model3d[]> = this.currentVehicleModels3dWithModsSubject.asObservable();
  currentVehicleModels3dWithValidMods$: Observable<Model3d[]> = this.currentVehicleModels3dWithValidModsSubject.asObservable();
  currentVehicleNotes$: Observable<Note[]> = this.currentVehicleNotesSubject.asObservable();
  currentVehiclePurpose$: Observable<VehiclePurpose> = this.currentVehiclePurposeSubject.asObservable();
  currentVehicleScan$: Observable<Scan> = this.currentVehicleScanSubject.asObservable();
  currentVehicleScans$: Observable<Scan[]> = this.currentVehicleScansSubject.asObservable();
  currentVehicleScansWithMods$: Observable<Scan[]> = this.currentVehicleScansWithModsSubject.asObservable();
  currentVehicleScansWithValidMods$: Observable<Scan[]> = this.currentVehicleScansWithValidModsSubject.asObservable();
  currentVehicleVideos$: Observable<Video[]> = this.currentVehicleVideosSubject.asObservable();
  dataSources$: Observable<DataSource[]> = this.dataSourcesSubject.asObservable();
  documents$: Observable<Document[]> = this.documentsSubject.asObservable();
  manufacturers$: Observable<Manufacturer[]> = this.manufacturersSubject.asObservable();
  models3d$: Observable<Model3d[]> = this.models3dSubject.asObservable();
  mods$: Observable<Mod[]> = this.modsSubject.asObservable();
  scanners$: Observable<Scanner[]> = this.scannersSubject.asObservable();
  scans$: Observable<Scan[]> = this.scansSubject.asObservable();
  users$: Observable<User[]> = this.usersSubject.asObservable();
  vehicleDesignations$: Observable<VehicleDesignation[]> = this.vehicleDesignationsSubject.asObservable();
  vehicleModels$: Observable<VehicleModel[]> = this.vehicleModelsSubject.asObservable();
  vehiclePurposes$: Observable<VehiclePurpose[]> = this.vehiclePurposesSubject.asObservable();
  vehicles$: Observable<Vehicle[]> = this.vehiclesSubject.asObservable();
  vehiclesWithMods$: Observable<Vehicle[]> = this.vehiclesWithModsSubject.asObservable();
  vehiclesWithValidMods$: Observable<Vehicle[]> = this.vehiclesWithValidModsSubject.asObservable();
  vehicleError$: Observable<string> = this.vehicleErrorSubject.asObservable();

  constructor(
    private dataSourceService: DataSourceService,
    private documentService: DocumentService,
    private errorService: ErrorService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private manufacturerService: ManufacturerService,
    private model3dService: Model3dService,
    private modService: ModService,
    private noteService: NoteService,
    private scannerService: ScannerService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private vehicleDesignationService: VehicleDesignationService,
    private vehicleModelService: VehicleModelService,
    private vehiclePurposeService: VehiclePurposeService,
    private videoService: VideoService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.allowVehicleModels3dSubject.next(
      this.settingsService.getIsVehicleModSourceAllowed(DbCollectionsEnum.MODELS3D)
    );
    this.allowVehicleScansSubject.next(this.settingsService.getIsVehicleModSourceAllowed(DbCollectionsEnum.SCANS));
    this.currentVehicleImages$ = this.imageDocService.currentVehicleImages$;
    this.currentVehicleModels3d$ = this.model3dService.currentVehicleModels3d$;
    this.currentVehicleModel3d$ = this.model3dService.currentVehicleModel3d$;
    this.currentVehicleNotes$ = this.noteService.currentVehicleNotes$;
    this.currentVehicleScans$ = this.scanService.currentVehicleScans$;
    this.currentVehicleScan$ = this.scanService.currentVehicleScan$;
    this.currentVehicleVideos$ = this.videoService.currentVehicleVideos$;
    this.dataSources$ = this.dataSourceService.dataSources$;
    this.documents$ = this.documentService.currentVehicleDocuments$;
    this.manufacturers$ = this.manufacturerService.manufacturers$;
    this.models3d$ = this.model3dService.models3d$;
    this.mods$ = this.modService.mods$;
    this.scanners$ = this.scannerService.scanners$;
    this.scans$ = this.scanService.scans$;
    this.users$ = this.userService.users$;
    this.vehicleDesignations$ = this.vehicleDesignationService.vehicleDesignations$;
    this.vehicleModels$ = this.vehicleModelService.vehicleModels$;
    this.vehiclePurposes$ = this.vehiclePurposeService.vehiclePurposes$;

    combineLatest([this.currentVehicleModels3d$, this.currentVehicleScans$, this.mods$]).subscribe(
      ([models3d, scans, mods]) => {
        let models3dWithMods: Model3d[];
        let models3dWithValidMods: Model3d[];
        let scansWithMods: Scan[];
        let scansWithValidMods: Scan[];

        if (models3d && models3d.length > 0) {
          models3dWithMods = models3d.filter((model3d) => model3d.modDetails && model3d.modDetails.url != null);
          models3dWithValidMods = models3d.filter((model3d) => model3d.modDetails && model3d.modDetails.modState === ModStatesEnum.VALID);
        }

        if (scans && scans.length > 0) {
          scansWithMods = scans.filter((scan) => scan.modDetails && scan.modDetails.url != null);
          scansWithValidMods = scans.filter((scan) => scan.modDetails && scan.modDetails.modState === ModStatesEnum.VALID);
        }

        this.currentVehicleModels3dWithModsSubject.next(models3dWithMods);
        this.currentVehicleModels3dWithValidModsSubject.next(models3dWithValidMods);
        this.currentVehicleScansWithModsSubject.next(scansWithMods);
        this.currentVehicleScansWithValidModsSubject.next(scansWithValidMods);
      }
    );
   }
   
  async addDocument(doc: Document, vehicle: Vehicle, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const vehicles = _this.vehiclesSubject.getValue();
      let returnValue: Document;

      if (currentUser && doc && vehicle && vehicles) {
        _this.documentService
          .createNewDocument(doc, DbCollectionsEnum.VEHICLES, vehicle._id, currentUser)
          .then((newDoc: Document) => {
            returnValue = newDoc;
            resolve(returnValue);
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(`Error creating new vehicle document: ${error.error}`);
            _this.vehicleErrorSubject.next(errMessage);
            reject(errMessage);
          })
          .finally(() => {
            _this.settingsService.setIsLoading(false);
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(
          `A Document object is required to add a new document to the current vehicle`
        );
        _this.vehicleErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async addImage(imageDoc: ImageDoc, vehicle: Vehicle, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && imageDoc && vehicle) {
        _this.settingsService.setIsLoading(true);
        const vehicles = _this.vehiclesSubject.getValue();
        let returnValue: ImageDoc;
  
        if (vehicles) {
          //keep the array consistent, it will be refreshed in the api automatically
          const vehicleIndex = vehicles.findIndex((v) => v._id === vehicle._id);
          const newVehicles = vehicles.slice(0);
  
          _this.imageDocService
            .createNewImageDoc(imageDoc, DbCollectionsEnum.VEHICLES, vehicle._id, currentUser)
            .then((newImage: ImageDoc) => {
              returnValue = newImage;
              newVehicles[vehicleIndex].editorId = currentUser._id;
              newVehicles[vehicleIndex].updatedAt = new Date();
              _this.vehiclesSubject.next(newVehicles);
              resolve(returnValue);
            })
            .catch((error) => {
              const errMessage = _this.errorService.handleError(
                `Error adding image to vehicleId ${vehicle._id}: ${error.error}`
              );
              _this.vehicleErrorSubject.next(errMessage);
              reject(errMessage);
            })
            .finally(() => {
              _this.settingsService.setIsLoading(false);
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(
            `A Image Document object is required to add a new image to the vehicle`
          );
          _this.vehicleErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `image, vehicle and user are required to add an image to a vehicle`;
        _this.errorService.handleError(errMessage);
        _this.vehicleErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async addNote(note: Note, vehicle: Vehicle, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && note && vehicle) {
        _this.settingsService.setIsLoading(true);
        const vehicles = _this.vehiclesSubject.getValue();
        let returnValue: Note;
  
        if (vehicles) {
          //keep the array consistent, it will be refreshed in the api automatically
          const vehicleIndex = vehicles.findIndex((v) => v._id === vehicle._id);
          const newVehicles = vehicles.slice(0);
  
          _this.noteService
            .createNewNote(note, DbCollectionsEnum.VEHICLES, vehicle._id, currentUser)
            .then((newNote: Note) => {
              returnValue = newNote;
              newVehicles[vehicleIndex].editorId = currentUser._id;
              newVehicles[vehicleIndex].updatedAt = new Date();
              _this.vehiclesSubject.next(newVehicles);
              resolve(returnValue);
            })
            .catch((error) => {
              const errMessage = _this.errorService.handleError(
                `Error creating new note for vehicleId ${vehicle._id}: ${error.error}`
              );
              _this.vehicleErrorSubject.next(errMessage);
              reject(errMessage);
            })
            .finally(() => {
              _this.settingsService.setIsLoading(false);
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(
            `A Note object is required to add a new note to the current vehicle`
          );
          _this.vehicleErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `note, vehicle and user are required to add a note to a vehicle`;
        _this.errorService.handleError(errMessage);
        _this.vehicleErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async addVideo(video: Video, vehicle: Vehicle, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && vehicle && video) {
        _this.settingsService.setIsLoading(true);
        const vehicles = _this.vehiclesSubject.getValue();
        let returnValue: Video;
  
        if (vehicles) {
          //keep the array consistent, it will be refreshed in the api automatically
          const vehicleIndex = vehicles.findIndex((v) => v._id === vehicle._id);
          const newVehicles = vehicles.slice(0);
  
          _this.videoService
            .createNewVideo(video, DbCollectionsEnum.VEHICLES, vehicle._id, currentUser)
            .then((newVideo: Video) => {
              returnValue = newVideo;
              newVehicles[vehicleIndex].editorId = currentUser._id;
                newVehicles[vehicleIndex].updatedAt = new Date();
              _this.vehiclesSubject.next(newVehicles);
              resolve(returnValue);
            })
            .catch((error) => {
              const errMessage = _this.errorService.handleError(
                `Error creating video ${JSON.stringify(video)} for vehicleId ${vehicle._id}: ${error.error}`
              );
              _this.vehicleErrorSubject.next(errMessage);
              reject(errMessage);
            })
            .finally(() => {
              _this.settingsService.setIsLoading(false);
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(
            `A video object and a vehicle are required to add a new video to the vehicle`
          );
          _this.vehicleErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `vehicle, video and user are required to add a video to a vehicle`;
        _this.errorService.handleError(errMessage);
        _this.vehicleErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async createNewVehicle(payload: Vehicle, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && payload) {
        _this.settingsService.setIsLoading(true);
        const vehicles = _this.vehiclesSubject.getValue();
        const newVehicles = vehicles.slice(0);
        const url = `${environment.baseAPIUrl}vehicle/create?userId=${currentUser?._id}`;
        const promises = [];
        let returnValue: Vehicle;
  
        _this.httpClient
          .post(url, payload, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (newVehicle: Vehicle) => {
              returnValue = newVehicle;
              _this.currentVehicleSubject.next(newVehicle);
              newVehicles.push(newVehicle);
              _this.vehiclesSubject.next(newVehicles);
  
  
              promises.push(
                _this.documentService.refreshDocumentsByParent(
                  DbCollectionsEnum.VEHICLES,
                  newVehicle ? newVehicle._id : null,
                  currentUser
                )
              );
              promises.push(
                _this.imageDocService.refreshImagesByParent(
                  DbCollectionsEnum.VEHICLES,
                  newVehicle ? newVehicle._id : null,
                  false,
                  currentUser
                )
              );
              promises.push(
                _this.model3dService.refreshModels3dByParent(
                  DbCollectionsEnum.VEHICLES,
                  newVehicle ? newVehicle._id : null,
                  currentUser
                )
              );
              promises.push(
                _this.noteService.refreshNotesByParent(DbCollectionsEnum.VEHICLES, newVehicle ? newVehicle._id : null, currentUser)
              );
              promises.push(
                _this.scanService.refreshScansByParent(DbCollectionsEnum.VEHICLES, newVehicle ? newVehicle._id : null, currentUser)
              );
              promises.push(_this.updateChildData(newVehicle, currentUser));
  
              Promise.allSettled(promises).then((results) => {
                _this.settingsService.setIsLoading(false);
                resolve(returnValue);
              });
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(
                `Error creating new vehicle ${JSON.stringify(payload)}: ${error.error}`
              );
              _this.vehicleErrorSubject.next(errMessage);
              _this.currentVehicleSubject.next(null);
  
              promises.push(_this.documentService.refreshDocumentsByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
              promises.push(_this.imageDocService.refreshImagesByParent(DbCollectionsEnum.VEHICLES, null, false, currentUser));
              promises.push(_this.model3dService.refreshModels3dByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
              promises.push(_this.noteService.refreshNotesByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
              promises.push(_this.scanService.refreshScansByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
              promises.push(_this.updateChildData(null, currentUser));
  
              Promise.allSettled(promises).then((results) => {
                _this.settingsService.setIsLoading(false);
                reject(error);
              });
            },
            complete: () => {
  
            }
          });
      } else {
        const errMessage = `payload and user are required to create a vehicle`;
        this.errorService.handleError(errMessage);
        this.vehicleErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async deleteVehicle(vehicleId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const currentVehicle = _this.currentVehicleSubject.getValue();
      const vehicles = _this.vehiclesSubject.getValue();
      const promises = [];
      let returnValue: Vehicle[];

      if (currentUser && vehicles && currentVehicle) {
        const vehicleIndex = vehicles.findIndex((vehicle) => vehicle._id === currentVehicle._id);
        vehicles.splice(vehicleIndex, 1);
        const url = `${environment.baseAPIUrl}vehicle/${currentVehicle._id}?userId=${currentUser._id}`;

        _this.httpClient
          .delete(url, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedVehicle: Vehicle) => {
              _this.currentVehicleSubject.next(null);
              returnValue = vehicles;
              _this.vehiclesSubject.next(vehicles);

              promises.push(_this.imageDocService.refreshImagesByParent(DbCollectionsEnum.VEHICLES, null, false, currentUser));
              promises.push(_this.model3dService.refreshModels3dByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
              promises.push(_this.noteService.refreshNotesByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
              promises.push(_this.scanService.refreshScansByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
              promises.push(_this.updateChildData(null, currentUser));

              Promise.allSettled(promises).then((results) => {
                _this.settingsService.setIsLoading(false);
                resolve(returnValue);
              });
            },
            error: (error: HttpErrorResponse) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = _this.errorService.handleError(error);
              _this.vehicleErrorSubject.next(errMessage);
              reject(errMessage);
            },
            complete: () => {

            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(
          `A vehicle and user are required to delete the current vehicle`
        );
        _this.vehicleErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveVehicle(vehicleId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && vehicleId) {
        _this.settingsService.setIsLoading(true);
        const vehicles = _this.vehiclesSubject.getValue();
        const vehicleIndex = vehicles.findIndex((vehicle) => vehicle._id === vehicleId);
        const newVehicles = vehicles.slice(0);
        const url = `${environment.baseAPIUrl}vehicle/${vehicleId}?userId=${currentUser?._id}`;
        const promises = [];
        let returnValue: Vehicle;
  
        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedVehicle: Vehicle) => {
              returnValue = updatedVehicle;
              newVehicles[vehicleIndex] = updatedVehicle;
              _this.vehiclesSubject.next(newVehicles);
              _this.currentVehicleSubject.next(updatedVehicle);
  
              promises.push(
                _this.documentService.refreshDocumentsByParent(
                  DbCollectionsEnum.VEHICLES,
                  updatedVehicle ? updatedVehicle._id : null,
                  currentUser
                )
              );
              promises.push(
                _this.imageDocService.refreshImagesByParent(
                  DbCollectionsEnum.VEHICLES,
                  updatedVehicle ? updatedVehicle._id : null,
                  false,
                  currentUser
                )
              );
              promises.push(
                _this.model3dService.refreshModels3dByParent(
                  DbCollectionsEnum.VEHICLES,
                  updatedVehicle ? updatedVehicle._id : null,
                  currentUser
                )
              );
              promises.push(
                _this.noteService.refreshNotesByParent(
                  DbCollectionsEnum.VEHICLES,
                  updatedVehicle ? updatedVehicle._id : null,
                  currentUser
                )
              );
              promises.push(
                _this.scanService.refreshScansByParent(
                  DbCollectionsEnum.VEHICLES,
                  updatedVehicle ? updatedVehicle._id : null,
                  currentUser
                )
              );
              promises.push(_this.updateChildData(updatedVehicle, currentUser));
  
              Promise.allSettled(promises).then((results) => {
                _this.settingsService.setIsLoading(false);
                resolve(returnValue);
              });
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.vehicleErrorSubject.next(errMessage);
              _this.currentVehicleSubject.next(null);
  
              promises.push(_this.documentService.refreshDocumentsByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
              promises.push(_this.imageDocService.refreshImagesByParent(DbCollectionsEnum.VEHICLES, null, false, currentUser));
              promises.push(_this.model3dService.refreshModels3dByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
              promises.push(_this.noteService.refreshNotesByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
              promises.push(_this.scanService.refreshScansByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
              promises.push(_this.updateChildData(null, currentUser));
  
              Promise.allSettled(promises).then((results) => {
                _this.settingsService.setIsLoading(false);
                reject(error);
              });
            },
            complete: () => {
  
            }
          });
      } else {
        const errMessage = `changes, vehicleId and user are required to update a vehicle`;
        this.errorService.handleError(errMessage);
        this.vehicleErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getAllowVehicleModels3d() {
    return this.allowVehicleModels3dSubject.getValue();
  }

  getAllowVehicleScans() {
    return this.allowVehicleScansSubject.getValue();
  }

  getCurrentVehicle() {
    return this.currentVehicleSubject.getValue();
  }

  getCurrentVehicleDesignation() {
    return this.currentVehicleDesignationSubject.getValue();
  }

  getCurrentVehicleModel() {
    return this.currentVehicleModelSubject.getValue();
  }

  getCurrentVehicleModel3d() {
    return this.currentVehicleModel3dSubject.getValue();
  }

  getCurrentVehicleModels3d() {
    return this.currentVehicleModels3dSubject.getValue();
  }

  getCurrentVehicleScan() {
    return this.currentVehicleScanSubject.getValue();
  }

  getCurrentVehicleScans() {
    return this.currentVehicleScansSubject.getValue();
  }

  getCurrentVehiclePurpose() {
    return this.currentVehiclePurposeSubject.getValue();
  }

  getCurrentVehicleVideos() {
    return this.currentVehicleVideosSubject.getValue();
  }

  async getVehicleById(vehicleId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const promises = [];
      let returnValue: Vehicle;

      if (currentUser && vehicleId) {
        _this.vehicles$
          .pipe(
            map((vehicles) => vehicles.find((vehicle) => vehicle._id === vehicleId)),
            filter((vehicle) => !!vehicle)
          ).subscribe({
            next: (vehicle: Vehicle) => {
              returnValue = vehicle;
              _this.currentVehicleSubject.next(vehicle);

              promises.push(
                _this.documentService.refreshDocumentsByParent(DbCollectionsEnum.VEHICLES, vehicle ? vehicle._id : null, currentUser)
              );

              promises.push(
                _this.imageDocService.refreshImagesByParent(
                  DbCollectionsEnum.VEHICLES,
                  vehicle ? vehicle._id : null,
                  false,
                  currentUser
                )
              );
              promises.push(
                _this.model3dService.refreshModels3dByParent(DbCollectionsEnum.VEHICLES, vehicle ? vehicle._id : null, currentUser)
              );
              promises.push(
                _this.noteService.refreshNotesByParent(DbCollectionsEnum.VEHICLES, vehicle ? vehicle._id : null, currentUser)
              );
              promises.push(
                _this.scanService.refreshScansByParent(DbCollectionsEnum.VEHICLES, vehicle ? vehicle._id : null, currentUser)
              );
              promises.push(
                _this.videoService.refreshVideosByParent(DbCollectionsEnum.VEHICLES, vehicle ? vehicle._id : null, currentUser)
              );
              promises.push(_this.updateChildData(vehicle, currentUser));

              Promise.allSettled(promises).then((results) => {
                const vehicleResult = results[results.length - 1];
                if (vehicleResult.status === 'fulfilled') {
                  returnValue = vehicleResult.value;
                  _this.currentVehicleSubject.next(returnValue);
                }

                _this.settingsService.setIsLoading(false);
                resolve(returnValue);
              });
            },
            error: (error: any) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = _this.errorService.handleError(`Error getting vehicleId ${vehicleId}:${error.error}`);
              _this.vehicleErrorSubject.next(errMessage);
              reject(errMessage);
            },
            complete: () => {

            }
          });
      } else {
        _this.currentVehicleSubject.next(null);

        promises.push(_this.documentService.refreshDocumentsByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
        promises.push(_this.imageDocService.refreshImagesByParent(DbCollectionsEnum.VEHICLES, null, false, currentUser));
        promises.push(_this.model3dService.refreshModels3dByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
        promises.push(_this.noteService.refreshNotesByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
        promises.push(_this.scanService.refreshScansByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
        promises.push(_this.videoService.refreshVideosByParent(DbCollectionsEnum.VEHICLES, null, currentUser));
        promises.push(_this.updateChildData(returnValue, currentUser));

        Promise.allSettled(promises).then((results) => {
          const vehicleResult = results[results.length - 1];
          if (vehicleResult.status === 'fulfilled') {
            returnValue = vehicleResult.value;
            _this.currentVehicleSubject.next(returnValue);
          }

          _this.settingsService.setIsLoading(false);
          resolve(returnValue);
        });
      }
    });
  }

  getVehicles(currentUser: User): Observable<Vehicle[]> {
    const vehiclesHttp$ = createHttpObservable(`${environment.baseAPIUrl}vehicle?userId=${currentUser?._id}`, {}, true);

    vehiclesHttp$
      .pipe(
        catchError((err) => {
          console.error(`Error getting vehicles: ${err}`);
          return of([]);
        })
      )
      .subscribe((vehicles: Vehicle[]) => {
        this.vehiclesSubject.next(vehicles);
        let vehiclesWithMods: Vehicle[];
        let vehiclesWithValidMods: Vehicle[];

        if (vehicles && vehicles.length > 0) {
          vehiclesWithMods = vehicles.filter((vehicle) => vehicle.hasMod === true);
          vehiclesWithValidMods = vehicles.filter((vehicle) => vehicle.hasValidMod === true);
        }

        this.vehiclesWithModsSubject.next(vehiclesWithMods);
        this.vehiclesWithValidModsSubject.next(vehiclesWithValidMods);
      });

      return vehiclesHttp$;
  }

  async saveNewVehicleModel3d(model3dPayload, modPayload, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && model3dPayload && modPayload) {
        _this.settingsService.setIsLoading(true);
        let returnValue: Vehicle = _this.currentVehicleSubject.getValue();
  
        if (returnValue) {
          _this.model3dService
            .createNewModel3d(model3dPayload, modPayload, DbCollectionsEnum.VEHICLES, returnValue._id, currentUser)
            .then((newModel3d: Model3d) => {
              const modSources = returnValue.modSources
                ? returnValue.modSources
                : {
                  models3d: [],
                  scans: [],
                };
  
              modSources.models3d.push(newModel3d._id);
  
              const vehicleChanges = {
                modSources: modSources,
                editorId: currentUser._id,
              };
  
              _this.saveVehicle(returnValue._id, vehicleChanges, currentUser)
                .then((updatedVehicle: Vehicle) => {
                  returnValue = updatedVehicle;
                  _this.currentVehicleSubject.next(updatedVehicle);
                  _this.vehicleErrorSubject.next(null);
                })
                .catch((vehicleError) => {
                  const errMessage = _this.errorService.handleError(
                    `Error adding newModel3dId ${newModel3d._id} to vehicleId ${returnValue._id}: ${vehicleError.message}`
                  );
                  _this.vehicleErrorSubject.next(errMessage);
                })
                .finally(() => {
                  _this.settingsService.setIsLoading(false);
                  resolve(returnValue);
                });
            })
            .catch((error) => {
              const errMessage = _this.errorService.handleError(`Error creating new vehicle 3d model: ${error.error}`);
              _this.vehicleErrorSubject.next(errMessage);
              reject(errMessage);
            })
            .finally(() => {
              _this.settingsService.setIsLoading(false);
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(
            `vehicle and new 3d model info required to add a 3d model to a vehicle`
          );
          _this.vehicleErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `model3dPayload, modPayload and user are required to create a vehicle 3D model`;
        _this.errorService.handleError(errMessage);
        _this.vehicleErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveNewVehicleScan(scanPayload, modPayload, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && modPayload && scanPayload) {
        _this.settingsService.setIsLoading(true);
        let returnValue: Vehicle = _this.currentVehicleSubject.getValue();
  
        if (returnValue) {
          _this.scanService
            .createNewScan(scanPayload, modPayload, DbCollectionsEnum.VEHICLES, returnValue._id, currentUser)
            .then((newScan: Scan) => {
              const modSources = returnValue.modSources
                ? returnValue.modSources
                : {
                  models3d: [],
                  scans: [],
                };
              modSources.scans.push(newScan._id);
  
              const vehicleChanges = {
                modSources: modSources,
                editorId: currentUser._id,
              };
  
              _this.saveVehicle(returnValue._id, vehicleChanges, currentUser)
                .then((updatedVehicle: Vehicle) => {
                  returnValue = updatedVehicle;
                  _this.currentVehicleSubject.next(updatedVehicle);
                  _this.vehicleErrorSubject.next(null);
                })
                .catch((vehicleError) => {
                  _this.vehicleErrorSubject.next(vehicleError.message);
                })
                .finally(() => {
                  _this.settingsService.setIsLoading(false);
                  resolve(returnValue);
                });
            })
            .catch((scanError) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = _this.errorService.handleError(
                `Error creating new scan ${scanPayload} for vehicleId ${returnValue._id}: ${scanError.message}`
              );
              _this.vehicleErrorSubject.next(errMessage);
              reject(errMessage);
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(`vehicle, scan and mod are required to save a new vehicle scan`);
          _this.vehicleErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `modPayload, scanPayload and user are required to create a vehicle scan`;
        _this.errorService.handleError(errMessage);
        _this.vehicleErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveVehicleModel3d(model3dId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && model3dId) {
        _this.settingsService.setIsLoading(true);
        const currentVehicle = _this.currentVehicleSubject.getValue();
        const currentModel3d = _this.currentVehicleModel3dSubject.getValue();
        let returnValue: Model3d;
  
        if (currentVehicle) {
          _this.model3dService
            .saveModel3d(model3dId, DbCollectionsEnum.VEHICLES, currentVehicle._id, changes, currentUser)
            .then((updatedModel3d: Model3d) => {
              returnValue = updatedModel3d;
  
              _this.setVehicleModel3d(currentVehicle, updatedModel3d, currentUser)
                .then((updatedVehicle: Vehicle) => {
                  _this.vehicleErrorSubject.next('');
                })
                .catch((vehicleError) => {
                  _this.vehicleErrorSubject.next(vehicleError.message);
                })
                .finally(() => {
                  _this.settingsService.setIsLoading(false);
                  resolve(returnValue);
                });
            })
            .catch((error) => {
              const errMessage = _this.errorService.handleError(
                `Error updating vehicle model3dId ${model3dId}:${error.error}`
              );
              _this.vehicleErrorSubject.next(errMessage);
              reject(errMessage);
            })
            .finally(() => {
              _this.settingsService.setIsLoading(false);
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(
            `vehicle, model3dId and changes are required to save vehicle 3d model changes`
          );
          _this.vehicleErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `changes, model3dId and user are required to update a vehicle 3D model`;
        _this.errorService.handleError(errMessage);
        _this.vehicleErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveVehicleScan(scanId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && scanId) {
        _this.settingsService.setIsLoading(true);
        const currentVehicle = _this.currentVehicleSubject.getValue();
        const currentScan = _this.currentVehicleScanSubject.getValue();
        let returnValue: Scan;
  
        if (currentVehicle) {
          _this.scanService
            .saveScan(scanId, DbCollectionsEnum.VEHICLES, currentVehicle._id, changes, currentUser)
            .then((updatedScan: Scan) => {
              returnValue = updatedScan;
  
              _this.setVehicleScan(currentVehicle, updatedScan, currentUser)
                .then((results: Scan) => {
                  returnValue = results;
                })
                .catch((error) => {
                  _this.vehicleErrorSubject.next(error.error);
                })
                .finally(() => {
                  _this.settingsService.setIsLoading(false);
                  resolve(returnValue);
                });
            })
            .catch((updateError) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = _this.errorService.handleError(
                `Error updating vehicleid ${currentVehicle._id} scanId ${scanId}: ${updateError.message}`
              );
              _this.vehicleErrorSubject.next(errMessage);
              reject(errMessage);
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(
            `vehicle, scanId and changes are required to save a vehicle scan.`
          );
          _this.vehicleErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `changes, scanId and user are required to update a vehicle scan`;
        _this.errorService.handleError(errMessage);
        _this.vehicleErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async setVehicleModel3d(vehicle: Vehicle, model3d: Model3d, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue = vehicle;

      _this.currentVehicleModel3dSubject.next(model3d);

      _this.updateChildData(vehicle, currentUser)
        .then((updatedVehicle: Vehicle) => {
          returnValue = updatedVehicle;
        })
        .catch((error) => {
          _this.vehicleErrorSubject.next(error.error);
        })
        .finally(() => {
          _this.settingsService.setIsLoading(false);
          resolve(returnValue);
        });
    });
  }

  async setVehicleScan(vehicle: Vehicle, scan: Scan, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      _this.currentVehicleScanSubject.next(scan);
      let returnValue = vehicle;

      _this.updateChildData(vehicle, currentUser)
        .then((vehicleWithChildData: Vehicle) => {
          returnValue = vehicleWithChildData;
        })
        .catch((error) => {
          _this.vehicleErrorSubject.next(error.error);
        })
        .finally(() => {
          _this.settingsService.setIsLoading(false);
          resolve(returnValue);
        });
    });
  }

  async updateChildData(vehicle: Vehicle, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      zip(
        _this.dataSources$,
        _this.manufacturers$,
        _this.mods$,
        _this.scanners$,
        _this.scans$,
        _this.vehicleDesignations$,
        _this.vehicleModels$,
        _this.vehiclePurposes$,
        _this.users$
      )
        .pipe(
          map(
            ([
              dataSources,
              manufacturers,
              mods,
              scanners,
              scans,
              vehicleDesignations,
              vehicleModels,
              vehiclePurposes,
              users,
            ]) => ({
              dataSources,
              manufacturers,
              mods,
              scanners,
              scans,
              vehicleDesignations,
              vehicleModels,
              vehiclePurposes,
              users,
            })
          )
        )
        .subscribe({
          next: ({
            dataSources,
            manufacturers,
            mods,
            scanners,
            scans,
            vehicleDesignations,
            vehicleModels,
            vehiclePurposes,
            users,
          }) => {
            if (vehicle) {
              _this.vehicleDesignationService.getVehicleDesignationById(vehicle.vehicleDesignationId);
              _this.vehicleModelService.getVehicleModelById(vehicle.vehicleModelId);
              _this.vehiclePurposeService.getVehiclePurposeById(vehicle.vehiclePurposeId);

              if (
                vehicle.modSources &&
                vehicle.modSources.scans &&
                Array.isArray(vehicle.modSources.scans) &&
                vehicle.modSources.scans.length > 0
              ) {
                const vehicleScans = scans.filter(function (scan) {
                  const idx = vehicle.modSources.scans.indexOf(scan._id);
                  if (idx != -1) {
                    return true;
                  }
                });

                if (vehicleScans.length > 0) {
                  vehicleScans.map(function (scan) {
                    if (scan.dataSourceId && dataSources.length > 0) {
                      const ds = dataSources.find((dataSource) => dataSource._id === scan.dataSourceId);
                      if (ds) {
                        scan.dataSourceName = ds.name;
                      }
                    }

                    if (scan.creatorId && users.length > 0) {
                      const creator = users.find((user) => user._id === scan.creatorId);
                      if (creator) {
                        scan.scanUploader = creator.fullName;
                      }
                    }

                    if (scan.scannerId && scanners.length > 0) {
                      const scanner = scanners.find((scanner) => scanner._id === scan.scannerId);
                      if (scanner) {
                        scan.scannerName = scanner.name;

                        if (scanner.manufacturerId && manufacturers.length > 0) {
                          const manu = manufacturers.find((manu) => manu._id === scanner.manufacturerId);
                          if (manu) {
                            scan.scannerManufacturerName = manu.name;
                          }
                        }
                      }
                    }
                  });
                }

                _this.currentVehicleScansSubject.next(vehicleScans);
              } else {
                _this.currentVehicleScansSubject.next(null);
              }

              if (vehicle.vehicleDesignationId) {
                const vehicleDesignation = vehicleDesignations.find(
                  (vehicleDesignation) => vehicleDesignation._id === vehicle.vehicleDesignationId
                );
                _this.currentVehicleDesignationSubject.next(vehicleDesignation);
              } else {
                _this.currentVehicleDesignationSubject.next(null);
              }

              if (vehicle.vehicleModelId) {
                const vehicleModel = vehicleModels.find((vehicleModel) => vehicleModel._id === vehicle.vehicleModelId);
                _this.currentVehicleModelSubject.next(vehicleModel);
              } else {
                _this.currentVehicleModelSubject.next(null);
              }

              if (vehicle.vehiclePurposeId) {
                const vehiclePurpose = vehiclePurposes.find(
                  (vehiclePurpose) => vehiclePurpose._id === vehicle.vehiclePurposeId
                );
                _this.currentVehiclePurposeSubject.next(vehiclePurpose);
              } else {
                _this.currentVehiclePurposeSubject.next(null);
              }
            } else {
              _this.modService.getModById(null, DbCollectionsEnum.VEHICLES, null, currentUser);
              _this.currentVehicleDesignationSubject.next(null);
              _this.currentVehicleModel3dSubject.next(null);
              _this.currentVehicleModelSubject.next(null);
              _this.currentVehiclePurposeSubject.next(null);
              _this.currentVehicleScanSubject.next(null);
              _this.currentVehicleScansSubject.next(null);
              _this.vehicleDesignationService.getVehicleDesignationById(null);
              _this.vehicleModelService.getVehicleModelById(null);
              _this.vehiclePurposeService.getVehiclePurposeById(null);
            }

            resolve(vehicle);
          },
          error: (error: any) => {
            const errMessage = _this.errorService.handleError(`Error updating vehicle child data: ${error.error}`);
            _this.vehicleErrorSubject.next(errMessage);
            reject(errMessage);
          },
          complete: () => {

          }
        });
    });
  }
}
