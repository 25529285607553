<div class="content" *ngIf="currentUser$ | async as currentUser">
  <mat-card appearance="outlined" class="document-card mat-elevation-z10">
    <img
      mat-card-image
      src="{{ doc.displayThumbnailUrl }}"
      alt="Image for {{ doc.name }}"
      (click)="viewDocument(doc, currentUser)"
      onerror="this.onerror=null; this.src='../../../assets/img/SDAT.png';"
    />
  </mat-card>
  <mat-card-actions>
    <button
      mat-icon-button
      color="warn"
      aria-label="Delete Document"
      matTooltip="Delete Document"
      *ngIf="isAdmin"
      (click)="deleteDocument(doc, currentUser)"
    >
      <img src="assets/img/TrashGray.png" alt="Delete" />
    </button>
    <button
      mat-icon-button
      color="primary"
      aria-label="View Document"
      matTooltip="View Document"
      (click)="viewDocument(doc, currentUser)"
    >
      <img src="assets/img/Search.png" alt="View" />
    </button>
  </mat-card-actions>

  <iframe id="iframeForDownload" *ngIf="safeDocumentUrl" [src]="safeDocumentUrl" style="visibility: hidden"></iframe>
</div>
