<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser">
  <h2 mat-dialog-title>{{ formTitle }}</h2>

  <mat-dialog-content>
    <form [formGroup]="form">
      <mat-horizontal-stepper [linear]="true" #stepper>
        <mat-step [stepControl]="vehicleInfoForm" errorMessage="Metadata is required.">
          <div [formGroup]="vehicleInfoForm">
            <ng-template matStepLabel>Vehicle Metadata</ng-template>
            <h3>Vehicle Info</h3>
            <input type="text" formControlName="creatorId" hidden value="currentUser._id" />
            <mat-form-field appearance="fill">
              <input matInput placeholder="*Nomenclature (i.e. Trailer, Cargo)" formControlName="name" />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>TAMCN (i.e. D08507K)</mat-label>
              <mat-select formControlName="vehicleDesignationId"
                (selectionChange)="onSelectedVehicleDesignation($event.value)">
                <mat-option *ngFor="let vehicleDesignation of vehicleDesignations$ | async as vehicleDesignations"
                  [value]="vehicleDesignation._id">
                  {{ vehicleDesignation.designation }}
                </mat-option>
              </mat-select>
              <button matSuffix mat-icon-button aria-label="Add TAMCN" matTooltip="Add TAMCN"
                (click)="addVehicleDesignation()">
                <mat-icon>add</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Model Number (i.e. M101A3)</mat-label>
              <mat-select formControlName="vehicleModelId" (selectionChange)="onSelectedVehicleModel($event.value)">
                <mat-option *ngFor="let vehicleModel of vehicleModels$ | async as vehicleModels"
                  [value]="vehicleModel._id">
                  {{ vehicleModel.name }}
                </mat-option>
              </mat-select>
              <button matSuffix mat-icon-button aria-label="Add Model Number" matTooltip="Add Model Number"
                (click)="addVehicleModel()">
                <mat-icon>add</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Equipment Type</mat-label>
              <mat-select formControlName="equipmentTypeId">
                <mat-option *ngFor="let equipmentType of equipmentTypes" [value]="equipmentType._id">
                  {{ equipmentType.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Movement Type</mat-label>
              <mat-select formControlName="movementTypeId"
                (selectionChange)="onSelectedVehicleMovementType($event.value)">
                <mat-option *ngFor="let movementType of movementTypes" [value]="movementType._id">
                  {{ movementType.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Purpose</mat-label>
              <mat-select formControlName="vehiclePurposeId" (selectionChange)="onSelectedVehiclePurpose($event.value)">
                <mat-option *ngFor="let purpose of vehiclePurposes$ | async as purposes" [value]="purpose._id">
                  {{ purpose.name }}
                </mat-option>
              </mat-select>
              <button matSuffix mat-icon-button aria-label="Add Vehicle Purpose" matTooltip="Add Vehicle Purpose"
                (click)="addVehiclePurpose()">
                <mat-icon>add</mat-icon>
              </button>
            </mat-form-field>
            <mat-slide-toggle formControlName="isMotorized" (change)="onIsMotorizedChanged($event)">Is Motorized?
            </mat-slide-toggle>
            <mat-form-field *ngIf="isMotorizedVehicle$ | async">
              <textarea matInput rows="2" placeholder="Engine" formControlName="engine"> </textarea>
            </mat-form-field>
            <mat-slide-toggle formControlName="hasBrakes" (change)="onHasBrakesChanged($event)">Has Brakes?
            </mat-slide-toggle>
            <mat-form-field *ngIf="hasBrakes$ | async">
              <textarea matInput rows="2" placeholder="Braking" formControlName="braking"> </textarea>
            </mat-form-field>
            <div formGroupName="maximumHorsepower">
              <table width="100%;" cellspacing="0">
                <tr>
                  <td>
                    <mat-form-field>
                      <input matInput type="number" placeholder="Maximum Engine Horsepower" formControlName="value" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-radio-group formControlName="units">Units:&nbsp;&nbsp;
                      <mat-radio-button value="KW">KW</mat-radio-button>
                    </mat-radio-group>
                  </td>
                </tr>
              </table>
            </div>
            <div formGroupName="maximumTorque">
              <table width="100%;" cellspacing="0">
                <tr>
                  <td>
                    <mat-form-field>
                      <input matInput type="number" placeholder="Maximum Engine Torque" formControlName="value" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-radio-group formControlName="units">Units:&nbsp;&nbsp;
                      <mat-radio-button value="N*m">N*m</mat-radio-button>
                    </mat-radio-group>
                  </td>
                </tr>
              </table>
            </div>
            <br>
            <div class="button-row">
              <button mat-raised-button aria-label="Close without saving" matTooltip="Close without saving"
                (click)="close()">
                Cancel
              </button>
              <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" matStepperNext>
                Next
              </button>
            </div>
            <div *ngIf="isDebugging$ | async">
              <pre>_id: {{ vehicleInfoForm.get('_id').valid }}</pre>
              <pre>creatorId: {{ vehicleInfoForm.get('creatorId').valid }}</pre>
              <pre>modelName: {{ vehicleInfoForm.get('modelName').valid }}</pre>
              <pre>name: {{ vehicleInfoForm.get('name').valid }}</pre>
              <pre>vehicleDesignationId: {{ vehicleInfoForm.get('vehicleDesignationId').valid }}</pre>
              <pre>vehicleModelId: {{ vehicleInfoForm.get('vehicleModelId').valid }}</pre>
              <pre>equipmentTypeId: {{ vehicleInfoForm.get('equipmentTypeId').valid }}</pre>
              <pre>movementTypeId: {{ vehicleInfoForm.get('movementTypeId').valid }}</pre>
              <pre>vehiclePurposeId: {{ vehicleInfoForm.get('vehiclePurposeId').valid }}</pre>
              <pre>engine: {{ vehicleInfoForm.get('engine').valid }}</pre>
              <pre>braking: {{ vehicleInfoForm.get('braking').valid }}</pre>
              <pre>maximumHorsepowerValue: {{ vehicleInfoForm.get('maximumHorsepower').valid }}</pre>
              <pre>maximumTorque: {{ vehicleInfoForm.get('maximumTorque').valid }}</pre>
            </div>
          </div>
        </mat-step>
        <mat-step [stepControl]="vehicleManeuverabilityInfoForm"
          errorMessage="Maneuverability information is required.">
          <div [formGroup]="vehicleManeuverabilityInfoForm">
            <ng-template matStepLabel>Vehicle Maneuverability Information</ng-template>
            <h3>Maneuverability Info</h3>
            <mat-form-field>
              <input matInput type="text" appTwoDigitDecimalNumber placeholder="Approach Angle"
                formControlName="angleOfApproach" matTooltip="Approach Angle: Maximum angle of a ramp onto which a vehicle can climb from a horizontal plane
                                without interference." />
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" appTwoDigitDecimalNumber placeholder="Departure Angle"
                formControlName="angleOfDeparture"
                matTooltip="Departure Angle: Maximum angle of a ramp from which a vehicle can exit without damage." />
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" appTwoDigitDecimalNumber placeholder="Ramp Over Angle"
                formControlName="rampBreakoverAngle"
                matTooltip="Ramp Over Angle: Angle between ties and the center of the vehicle 's underbody." />
            </mat-form-field>
            <div formGroupName="groundClearance">
              <table width="100%;" cellspacing="0">
                <tr>
                  <td style="width: 50%">
                    <mat-form-field
                      matTooltip="Ground Clearance:  Minimum distance between the lower end of the vehicle body and a horizontal surface the vehicle is on.">
                      <mat-label>Ground Clearance</mat-label>
                      <input matInput type="text" appTwoDigitDecimalNumber placeholder="Ground Clearance"
                        formControlName="value" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-radio-group name="groundClearanceUnits" formControlName="units">
                      Units:&nbsp;&nbsp;
                      <mat-radio-button value="cm">cm</mat-radio-button>
                    </mat-radio-group>
                  </td>
                </tr>
              </table>
            </div>
            <div formGroupName="centerOfGravity">
              <div formGroupName="horizontal">
                <table width="100%;" cellspacing="0">
                  <tr>
                    <td style="width: 50%">
                      <mat-form-field
                        matTooltip="Horizontal Center of Gravity:  Horizontal distance measured to the rear of the centerline of the vehicle from the front axle">
                        <mat-label>Center of Gravity - Horizontal</mat-label>
                        <input matInput type="text" appTwoDigitDecimalNumber placeholder="Horizontal Center of Gravity"
                          formControlName="value" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-radio-group formControlName="units">Units:&nbsp;&nbsp;
                        <mat-radio-button value="cm">cm</mat-radio-button>
                      </mat-radio-group>
                    </td>
                  </tr>
                </table>
              </div>
              <div formGroupName="vertical">
                <table width="100%;" cellspacing="0">
                  <tr>
                    <td style="width: 50%">
                      <mat-form-field matTooltip="Vertical Center of Gravity:  Height measured from the ground">
                        <mat-label>Center of Gravity - Vertical</mat-label>
                        <input matInput type="text" appTwoDigitDecimalNumber placeholder="Vertical Center of Gravity"
                          formControlName="value" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-radio-group formControlName="units">Units:&nbsp;&nbsp;
                        <mat-radio-button value="m">m</mat-radio-button>
                      </mat-radio-group>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div formGroupName="curbWeight">
              <table width="100%;" cellspacing="0">
                <tr>
                  <td style="width: 50%">
                    <mat-form-field>
                      <mat-label>Curb Weight</mat-label>
                      <input matInput type="text" appTwoDigitDecimalNumber placeholder="Curb Weight"
                        formControlName="value" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-radio-group formControlName="units">Units:&nbsp;&nbsp;
                      <mat-radio-button value="kg">kg</mat-radio-button>
                    </mat-radio-group>
                  </td>
                </tr>
              </table>
            </div>
            <div formGroupName="grossVehicleWeight">
              <table width="100%;" cellspacing="0">
                <tr>
                  <td style="width: 50%">
                    <mat-form-field>
                      <mat-label>Gross Vehicle Weight</mat-label>
                      <input matInput type="text" appTwoDigitDecimalNumber placeholder="Gross Vehicle Weight"
                        formControlName="value" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-radio-group formControlName="units">Units:&nbsp;&nbsp;
                      <mat-radio-button value="kg">kg</mat-radio-button>
                    </mat-radio-group>
                  </td>
                </tr>
              </table>
            </div>
            <div formGroupName="overallDimensions">
              <div formGroupName="vehicleLength">
                <table width="100%;" cellspacing="0">
                  <tr>
                    <td style="width: 50%">
                      <mat-form-field>
                        <mat-label>Length</mat-label>
                        <input matInput type="text" appTwoDigitDecimalNumber placeholder="Overall Vehicle Length"
                          formControlName="value" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-radio-group formControlName="units">Units:&nbsp;&nbsp;
                        <mat-radio-button value="m">m</mat-radio-button>
                      </mat-radio-group>
                    </td>
                  </tr>
                </table>
              </div>
              <div formGroupName="vehicleWidth">
                <table width="100%;" cellspacing="0">
                  <tr>
                    <td style="width: 50%">
                      <mat-form-field>
                        <mat-label>Width</mat-label>
                        <input matInput type="text" appTwoDigitDecimalNumber placeholder="Overall vehicle width"
                          formControlName="value" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-radio-group formControlName="units">Units:&nbsp;&nbsp;
                        <mat-radio-button value="m">m</mat-radio-button>
                      </mat-radio-group>
                    </td>
                  </tr>
                </table>
              </div>
              <div formGroupName="vehicleHeight">
                <table width="100%;" cellspacing="0">
                  <tr>
                    <td style="width: 50%">
                      <mat-form-field>
                        <mat-label>Height</mat-label>
                        <input matInput type="text" appTwoDigitDecimalNumber placeholder="Overall vehicle height"
                          formControlName="value" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-radio-group formControlName="units">Units:&nbsp;&nbsp;
                        <mat-radio-button value="m">m</mat-radio-button>
                      </mat-radio-group>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <mat-form-field appearance="fill">
              <mat-label>Steering Type</mat-label>
              <mat-select formControlName="steeringTypeId">
                <mat-option *ngFor="let steeringType of steeringTypes" [value]="steeringType._id">
                  {{ steeringType.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="isWheeledVehicle$ | async">
              <input matInput type="text" appTwoDigitDecimalNumber placeholder="Maximum Steering Angle"
                formControlName="maximumSteeringAngle"
                matTooltip="Maximum Steering Angle:  Maximum deflection of the wheels." />
            </mat-form-field>
            <div formGroupName="minimumTurningRadius">
              <table width="100%;" cellspacing="0">
                <tr>
                  <td style="width: 50%">
                    <mat-form-field
                      matTooltip="Minimum Turning Radius:  Radius of the path of the vehicle's centerline when in it 's tightest turn possible.">
                      <mat-label>Minimum Turning Radius</mat-label>
                      <input matInput type="text" appTwoDigitDecimalNumber placeholder="Minimum Turning Radius"
                        formControlName="value" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-radio-group formControlName="units">Units:&nbsp;&nbsp;
                      <mat-radio-button value="m">m</mat-radio-button>
                    </mat-radio-group>
                  </td>
                </tr>
              </table>
            </div>
            <mat-form-field *ngIf="isWheeledVehicle$ | async">
              <div formGroupName="tireRadius">
                <table width="100%;" cellspacing="0">
                  <tr>
                    <td style="width: 50%">
                      <mat-form-field>
                        <mat-label>Tire Radius</mat-label>
                        <input matInput type="text" appTwoDigitDecimalNumber placeholder="Tire Radius"
                          formControlName="value" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-radio-group formControlName="units">Units:&nbsp;&nbsp;
                        <mat-radio-button value="cm">cm</mat-radio-button>
                      </mat-radio-group>
                    </td>
                  </tr>
                </table>
              </div>
            </mat-form-field>
            <mat-form-field *ngIf="isWheeledVehicle$ | async">
              <mat-label>Number of Wheels</mat-label>
              <input matInput type="number" placeholder="Number of Wheels" formControlName="numberOfWheels" />
            </mat-form-field>
            <mat-form-field>
              <mat-label>Number of Axles</mat-label>
              <input matInput type="number" placeholder="Number of Axles" formControlName="numberOfAxles"
                (ngModelChange)="onNumberOfAxlesChanged($event)" />
            </mat-form-field>
            <div formGroupName="wheelbase">
              <label matTooltip="Wheelbase: Axle to axle distance (from front to back).">Wheelbase - </label>
              <mat-radio-group formControlName="units">Units:&nbsp;&nbsp;
                <mat-radio-button value="m">m</mat-radio-button>
              </mat-radio-group>
              <div formArrayName="measurements">
                <table mat-table [dataSource]="measurementsDataSource" class="mat-elevation-z8">
                  <ng-container matColumnDef="fromAxlePosition">
                    <th mat-header-cell *matHeaderCellDef>From Axle</th>
                    <td mat-cell *matCellDef="let measurement; let index = index" [formGroupName]="index">
                      <input type="number" formControlName="fromAxlePosition" ng-disabled="true" />
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="toAxlePosition">
                    <th mat-header-cell *matHeaderCellDef>To Axle</th>
                    <td mat-cell *matCellDef="let measurement; let index = index" [formGroupName]="index">
                      <input type="number" formControlName="toAxlePosition" ng-disabled="true" />
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef>Distance</th>
                    <td mat-cell *matCellDef="let measurement; let index = index" [formGroupName]="index">
                      <input type="text" appTwoDigitDecimalNumber formControlName="value" />
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedWheelbaseMeasurementColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedWheelbaseMeasurementColumns"></tr>
                </table>
              </div>
            </div>
            <br>
            <div formGroupName="wheelTrack">
              <table width="100%;" cellspacing="0">
                <tr>
                  <td style="width: 50%">
                    <mat-form-field
                      matTooltip="Wheel Track:  Distance between the centerline of two wheels on the same axle.">
                      <mat-label>Wheel Track Distance</mat-label>
                      <input matInput type="text" appTwoDigitDecimalNumber placeholder="Wheel Track"
                        formControlName="value" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-radio-group formControlName="units">Units:&nbsp;&nbsp;
                      <mat-radio-button value="m">m</mat-radio-button>
                    </mat-radio-group>
                  </td>
                </tr>
              </table>
            </div>
            <div class="button-row">
              <button mat-raised-button aria-label="Close without saving" matTooltip="Close without saving"
                (click)="close()">
                Cancel
              </button>
              <button mat-raised-button aria-label="Back to previous step" matTooltip="Back to previous step"
                matStepperPrevious>
                Back
              </button>
              <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" matStepperNext>
                Next
              </button>
            </div>

            <div *ngIf="isDebugging$ | async">
              <pre>angleOfApproach: {{ vehicleManeuverabilityInfoForm.get('angleOfApproach').valid }}</pre>
              <pre>angleOfDeparture: {{ vehicleManeuverabilityInfoForm.get('angleOfDeparture').valid }}</pre>
              <pre>centerOfGravity: {{ centerOfGravityForm.valid }}</pre>
              <pre>curbWeight: {{ vehicleManeuverabilityInfoForm.get('curbWeight').valid }}</pre>
              <pre>grossVehicleWeight: {{ vehicleManeuverabilityInfoForm.get('grossVehicleWeight').valid }}</pre>
              <pre>groundClearance: {{ vehicleManeuverabilityInfoForm.get('groundClearance').valid }}</pre>
              <pre>overallDimensions: {{ vehicleManeuverabilityInfoForm.get('overallDimensions').valid }}</pre>
              <pre>rampBreakoverAngle: {{ vehicleManeuverabilityInfoForm.get('rampBreakoverAngle').valid }}</pre>
              <pre>minimumTurningRadius: {{ vehicleManeuverabilityInfoForm.get('minimumTurningRadius').valid }}</pre>
              <pre>steeringTypeId: {{ vehicleManeuverabilityInfoForm.get('steeringTypeId').valid }}</pre>
              <pre>numberOfAxles: {{ vehicleManeuverabilityInfoForm.get('numberOfAxles').valid }}</pre>
              <pre>tireRadius: {{ vehicleManeuverabilityInfoForm.get('tireRadius').valid }}</pre>
              <pre>wheelbase: {{ wheelbaseForm.valid }}</pre>
              <pre>wheelTrack: {{ vehicleManeuverabilityInfoForm.get('wheelTrack').valid }}</pre>
            </div>
          </div>
        </mat-step>

        <mat-step [stepControl]="fileForm">
          <div [formGroup]="fileForm">
            <ng-template matStepLabel>Vehicle Files</ng-template>
            <h3>Vehicle Image</h3>
            <div>
              <span>
                <button (click)="imageFileInput.click()">
                  <mat-icon>library_add</mat-icon>
                  <span>Select Image File</span>
                  <input #imageFileInput type="file" (change)="imageFileInputChange($event)" accept="image/*"
                    style="display: none" />
                </button>
              </span>

              <br><br>

              <!-- Image Preview -->
              <div class="imagePreview" *ngIf="imageURL && imageURL !== ''">
                <img [src]="imageURL" alt="{{ imageFileName }}" style="width: 250px; height: auto"
                  onerror="this.onerror=null; this.src='../../../../assets/img/SDAT.png';" />
              </div>
            </div>
          </div>

          <div class="button-row">
            <button mat-raised-button aria-label="Close without saving" matTooltip="Close without saving"
              (click)="close()">
              Cancel
            </button>
            <button mat-raised-button aria-label="Back to previous step" matTooltip="Back to previous step"
              matStepperPrevious>
              Back
            </button>
            <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" matStepperNext>
              Next
            </button>
          </div>

          <div *ngIf="isDebugging$ | async">
            <pre>imageFileName: {{ fileForm.get('imageFileName').valid }}</pre>
            <pre>imageUrl: {{ fileForm.get('imageUrl').valid }}</pre>
          </div>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          <div class="button-row">
            <button mat-raised-button aria-label="Close without saving" matTooltip="Close without saving"
              (click)="close()">
              Cancel
            </button>

            <button mat-raised-button color="primary" aria-label="Save and close" matToolip="Save and close" #saveButton
              (click)="save(currentUser)" [disabled]="form.invalid" *ngIf="getShowVehicleSaveButton(currentUser.role)">
              {{ saveButtonText }}
            </button>
            <div *ngIf="isDebugging$ | async">
              <pre>fileForm: {{ fileForm.valid }}</pre>
              <pre>vehicleInfoForm: {{ vehicleInfoForm.valid }}</pre>
              <pre>vehicleManeuverabilityInfoForm: {{ vehicleManeuverabilityInfoForm.valid }}</pre>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </mat-dialog-content>
</div>