import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';

import { DataSource, User } from '@shared/models';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import { ErrorService, LogService, SettingsService, UserService } from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class DataSourceService {
  private currentDataSourceSubject = new BehaviorSubject<DataSource>(null);
  private dataSourcesSubject = new BehaviorSubject<DataSource[]>([]);
  private dataSourceErrorSubject = new BehaviorSubject<string>(null);
  currentDataSource$: Observable<DataSource> = this.currentDataSourceSubject.asObservable();
  dataSources$: Observable<DataSource[]> = this.dataSourcesSubject.asObservable();
  dataSourceError$: Observable<string> = this.dataSourceErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) {
  }

  async createNewDataSource(payload, currentUser: User) {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (payload && currentUser) {
        const dataSources = _this.dataSourcesSubject.getValue();
        const newDataSources = dataSources.slice(0);
        const url = `${environment.baseAPIUrl}dataSource/create?userId=${currentUser?._id}`;
        let returnValue: DataSource;
  
        _this.httpClient
          .post(url, payload, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (newDataSource: DataSource) => {
              returnValue = newDataSource;
              _this.currentDataSourceSubject.next(newDataSource);
              newDataSources.push(newDataSource);
              _this.dataSourcesSubject.next(newDataSources);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.dataSourceErrorSubject.next(errMessage);
              _this.currentDataSourceSubject.next(null);
              reject(error);
            },
            complete: () => {
  
            }
          });
      } else {
        const errMessage = `payload and user are required to create a data source`;
        this.errorService.handleError(errMessage);
        this.dataSourceErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveDataSource(dataSourceId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && dataSourceId) {
        const dataSources = _this.dataSourcesSubject.getValue();
        const dataSourceIndex = dataSources.findIndex((dataSource) => dataSource._id === dataSourceId);
        const newDataSources = dataSources.slice(0);
        const url = `${environment.baseAPIUrl}dataSource/${dataSourceId}?userId=${currentUser?._id}`;
        let returnValue: DataSource;
  
        newDataSources[dataSourceIndex] = {
          ...dataSources[dataSourceIndex],
          ...changes,
        };
  
        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedDataSource: DataSource) => {
              returnValue = updatedDataSource;
              _this.dataSourcesSubject.next(newDataSources);
              _this.currentDataSourceSubject.next(updatedDataSource);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.dataSourceErrorSubject.next(errMessage);
              _this.currentDataSourceSubject.next(null);
              reject(error);
            },
            complete: () => {
  
            }
          });
      } else {
        const errMessage = `dataSourceId, changes and user are required to update a data source`;
        this.errorService.handleError(errMessage);
        this.dataSourceErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getCurrentDataSource() {
    return this.currentDataSourceSubject.getValue();
  }

  getDataSources(currentUser: User): Observable<DataSource[]> {
    const dataSourcesHttp$ = createHttpObservable(`${environment.baseAPIUrl}dataSource?userId=${currentUser?._id}`, {}, true);

    dataSourcesHttp$
      .pipe(
        catchError((err) => {
          console.error(`Error getting data sources: ${err}`);
          return of([]);
        })
      )
      .subscribe((dataSources: DataSource[]) => this.dataSourcesSubject.next(dataSources));

    return dataSourcesHttp$;
  }

  async getDataSourceById(dataSourceId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      let returnValue: DataSource;

      if (currentUser && dataSourceId) {
        _this.dataSources$
          .pipe(
            map((dataSources) => dataSources.find((dataSource) => dataSource._id === dataSourceId)),
            filter((dataSource) => !!dataSource)
          )
          .subscribe({
            next: (dataSource: DataSource) => {
              returnValue = dataSource;
              _this.currentDataSourceSubject.next(dataSource);
              resolve(returnValue);
            },
            error: (error: Error) => {
              const errMessage = _this.errorService.handleError(error);
              reject(errMessage);
            },
            complete: () => {

            }
          });
      } else {
        _this.currentDataSourceSubject.next(returnValue);
        resolve(returnValue);
      }
    });
  }
}
