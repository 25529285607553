<div style="min-height: 100%; height: 100%; min-width: 600px" *ngIf="currentUser$ | async as currentUser">
  <h2 mat-dialog-title>{{ formTitle }}</h2>

  <mat-dialog-content>
    <div
      *ngIf="{
        isDebugging: isDebugging$ | async,
        selectedShip: selectedShip$ | async,
        ships: ships$ | async,
        shipModelScanError: shipModelScanError$ | async,
        shipModelScanHint: shipModelScanHint$ | async,
        viewFormat: viewFormat$ | async
      } as data"
    >
      <form [formGroup]="form">
        <mat-horizontal-stepper [linear]="true" #stepper>
          <mat-step [stepControl]="projectInfoForm" errorMessage="Name is required.">
            <div [formGroup]="projectInfoForm">
              <ng-template matStepLabel>Name</ng-template>
              <h3>Project Name</h3>
              <mat-form-field appearance="fill">
                <mat-label>Project Name</mat-label>
                <input matInput type="text" placeholder="Project Name" formControlName="name" />
              </mat-form-field>

              <br>

              <mat-form-field>
                <mat-label>Project Description</mat-label>
                <textarea matInput rows="2" placeholder="Project Description" formControlName="description"> </textarea>
              </mat-form-field>
            </div>

            <br>
            <div *ngIf="data.isDebugging">
              <pre>description: {{ projectInfoForm.get('description').valid }}</pre>
              <pre>name: {{ projectInfoForm.get('name').valid }}</pre>
            </div>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" matStepperNext>
                Next
              </button>
            </div>
          </mat-step>

          <mat-step [stepControl]="fileForm">
            <div [formGroup]="fileForm">
              <ng-template matStepLabel>Image</ng-template>
              <h3>Project Image</h3>
              <div>
                <span>
                  <button (click)="imageFileInput.click()">
                    <mat-icon>library_add</mat-icon>
                    <span>Select Image File</span>
                    <input
                      #imageFileInput
                      type="file"
                      (change)="imageFileInputChange($event)"
                      accept="image/*"
                      style="display: none"
                    />
                  </button>
                </span>

                <br><br>

                <!-- Image Preview -->
                <div class="imagePreview" *ngIf="imageURL && imageURL !== ''">
                  <img
                    [src]="imageURL"
                    alt="{{ imageFileName }}"
                    style="width: 250px; height: auto"
                    onerror="this.onerror=null; this.src='../../../../assets/img/SDAT.png';"
                  />
                </div>
              </div>
            </div>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Back to previous step"
                matTooltip="Back to previous step"
                matStepperPrevious
              >
                Back
              </button>
              <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" matStepperNext>
                Next
              </button>
            </div>
          </mat-step>

          <mat-step [stepControl]="shipInfoForm" errorMessage="Ship is required">
            <div [formGroup]="shipInfoForm">
              <ng-template matStepLabel>Ship</ng-template>
              <div *ngIf="isNewProject; then newShipBlock; else existingShipBlock"></div>

              <ng-template #newShipBlock>
                <app-ship-list [ships]="data.ships"></app-ship-list>
              </ng-template>

              <ng-template #existingShipBlock>
                <h3>{{ existingShipTitle }}</h3>
              </ng-template>

              <div *ngIf="data.isDebugging">
                <br>
                <div>shipId: {{ shipInfoForm.get('shipId').valid }}</div>
              </div>
            </div>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Back to previous step"
                matTooltip="Back to previous step"
                matStepperPrevious
              >
                Back
              </button>
              <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" matStepperNext>
                Next
              </button>
            </div>
          </mat-step>

          <mat-step [stepControl]="shipModelScanForm" errorMessage="{{ data.shipModelScanError }}">
            <div [formGroup]="shipModelScanForm">
              <ng-template matStepLabel>Ship 3D Model / Scan</ng-template>

              <div
                *ngIf="{
                  selectedShip: selectedShip$ | async,
                  selectedShipMod: selectedShipMod$ | async,
                  selectedShipMods: selectedShipMods$ | async,
                  selectedShipModModel3d: selectedShipModModel3d$ | async,
                  selectedShipModScan: selectedShipModScan$ | async,
                  selectedShipModels3d: selectedShipModels3d$ | async,
                  selectedShipScans: selectedShipScans$ | async
                } as shipData"
              >
                <h3>{{ data.shipModelScanHint }}</h3>

                <mat-form-field appearance="fill" *ngIf="showShipModel3dSelect">
                  <mat-label>Ship 3D Model</mat-label>
                  <mat-select formControlName="shipModel3dId" (selectionChange)="onShipModel3dChange($event.value, currentUser)" [disabled]="isExistingProject">
                    <mat-option *ngIf="showShipModelsAndScansHint">--Select 3D Model--</mat-option>
                    <mat-option *ngFor="let model3d of shipData.selectedShipModels3d" [value]="model3d._id">
                      {{ model3d.name }} | Modeled: {{ model3d.modelDate | date: 'short' }} by
                      {{ model3d.nameOfPersonModeling }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" *ngIf="showShipScanSelect">
                  <mat-label>Ship Scan</mat-label>
                  <mat-select formControlName="shipScanId" (selectionChange)="onShipScanChange($event.value, currentUser)" [disabled]="isExistingProject">
                    <mat-option *ngIf="showShipModelsAndScansHint">--Select Scan--</mat-option>
                    <mat-option *ngFor="let scan of shipData.selectedShipScans" [value]="scan._id">
                      {{ scan.name }} | Scanned: {{ scan.scanDate | date: 'short' }} by {{ scan.nameOfPersonScanning }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div *ngIf="data.isDebugging">
                  <br>
                  <div>
                    shipModId: {{ shipModelScanForm.get('shipModId').value }}
                    {{ shipModelScanForm.get('shipModId').valid }}
                  </div>
                  <div>
                    shipModel3dId: {{ shipModelScanForm.get('shipModel3dId').value }}
                    {{ shipModelScanForm.get('shipModel3dId').valid }}
                  </div>
                  <div>
                    shipModSourceCollection: {{ shipModelScanForm.get('shipModSourceCollection').value }}
                    {{ shipModelScanForm.get('shipModSourceCollection').valid }}
                  </div>
                  <div>
                    shipModSourceId: {{ shipModelScanForm.get('shipModSourceId').value }}
                    {{ shipModelScanForm.get('shipModSourceId').valid }}
                  </div>
                  <div>
                    shipScanId: {{ shipModelScanForm.get('shipScanId').value }}
                    {{ shipModelScanForm.get('shipScanId').valid }}
                  </div>
                </div>
              </div>
            </div>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Back to previous step"
                matTooltip="Back to previous step"
                matStepperPrevious
              >
                Back
              </button>
              <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" matStepperNext>
                Next
              </button>
            </div>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Back to previous step"
                matTooltip="Back to previous step"
                matStepperPrevious
              >
                Back
              </button>
              <button
                mat-raised-button
                color="primary"
                aria-label="Save and close"
                matToolip="Save and close"
                #saveButton
                (click)="save(currentUser)"
                [disabled]="form.invalid"
              >
                {{ saveButtonText }}
              </button>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </form>
    </div>
  </mat-dialog-content>
</div>
