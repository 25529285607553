import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { User, Vehicle } from '@shared/models';
import {
  ErrorService,
  LogService,
  ProjectService,
  ScanService,
  SettingsService,
  UnrealServerService,
  UserService,
  VehicleDesignationService,
  VehicleService,
} from '@shared/services';
import { UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
  selector: 'app-vehicle-card',
  templateUrl: './vehicle-card.component.html',
  styleUrls: ['./vehicle-card.component.css'],
})
export class VehicleCardComponent implements OnInit {
  @Input()
  vehicle: Vehicle;

  currentUser$: Observable<User>;
  private vehicleErrorSubject = new BehaviorSubject<string>(null);
  vehicleError$: Observable<string> = this.vehicleErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private projectService: ProjectService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private VehicleDesignationService: VehicleDesignationService,
    private vehicleService: VehicleService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
  }

  get canDelete(): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === this.vehicle.creatorId) {
      let projectCount = (this.vehicle.usedInProjects ? this.vehicle.usedInProjects.length : 0)  
      + (this.vehicle.usedInProjects ? this.vehicle.usedInProjects.length : 0);
      if (projectCount === 0) {
        returnValue = true;
      }
    }

    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get vehicleDesignation(): string {
    return this.vehicle.designation || this.vehicle.modelName;
  }

  deleteVehicle(vehicle: Vehicle): void {
    const _this = this;
    const currentUser = this.userService.getCurrentUser();

    this.vehicleService
      .getVehicleById(vehicle._id, currentUser)
      .then((v: Vehicle) => {
        Swal.fire({
          title: `Delete Vehicle:  ${vehicle.name} | ${vehicle.designation}?`,
          showCancelButton: true,
          confirmButtonText: 'Delete',
        }).then((result) => {
          if (result.isConfirmed) {
            this.vehicleService
              .deleteVehicle(vehicle._id, currentUser)
              .then((vehicles: Vehicle[]) => {
                this.router.navigate(['/vehicles']);
              })
              .catch((error) => {
                if (_this.settingsService.getShowPopupErrorMessages()) {
                  Swal.fire(
                    'Error',
                    `There was an error deleting the vehicle.  Please email ${environment.techSupportEmail}.`,
                    'error'
                  );
                }
              });
          }
        });
      })
      .catch((error) => {
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error`,
            `There was an error getting the vehicle information to delete.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      });
  }

  viewVehicle(vehicle: Vehicle): void {
    const currentUser = this.userService.getCurrentUser();

    if (vehicle && currentUser) {
      this.settingsService.setIsLoading(true);
      //first, make sure there is no project selected, that will clear any project, ship and vehicle so that the appropriate values are there if they load the unreal viewer
      const promises = [];
      promises.push(this.projectService.getProjectById(null, currentUser));
      promises.push(this.vehicleService.getVehicleById(vehicle._id, currentUser));

      Promise.allSettled(promises)
        .then((results) => {
          if (results[1].status === 'fulfilled') {
            const navigationUrl = `/vehicles/${vehicle._id}`;
            this.router.navigateByUrl(navigationUrl);
          } else {
            this.settingsService.setIsLoading(false);
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                `Error Loading Vehicle`,
                `There was an error loading the vehicle information.  Please email  ${environment.techSupportEmail}.`,
                'error'
              );
            }
          }
        });
    } else {
      this.settingsService.setIsLoading(false);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error Displaying Vehicle',
          `Unable to load vehicle data.  Please email ${environment.techSupportEmail}`,
          'error'
        );
			}
    }
  }
}
