import { Component, Input, OnInit } from '@angular/core';
import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { ImageDoc, Project, Ship, User, Vehicle } from '@shared/models';
import { ErrorService, FileService, ImageDocService, LogService, SettingsService, UserService } from '@shared/services';
import { DbCollectionsEnum, UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

import { ReportItemDialogComponent } from '../../report/report-item-dialog/report-item-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-image-doc-card',
  templateUrl: './image-doc-card.component.html',
  styleUrls: ['./image-doc-card.component.css'],
})
export class ImageDocCardComponent implements OnInit {
  @Input()
  img: ImageDoc;

  @Input()
  project: Project;

  @Input()
  ship: Ship;

  @Input()
  vehicle: Vehicle;

  currentUser$: Observable<User>;
  private imgDocErrorSubject = new BehaviorSubject<string>(null);
  private imageSafeUrlSubject = new BehaviorSubject<SafeResourceUrl>(null);
  imgDocError$: Observable<string> = this.imgDocErrorSubject.asObservable();
  imageSafeUrl$: Observable<SafeResourceUrl> = this.imageSafeUrlSubject.asObservable();

  constructor(
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private errorService: ErrorService,
    private fileService: FileService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
  }

  get canDelete(): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === this.img.creatorId) {
      returnValue = true;
    }

    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get safeImageUrl(): SafeResourceUrl {
    return this.imageSafeUrlSubject.getValue();
  }

  async deleteImage(img: ImageDoc, currentUser: User): Promise<any> {
    const _this = this;
    let parentCollection, parentId;

    if (currentUser) {
      if (this.project) {
        parentCollection = DbCollectionsEnum.PROJECTS;
        parentId = this.project._id;
      } else if (this.ship) {
        parentCollection = DbCollectionsEnum.SHIPS;
        parentId = this.ship._id;
      } else if (this.vehicle) {
        parentCollection = DbCollectionsEnum.VEHICLES;
        parentId = this.vehicle._id;
      } else {
        _this.errorService.handleError(`Unable to delete imageId ${img._id} because it is not tied to a valid parent.`);
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error Deleting Image',
            `An image must be tied to a project, ship or vehicle.  Please email ${environment.techSupportEmail}`,
            'error'
          );
        }
      }

      if (parentCollection) {
        const title = `Do you want to delete the ${img.name} image`;

        Swal.fire({
          title: title,
          showCancelButton: true,
          confirmButtonText: 'Delete',
        }).then((result) => {
          if (result.isConfirmed) {
            this.imageDocService
              .deleteImage(img._id, parentCollection, parentId, currentUser)
              .then(function (deleteResults) {
                console.log(`deleted imageId ${img._id}`);
              })
              .catch(function (deleteError) {
                _this.errorService.handleError(`Error deleting imageId ${img._id}: ${deleteError}`);
                if (_this.settingsService.getShowPopupErrorMessages()) {
                  const errorTitle = 'Error Deleting Image';
                  Swal.fire(errorTitle, deleteError.message, 'error');
                }
              });
          }
        });
      }
    } else {
      const errMessage = _this.errorService.handleError(`User is required to delete an image`);
      if (_this.settingsService.getShowPopupErrorMessages()) {
        const errorTitle = 'Error Deleting Image';
        Swal.fire(errorTitle, errMessage, 'error');
      }
    }
  }

  async download(img: ImageDoc, currentUser: User) {
    if (img?.url && currentUser) {
      try {
        this.settingsService.setIsLoading(true);
        const downloadUrl = await this.fileService.getPresignedUrl(img.url, img.name, currentUser, false);
        this.imageSafeUrlSubject.next(this.domSanitizer.bypassSecurityTrustResourceUrl(downloadUrl));

        if (downloadUrl) {
          const element = document.getElementById('iframeForDownload_imageDocCard') as HTMLElement;
          if (element) {
            element.click();
          }
        }
      } catch (ex) {
        this.errorService.handleError(`Error downloading image ${this.img.name}: ${ex}`);
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Downloading Image ${this.img.name}`,
            `There was an error downloading the file.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      } finally {
        this.settingsService.setIsLoading(false);
      }
    } else {
      this.errorService.handleError(`No image was provided to download.`);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire('Error', `No image was provided to download.  Please email ${environment.techSupportEmail}.`, 'error');
      }
    }
  }

  editImage() {
    const currentUser = this.userService.getCurrentUser();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: currentUser,
      imageDoc: this.img,
    };

    const dialogRef = this.dialog.open(ReportItemDialogComponent, dialogConfig);
  }

  async viewImage(img: ImageDoc, currentUser: User) {
    if (img?.url && currentUser) {
      try {
        this.settingsService.setIsLoading(true);
        const downloadUrl = await this.fileService.getPresignedUrl(img.url, img.name, currentUser, true);
        if (downloadUrl) {
          window.open(downloadUrl, '_blank');
        }
      } catch (ex) {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Viewing Image ${img.name}`,
            `There was an error viewing the file.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      } finally {
        this.settingsService.setIsLoading(false);
      }
    } else {
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error',
          `No image was provided to view.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
			}
    }
  }
}
