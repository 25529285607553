import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { LogService, SettingsService } from '@shared/services';

import { environment } from '@environment';

//import { postmark } from 'postmark';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  name;

  constructor(private logService: LogService, private settingsService: SettingsService) {
    this.name = this.constructor.name;
  }

  async emailErrorMessage(error: any): Promise<any> {
    const _this = this;

    return new Promise(async (resolve, reject) => {
      //TODO...fix
      resolve('postmark is not implemented');

      /*
      const postmarkClient = new postmark.Client(environment.postmark.token);
      const htmlBody = `<p><b>${environment.appName}</b> encountered an error: ${error}.</p>`;
      let result;
  
      const mailOptions = {
        From: environment.techSupportEmail,
        To: environment.techSupportEmail,
        Subject: `${environment.appName} Error`,
        HtmlBoy: htmlBody
      };

      try {
        result = await postmarkClient.sendEmail(mailOptions);
      } catch (ex) {
        _this.handleError(`Error sending error email for ${environment.appName}: ${ex}`);
      } finally {
        resolve(result || '');
      }
        */
    })

  }

  handleError(error: any): string {
    let errMessage, errStack, returnValue;

    if (error instanceof HttpErrorResponse) {
      errMessage =  `There was an HTTP error: ${error.error}, status code: ${
        (error as HttpErrorResponse).status
      }`;
      this.logService.logError(
        errMessage,
        null
      );
      returnValue = error.error;
    } else if (error instanceof TypeError) {
      errMessage = `There was a type error: ${error.message}`;
      errStack = error.stack;
      this.logService.logError(`There was a type error: ${errMessage}`, errStack);
      returnValue = {'Message': error.message, 'Error Stack': errStack}.toString();
    } else if (error instanceof Error) {
      errMessage = `There was a general error: ${error.message}`;
      errStack = error.stack;
      this.logService.logError(`There was a general error: ${errMessage}`, errStack);
      returnValue = {'Message': error.message, 'Error Stack': errStack}.toString();
    } else {
      errMessage = error.toString();
      errStack = error.stack;
      this.logService.logError(`${errMessage}`, errStack);
      returnValue = {'Message': error.message, 'Error Stack': errStack}.toString();
    }

    if (this.settingsService.getEmailErrorMessages()) {
      this.emailErrorMessage(returnValue);
    } 

    return returnValue;
  }
}
