<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser">
  <div class="content">
    <ng-container *ngIf="{
        selectedUser: selectedUser$ | async,
        documents: userDocuments$ | async,
        images: userImages$ | async,
        userActivitySubscriptions: userActivitySubscriptions$ | async,
        userProjects: userProjects$ | async,
        userReports: userReports$ | async,
      } as data">
      <div *ngIf="data.selectedUser">
        <h1 style="display: inline-block">{{ data.selectedUser.fullName }}</h1>
        <div class="toolbar-action-buttons mat-toolbar-single-row">
          <a mat-stroked-button aria-label="Expand all" matTooltip="Expand all" (click)="accordion.openAll()">Expand
            All</a>
          <a mat-stroked-button aria-label="Collapse all" matTooltip="Collapse all"
            (click)="accordion.closeAll()">Collapse All</a>
          <a mat-stroked-button aria-label="Edit User" matTooltip="Edit User"
            (click)="editUser(data.selectedUser, currentUser)">
            <img src="assets/img/Edit.png" alt="Edit" />
            <span>&nbsp;&nbsp;Edit User</span>
          </a>

          <a mat-stroked-button *ngIf="showUnsubscribeButton" aria-label="Unsubscribe User from All Project, Ship and Vehicles Notifications"
            matTooltip="Unsubscribe User from All Project, Ship and Vehicles Notification"
            (click)="unsubscribeFromAll(data.selectedUser, currentUser)">
            <img src="assets/img/Unsubscribe.png" alt="Edit" />
            <span>&nbsp;&nbsp;Unsubscribe from All Notifications</span>
          </a>

          <a mat-stroked-button *ngIf="showSubscriptionSaveButton" aria-label="Save Subscription Changes"
            matTooltip="Save Subscription Changes"
            (click)="saveSubscriptionChanges()">
            <img src="assets/img/Save.png" alt="Save Subscription Changes" />
            <span>&nbsp;&nbsp;Save Subscription Changes</span>
          </a>
        </div>

        <div>
          <p *ngIf="errorMsg">{{ errorMsg }}</p>
        </div>

        <mat-accordion class="example-headers-align" multi>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>ABOUT</mat-panel-title>
              <mat-panel-description>Contact information and other basic information about this user.
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="user-grid-container">
              <div>
                <table style="width: 100%">
                  <tr>
                    <td style="width: 25%">Name</td>
                    <td style="width: 75%">{{ data.selectedUser.fullName }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Permission</td>
                    <td style="width: 75%">{{ data.selectedUser.role }}</td>
                  </tr>
                  <tr height="25px">
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Organization</td>
                    <td style="width: 75%">{{ data.selectedUser.organizationName }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Title / Position</td>
                    <td style="width: 75%">{{ data.selectedUser.title }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Employee ID</td>
                    <td style="width: 75%">{{ data.selectedUser.employeeId }}</td>
                  </tr>
                  <tr height="25px">
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Email</td>
                    <td style="width: 75%">{{ data.selectedUser.email }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Phone</td>
                    <td style="width: 75%">{{ data.selectedUser.phoneNumber | mask: '(000) 000-0000' }}</td>
                  </tr>
                </table>
              </div>
              <div>
                <img class="user-image" src="{{ data.selectedUser.imageUrl }} "
                  alt="{{ data.selectedUser.fullName }} image "
                  onerror="this.onerror=null; this.src='../../../assets/img/SDAT.png' ;" />
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>PROJECTS ({{ data.selectedUser.projectCount }})</mat-panel-title>
              <mat-panel-description>Links to the projects the user created. </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="data.userProjects">
              <app-project-overview-table [projectOverviews]="data.userProjects" [showPaginator]="true"
                [showFilter]="true"></app-project-overview-table>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>DOCUMENTS ({{ getNumberOfItemsInArray(data.documents) }})</mat-panel-title>
              <mat-panel-description>Links to documents this user has uploaded and is managing. </mat-panel-description>
            </mat-expansion-panel-header>

            <div *ngIf="data.documents">
              <app-document-table [documents]="data.documents" [showPaginator]="true"
                [showFilter]="true"></app-document-table>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>IMAGES ({{ getNumberOfItemsInArray(data.images) }}) </mat-panel-title>
              <mat-panel-description>Links to images this user has uploaded and is managing. </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="data.images">
              <app-image-doc-table [images]="data.images" [showPaginator]="true" [showFilter]="true">
              </app-image-doc-table>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>REPORTS ({{ data.selectedUser.reportCount }})</mat-panel-title>
              <mat-panel-description>Links to the reports this user has made using SDAT's report tool.
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div *ngIf="data.userReports">
              <app-report-overview-table [reportOverviews]="data.userReports" [showPaginator]="true"
                [showFilter]="true"></app-report-overview-table>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>USER SUBSCRIPTIONS ({{ getNumberOfItemsInArray(data.userActivitySubscriptions)
                }})</mat-panel-title>
              <mat-panel-description>Projects, Ships and Vehicles this user is subscription to activity notifications
                on.
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div>
              <app-user-subscriptions-table [selectedUser]="data.selectedUser" [showPaginator]="true"
                [showFilter]="true" [userSubscriptions]="data.userActivitySubscriptions"></app-user-subscriptions-table>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <canvas id="canvasForImageProcessing "></canvas>
      </div>
    </ng-container>
  </div>
</div>