import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, zip } from 'rxjs';
import { concatAll, catchError, map, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import {
  DataSource,
  Document,
  ImageDoc,
  Manufacturer,
  Mod,
  Model3d,
  Note,
  ReportSection,
  Scan,
  Scanner,
  Ship,
  ShipClass,
  ShipDesignation,
  User,
  Video
} from '@shared/models';
import {
  DataSourceService,
  DocumentService,
  ErrorService,
  FileService,
  ImageDocService,
  ManufacturerService,
  ModService,
  Model3dService,
  NoteService,
  ReportService,
  ScanService,
  ScannerService,
  SettingsService,
  ShipService,
  ShipClassService,
  ShipDesignationService,
  UnrealServerService,
  UserService,
  VideoService
} from '@shared/services';
import {
  FileObjectTypesEnum,
  DbCollectionsEnum,
  ReportSectionIdsEnum,
  ScanDisplayTypesEnum,
  TagFileTypesEnum,
  TagTypesEnum,
  UserRolesEnum,
  ScanTypesEnum,
} from '@shared/enums';
import { createTag } from '@shared/utils';

import { environment } from '@environment';

import { Model3dDialogComponent } from '../model3d/model3d-dialog/model3d-dialog.component';
import { ReportItemDialogComponent } from '../report/report-item-dialog/report-item-dialog.component';
import { ScanDialogComponent } from '../scan/scan-dialog/scan-dialog.component';
import { ShipDialogComponent } from './ship-dialog/ship-dialog.component';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
  selector: 'app-ship',
  templateUrl: './ship.component.html',
  styleUrls: ['./ship.component.css'],
})
export class ShipComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  activeVideoIndex: number;
  currentVideo: Video;
  errorMsg: boolean;
  errorText = 'Please select a file';
  documentFileObj: File;
  imageFileObj: File;
  snapshotFileObj: File;
  videoFileObj: File;
  allowShipModels3d$: Observable<boolean>;
  allowShipScans$: Observable<boolean>;
  currentUser$: Observable<User>;
  dataSources$: Observable<DataSource[]>;
  manufacturer$: Observable<Manufacturer>;
  manufacturers$: Observable<Manufacturer[]>;
  model3d$: Observable<Model3d>;
  models3d$: Observable<Model3d[]>;
  modelUploader$: Observable<User>;
  reportSections$: Observable<ReportSection[]>;
  scan$: Observable<Scan>;
  scanner$: Observable<Scanner>;
  scanners$: Observable<Scanner[]>;
  scanUploader$: Observable<User>;
  ship$: Observable<Ship>;
  shipClass$: Observable<ShipClass>;
  shipDesignation$: Observable<ShipDesignation>;
  shipClasses$: Observable<ShipClass[]>;
  shipDesignations$: Observable<ShipDesignation[]>;
  shipDocuments$: Observable<Document[]>;
  shipImages$: Observable<ImageDoc[]>;
  shipModels3d$: Observable<Model3d[]>;
  shipMod$: Observable<Mod>;
  shipMods$: Observable<Mod[]>;
  shipNotes$: Observable<Note[]>;
  shipPanoramicScans$: Observable<Scan[]>;
  shipPointCloudScans$: Observable<Scan[]>;
  shipScans$: Observable<Scan[]>;
  shipVideos$: Observable<Video[]>;
  decimationLevelForm: UntypedFormGroup;
  model3dForm: UntypedFormGroup;
  registrationErrorForm: UntypedFormGroup;
  scanForm: UntypedFormGroup;
  shipForm: UntypedFormGroup;
  shipDocumentation: UntypedFormArray;
  safeSrc: SafeResourceUrl;
  currentUser: User;
  isReadOnly: boolean;
  isOpeningUnrealViewer: boolean;
  events: string[] = [];
  videoData: any;

  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private dataSourceService: DataSourceService,
    private documentService: DocumentService,
    private errorService: ErrorService,
    private fileService: FileService,
    private imageDocService: ImageDocService,
    private manufacturerService: ManufacturerService,
    private modService: ModService,
    private model3dService: Model3dService,
    private noteService: NoteService,
    private reportService: ReportService,
    private scanService: ScanService,
    private scannerService: ScannerService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private shipClassService: ShipClassService,
    private shipDesignationService: ShipDesignationService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private videoService: VideoService,
  ) { }

  ngOnInit(): void {
    this.settingsService.setIsLoading(true);
    this.allowShipModels3d$ = this.shipService.allowShipModel3ds$;
    this.allowShipScans$ = this.shipService.allowShipScans$;
    this.currentUser$ = this.userService.currentUser$;
    this.dataSources$ = this.dataSourceService.dataSources$;
    this.manufacturer$ = this.scanService.currentShipScanManufacturer$;
    this.manufacturers$ = this.manufacturerService.manufacturers$;
    this.model3d$ = this.model3dService.currentShipModel3d$;
    this.models3d$ = this.model3dService.models3d$;
    this.modelUploader$ = this.model3dService.currentShipModel3dCreator$;
    this.reportSections$ = this.reportService.reportSections$;
    this.scan$ = this.shipService.currentShipScan$;
    this.ship$ = this.shipService.currentShip$;
    this.scanUploader$ = this.scanService.currentShipScanCreator$;
    this.scanner$ = this.scanService.currentShipScanScanner$;
    this.scanners$ = this.scannerService.manufacturerScanners$;
    this.shipClass$ = this.shipService.currentShipClass$;
    this.shipDesignation$ = this.shipService.currentShipDesignation$;
    this.shipDocuments$ = this.documentService.currentShipDocuments$;
    this.shipImages$ = this.imageDocService.currentShipImages$;
    this.shipModels3d$ = this.shipService.currentShipModels3d$;
    this.shipMod$ = this.modService.currentShipMod$;
    this.shipMods$ = this.modService.currentShipMods$;
    this.shipNotes$ = this.noteService.currentShipNotes$;
    this.shipPanoramicScans$ = this.shipService.currentShipPanoramicScans$;
    this.shipPointCloudScans$ = this.shipService.currentShipPointCloudScans$;
    this.shipScans$ = this.shipService.currentShipScans$;
    this.shipVideos$ = this.shipService.currentShipVideos$;
    this.isOpeningUnrealViewer = false;

    // see https://www.thinktecture.com/en/angular/rxjs-antipattern-1-nested-subs/
    zip(
      this.currentUser$,
      this.scan$,
      this.ship$,
      this.scanUploader$,
      this.scanner$,
      this.manufacturer$,
      this.shipClass$,
      this.shipDesignation$,
      this.shipDocuments$,
      this.shipImages$,
      this.shipMod$,
      this.shipMods$,
      this.shipNotes$,
      this.shipPanoramicScans$,
      this.shipPointCloudScans$,
      this.shipScans$,
      this.shipModels3d$,
      this.dataSources$,
      this.manufacturers$,
      this.scanners$,
      this.model3d$,
      this.models3d$,
      this.modelUploader$,
      this.reportSections$,
      this.allowShipModels3d$,
      this.allowShipScans$
    )
      .pipe(untilDestroyed(this))
      .pipe(
        map(
          ([
            user,
            scan,
            ship,
            scanUploader,
            scanner,
            manufacturer,
            shipClass,
            shipDesignation,
            shipDocuments,
            shipImages,
            shipMod,
            shipMods,
            shipNotes,
            shipPanoramicScans,
            shipPointCloudScans,
            shipScans,
            shipModels3d,
            dataSources,
            manufacturers,
            scanners,
            model3d,
            models3d,
            modelUploader,
            reportSections,
            allowShipModels3d,
            allowShipScans,
          ]) => ({
            user,
            scan,
            ship,
            scanUploader,
            scanner,
            manufacturer,
            shipClass,
            shipDesignation,
            shipDocuments,
            shipImages,
            shipMod,
            shipMods,
            shipNotes,
            shipPanoramicScans,
            shipPointCloudScans,
            shipScans,
            shipModels3d,
            dataSources,
            manufacturers,
            scanners,
            model3d,
            models3d,
            modelUploader,
            reportSections,
            allowShipModels3d,
            allowShipScans,
          })
        )
      )
      .subscribe(
        ({
          user,
          scan,
          ship,
          scanUploader,
          scanner,
          manufacturer,
          shipClass,
          shipDesignation,
          shipDocuments,
          shipImages,
          shipMod,
          shipMods,
          shipNotes,
          shipPanoramicScans,
          shipPointCloudScans,
          shipScans,
          shipModels3d,
          dataSources,
          manufacturers,
          scanners,
          model3d,
          models3d,
          modelUploader,
          reportSections,
          allowShipModels3d,
          allowShipScans,
        }) => {
          this.currentUser = user;
          const isAdmin = user && user.role === UserRolesEnum.ADMIN;
          this.isReadOnly = isAdmin ? false : true;

          if (allowShipScans) {
            this.decimationLevelForm = new UntypedFormGroup({
              value: new UntypedFormControl(scan && scan.decimationLevel ? scan.decimationLevel.value : 0,
                Validators.required
              ),
              units: new UntypedFormControl(scan && scan.decimationLevel ? scan.decimationLevel.units : 'mm',
                Validators.required
              ),
            });

            this.registrationErrorForm = new UntypedFormGroup({
              value: new UntypedFormControl(scan && scan.registrationError ? scan.registrationError.value : 0,
                Validators.required
              ),
              units: new UntypedFormControl(scan && scan.registrationError ? scan.registrationError.units : 'mm',
                Validators.required
              ),
            });

            this.scanForm = this.fb.group({
              _id: [scan ? scan._id : '', Validators.required],
              classification: [scan ? scan.classification : 'Unclass',
              Validators.required,
              ],
              creatorId: [scan ? scan.creatorId : '', Validators.required],
              dataSourceId: [scan ? scan.dataSourceId : '', Validators.required],
              decimationLevel: this.decimationLevelForm,
              description: [scan ? scan.description : ''],
              editorId: [this.currentUser ? this.currentUser._id : '', Validators.required],
              manufacturerId: [manufacturer ? manufacturer._id : '', Validators.required],
              nameOfPersonScanning: [scan ? scan.nameOfPersonScanning : '',
              Validators.required,
              ],
              registrationError: this.registrationErrorForm,
              scanDate: [scan && scan.scanDate ? scan.scanDate : '',
              Validators.required,
              ],
              scanUploader: [scanUploader ? scanUploader.fullName : '', Validators.required],
              scannerId: [scan ? scan.scannerId : '', Validators.required],
              scanType: [scan ? scan.scanType : '', Validators.required],
            });
          }

          if (allowShipModels3d) {
            this.model3dForm = this.fb.group({
              _id: [model3d ? model3d._id : '', Validators.required],
              classification: [model3d ? model3d.classification : 'Unclass',
              Validators.required,
              ],
              creatorId: [model3d ? model3d.creatorId : '', Validators.required],
              dataSourceId: [model3d ? model3d.dataSourceId : '', Validators.required],
              description: [model3d ? model3d.description : ''],
              editorId: [this.currentUser ? this.currentUser._id : '', Validators.required],
              fidelityType: [{ value: model3d ? model3d.fidelityType : '' }, Validators.required],
              nameOfPersonModeling: [model3d ? model3d.nameOfPersonModeling : '', Validators.required],
              modelDate: [model3d && model3d.modelDate ? model3d.modelDate : '', Validators.required],
              modelUploader: [modelUploader ? modelUploader.fullName : '', Validators.required],
            });
          }

          this.shipDocumentation = new UntypedFormArray([]);
          this.shipForm = this.fb.group({
            _id: [ship ? ship._id : '', Validators.required],
            documentation: this.shipDocumentation,
            editorId: [this.currentUser ? this.currentUser._id : '', Validators.required],
          });

          if (shipDocuments && Array.isArray(shipDocuments) && shipDocuments.length > 0) {
            for (let x = 0; x < shipDocuments.length; x++) {
              this.shipDocumentation.push(new UntypedFormControl(shipDocuments[x]));
            }
          }

          this.settingsService.setIsLoading(false);
        }
      );
  }

  ngOnDestroy(): void {
  }

  get hasDocumentFileName(): boolean {
    let returnValue = false;

    if (this.documentFileObj && this.documentFileObj.name !== null) {
      returnValue = true;
    }

    return returnValue;
  }

  get hasImageFileName(): boolean {
    let returnValue = false;

    if (this.imageFileObj && this.imageFileObj.name !== null) {
      returnValue = true;
    }

    return returnValue;
  }

  get hasVideoFileName(): boolean {
    let returnValue = false;

    if (this.videoFileObj && this.videoFileObj.name !== null) {
      returnValue = true;
    }

    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  getIsGettingNotifications(ship: Ship): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (ship && user) {
      const idx = ship.userIdsToSendChangeNotificationsTo.indexOf(user._id);
      if (idx != -1) {
        returnValue = true;
      }
    }

    return returnValue;
  }

  get getIsReadOnly(): boolean {
    return this.isReadOnly;
  }

  async addNote(ship: Ship) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: this.currentUser,
      isNewNote: true,
      ship: ship,
    };

    const dialogRef = this.dialog.open(ReportItemDialogComponent, dialogConfig);
  }

  async addSnapshotNote(ship: Ship, imgDoc: ImageDoc) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: this.currentUser,
      imageDoc: imgDoc,
      isNewNote: true,
      ship: ship,
    };

    const dialogRef = this.dialog.open(ReportItemDialogComponent, dialogConfig);
  }

  createModel3d(ship: Ship, currentUser: User): void {
    const model3d: Model3d = {
      _id: new ObjectID().toString(),
      classification: 'Unclass',
      creatorId: currentUser._id,
      fidelityType: 'Engineering',
      modelUploader: currentUser.fullName,
      name: '',
      nameOfPersonModeling: '',
      parent: {
        _id: ship._id,
        collection: DbCollectionsEnum.SHIPS,
      }
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: currentUser,
      isNewModel: true,
      model3d: model3d,
      parentShip: ship,
    };

    const dialogRef = this.dialog.open(Model3dDialogComponent, dialogConfig);
  }

  createScan(ship: Ship, currentUser: User): void {
    const scan: Scan = {
      _id: new ObjectID().toString(),
      name: '',
      creatorId: currentUser._id,
      editorId: currentUser._id,
      scanUploader: currentUser.fullName,
      parent: {
        _id: ship._id,
        collection: DbCollectionsEnum.SHIPS,
      },
      scanDisplayType: ScanDisplayTypesEnum.POINT_CLOUD,
      scanType: ScanTypesEnum.RAW,
      siteFileName: ''
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: currentUser,
      isNewScan: true,
      parentShip: ship,
      scan: scan,
    };

    const dialogRef = this.dialog.open(ScanDialogComponent, dialogConfig);
  }

  async deleteVideo(video: Video, ship: Ship): Promise<any> {
    const _this = this;
    const title = `Do you want to delete the ${video.name} video?`;

    Swal.fire({
      title: title,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        this.videoService
          .deleteVideo(video._id, DbCollectionsEnum.SHIPS, ship._id, _this.currentUser)
          .then(function (deleteResults) {
            console.log(`deleted videoId ${video._id}`);
          })
          .catch(function (deleteError) {
            if (_this.settingsService.getShowPopupErrorMessages()) {
              let errorTitle = 'Error Deleting Video';
              Swal.fire(errorTitle, deleteError.message, 'error');
            }
          });
      }
    });
  }

  async documentFileInputChange($event: Event): Promise<any> {
    const _this = this;
    _this.errorMsg = false;
    const fileEl = $event.target as HTMLInputElement;
    const ship = _this.shipService.getCurrentShip();
    const shipClass = _this.shipService.getCurrentShipClass();

    if (fileEl?.files.length > 0 && ship) {
      const errors = [];
      const promises = [];
      const documentTypes = [];
      const fileTags = [];
      const docTags = [];
      const files = fileEl.files;

      for (let x = 0; x < files.length; x++) {
        const FILE = files[x];
        const fileType1 = FILE.type;
        let documentType = '';

        //get document type
        if (fileType1.indexOf('audio') > -1) {
          documentType = TagFileTypesEnum.AUDIO;
        } else if (fileType1.indexOf('image') > -1) {
          documentType = TagFileTypesEnum.IMAGE;
        } else if (fileType1.indexOf('video') > -1) {
          documentType = TagFileTypesEnum.VIDEO;
        } else {
          const inputOptions = {
            Picture: 'Image',
            Manual: 'Manual',
            Other: 'Other',
          };

          const { value: docType } = await Swal.fire({
            title: `Select Document Type for file: ${FILE.name}`,
            input: 'radio',
            inputOptions: inputOptions,
            inputValidator: (value) => {
              if (!value) {
                return 'Please select the document type';
              }
            },
          });

          if (docType) {
            documentType = docType;
          }
        }

        documentTypes.push(documentType || '');

        const tag = createTag(TagTypesEnum.FILE_TYPE, documentType);
        fileTags.push({
          fileName: FILE.name,
          tags: tag
        });
        docTags.push({
          fileName: FILE.name,
          tags: [tag.value, 'ship', ship.name, shipClass.class, ship.designationWithHullNumber]
        });

        promises.push(_this.fileService.uploadFile(FILE, FileObjectTypesEnum.SHIP, ship._id, fileTags[x].tags, documentType, _this.currentUser))
      }

      _this.settingsService.setIsLoading(true);
      await Promise.allSettled(promises)
        .then(async (results) => {
          const shipPromises = [];
          for (let y = 0; y < results.length; y++) {
            const result = results[y];

            if (result.status === 'rejected') {
              errors.push(result.reason);
            } else {

              const doc: Document = {
                _id: new ObjectID().toString(),
                name: result.value.nameWithoutExtension,
                documentType: documentTypes[y],
                encodedVideoUrl: result.value.encodedVideoUrl,
                parent: {
                  _id: ship._id,
                  collection: DbCollectionsEnum.SHIPS,
                },
                url: result.value.locationUrl,
                thumbnailUrl: result.value.thumbnailUrl || environment.defaultThumbnailImageUrl,
                creatorId: this.currentUser._id,
                tags: docTags[y].tags,
              };

              shipPromises.push(_this.shipService.addDocument(doc, ship, _this.currentUser));
            }

            shipPromises.push(_this.documentService.refreshDocumentsByParent(DbCollectionsEnum.SHIPS, ship._id, _this.currentUser));
          }

          await Promise.allSettled(shipPromises)
            .then((shipResults) => {
              let isProcessing = false;
              shipResults.forEach((pResult) => {
                if (pResult.status === 'rejected') {
                  errors.push(pResult.reason);
                } else {
                  if (pResult.value.processing) {
                    isProcessing = true;
                  }
                }
              });

              if (errors.length > 0) {
                _this.errorService.handleError(`Error uploading documents for shipId ${ship._id}:  ${errors.join(',')}`);
              }

              _this.settingsService.setIsLoading(false);
              if (isProcessing) {
                let message = 'Due to the large file size, your files are being uploaded in the background.  You will be emailed a success or failure message upon completion.';

                if (errors.length > 0) {
                  message += `\n\nPlease note that there were some errors processing your files.  Contact ${environment.techSupportEmail} with any questions.`
                }

                Swal.fire(
                  'File Upload in Process',
                  message,
                  'info'
                );
              } else if (errors.length > 0 && _this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  'Error Uploading Ship Document(s)',
                  `There were one or more error processing your documents.  Please email ${environment.techSupportEmail}`,
                  'error'
                );
              }
            });
        });
    }
  }

  getNumberOfItemsInArray(items) {
    let returnValue = 0;

    if (items && Array.isArray(items)) {
      returnValue = items.length;
    }

    return returnValue;
  }

  getVideoNotes(shipNotes: Note[], videoId: string) {
    if (shipNotes && shipNotes.length > 0) {
      return shipNotes.filter((note) => note.videoId === videoId);
    }
  }

  async imageFileInputChange($event: Event): Promise<any> {
    const _this = this;
    _this.errorMsg = false;
    const fileEl = $event.target as HTMLInputElement;
    const ship = _this.shipService.getCurrentShip();

    if (fileEl?.files.length > 0 && ship) {
      _this.settingsService.setIsLoading(true);
      const errors = [];
      const promises = [];
      const files = fileEl.files;
      let imgType = TagFileTypesEnum.IMAGE;
      const tags = [];
      const imgTags = [];
      const tag = createTag(TagTypesEnum.FILE_TYPE, imgType);
      tags.push(tag);
      imgTags.push(tag.value);
      imgTags.push('ship');
      imgTags.push(ship.name);
      imgTags.push(ship.designationWithHullNumber);

      for (let x = 0; x < files.length; x++) {
        const FILE = files[x];
        promises.push(_this.fileService.uploadFile(FILE, FileObjectTypesEnum.SHIP, ship._id, tags, imgType, this.currentUser))
      };

      await Promise.allSettled(promises)
        .then(async (results) => {
          const shipPromises = [];
          results.forEach((result) => {
            if (result.status === 'rejected') {
              errors.push(result.reason);
            } else {
              const imageDoc: ImageDoc = {
                _id: new ObjectID().toString(),
                displayThumbnailUrl: result.value.thumbnailDisplayUrl,
                displayUrl: result.value.locationDisplayUrl,
                isMainImage: false,
                isSnapshot: false,
                name: result.value.nameWithoutExtension,
                parent: {
                  _id: ship._id,
                  collection: DbCollectionsEnum.SHIPS,
                },
                url: result.value.locationUrl,
                thumbnailUrl: result.value.thumbnailUrl || environment.defaultThumbnailImageUrl,
                creatorId: this.currentUser._id,
                tags: imgTags,
              };

              shipPromises.push(_this.shipService.addImage(imageDoc, ship, _this.currentUser));
            }

            shipPromises.push(_this.imageDocService.refreshImagesByParent(DbCollectionsEnum.SHIPS, ship._id, false, _this.currentUser))
          });

          await Promise.allSettled(shipPromises)
            .then((shipResults) => {
              let isProcessing = false;
              shipResults.forEach((pResult) => {
                if (pResult.status === 'rejected') {
                  errors.push(pResult.reason);
                } else {
                  if (pResult.value.processing) {
                    isProcessing = true;
                  }
                }
              });

              if (errors.length > 0) {
                _this.errorService.handleError(`Error uploading images for shipId ${ship._id}:  ${errors.join(',')}`);
              }

              _this.settingsService.setIsLoading(false);
              if (isProcessing) {
                let message = 'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.';

                if (errors.length > 0) {
                  message += `\n\nPlease note that there were some errors processing your files.  Contact ${environment.techSupportEmail} with any questions.`
                }

                Swal.fire(
                  'File Upload in Process',
                  message,
                  'info'
                );
              } else if (errors.length > 0 && _this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  'Error Uploading Ship Image(s)',
                  `There were one or more error processing your images.  Please email ${environment.techSupportEmail}`,
                  'error'
                );
              }
            });
        });

    } else {
      _this.errorMsg = true;
      _this.errorText = 'Please select an image';
    }
  }

  async snapshotFileInputChange($event: Event): Promise<any> {
    const _this = this;
    this.errorMsg = false;
    const FILE = ($event.target as HTMLInputElement).files[0];
    this.snapshotFileObj = FILE;
    const reportSections = this.reportService.getReportSections();

    if (!this.snapshotFileObj) {
      this.errorMsg = true;
      this.errorText = 'Please select a snapshot';
    } else if (!reportSections || reportSections.length == 0) {
      this.errorMsg = true;
      this.errorText = 'Unable to locate report sections to add snapshot to';
      this.errorService.handleError(this.errorText);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire('Error Adding Snapshot', `${this.errorText}.  Please email ${environment.techSupportEmail}`, 'error');
      }
    } else {
      this.settingsService.setIsLoading(true);
      const fileType1 = this.snapshotFileObj.type;
      const fileType = this.snapshotFileObj.name
        .substring(this.snapshotFileObj.name.lastIndexOf('.') + 1)
        .toUpperCase();
      const ship = this.shipService.getCurrentShip();
      let imgType = TagFileTypesEnum.SNAPSHOT;
      let validReportSections = this.reportService.getReportSectionsUserCanAddNoteTo(DbCollectionsEnum.SHIPS);
      let reportSectionId, reportSection;

      const tags = [];
      const imgTags = [];
      const tag = createTag(TagTypesEnum.FILE_TYPE, imgType);
      tags.push(tag);
      imgTags.push(tag.value);
      imgTags.push('ship');
      imgTags.push(ship.name);
      imgTags.push(ship.designationWithHullNumber);

      if (validReportSections.length > 0) {
        if (validReportSections.length === 1) {
          reportSectionId = validReportSections[0]._id;
        } else {
          const reportSectionOptions = {};
          validReportSections.map((reportSection) => {
            reportSectionOptions[reportSection._id] = reportSection.titleCase;
          });

          const { value: selectedReportSection } = await Swal.fire({
            title: 'Select Report Section for the Snapshot',
            input: 'select',
            inputOptions: reportSectionOptions,
            inputPlaceholder: 'Select a report section',
            showCancelButton: true,
          });

          if (selectedReportSection) {
            reportSectionId = selectedReportSection;
          }
        }

        reportSectionId = reportSectionId || ReportSectionIdsEnum.SNAPSHOT;
        reportSection = reportSections.find((reportSection) => reportSection._id === reportSectionId);

        this.fileService
          .uploadFile(this.snapshotFileObj, FileObjectTypesEnum.SHIP, ship._id, tags, imgType, this.currentUser)
          .then((results) => {
            this.settingsService.setIsLoading(true);

            const imageDoc: ImageDoc = {
              _id: new ObjectID().toString(),
              displayThumbnailUrl: results.thumbnailDisplayUrl,
              displayUrl: results.locationDisplayUrl,
              isMainImage: false,
              isSnapshot: true,
              name: results.nameWithoutExtension,
              parent: {
                _id: ship._id,
                collection: DbCollectionsEnum.SHIPS,
              },
              reportSectionId: reportSectionId,
              url: results.locationUrl,
              thumbnailUrl: results.thumbnailUrl || environment.defaultThumbnailImageUrl,
              creatorId: this.currentUser._id,
              tags: imgTags,
            };

            this.shipService
              .addImage(imageDoc, ship, this.currentUser)
              .then((shipResults) => {
                this.errorMsg = false;
                this.errorText = '';
                this.settingsService.setIsLoading(false);

                if (results.processing) {
                  Swal.fire(
                    'File Upload in Process',
                    'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.',
                    'info'
                  );
                }
              })
              .catch((shipError) => {
                _this.settingsService.setIsLoading(false);
                _this.errorMsg = true;
                _this.errorText = `${shipError}`;
                if (_this.settingsService.getShowPopupErrorMessages()) {
                  Swal.fire(
                    'Error Saving Ship Snapshot',
                    `${shipError}.  Please email ${environment.techSupportEmail} with any questions.`,
                    'error'
                  );
                }
              });
          })
          .catch((uploadError) => {
            _this.settingsService.setIsLoading(false);
            _this.errorService.handleError(`Error uploading ship snapshot: ${uploadError}`);
            _this.errorMsg = true;
            _this.errorText = uploadError.message;
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error Uploading Snapshot',
                `There was an error uploading the snapshot: ${uploadError.message}.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          });
      } else {
        const errMessage = _this.errorService.handleError(
          `Report sections cannot be found so a snapshot cannot be added.`
        );
        _this.errorMsg = true;
        _this.errorText = errMessage;
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Adding Snapshot`,
            `Invalid note report section.  Please email ${environment.techSupportEmail}`,
            'error'
          );
        }
      }
    }
  }

  editShip(ship: Ship, currentUser: User) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: currentUser,
      scan: null,
      ship: ship,
      isNewShip: false,
    };

    const dialogRef = this.dialog.open(ShipDialogComponent, dialogConfig);
  }

  getReportSectionId(reportSection: string) {
    return ReportSectionIdsEnum[reportSection];
  }

  getReportSectionTitleCase(reportSections: ReportSection[], reportSectionId: string) {
    let returnValue = '';
    let matching;

    if (Array.isArray(reportSections) && reportSections.length > 0 && reportSectionId) {
      matching = reportSections.find((reportSection) => reportSection._id === reportSectionId);
    }

    returnValue = matching ? matching.titleCase : '';
    return returnValue;
  }

  getReportSectionsUserCanAddNoteTo(reportSections: ReportSection[], parentCollection: string) {
    return reportSections.filter((reportSection) => {
      let returnValue = true;
      const idx = reportSection.parentCollections.indexOf(parentCollection);

      if (!reportSection.userCanAddNotes || idx === -1) {
        returnValue = false;
      }

      return returnValue;
    });
  }

  getNonSnapshotImages(shipImages: ImageDoc[]) {
    if (shipImages && shipImages.length > 0) {
      return shipImages.filter((img) => !img.isSnapshot);
    }
  }

  getNonSnapshotNotes(shipNotes: Note[]) {
    if (shipNotes && shipNotes.length > 0) {
      return shipNotes.filter((note) => !note.imageId);
    }
  }

  getSnapshotImages(shipImages: ImageDoc[]) {
    if (shipImages && shipImages.length > 0) {
      return shipImages.filter((img) => img.isSnapshot);
    }
  }

  getSnapshotNotes(shipNotes: Note[], imageId: string) {
    if (shipNotes && shipNotes.length > 0) {
      return shipNotes.filter((note) => note.imageId === imageId);
    }
  }

  initVideo() {
    this.videoData.play();
  }

  nextVideo(): void {
    const shipVideos = this.shipService.getCurrentShipVideos();
    this.activeVideoIndex += 1;
    if (this.activeVideoIndex == shipVideos.length) {
      this.activeVideoIndex = 0;
    }
    this.currentVideo = shipVideos[this.activeVideoIndex];
  }

  startVideoPlaylist(video: Video, index: number): void {
    this.activeVideoIndex = index;
    this.currentVideo = video;
  }

  updateNotifications(getNotifications: boolean, ship: Ship) {
    const user = this.userService.getCurrentUser();

    if (user && ship) {
      const idx = ship.userIdsToSendChangeNotificationsTo.indexOf(user._id);
      let changes = {
        editorId: this.currentUser._id,
        userIdsToSendChangeNotificationsTo: ship.userIdsToSendChangeNotificationsTo
      };
      let madeChanges = false;

      if (getNotifications && idx === -1) {
        madeChanges = true;
        changes.userIdsToSendChangeNotificationsTo.push(user._id);
      } else if (!getNotifications && idx > -1) {
        madeChanges = true;
        delete changes.userIdsToSendChangeNotificationsTo[idx];
      }

      if (madeChanges) {
        this.settingsService.setIsLoading(true);
        this.shipService
          .saveShip(ship._id, changes, user)
          .then((updatedShip: Ship) => {
            this.settingsService.setIsLoading(false);
          })
          .catch((error) => {
            this.settingsService.setIsLoading(false);
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error',
                `There was an error updating your notifications preference.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          });
      }
    }
  }

  //see https://www.positronx.io/angular-video-player-using-ngx-videogular-with-customized-controls-example/
  videoPlayerInit(vData: any) {
    this.videoData = vData;
    this.videoData.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.initVideo.bind(this));
    this.videoData.getDefaultMedia().subscriptions.ended.subscribe(this.nextVideo.bind(this));
  }

  async videoFileInputChange($event: Event): Promise<any> {
    const _this = this;
    _this.errorMsg = false;
    const fileEl = $event.target as HTMLInputElement;
    const ship = _this.shipService.getCurrentShip();

    if (fileEl?.files.length > 0 && ship) {
      _this.settingsService.setIsLoading(true);
      const errors = [];
      const promises = [];
      const files = fileEl.files;
      const tags = [];
      const videoTags = [];
      const tag = createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.VIDEO);
      tags.push(tag);
      videoTags.push(tag.value);
      videoTags.push('ship');
      videoTags.push(ship.name);

      for (let x = 0; x < files.length; x++) {
        const FILE = files[x];
        promises.push(_this.fileService.uploadFile(FILE, FileObjectTypesEnum.SHIP, ship._id, tags, TagFileTypesEnum.VIDEO, _this.currentUser))
      };

      await Promise.allSettled(promises)
        .then(async (results) => {
          const shipPromises = [];
          results.forEach((result) => {
            if (result.status === 'rejected') {
              errors.push(result.reason);
            } else {
              const video: Video = {
                _id: new ObjectID().toString(),
                encodedUrl: result.value.encodedVideoUrl,
                name: result.value.nameWithoutExtension,
                parent: {
                  _id: ship._id,
                  collection: DbCollectionsEnum.SHIPS,
                },
                url: result.value.locationUrl,
                creatorId: this.currentUser._id,
                tags: videoTags,
              };

              shipPromises.push(_this.shipService.addVideo(video, ship, _this.currentUser));
            }

            shipPromises.push(_this.videoService.refreshVideosByParent(DbCollectionsEnum.SHIPS, ship._id, _this.currentUser));
          });

          await Promise.allSettled(shipPromises)
            .then((shipResults) => {
              let isProcessing = false;
              shipResults.forEach((pResult) => {
                if (pResult.status === 'rejected') {
                  errors.push(pResult.reason);
                } else {
                  if (pResult.value.processing) {
                    isProcessing = true;
                  }
                }
              });

              if (errors.length > 0) {
                _this.errorService.handleError(`Error uploading videos for shipId ${ship._id}:  ${errors.join(',')}`);
              }

              _this.settingsService.setIsLoading(false);
              if (isProcessing) {
                let message = 'Due to the large file size, your files are being uploaded in the background.  You will be emailed a success or failure message upon completion.';

                if (errors.length > 0) {
                  message += `\n\nPlease note that there were some errors processing your files.  Contact ${environment.techSupportEmail} with any questions.`
                }

                Swal.fire(
                  'File Upload in Process',
                  message,
                  'info'
                );
              } else if (errors.length > 0 && _this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  'Error Uploading Ship Video(s)',
                  `There were one or more error processing your videos.  Please email ${environment.techSupportEmail}`,
                  'error'
                );
              }
            });
        });
    } else {
      _this.errorMsg = true;
      _this.errorText = 'Please select a video';
    }
  }
}
