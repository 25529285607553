import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';

import { VehicleDesignation, User } from '@shared/models';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import {
  ErrorService,
  LogService,
  SettingsService,
  UserService
} from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class VehicleDesignationService {
  private currentVehicleDesignationSubject = new BehaviorSubject<VehicleDesignation>(null);
  private vehicleDesignationsSubject = new BehaviorSubject<VehicleDesignation[]>([]);
  private vehicleClassDesignationsSubject = new BehaviorSubject<VehicleDesignation[]>([]);
  private vehicleDesignationErrorSubject = new BehaviorSubject<string>(null);
  currentVehicleDesignation$: Observable<VehicleDesignation> = this.currentVehicleDesignationSubject.asObservable();
  vehicleDesignations$: Observable<VehicleDesignation[]> = this.vehicleDesignationsSubject.asObservable();
  vehicleClassDesignations$: Observable<VehicleDesignation[]> = this.vehicleClassDesignationsSubject.asObservable();
  vehicleDesignationError$: Observable<string> = this.vehicleDesignationErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) { 
  }

  async createNewVehicleDesignation(payload: VehicleDesignation, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && payload) {
        _this.settingsService.setIsLoading(true);
        const vehicleDesignations = _this.vehicleDesignationsSubject.getValue();
        const newVehicleDesignations = vehicleDesignations.slice(0);
        const url = `${environment.baseAPIUrl}vehicleDesignation/create?userId=${currentUser?._id}`;
        let returnValue: VehicleDesignation;
  
        _this.httpClient
          .post(url, payload, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (newVehicleDesignation: VehicleDesignation) => {
              returnValue = newVehicleDesignation;
              _this.currentVehicleDesignationSubject.next(newVehicleDesignation);
              newVehicleDesignations.push(newVehicleDesignation);
              _this.vehicleDesignationsSubject.next(newVehicleDesignations);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.vehicleDesignationErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        const errMessage = `payload and user are required to create a vehicle designation`;
        _this.errorService.handleError(errMessage);
        _this.vehicleDesignationErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveVehicleDesignation(vehicleDesignationId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && vehicleDesignationId) {
        _this.settingsService.setIsLoading(true);
        const vehicleDesignations = _this.vehicleDesignationsSubject.getValue();
        const vehicleDesignationIndex = vehicleDesignations.findIndex(
          (vehicleDesignation) => vehicleDesignation._id === vehicleDesignationId
        );
        const newVehicleDesignations = vehicleDesignations.slice(0);
        const url = `${environment.baseAPIUrl}vehicleDesignation/${vehicleDesignationId}?userId=${currentUser?._id}`;
        let returnValue: VehicleDesignation;
  
        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedVehicleDesignation: VehicleDesignation) => {
              returnValue = updatedVehicleDesignation;
              newVehicleDesignations[vehicleDesignationIndex] = updatedVehicleDesignation;
              _this.currentVehicleDesignationSubject.next(updatedVehicleDesignation);
              _this.vehicleDesignationsSubject.next(newVehicleDesignations);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.vehicleDesignationErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        const errMessage = `changes, vehicleDesignationId and user are required to update a vehicle designation`;
        _this.errorService.handleError(errMessage);
        _this.vehicleDesignationErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getCurrentVehicleDesignation() {
    return this.currentVehicleDesignationSubject.getValue();
  }

  getVehicleDesignations(currentUser: User): Observable<VehicleDesignation[]> {
    const vehicleDesignationsHttp$ = createHttpObservable(`${environment.baseAPIUrl}vehicleDesignation?userId=${currentUser?._id}`, {}, true);

    vehicleDesignationsHttp$
      .pipe(
        catchError((err) => {
          console.error(`Error getting vehicle designations: ${err}`);
          return of([]);
        })
      )
      .subscribe((vehicleDesignations: VehicleDesignation[]) =>
        this.vehicleDesignationsSubject.next(vehicleDesignations)
      );

    return vehicleDesignationsHttp$;
  }

  getVehicleDesignationById(vehicleDesignationId: string): any {
    if (vehicleDesignationId) {
      return this.vehicleDesignations$
        .pipe(
          map((vehicleDesignations) =>
            vehicleDesignations.find((vehicleDesignation) => vehicleDesignation._id === vehicleDesignationId)
          ),
          filter((vehicleDesignation) => !!vehicleDesignation)
        )
        .subscribe((vehicleDesignation) => this.currentVehicleDesignationSubject.next(vehicleDesignation));
    } else {
      this.currentVehicleDesignationSubject.next(null);
    }
  }
}
