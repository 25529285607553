<div class="content" *ngIf="currentUser$ | async as currentUser">
  <mat-card appearance="outlined" class="document-card mat-elevation-z10">
    <mat-card-header (click)="viewSnapshot(img, currentUser)">
      <mat-card-title>{{ img.name }}</mat-card-title>
      <mat-card-subtitle>Report Section: {{ getReportSectionTitleCase(img.reportSectionId) }} </mat-card-subtitle>
    </mat-card-header>
    <img
      mat-card-image
      src="{{ img.displayThumbnailUrl || img.displayUrl || img.url }}"
      alt="Image for {{ img.name }}"
      (click)="viewSnapshot(img, currentUser)"
      onerror="this.onerror=null; this.src='../../../assets/img/SDAT.png';"
    />
    <mat-card-content>
      <div *ngIf="getSnapshotNotes() as snapshotNotes">
        <app-note-table
          [notes]="snapshotNotes"
          [project]="project"
          [reportSections]="reportSections"
          [ship]="ship"
          [vehicle]="vehicle"
          [isSnapshotOrVideoNotes]="true"
        ></app-note-table>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-icon-button
        color="warn"
        aria-label="Delete Snapshot"
        matTooltip="Delete Snapshot"
        *ngIf="canDelete"
        (click)="deleteSnapshot()"
      >
        <img src="assets/img/TrashGray.png" alt="Delete" />
      </button>
      <button
        mat-icon-button
        color="primary"
        aria-label="Edit Snapshot Name and Report Section"
        matTooltip="Edit Snapshot Name and Report Section"
        (click)="editSnapshot()"
      >
        <img src="assets/img/Edit.png" alt="Edit" />
      </button>
      <button
        mat-icon-button
        color="primary"
        aria-label="Add Snapshot Note"
        matTooltip="Add Snapshot Note"
        (click)="addSnapshotNote()"
      >
        <img src="assets/img/NewAction.png" alt="Download" />
      </button>
      <button
        mat-icon-button
        color="primary"
        aria-label="Download Snapshot"
        matTooltip="Download {{ img.name }}"
        (click)="download(img, currentUser)"
      >
        <img src="assets/img/Download.png" alt="Download" />
      </button>
      <button
        mat-icon-button
        color="primary"
        aria-label="View Snapshot"
        matTooltip="View Snapshot"
        (click)="viewSnapshot(img, currentUser)"
      >
        <img src="assets/img/Search.png" alt="View" />
      </button>
    </mat-card-actions>
  </mat-card>

  <iframe id="iframeForDownload" *ngIf="safeImageUrl" [src]="safeImageUrl" style="visibility: hidden"></iframe>
</div>
