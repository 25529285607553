<div class="content">
  <div
    *ngIf="{
    } as data"
  >
    <mat-card appearance="outlined" class="project-card mat-elevation-z10">
      <mat-card-header (click)="viewProject(project)">
        <mat-card-title>{{ project.name }}</mat-card-title>
      </mat-card-header>
      <img
        mat-card-image
        src="{{ project.displayThumbnailUrl || project.displayUrl || project.imageThumbnailUrl || project.imageUrl  }}"
        alt="Image for {{ project.name }}"
        (click)="viewProject(project)"
        onerror="this.onerror=null; this.src='../../../../assets/img/SDAT.png';"
      />
      <mat-card-actions>
        <a
          mat-icon-button
          aria-label="Delete Project"
          matTooltip="Delete Project"
          *ngIf="canDelete"
          (click)="deleteProject(project)"
        >
          <img src="assets/img/TrashGray.png" alt="Delete" />
        </a>
        <a
          mat-icon-button
          aria-label="Open Project in Free Roam"
          matTooltip="Open Project in Free Roam"
          (click)="openProjectViewer(project, false)"
        >
          <img src="assets/img/UEViewer.png" alt="Open {{ unrealViewerName }}" />
        </a>
        <div *ngIf="showViewerLite && project.hasValidPanoMod">
          <a
            mat-icon-button
            aria-label="Open Project in Free Roam Lite"
            matTooltip="Open Project in {{ unrealViewerNameLite }}"
            (click)="openProjectViewer(project, true)"
          >
            <img src="assets/img/UEViewerLite.png" alt="Open {{ unrealViewerNameLite }}" />
          </a>
        </div>
        <a
          mat-icon-button
          aria-label="View / Edit Project"
          matTooltip="View / Edit Project"
          (click)="viewProject(project)"
        >
          <img src="assets/img/Search.png" alt="View" />
        </a>
      </mat-card-actions>
      <mat-card-footer (click)="viewProject(project)">
        <p style="text-align: right; font-style: italic; font-size: smaller; margin-right: 15px">
          Last Modified: {{ project.updatedAt | date: 'short' }}
        </p>
      </mat-card-footer>
    </mat-card>
  </div>
</div>
