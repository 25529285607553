<div class="content" *ngIf="currentUser$ | async as currentUser">
  <mat-card appearance="outlined" class="user-card mat-elevation-z10 {{ getUserDisplayClass(user) }}">
    <mat-card-header (click)="viewUser(user)">
      <mat-card-title>{{ user.fullName }}</mat-card-title>
      <mat-card-subtitle>{{ user.email }}</mat-card-subtitle>
    </mat-card-header>
    <img
      mat-card-image
      src="{{ user.imageUrl }}"
      alt="Image for {{ user.fullName }}"
      (click)="viewUser(user)"
      onerror="this.onerror=null; this.src='../../../../assets/img/SDAT.png';"
    />
    <mat-card-content>
      <p>{{ user.role }}</p>
    </mat-card-content>
    <mat-card-actions>
      <mat-toolbar class="actionButtonBar">
        <span>
          <a
            mat-icon-button
            color="warn"
            aria-label="Delete User"
            matTooltip="Delete User"
            *ngIf="canDelete(user)"
            (click)="deleteUser(user)"
          >
            <img src="assets/img/TrashGray.png" alt="Delete" />
          </a>
          <a
            mat-icon-button
            color="primary"
            aria-label="View / Edit User"
            matTooltip="View / Edit User"
            (click)="viewUser(user)"
          >
            <img src="assets/img/Search.png" alt="View" />
          </a>
        </span>
        <span class="toolbarSpacer"></span>
        <mat-checkbox
          *ngIf="currentUser._id !== user._id"
          class="selectUserCheckbox"
          aria-label="Toggle Select User for Group Edit"
          matTooltip="Toggle Select User for Group Edit"
          (change)="toggleUserInEditGroup($event.checked, user)"
        >
        </mat-checkbox>
      </mat-toolbar>
    </mat-card-actions>
    <mat-card-footer (click)="viewUser(user)">
      <div
        *ngIf="
          user.status && user.status.lastLogin && user.status.lastLogin.date;
          then hasLoggedIn;
          else hasNotLoggedIn
        "
      ></div>

      <ng-template #hasLoggedIn>
        <p style="text-align: right; font-style: italic; font-size: smaller; margin-right: 15px">
          Last Online: {{ user.status.lastLogin.date | date: 'short' }}
        </p>
      </ng-template>

      <ng-template #hasNotLoggedIn>
        <p style="text-align: right; font-style: italic; font-size: smaller; margin-right: 15px">Last Online: Never</p>
      </ng-template>
    </mat-card-footer>
  </mat-card>
</div>
