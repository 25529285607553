import { KeyframeCollisionStatusEnum } from './keyframe-collision-status.enum';

export interface CollisionType {
  _id: KeyframeCollisionStatusEnum;
  displayName: string;
  totalDisplayName: string;
}

export interface CollisionTypeSummary {
  _id: KeyframeCollisionStatusEnum;
  numberOfCollisions: number;
}

export const EncroachmentViolation: CollisionType = {
  _id: KeyframeCollisionStatusEnum.ENCROACHMENT_VIOLATION,
  displayName: 'Encroachment Violation',
  totalDisplayName: 'Total Encroachment Violations Along Path',
};

export const HardCollision: CollisionType = {
  _id: KeyframeCollisionStatusEnum.HARD_COLLISION,
  displayName: 'Hard Collision',
  totalDisplayName: 'Total Collisions Along Path',
};
