enum VehicleMovementTypesEnum {
  TRACKS = 'tracks',
  WHEELS = 'wheels',
}

interface VehicleMovementType {
  _id: string;
  displayName: string;
}

export const TrackedVehicleMovement: VehicleMovementType = {
  _id: VehicleMovementTypesEnum.TRACKS,
  displayName: 'Tracks',
};

export const WheeledVehicleMovement: VehicleMovementType = {
  _id: VehicleMovementTypesEnum.WHEELS,
  displayName: 'Wheels',
};
