import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export enum RxJsLoggingLevel {
  TRACE,
  DEBUG,
  INFO,
  ERROR,
}

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private rxjsLoggingLevel = RxJsLoggingLevel.INFO;

  // see https://www.loggly.com/blog/angular-exception-logging-made-simple/
  private loggly: any = new LogglyTracker();

  constructor() {
    this.loggly.push({
      subdomain: environment.loggly.subdomain,
      logglyKey: environment.loggly.token,
      tag: environment.loggly.tags,
      logToConsole: environment.loggly.logToConsole,
      includeUrl: true,
      includeTimestamp: true,
    });

    this.loggly.push({ message: `Loggly initialized on ${environment.appName} ${environment.appVersion}`});
  }

  debug = (level: number, message: string) => (source: Observable<any>) =>
    source.pipe(
      tap((val) => {
        if (level >= this.rxjsLoggingLevel) {
          console.log(message + ': ', val);
        }
      })
    );

  logError(errText, errStack): string {
    if (errStack == null) {
      this.loggly.push({ message: errText });
    } else {
      this.loggly.push({ message: errText, stack: errStack });
    }
    return errText;
  }

  logInfo(info): string {
    this.loggly.push({ message: info });
    return info;
  }

  setRxJsLoggingLevel(level: RxJsLoggingLevel): void {
    this.rxjsLoggingLevel = level;
  }
}
