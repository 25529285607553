import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';

import { VehiclePurpose, User } from '@shared/models';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import {
  ErrorService,
  LogService,
  SettingsService,
  UserService
} from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class VehiclePurposeService {
  private currentVehiclePurposeSubject = new BehaviorSubject<VehiclePurpose>(null);
  private vehiclePurposesSubject = new BehaviorSubject<VehiclePurpose[]>([]);
  private vehiclePurposeErrorSubject = new BehaviorSubject<string>(null);
  currentVehiclePurpose$: Observable<VehiclePurpose> = this.currentVehiclePurposeSubject.asObservable();
  vehiclePurposes$: Observable<VehiclePurpose[]> = this.vehiclePurposesSubject.asObservable();
  vehiclePurposeError$: Observable<string> = this.vehiclePurposeErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) {
   }

  async createNewVehiclePurpose(payload: VehiclePurpose, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && payload) {
        _this.settingsService.setIsLoading(true);
        const vehiclePurposes = _this.vehiclePurposesSubject.getValue();
        const newVehiclePurposes = vehiclePurposes.slice(0);
        const url = `${environment.baseAPIUrl}vehiclePurpose/create?userId=${currentUser?._id}`;
        let returnValue: VehiclePurpose;
  
        _this.httpClient
          .post(url, payload, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (newVehiclePurpose: VehiclePurpose) => {
              returnValue = newVehiclePurpose;
              _this.currentVehiclePurposeSubject.next(newVehiclePurpose);
              newVehiclePurposes.push(newVehiclePurpose);
              _this.vehiclePurposesSubject.next(newVehiclePurposes);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.vehiclePurposeErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        const errMessage = `payload and user are required to create a vehicle purpose`;
        _this.errorService.handleError(errMessage);
        _this.vehiclePurposeErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveVehiclePurpose(vehiclePurposeId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && vehiclePurposeId) {
        _this.settingsService.setIsLoading(true);
        const vehiclePurposes = _this.vehiclePurposesSubject.getValue();
        const vehiclePurposeIndex = vehiclePurposes.findIndex(
          (vehiclePurpose) => vehiclePurpose._id === vehiclePurposeId
        );
        const newVehiclePurposes = vehiclePurposes.slice(0);
        const url = `${environment.baseAPIUrl}vehiclePurpose/${vehiclePurposeId}?userId=${currentUser?._id}`;
        let returnValue: VehiclePurpose;
  
        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedVehiclePurpose: VehiclePurpose) => {
              returnValue = updatedVehiclePurpose;
              newVehiclePurposes[vehiclePurposeIndex] = updatedVehiclePurpose;
              _this.vehiclePurposesSubject.next(newVehiclePurposes);
              _this.currentVehiclePurposeSubject.next(updatedVehiclePurpose);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.vehiclePurposeErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        const errMessage = `changes, vehiclePurposeId and user are required to update a vehicle purpose`;
        _this.errorService.handleError(errMessage);
        _this.vehiclePurposeErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getCurrentVehiclePurpose() {
    return this.currentVehiclePurposeSubject.getValue();
  }

  getVehiclePurposes(currentUser: User): Observable<VehiclePurpose[]> {
    const vehiclePurposesHttp$ = createHttpObservable(`${environment.baseAPIUrl}vehiclePurpose?userId=${currentUser?._id}`, {}, true);

    vehiclePurposesHttp$
      .pipe(
        catchError((err) => {
          console.error(`Error getting vehicle purposes: ${err}`);
          return of([]);
        })
      )
      .subscribe((vehiclePurposes: VehiclePurpose[]) => this.vehiclePurposesSubject.next(vehiclePurposes));

    return vehiclePurposesHttp$;
  }

  getVehiclePurposeById(vehiclePurposeId: string): any {
    if (vehiclePurposeId) {
      return this.vehiclePurposes$
        .pipe(
          map((vehiclePurposes) => vehiclePurposes.find((vehiclePurpose) => vehiclePurpose._id === vehiclePurposeId)),
          filter((vehiclePurpose) => !!vehiclePurpose)
        )
        .subscribe((vehiclePurpose) => this.currentVehiclePurposeSubject.next(vehiclePurpose));
    } else {
      this.currentVehiclePurposeSubject.next(null);
    }
  }
}
