import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of, Subject, timer, throwError } from 'rxjs';
import { catchError, delayWhen, filter, map, retryWhen, switchAll, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import {
  ErrorService,
  LogService,
  SettingsService
} from '@shared/services';

import { environment } from '@environment';

//see https://rxjs.dev/api/webSocket/webSocket and https://javascript-conference.com/blog/real-time-in-angular-a-journey-into-websocket-and-rxjs/

/* see https://www.websparrow.org/angular/how-to-add-javascript-file-in-angular-project
    https://docs.unrealengine.com/4.26/en-US/Resources/Showcases/PixelStreamingShowcase/
    https://juristr.com/blog/2016/09/ng2-event-registration-document/ */

@Injectable({
  providedIn: 'root',
})
export class UnrealInteractionService {
  private unrealPlayerConnectedHttpSubject = new BehaviorSubject<any>(null);
  private unrealPlayerDisconnectedHttpSubject = new BehaviorSubject<any>(null);
  private unrealServerDisconnectedHttpSubject = new BehaviorSubject<any>(null);
  private unrealConnectedSubject = new BehaviorSubject<boolean>(false);
  unrealWebsocketSubject: WebSocketSubject<any>;
  private unrealMessagesSubject = new Subject();
  private reconnectInterval = 2000;

  //unrealPlayerConnectedHttp$: Observable<any> = this.unrealPlayerConnectedHttpSubject.asObservable();
  unrealPlayerDisconnectedHttp$: Observable<any> = this.unrealPlayerDisconnectedHttpSubject.asObservable();
  unrealServerDisconnectedHttp$: Observable<any> = this.unrealServerDisconnectedHttpSubject.asObservable();
  unrealMessages$: Observable<any> = this.unrealMessagesSubject.pipe(
    switchAll(),
    catchError((e) => {
      throw e;
    })
  );
  unrealConnected$: Observable<boolean> = this.unrealConnectedSubject.asObservable();

  answerEvent$: Observable<any>;
  configEvent$: Observable<any>;
  iceCandidateEvent$: Observable<any>;
  offerEvent$: Observable<any>;
  pingEvent$: Observable<any>;
  playerCountEvent$: Observable<any>;
  statsEvent$: Observable<any>;

  constructor(
    private httpClient: HttpClient,
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService
  ) {
    this.unrealPlayerConnectedHttpSubject.next(this.getPlayerConnected());
    this.unrealPlayerDisconnectedHttpSubject.next(this.getPlayerDisconnected());
   }

  //see https://howtodoinjava.com/angular/rxjs-observable-httpclient/

  //see https://javascript-conference.com/blog/real-time-in-angular-a-journey-into-websocket-and-rxjs/
  private reconnect(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      retryWhen((errors) =>
        errors.pipe(
          tap((val) => this.logService.logInfo(`Unreal websockets trying to reconnect: ${val}`)),
          delayWhen((_) => timer(this.reconnectInterval))
        )
      )
    );
  }

  close(): void {
    this.unrealWebsocketSubject.complete();
  }

  connect(cfg: { endpoint: string; reconnect: boolean }): Promise<any> {
    return new Promise((resolve, reject) => {
      if ((!this.unrealWebsocketSubject || this.unrealWebsocketSubject.closed) && cfg.endpoint) {
        this.unrealWebsocketSubject = this.getNewWebSocket(cfg.endpoint, cfg.reconnect || false);

        // first subscriber, open WebSocket connection
        const messages = this.unrealWebsocketSubject.subscribe((data) => {
          console.log(`received unreal websocket message in unreal-interaction.service: ${JSON.stringify(data)}`);
        });

        this.unrealWebsocketSubject
          .pipe(
            cfg.reconnect ? this.reconnect : (o) => o,
            tap({
              error: (error) => console.log(error),
            }),
            catchError((_) => EMPTY)
          )
          .subscribe((messages) => {
            this.unrealMessagesSubject.next(messages);
          });

        //console.log(`messages in unreal-interaction.service:`,  messages);

        //this.unrealMessagesSubject.next(messages);
        this.iceCandidateEvent$ = this.unrealWebsocketSubject.multiplex(
          () => ({ type: 'iceCandidate', action: 'subscribe' }),
          () => ({ type: 'iceCandidate', action: 'unsubscribe' }),
          (message) => message && message.type === 'iceCandidate'
        );

        this.answerEvent$ = this.unrealWebsocketSubject.multiplex(
          () => ({ type: 'answer', action: 'subscribe' }),
          () => ({ type: 'answer', action: 'unsubscribe' }),
          (message) => message && message.type === 'answer'
        );

        this.configEvent$ = this.unrealWebsocketSubject.multiplex(
          () => ({ type: 'config', action: 'subscribe' }),
          () => ({ type: 'config', action: 'unsubscribe' }),
          (message) => message && message.type === 'config'
        );

        resolve(this.unrealWebsocketSubject);
      } else {
        resolve(this.unrealWebsocketSubject);
      }
    });
  }

  getNewWebSocket(endpoint: string, reconnect: boolean) {
    const _this = this;
    return webSocket({
      url: endpoint,
      closeObserver: {
        next: () => {
          console.log(`Unreal websockets connection closed`);
          _this.unrealWebsocketSubject.next(null);
          this.unrealConnectedSubject.next(false);
          this.connect({ endpoint: endpoint, reconnect: true });
        },
      },
      deserializer: ({ data }) => {
        console.log(`message received in deserialization Unreal websockets function: ${JSON.stringify(data)}`);
      },
      openObserver: {
        next: (msg) => {
          console.log(`<- SS:  Unreal websockets connected in unreal-interaction.service: ${JSON.stringify(msg)}`);
          this.unrealConnectedSubject.next(true);
        },
      },
    });
  }

  getPlayerConnected(): Observable<string> {
    const url = `${environment.baseAppURL}/server/clientConnected`;

    return this.httpClient.get<string>(url);
  }

  getPlayerDisconnected(): Observable<string> {
    const url = `${environment.baseAppURL}/server/clientDisconnected`;

    return this.httpClient.get<string>(url);
  }

  getServerDisconnected(): Observable<string> {
    const url = `${environment.baseAppURL}/server/serverDisconnected`;

    return this.httpClient.get<string>(url);
  }

  sendMessage(msg: any): void {
    console.log(`Received msg ${msg} to send thru websockets`);
    this.unrealWebsocketSubject.next(msg); // This will send a message to the server once a connection is made. Remember value is serialized with JSON.stringify by default!
  }
}
