import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of, zip } from 'rxjs';
import { catchError, filter, map, switchMap, tap, toArray } from 'rxjs/operators';

import { ImageDoc, User } from '@shared/models';
import { DbCollectionsEnum } from '@shared/enums';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import { ErrorService, LogService, SettingsService, UserService } from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class ImageDocService {
  private currentImageSubject = new BehaviorSubject<ImageDoc>(null);
  private currentProjectImagesSubject = new BehaviorSubject<ImageDoc[]>(null);
  private currentShipImagesSubject = new BehaviorSubject<ImageDoc[]>(null);
  private currentVehicleImagesSubject = new BehaviorSubject<ImageDoc[]>(null);
  private selectedUserImagesSubject = new BehaviorSubject<ImageDoc[]>(null);
  private imageErrorSubject = new BehaviorSubject<string>(null);
  currentImage$: Observable<ImageDoc> = this.currentImageSubject.asObservable();
  currentProjectImages$: Observable<ImageDoc[]> = this.currentProjectImagesSubject.asObservable();
  currentShipImages$: Observable<ImageDoc[]> = this.currentShipImagesSubject.asObservable();
  currentVehicleImages$: Observable<ImageDoc[]> = this.currentVehicleImagesSubject.asObservable();
  selectedUserImages$: Observable<ImageDoc[]> = this.selectedUserImagesSubject.asObservable();
  imageError$: Observable<string> = this.imageErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) {
  }

  async createNewImageDoc(payload, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && payload && parentCollection && parentId) {
        try {
          let images;
          const url = `${environment.baseAPIUrl}image/create?userId=${currentUser?._id}`;
          let returnValue: ImageDoc;
  
          switch (parentCollection) {
            case DbCollectionsEnum.PROJECTS:
              images = _this.currentProjectImagesSubject.getValue();
              break;
            case DbCollectionsEnum.SHIPS:
              images = _this.currentShipImagesSubject.getValue();
              break;
            case DbCollectionsEnum.VEHICLES:
              images = _this.currentVehicleImagesSubject.getValue();
              break;
            default:
              const errMessage = `${parentCollection} is not a valid document image doc collection`;
              _this.errorService.handleError(errMessage);
              reject(errMessage);
              break;
          }
  
          const newImages = images ? images.slice(0) : [];
  
          _this.httpClient
            .post(url, payload, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (newImage: ImageDoc) => {
                returnValue = newImage;
                newImages.push(newImage);
  
                switch (parentCollection) {
                  case DbCollectionsEnum.PROJECTS:
                    _this.currentProjectImagesSubject.next(newImages);
                    break;
                  case DbCollectionsEnum.SHIPS:
                    _this.currentShipImagesSubject.next(newImages);
                    break;
                  case DbCollectionsEnum.VEHICLES:
                    _this.currentVehicleImagesSubject.next(newImages);
                    break;
                }
  
                resolve(returnValue);
              }, 
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.imageErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {
  
              }
            });
        } catch (ex) {
          const errMessage = `Error creating new image doc for parent ${parentCollection} parentId ${parentId}: ${ex.message}`;
          _this.errorService.handleError(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `payload, parent collection, parentId and user are required to create an image document`;
        this.errorService.handleError(errMessage);
        this.imageErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async deleteImage(imageId: string, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let isValidParentCollection = true;
      let returnValue: ImageDoc[];

      if (currentUser && imageId && parentCollection && parentId) {
        //this will delete the image and if it's a snapshot, any related notes
        const url = `${environment.baseAPIUrl}image/${imageId}?userId=${currentUser._id}`;

        switch (parentCollection) {
          case DbCollectionsEnum.PROJECTS:
            returnValue = _this.currentProjectImagesSubject.getValue();
            break;
          case DbCollectionsEnum.SHIPS:
            returnValue = _this.currentShipImagesSubject.getValue();
            break;
          case DbCollectionsEnum.VEHICLES:
            returnValue = _this.currentVehicleImagesSubject.getValue();
            break;
          default:
            isValidParentCollection = false;
            break;
        }

        if (isValidParentCollection) {
          _this.httpClient
            .delete(url, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (deleteResults: any) => {
                if (_this.settingsService.getIsDebugging()) {
                  _this.logService.logInfo(`successfully deleted imageId ${imageId}`);
                }

                _this
                  .refreshImagesByParent(parentCollection, parentId, false, currentUser)
                  .then((images) => {
                    returnValue = images;
                    switch (parentCollection) {
                      case DbCollectionsEnum.PROJECTS:
                        _this.currentProjectImagesSubject.next(images);
                        break;
                      case DbCollectionsEnum.SHIPS:
                        _this.currentShipImagesSubject.next(images);
                        break;
                      case DbCollectionsEnum.VEHICLES:
                        _this.currentVehicleImagesSubject.next(images);
                        break;
                    }
                  })
                  .catch((imagesError) => {
                    const errMessage = _this.errorService.handleError(
                      `Error refreshing images for parent ${parentCollection} with id ${parentId}: ${imagesError}`
                    );
                    _this.imageErrorSubject.next(errMessage);
                    reject(errMessage);
                  })
                  .finally(() => {
                    _this.settingsService.setIsLoading(false);
                    resolve(returnValue);
                  });
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.imageErrorSubject.next(errMessage);
                _this.settingsService.setIsLoading(false);
                reject(error);
              },
              complete: () => {

              }
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = `${parentCollection} is not a valid image doc parent collection`;
          _this.errorService.handleError(errMessage);
          _this.imageErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = `imageId, parentCollection and parentId are required to delete an image`;
        _this.errorService.handleError(errMessage);
        _this.imageErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async getImageBase64Url(imageUrl: string): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      const canvas = <HTMLCanvasElement>document.getElementById('canvasForImageProcessing');
      const ctx = canvas.getContext('2d');

      if (canvas && ctx && imageUrl) {
        try {
          const image = new Image();
          image.crossOrigin = 'anonymous';
          image.src = imageUrl;

          image.onload = function () {
            canvas.width = 400;
            canvas.height = 300;
            ctx.drawImage(image, 0, 0, 400, 300);
            const url = canvas.toDataURL();
            ctx.clearRect(0, 0, 400, 300);
            resolve(url);
          };

          image.onerror = function (err) {
            const errMessage = _this.errorService.handleError(
              `Error getting base64DataUrl for imageUrl ${imageUrl}: ${err}`
            );
            ctx.clearRect(0, 0, 400, 300);
            resolve('');
          };
        } catch (ex) {
          const errMessage = _this.errorService.handleError(`Error getting base64 data url for imageUrl ${imageUrl}`);
          resolve('');
        }
      } else {
        resolve('');
      }
    });
  }

  getCurrentImage() {
    return this.currentImageSubject.getValue();
  }

  getCurrentProjectImages() {
    return this.currentProjectImagesSubject.getValue();
  }

  getCurrentShipImages() {
    return this.currentShipImagesSubject.getValue();
  }

  getCurrentVehicleImages() {
    return this.currentVehicleImagesSubject.getValue();
  }

  async refreshImagesByParent(parentCollection: string, parentId: string, getBase64: boolean, currentUser: User): Promise<any> {
    const _this = this;
    
    return new Promise((resolve, reject) => {;
      _this.settingsService.setIsLoading(true);
      let returnValue: ImageDoc[];

      const getImages = function (parentCollection, parentId) {
        return new Promise((resolve2, reject2) => {
          let imagesToReturn: ImageDoc[];

          if (parentId && parentCollection) {
            const url = `${environment.baseAPIUrl}image/${parentCollection}/${parentId}?userId=${currentUser?._id}`;

            _this.httpClient
              .get(url, {
                headers: getCustomHeaders(true),
                responseType: 'json',
              })
              .subscribe({
                next: (images: ImageDoc[]) => {
                  imagesToReturn = images;

                  switch (parentCollection) {
                    case DbCollectionsEnum.PROJECTS:
                      _this.currentProjectImagesSubject.next(imagesToReturn);
                      break;
                    case DbCollectionsEnum.SHIPS:
                      _this.currentShipImagesSubject.next(imagesToReturn);
                      break;
                    case DbCollectionsEnum.VEHICLES:
                      _this.currentVehicleImagesSubject.next(imagesToReturn);
                      break;
                  }
  
                  resolve2(imagesToReturn);
                },
                error: (error: HttpErrorResponse) => {
                  const errMessage = _this.errorService.handleError(
                    `Error getting images for parent ${parentCollection} id ${parentId}: ${error.error}`
                  );
                  _this.imageErrorSubject.next(errMessage);
  
                  switch (parentCollection) {
                    case DbCollectionsEnum.PROJECTS:
                      _this.currentProjectImagesSubject.next(imagesToReturn);
                      break;
                    case DbCollectionsEnum.SHIPS:
                      _this.currentShipImagesSubject.next(imagesToReturn);
                      break;
                    case DbCollectionsEnum.VEHICLES:
                      _this.currentVehicleImagesSubject.next(imagesToReturn);
                      break;
                  }
  
                  reject2(error);
                },
                complete: () => {

                }
            });
          } else if (parentCollection) {
            switch (parentCollection) {
              case DbCollectionsEnum.PROJECTS:
                _this.currentProjectImagesSubject.next(imagesToReturn);
                break;
              case DbCollectionsEnum.SHIPS:
                _this.currentShipImagesSubject.next(imagesToReturn);
                break;
              case DbCollectionsEnum.VEHICLES:
                _this.currentVehicleImagesSubject.next(imagesToReturn);
                break;
            }

            resolve2(imagesToReturn);
          } else {
            _this.currentProjectImagesSubject.next(imagesToReturn);
            _this.currentShipImagesSubject.next(imagesToReturn);
            _this.currentVehicleImagesSubject.next(imagesToReturn);
            resolve2(imagesToReturn);
          }
        });
      };

      getImages(parentCollection, parentId)
        .then((images: ImageDoc[]) => {
          returnValue = images;
          if (returnValue && returnValue.length > 0 && getBase64) {
            const bPromises = [];

            for (let x = 0; x < returnValue.length; x++) {
              bPromises.push(_this.getImageBase64Url(returnValue[x].displayUrl || returnValue[x].displayThumbnailUrl));
            }

            Promise.allSettled(bPromises).then((bResults) => {
              for (let x = 0; x < bResults.length; x++) {
                const r = bResults[x];

                if (r.status === 'fulfilled') {
                  returnValue[x].base64DataUrl = r.value;
                } else {
                  returnValue[x].base64DataUrl = '';
                }
              }

              switch (parentCollection) {
                case DbCollectionsEnum.PROJECTS:
                  _this.currentProjectImagesSubject.next(returnValue);
                  break;
                case DbCollectionsEnum.SHIPS:
                  _this.currentShipImagesSubject.next(returnValue);
                  break;
                case DbCollectionsEnum.VEHICLES:
                  _this.currentVehicleImagesSubject.next(returnValue);
                  break;
              }

              resolve(returnValue);
            });
          } else {
            resolve(returnValue);
          }
        })
        .catch((error) => {
          const errMessage = _this.errorService.handleError(
            `Error getting images for parent ${parentCollection} id ${parentId}: ${error.error}`
          );
          _this.imageErrorSubject.next(errMessage);
          reject(errMessage);
        })
        .finally(() => {
          this.settingsService.setIsLoading(false);
        });
    });
  }

  async refreshImagesByUser(currentUser: User, getBase64: boolean): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue: ImageDoc[];

      const getImages = function (userId) {
        return new Promise((resolve2, reject2) => {
          let imagesToReturn: ImageDoc[];

          if (userId) {
            const url = `${environment.baseAPIUrl}image/user/${userId}/overview`;

            _this.httpClient
              .get(url, {
                headers: getCustomHeaders(true),
                responseType: 'json',
              })
              .subscribe({
                next: (images: ImageDoc[]) => {
                  imagesToReturn = images;
                  _this.selectedUserImagesSubject.next(imagesToReturn);
                  resolve2(imagesToReturn);
                },
                error: (error: HttpErrorResponse) => {
                  const errMessage = _this.errorService.handleError(
                    `Error getting images for userId ${userId}: ${error.error}`
                  );
                  _this.imageErrorSubject.next(errMessage);
                  _this.selectedUserImagesSubject.next(imagesToReturn);
                  reject2(error);
                },
                complete: () => {

                }
            });
          } else {
            _this.selectedUserImagesSubject.next(imagesToReturn);
            resolve2(imagesToReturn);
          }
        });
      };

      getImages(currentUser?._id)
        .then((images: ImageDoc[]) => {
          returnValue = images;
          if (returnValue && returnValue.length > 0 && getBase64) {
            const bPromises = [];

            for (let x = 0; x < returnValue.length; x++) {
              bPromises.push(_this.getImageBase64Url(returnValue[x].displayUrl || returnValue[x].displayThumbnailUrl));
            }

            Promise.allSettled(bPromises).then((bResults) => {
              for (let x = 0; x < bResults.length; x++) {
                const r = bResults[x];

                if (r.status === 'fulfilled') {
                  returnValue[x].base64DataUrl = r.value;
                } else {
                  returnValue[x].base64DataUrl = '';
                }
              }

              _this.selectedUserImagesSubject.next(returnValue);
              resolve(returnValue);
            });
          } else {
            resolve(returnValue);
          }
        })
        .catch((error) => {
          const errMessage = _this.errorService.handleError(
            `Error getting images for userId ${currentUser._id}: ${error.error}`
          );
          _this.imageErrorSubject.next(errMessage);
          reject(errMessage);
        })
        .finally(() => {
          this.settingsService.setIsLoading(false);
        });
    });
  }

  async saveImage(imageDocId: string, parentCollection: string, parentId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && imageDocId && parentCollection && parentId) {
        _this.settingsService.setIsLoading(true);
        let images: ImageDoc[];
        let validParentCollection = true;
        let returnValue: ImageDoc;
  
        switch (parentCollection) {
          case DbCollectionsEnum.PROJECTS:
            images = _this.currentProjectImagesSubject.getValue();
            break;
          case DbCollectionsEnum.SHIPS:
            images = _this.currentShipImagesSubject.getValue();
            break;
          case DbCollectionsEnum.VEHICLES:
            images = _this.currentVehicleImagesSubject.getValue();
            break;
          default:
            validParentCollection = false;
            break;
        }
  
        if (validParentCollection) {
          const imageIndex = images.findIndex((image) => image._id === imageDocId);
          const newImages = images.slice(0);
          const url = `${environment.baseAPIUrl}image/${imageDocId}?userId=${currentUser?._id}`;
  
          newImages[imageIndex] = {
            ...images[imageIndex],
            ...changes,
          };
  
          _this.httpClient
            .put(url, changes, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (updatedImage: ImageDoc) => {
                returnValue = updatedImage;
                _this.currentImageSubject.next(updatedImage);
  
                switch (parentCollection) {
                  case DbCollectionsEnum.PROJECTS:
                    _this.currentProjectImagesSubject.next(images);
                    break;
                  case DbCollectionsEnum.SHIPS:
                    _this.currentShipImagesSubject.next(images);
                    break;
                  case DbCollectionsEnum.VEHICLES:
                    _this.currentVehicleImagesSubject.next(images);
                    break;
                }
  
                _this.settingsService.setIsLoading(false);
                resolve(returnValue);
              },
              error: (error: HttpErrorResponse) => {
                _this.settingsService.setIsLoading(false);
                const errMessage = _this.errorService.handleError(
                  `Error updating imageDocId ${imageDocId}: ${error.error}`
                );
                _this.imageErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {
  
              }
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(
            `${parentCollection} is not a valid imageDoc parent collection`
          );
          _this.imageErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `changes, imageDocId, parent collection, parent id and user are required save an image`;
        this.errorService.handleError(errMessage);
        this.imageErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }
}
