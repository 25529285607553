import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Note, Project, Ship, User, Vehicle, Video } from '@shared/models';
import {
  ErrorService,
  FileService,
  LogService,
  ProjectService,
  SettingsService,
  ShipService,
  UserService,
  VehicleService,
  VideoService,
} from '@shared/services';
import { DbCollectionsEnum, UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

import { ReportItemDialogComponent } from '../../report/report-item-dialog/report-item-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.css'],
})
export class VideoCardComponent implements OnInit {
  @Input()
  video: Video;

  @Input()
  notes: Note[];

  @Input()
  project: Project;

  @Input()
  ship: Ship;

  @Input()
  vehicle: Vehicle;

  currentUser$: Observable<User>;
  data: any;
  private videoSafeUrlSubject = new BehaviorSubject<SafeResourceUrl>(null);
  videoSafeUrl$: Observable<SafeResourceUrl> = this.videoSafeUrlSubject.asObservable();

  constructor(
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private fileService: FileService,
    private errorService: ErrorService,
    private logService: LogService,
    private projectService: ProjectService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private videoService: VideoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    //use the webm format if available
    this.videoSafeUrlSubject.next(this.video.encodedDisplayUrl || this.video.displayUrl);
  }

  get canDelete(): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === this.video.creatorId) {
      returnValue = true;
    }

    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get safeVideoUrl(): SafeResourceUrl {
    return this.videoSafeUrlSubject.getValue();
  }

  async addVideoNote() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: this.userService.getCurrentUser(),
      isNewNote: true,
      project: this.project,
      ship: this.ship,
      vehicle: this.vehicle,
      video: this.video,
    };

    const dialogRef = this.dialog.open(ReportItemDialogComponent, dialogConfig);
  }

  async deleteVideo(): Promise<any> {
    const _this = this;
    const currentUser = _this.userService.getCurrentUser();
    let title = `Do you want to delete the ${this.video.name} video and any related notes?`;

    Swal.fire({
      title: title,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        _this.settingsService.setIsLoading(true);
        _this.videoService
          .deleteVideo(_this.video._id, _this.video.parent.collection, _this.video.parent._id, currentUser)
          .then(function (deleteResults) {
            if (_this.settingsService.getIsDebugging()) {
              _this.logService.logInfo(`deleted videoId ${_this.video._id} and related notes`);
            }
            _this.settingsService.setIsLoading(false);
          })
          .catch(function (deleteError) {
            const errorTitle = 'Error Deleting Video and Notes';
            _this.errorService.handleError(`${errorTitle}: ${deleteError}`);
            _this.settingsService.setIsLoading(false);
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                errorTitle,
                `There was an error deleting the video and related notes.  Please email ${environment.techSupportEmail}`,
                'error'
              );
            }
          });
      }
    });
  }

  async download(video: Video, currentUser: User) {
    if (video?.url && currentUser) {
      try {
        this.settingsService.setIsLoading(true);
        const fileResults = await this.fileService.downloadFile(
          this.video.url || this.video.encodedUrl,
          this.video.name,
          currentUser,
          false
        );
        this.videoSafeUrlSubject.next(this.domSanitizer.bypassSecurityTrustResourceUrl(fileResults.signedUrl));

        if (this.videoSafeUrlSubject.getValue()) {
          const element = document.getElementById('iframeForDownload') as HTMLElement;
          if (element) {
            element.click();
          }
        }
      } catch (ex) {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          this.settingsService.setIsLoading(false);
          Swal.fire(
            `Error Downloading Video ${this.video.name}`,
            `There was an error downloading the file.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      } finally {
        this.settingsService.setIsLoading(false);
      }
    } else {
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire('Error', `No video was provided to download.  Please email ${environment.techSupportEmail}.`, 'error');
			}
    }
  }

  editVideo() {
    const currentUser = this.userService.getCurrentUser();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: currentUser,
      isNewNote: false,
      project: this.project,
      ship: this.ship,
      vehicle: this.vehicle,
      video: this.video,
    };

    const dialogRef = this.dialog.open(ReportItemDialogComponent, dialogConfig);
  }

  getVideoNotes() {
    if (this.notes && this.notes.length > 0 && this.video) {
      return this.notes.filter((note) => note.videoId === this.video._id);
    }
  }

  initVideo() {
    this.data.play();
  }

  //see https://www.positronx.io/angular-video-player-using-ngx-videogular-with-customized-controls-example/
  videoPlayerInit(data: any) {
    this.data = data;
    this.data.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.initVideo.bind(this));
  }
}
