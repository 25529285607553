import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Project, ProjectOverview, UnrealServer, User } from '@shared/models';
import {
  ErrorService,
  LogService,
  ModService,
  ProjectService,
  SettingsService,
  UnrealServerService,
  UserService,
} from '@shared/services';
import { UnrealScenesEnum, UnrealUIStatesEnum, UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
  selector: 'app-project-overview-table',
  templateUrl: './project-overview-table.component.html',
  styleUrls: ['./project-overview-table.component.css'],
})
export class ProjectOverviewTableComponent implements OnInit {
  @Input()
  projectOverviews: ProjectOverview[];

  @Input()
  showPaginator: boolean;

  @Input()
  showFilter: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentUser$: Observable<User>;
  displayedProjectOverviewColumns: string[] = [
    'name',
    'shipName',
    'vehicleName',
    'lastUpdatedBy',
    'lastUpdatedAt',
    'actions',
  ];
  isOpeningUnrealViewer: boolean = false;
  isOpeningProject: boolean = false;
  private projectErrorSubject = new BehaviorSubject<string>(null);
  projectError$: Observable<string> = this.projectErrorSubject.asObservable();
  dataSource;

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private modService: ModService,
    private projectService: ProjectService,
    private settingsService: SettingsService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.dataSource = new MatTableDataSource(this.projectOverviews);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get unrealViewerName(): string {
    return environment.unreal.viewerName;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openProjectViewer(project: ProjectOverview) {
    this.settingsService.setIsLoading(true);
    const user = this.userService.getCurrentUser();

    if (project && user) {
      this.projectService
        .getProjectById(project._id, user)
        .then((p: Project) => {
          const shipMod = this.modService.getCurrentShipMod();
          const vehicleMod = this.modService.getCurrentVehicleMod();
          let navigationUrl = `/projects/${project._id}/viewer?sceneName=${UnrealScenesEnum.VIEWER}&uiState=${UnrealUIStatesEnum.PROJECT}`;
          this.isOpeningUnrealViewer = true;

          if (shipMod) {
            navigationUrl += `&pcName=${shipMod.name}`;
          }

          if (vehicleMod) {
            navigationUrl += `&vehicleName=${vehicleMod.name}`;
          }

          this.router
            .navigateByUrl(navigationUrl)
            .then(() => {
              this.logService.logInfo(`successfully navigated to ${navigationUrl}`);
            })
            .catch((unrealError) => {
              this.settingsService.setIsLoading(false);
              const errMessage = this.errorService.handleError(
                `Error loading ${environment.unreal.viewerName} at ${navigationUrl}: ${unrealError.message}`
              );
              if (this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  `Error Opening ${environment.unreal.viewerName}`,
                  `${unrealError}.  Please email ${environment.techSupportEmail}.`,
                  'error'
                );
              }
            })
            .finally(() => {
              this.settingsService.setIsLoading(false);
              this.settingsService.setLoadingId(null);
            });
        })
        .catch((error) => {
          this.isOpeningUnrealViewer = false;
          this.settingsService.setIsLoading(false);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error`,
              `Error getting project information needed for the viewer.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        });
    } else {
      this.settingsService.setIsLoading(false);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          `Error Opening ${environment.unreal.viewerName}`,
          `Project and user are required.  Please email ${environment.techSupportEmail}`,
          'error'
        );
			}
    }
  }

  viewProject(project: ProjectOverview): void {
    const user = this.userService.getCurrentUser();

    if (project && user) {
      this.isOpeningProject = true;

      this.projectService
        .getProjectById(project._id, user)
        .then((p: Project) => {
          const navigationUrl = `/projects/${project._id}`;
          this.router.navigateByUrl(navigationUrl);
        })
        .catch((error) => {
          this.isOpeningProject = false;
          this.settingsService.setIsLoading(false);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error`,
              `Error getting project information.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        })
        .finally(() => {
          this.settingsService.setIsLoading(false);
        });
    } else {
      this.settingsService.setIsLoading(false);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error Displaying Project',
          `Unable to load project data.  Please email ${environment.techSupportEmail}`,
          'error'
        );
			}
    }
  }
}
