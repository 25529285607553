<div class="content" *ngIf="currentUser$ | async as currentUser">
  <mat-card appearance="outlined" class="document-card mat-elevation-z10">
    <mat-card-header>
      <mat-card-title>{{ video.name }}</mat-card-title>
    </mat-card-header>
    <div class="video-player-wrapper">
      <vg-player (onPlayerReady)="videoPlayerInit($event)">
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>
        <vg-scrub-bar>
          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>
        <vg-controls>
          <vg-play-pause></vg-play-pause>
          <vg-playback-button></vg-playback-button>
          <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
          <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
          <vg-mute></vg-mute>
          <vg-volume></vg-volume>
          <vg-fullscreen></vg-fullscreen>
        </vg-controls>
        <video #media [vgMedia]="$any(media)" [src]="video.displayUrl || video.url" id="video._id" preload="none" crossorigin></video>
      </vg-player>
    </div>
    <mat-card-content>
      <div *ngIf="getVideoNotes() as videoNotes">
        <app-note-table
          [notes]="videoNotes"
          [project]="project"
          [ship]="ship"
          [vehicle]="vehicle"
          [isSnapshotOrVideoNotes]="true"
        ></app-note-table>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-icon-button
        color="warn"
        aria-label="Delete Video"
        matTooltip="Delete Video"
        *ngIf="canDelete"
        (click)="deleteVideo()"
      >
        <img src="assets/img/TrashGray.png" alt="Delete" />
      </button>
      <button
        mat-icon-button
        color="primary"
        aria-label="Edit Video Details"
        matTooltip="Edit Video Name"
        (click)="editVideo()"
      >
        <img src="assets/img/Edit.png" alt="Edit" />
      </button>
      <button
        mat-icon-button
        color="primary"
        aria-label="Add Video Note"
        matTooltip="Add Video Note"
        (click)="addVideoNote()"
      >
        <img src="assets/img/NewAction.png" alt="Download" />
      </button>
      <button
        mat-icon-button
        color="primary"
        aria-label="Download Video"
        matTooltip="Download {{ video.name }}"
        (click)="download(video, currentUser)"
      >
        <img src="assets/img/Download.png" alt="Download" />
      </button>
    </mat-card-actions>
  </mat-card>

  <iframe id="iframeForDownload" *ngIf="safeVideoUrl" [src]="safeVideoUrl" style="visibility: hidden"></iframe>
</div>
