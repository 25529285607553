<div style="min-height: 100%; height: 100%; min-width: 600px" *ngIf="currentUser$ | async as currentUser">
  <h2 mat-dialog-title>EDIT MULTIPLE USERS</h2>

  <mat-dialog-content>
    <div
      *ngIf="{
        isDebugging: isDebugging$ | async
      } as data"
    >
      <h3>Selected Users: {{ selectedUsersNames }}</h3>

      <mat-form-field appearance="fill">
        <mat-label>User Type</mat-label>
        <mat-select (selectionChange)="onUserRoleChange($event.value)">
          <mat-option>Choose User Type</mat-option>
          <mat-option *ngFor="let userRole of userRoles" [value]="userRole">{{ userRole }}</mat-option>
        </mat-select>
      </mat-form-field>

      <br><br>
      <h3>
        Delete Users: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="right">
          <button
            mat-raised-button
            color="warn"
            aria-label="Delete selected users"
            matTooltip="Deleted selected users"
            id="btnDeleteSelectedUsers"
            (click)="deleteSelectedUsers()"
          >
            Delete
          </button>
        </span>
      </h3>

      <br><br>
      <button mat-raised-button aria-label="Close without saving" matTooltip="Close without saving" (click)="cancel()">
        Cancel
      </button>
    </div>
  </mat-dialog-content>
</div>
