import { Component, Input, OnInit } from '@angular/core';
import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Document, Project, Ship, User, Vehicle } from '@shared/models';
import { DocumentService, ErrorService, FileService, LogService, SettingsService, UserService } from '@shared/services';
import { DbCollectionsEnum, UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.css'],
})
export class DocumentCardComponent implements OnInit {
  @Input()
  doc: Document;

  @Input()
  project: Project;

  @Input()
  ship: Ship;

  @Input()
  vehicle: Vehicle;

  currentUser$: Observable<User>;
  private documentErrorSubject = new BehaviorSubject<string>(null);
  private documentSafeUrlSubject = new BehaviorSubject<SafeResourceUrl>(null);
  documentSafeUrl$: Observable<SafeResourceUrl> = this.documentSafeUrlSubject.asObservable();
  documentError$: Observable<string> = this.documentErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private domSanitizer: DomSanitizer,
    private documentService: DocumentService,
    private fileService: FileService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
  }

  get canDelete(): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === this.doc.creatorId) {
      returnValue = true;
    }

    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get safeDocumentUrl(): SafeResourceUrl {
    return this.documentSafeUrlSubject.getValue();
  }

  async deleteDocument(doc: Document, currentUser: User): Promise<any> {
    const _this = this;

    if (doc?.parent?.collection) {
      Swal.fire({
        title: `Do you want to delete the ${doc.name} document?`,
        showCancelButton: true,
        confirmButtonText: 'Delete',
      }).then((result) => {
        if (result.isConfirmed) {
          this.documentService
            .deleteDocument(doc._id, doc.parent.collection, doc.parent._id, currentUser)
            .then(function (deleteResults) {
              console.log(deleteResults);
            })
            .catch(function (deleteError) {
              _this.errorService.handleError(`Error deleting docId ${doc._id}: ${deleteError}`);
              if (_this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire('Error Deleting Document', deleteError.message, 'error');
              }
            });
        }
      });
    } else {
      _this.errorService.handleError(`Unable to delete docId ${doc._id} because it does not hvae a valid parent.`);
      if (_this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error Deleting Document',
          `A document must be tied to a valid parent.  Please email ${environment.techSupportEmail}`,
          'error'
        );  
      }
    }
  }

  async download(doc: Document, currentUser: User) {
    if (doc?.url && currentUser) {
      try {
        this.settingsService.setIsLoading(true);
        const downloadUrl = await this.fileService.getPresignedUrl(doc.url, doc.name, currentUser, false);
        this.documentSafeUrlSubject.next(this.domSanitizer.bypassSecurityTrustResourceUrl(downloadUrl));

        if (downloadUrl) {
          const element = document.getElementById('iframeForDownload') as HTMLElement;
          if (element) {
            element.click();
          }
        }
      } catch (ex) {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Downloading Document ${doc.name}`,
            `There was an error downloading the file.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      } finally {
        this.settingsService.setIsLoading(false);
      }
    } else {
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error',
          `No document was provided to download.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
			}
    }
  }

  async viewDocument(doc: Document, currentUser: User) {
    if (doc?.url && currentUser) {
      try {
        this.settingsService.setIsLoading(true);
        const downloadUrl = await this.fileService.getPresignedUrl(doc.url, doc.name, currentUser, true);
        if (downloadUrl) {
          window.open(downloadUrl, '_blank');
        }
      } catch (ex) {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Viewing Document ${doc.name}`,
            `There was an error viewing the file.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      } finally {
        this.settingsService.setIsLoading(false);
      }
    } else {
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error',
          `No document was provided to view.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
			}
    }
  }
}
