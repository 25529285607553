import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/src/sweetalert2';

import { User } from '@shared/models';
import { 
  AreaMeasurementService,
  DataSourceService,
  DocumentService,
  ErrorService,
  LogService,
  ManufacturerService,
  ModService,
  Model3dService,
  ProjectService,
  ReportService,
  ScanService,
  ScannerService,
  SettingsService,
  ShipClassService,
  ShipDesignationService,
  ShipService,
  UnrealInteractionService,
  UnrealServerService,
  UserService,
  VehicleDesignationService,
  VehicleModelService,
  VehiclePurposeService,
  VehicleService
 } from '@shared/services';
import { UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  userError$: Observable<string>;

  constructor(
    private dataSourceService: DataSourceService,
    private errorService: ErrorService,
    private logService: LogService,
    private manufacturerService: ManufacturerService,
    private modService: ModService,
    private projectService: ProjectService,
    private reportService: ReportService,
    private scannerService: ScannerService,
    private settingsService: SettingsService,
    private shipClassService: ShipClassService,
    private shipDesignationService: ShipDesignationService,
    private shipService: ShipService,
    private userService: UserService,
    private vehicleDesignationService: VehicleDesignationService,
    private vehicleModelService: VehicleModelService,
    private vehiclePurposeService: VehiclePurposeService,
    private vehicleService: VehicleService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userError$ = this.userService.userError$;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  forgotPassword(): void {
    // TODO implement forgot password
  }

  goToPrivacyPolicy(): void {
    // TODO create page or link
    Swal.fire('Privacy Policy', 'Coming soon...');
  }

  goToFAQs(): void {
    // TODO create page or link
    Swal.fire('FAQs', 'Coming soon...');
  }

  goToSupport(): void {
    // TODO create page or link
    Swal.fire('Support', 'Coming soon...');
  }

  goToAgreements(): void {
    // TODO create page or link
    Swal.fire('Agreements', 'Coming soon...');
  }

  goToVersion(): void {
    // TODO create page or link
    Swal.fire('Version', `SDAT Version ${environment.appVersion}`);
  }

  onSubmit(submittedForm): void {
    const _this = this;
    if (submittedForm.invalid) {
      return;
    }
    _this.settingsService.setIsLoading(true);

    _this.userService
      .loginUser(submittedForm.value)
      .then((user: User) => {
        this.populateUserData(user);
        _this.settingsService.setIsLoading(false);
        _this.router.navigate(['/home']);
      })
      .catch((error) => {
        _this.errorService.handleError(`Error logging in user ${submittedForm.value}: ${error}`);
        _this.settingsService.setIsLoading(false);
        		  
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error',
            `There was an error logging you in:
          ${error.error}
          Please email ${environment.techSupportEmail} with any questions.`,
            'error'
          );
        }
      });
  }

  populateUserData(currentUser: User) {
    this.dataSourceService.getDataSources(currentUser);
    this.manufacturerService.getManufacturers(currentUser);
    this.reportService.getReportSectionsFromAPI(currentUser);
    this.scannerService.getScanners(currentUser);
    this.shipClassService.getShipClasses(currentUser);
    this.shipDesignationService.getShipDesignations(currentUser);
    this.vehicleDesignationService.getVehicleDesignations(currentUser);
    this.vehicleModelService.getVehicleModels(currentUser);
    this.vehiclePurposeService.getVehiclePurposes(currentUser);
    this.modService.getMods(currentUser);
    this.projectService.getProjects(currentUser);
    this.projectService.refreshRecentProjects(currentUser);
    this.shipService.getShips(currentUser);
    this.vehicleService.getVehicles(currentUser);
  }
}
