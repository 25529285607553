import { AfterViewChecked, AfterViewInit, Component, Input, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatAccordion } from '@angular/material/expansion';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatCheckboxModule, MatCheckboxChange } from '@angular/material/checkbox';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, zip } from 'rxjs';
import { concatAll, catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import isEqual from 'lodash-es/isEqual';
import sortBy from 'lodash-es/sortBy';
import Swal from 'sweetalert2/src/sweetalert2';

import { Mod, Model3d, Note, Project, Report, ReportSection, Scan, Ship, Vehicle, User } from '@shared/models';
import {
  ErrorService,
  LogService,
  ModService,
  Model3dService,
  NoteService,
  ProjectService,
  ReportService,
  ScanService,
  SettingsService,
  UserService,
} from '@shared/services';
import { DbCollectionsEnum, ReportSectionIdsEnum, UserRolesEnum } from '@shared/enums';
import { createTag, getYesOrNo } from '@shared/utils';

import { environment } from '@environment';

import { NoteTableComponent } from '../note-table/note-table.component';
import { ReportItemDialogComponent } from './report-item-dialog/report-item-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(NoteTableComponent) noteTableViewChild!: NoteTableComponent;
  errorMsg: boolean;
  errorText = '';
  isFinalizedBehaviorSubject = new BehaviorSubject<boolean>(false);
  currentProject$: Observable<Project>;
  currentProjectNotes$: Observable<Note[]>;
  currentProjectShip$: Observable<Ship>;
  currentProjectShipMod$: Observable<Mod>;
  currentProjectShipModModel3d$: Observable<Model3d>;
  currentProjectShipModScan$: Observable<Scan>;
  currentProjectVehicle$: Observable<Vehicle>;
  currentProjectVehicleMod$: Observable<Mod>;
  currentProjectVehicleModModel3d$: Observable<Model3d>;
  currentProjectVehicleModScan$: Observable<Scan>;
  reportSections$: Observable<ReportSection[]>;
  selectedReport$: Observable<Report>;
  selectedReportIFrameSrc$: Observable<string>;
  currentUser$: Observable<User>;
  isFinalized$: Observable<boolean> = this.isFinalizedBehaviorSubject.asObservable();
  currentReportNotes: Note[];
  currentUser: User;
  safeSrc: SafeResourceUrl;
  displayedReportColumns: string[] = ['name', 'description', 'view'];
  project: Project;
  report: Report;
  ship: Ship;
  shipMod: Mod;
  shipModel3d: Model3d;
  shipScan: Scan;
  vehicle: Vehicle;
  vehicleMod: Mod;
  vehicleModel3d: Model3d;
  vehicleScan: Scan;

  analysisForm: UntypedFormGroup;
  collisionForm: UntypedFormGroup;
  form: UntypedFormGroup;
  minimumClearanceForm: UntypedFormGroup;
  pathTraveledForm: UntypedFormGroup;
  projectForm: UntypedFormGroup;
  shipForm: UntypedFormGroup;
  snapshotForm: UntypedFormGroup;
  vehicleForm: UntypedFormGroup;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private fb: UntypedFormBuilder,
    private errorService: ErrorService,
    private logService: LogService,
    private modService: ModService,
    private model3dService: Model3dService,
    private noteService: NoteService,
    private projectService: ProjectService,
    private reportService: ReportService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private userService: UserService,
    private router: Router,
    private location: Location
  ) {
    this.settingsService.setIsLoading(true);
    this.currentProject$ = this.projectService.currentProject$;
    this.currentProjectNotes$ = this.noteService.currentProjectNotes$;
    this.currentProjectShip$ = this.projectService.currentProjectShip$;
    this.currentProjectShipMod$ = this.modService.currentShipMod$;
    this.currentProjectShipModModel3d$ = this.model3dService.currentShipModel3d$;
    this.currentProjectShipModScan$ = this.scanService.currentShipScan$;
    this.currentProjectVehicle$ = this.projectService.currentProjectVehicle$;
    this.currentProjectVehicleMod$ = this.modService.currentVehicleMod$;
    this.currentProjectVehicleModModel3d$ = this.model3dService.currentVehicleModel3d$;
    this.currentProjectVehicleModScan$ = this.scanService.currentVehicleScan$;
    this.currentUser$ = this.userService.currentUser$;
    this.reportSections$ = this.reportService.reportSections$;
    this.selectedReport$ = this.reportService.currentReport$;
    this.selectedReportIFrameSrc$ = this.reportService.currentReportIFrameSrc$;

    zip(
      this.currentProject$,
      this.currentProjectNotes$,
      this.currentProjectShip$,
      this.currentProjectShipMod$,
      this.currentProjectShipModModel3d$,
      this.currentProjectShipModScan$,
      this.currentProjectVehicle$,
      this.currentProjectVehicleMod$,
      this.currentProjectVehicleModModel3d$,
      this.currentProjectVehicleModScan$,
      this.currentUser$,
      this.selectedReport$,
      this.reportSections$
    )
      .pipe(untilDestroyed(this))
      .pipe(
        map(
          ([
            project,
            projectNotes,
            ship,
            shipMod,
            shipModel3d,
            shipScan,
            vehicle,
            vehicleMod,
            vehicleModel3d,
            vehicleScan,
            user,
            report,
            reportSections,
          ]) => ({
            project,
            projectNotes,
            ship,
            shipMod,
            shipModel3d,
            shipScan,
            vehicle,
            vehicleMod,
            vehicleModel3d,
            vehicleScan,
            user,
            report,
            reportSections,
          })
        )
      )
      .subscribe(
        ({
          project,
          projectNotes,
          ship,
          shipMod,
          shipModel3d,
          shipScan,
          vehicle,
          vehicleMod,
          vehicleModel3d,
          vehicleScan,
          user,
          report,
          reportSections,
        }) => {
          this.currentUser = user;
          this.project = project;
          this.report = report;
          this.ship = ship;
          this.shipMod = shipMod;
          this.shipModel3d = shipModel3d;
          this.shipScan = shipScan;
          this.vehicle = vehicle;
          this.vehicleMod = vehicleMod;
          this.vehicleModel3d = vehicleModel3d;
          this.vehicleScan = vehicleScan;

          if (report && report.finalized && report.finalized.finalizedDate) {
            this.isFinalizedBehaviorSubject.next(true);
          }

          const analysisSectionId = this.getReportSection(reportSections, ReportSectionIdsEnum.ANALYSIS);
          const collisionSectionId = this.getReportSection(reportSections, ReportSectionIdsEnum.COLLISION);
          const minimumClearanceSectionId = this.getReportSection(
            reportSections,
            ReportSectionIdsEnum.MINIMUM_CLEARANCE
          );
          const pathTraveledSectionId = this.getReportSection(reportSections, ReportSectionIdsEnum.PATH_TRAVELED);
          const projectInfoSectionId = this.getReportSection(reportSections, ReportSectionIdsEnum.PROJECT_INFO);
          const propagatedErrorSectionId = this.getReportSection(reportSections, ReportSectionIdsEnum.PROPAGATED_ERROR);
          const shipSectionId = this.getReportSection(reportSections, ReportSectionIdsEnum.SHIP);
          const snapshotSectionId = this.getReportSection(reportSections, ReportSectionIdsEnum.SNAPSHOT);
          const userSectionId = this.getReportSection(reportSections, ReportSectionIdsEnum.USER);
          const vehicleSectionId = this.getReportSection(reportSections, ReportSectionIdsEnum.VEHICLE);

          this.analysisForm = fb.group({
            notes: new UntypedFormControl(report ? report.options.analysis.notes : false),
            sectionId: new UntypedFormControl(analysisSectionId),
            snapshots: new UntypedFormControl(report ? report.options.analysis.snapshots : false),
            videos: new UntypedFormControl(report ? report.options.analysis.videos : false),
          });

          this.collisionForm = fb.group({
            count: new UntypedFormControl(report ? report.options.collision.count : false),
            notes: new UntypedFormControl(report ? report.options.collision.notes : false),
            sectionId: new UntypedFormControl(collisionSectionId),
            snapshots: new UntypedFormControl(report ? report.options.collision.snapshots : false),
            videos: new UntypedFormControl(report ? report.options.collision.videos : false),
          });

          this.minimumClearanceForm = fb.group({
            lateralLeft: new UntypedFormControl(report ? report.options.minimumClearance.lateralLeft : false),
            lateralRight: new UntypedFormControl(report ? report.options.minimumClearance.lateralRight : false),
            longitudinalFront: new UntypedFormControl(report ? report.options.minimumClearance.longitudinalFront : false),
            longitudinalBack: new UntypedFormControl(report ? report.options.minimumClearance.longitudinalBack : false),
            notes: new UntypedFormControl(report ? report.options.minimumClearance.notes : false),
            sectionId: new UntypedFormControl(minimumClearanceSectionId),
            snapshots: new UntypedFormControl(report ? report.options.minimumClearance.snapshots : false),
            verticalGround: new UntypedFormControl(report ? report.options.minimumClearance.verticalGround : false),
            verticalRoof: new UntypedFormControl(report ? report.options.minimumClearance.verticalRoof : false),
            videos: new UntypedFormControl(report ? report.options.minimumClearance.videos : false),
          });

          this.pathTraveledForm = fb.group({
            notes: new UntypedFormControl(report ? report.options.pathTraveled.notes : false),
            sectionId: new UntypedFormControl(pathTraveledSectionId),
            snapshots: new UntypedFormControl(report ? report.options.pathTraveled.snapshots : false),
            videos: new UntypedFormControl(report ? report.options.pathTraveled.videos : false),
          });

          this.projectForm = fb.group({
            propagatedError: new UntypedFormControl(report ? report.options.project.propagatedError : false)
          });

          this.shipForm = fb.group({
            image: new UntypedFormControl(report ? report.options.ship.image : false),
            model3d: this.createModel3dFormGroup(report ? report.options.ship.model3d : null),
            notes: new UntypedFormControl(report ? report.options.ship.notes : false),
            scan: this.createScanFormGroup(report ? report.options.ship.scan : null),
            sectionId: new UntypedFormControl(shipSectionId),
            snapshots: new UntypedFormControl(report ? report.options.ship.snapshots : false),
            videos: new UntypedFormControl(report ? report.options.ship.videos : false),
          });

          this.snapshotForm = fb.group({
            notes: new UntypedFormControl(report ? report.options.snapshot.notes : false),
            snapshots: new UntypedFormControl(report ? report.options.snapshot.snapshots : false),
            sectionId: new UntypedFormControl(snapshotSectionId),
          });

          this.vehicleForm = fb.group({
            axles: new UntypedFormControl(report ? report.options.vehicle.axles : false),
            brakes: new UntypedFormControl(report ? report.options.vehicle.brakes : false),
            engine: new UntypedFormControl(report ? report.options.vehicle.engine : false),
            generalInfo: new UntypedFormControl(report ? report.options.vehicle.generalInfo : false),
            image: new UntypedFormControl(report ? report.options.vehicle.image : false),
            maneuverability: new UntypedFormControl(report ? report.options.vehicle.maneuverability : false),
            model3d: this.createModel3dFormGroup(report ? report.options.vehicle.model3d : null),
            notes: new UntypedFormControl(report ? report.options.vehicle.notes : false),
            scan: this.createScanFormGroup(report ? report.options.vehicle.scan : null),
            sectionId: new UntypedFormControl(vehicleSectionId),
            snapshots: new UntypedFormControl(report ? report.options.vehicle.snapshots : false),
            steering: new UntypedFormControl(report ? report.options.vehicle.steering : false),
            videos: new UntypedFormControl(report ? report.options.vehicle.videos : false),
          });

          this.form = fb.group({
            analysisForm: this.analysisForm,
            collisionForm: this.collisionForm,
            minimumClearance: this.minimumClearanceForm,
            pathTraveledForm: this.pathTraveledForm,
            projectForm: this.projectForm,
            shipForm: this.shipForm,
            snapshotForm: this.snapshotForm,
            vehicleForm: this.vehicleForm,
          });

          this.currentReportNotes = projectNotes ? projectNotes.filter((note) => note.reportId) : [];
        }
      );
  }

  ngOnInit(): void {
    zip(this.currentUser$, this.selectedReport$, this.selectedReportIFrameSrc$)
      .pipe(untilDestroyed(this))
      .pipe(
        map(([user, report, reportIFrameSrc]) => ({
          user,
          report,
          reportIFrameSrc,
        }))
      )
      .subscribe(({ user, report, reportIFrameSrc }) => {
        this.currentUser = user;
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(reportIFrameSrc);
        this.settingsService.setIsLoading(false);
      });
  }

  ngAfterViewInit() {
    //noteTableViewChild is set up after the view is initialized
    if (this.settingsService.getIsDebugging()) {
      this.logService.logInfo(`AfterViewInit in report.component`);
    }
  }

  ngAfterViewChecked() {
    //noteTableViewChild is updated after the view has been checked
    const projectNotes = this.noteService.getCurrentProjectNotes();
    const reportNotes = projectNotes.filter((note) => note.reportId);
    const matches = isEqual(reportNotes, this.currentReportNotes);

    if (!matches) {
      this.form.markAsDirty();
    }
  }

  ngOnDestroy(): void {
    const _this = this;

    if (_this.form && _this.form.dirty) {
      Swal.fire({
        title: 'Save Changes?',
        icon: 'warning',
        text: 'Do you want to save the changes you made to this report?',
        timer: 5000,
        timerProgressBar: false,
        confirmButtonText: 'Save',
        showCancelButton: true,
        cancelButtonText: 'Discard Changes',
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          if (_this.settingsService.getIsDebugging()) {
            _this.logService.logInfo(`Save report on close dismissed by timer.  Changes lost.`);
          }
        } else if (result.isDenied) {
          if (_this.settingsService.getIsDebugging()) {
            _this.logService.logInfo(
              `UserId ${this.currentUser._id} chose to discard changes on reportId ${_this.report._id} before leaving the page`
            );
          }
        } else if (result.isConfirmed) {
          if (_this.settingsService.getIsDebugging()) {
            _this.logService.logInfo(`User requested to save reportId ${_this.report._id} before leaving the page`);
          }

          _this.settingsService.setIsLoading(true);

          _this
            .save(_this.report._id, _this.project, _this.currentUser._id)
            .then((results) => {
              if (_this.settingsService.getIsDebugging()) {
                _this.logService.logInfo(`Successfully saved reportId ${_this.report._id} before leaving the page`);
              }
            })
            .catch((error) => {
              const errMessage = _this.errorService.handleError(
                `Error saving reportId ${_this.report._id} before leaving the page: ${error}`
              );
            })
            .finally(() => {
              _this.settingsService.setIsLoading(false);
            });
        }
      });
    }
  }

  get isFinalizedReport(): boolean {
    return this.isFinalizedBehaviorSubject.getValue();
  }

  get isShipModel3d(): boolean {
    return this.shipModel3d != null;
  }

  get isShipScan(): boolean {
    return this.shipScan != null;
  }

  get isVehicleModel3d(): boolean {
    return this.vehicleModel3d != null;
  }

  get isVehicleScan(): boolean {
    return this.vehicleScan != null;
  }

  get areAllAnalysisComponentsSelected(): boolean {
    let returnValue = true;

    for (const field in this.analysisForm.controls) {
      if (this.analysisForm.controls[field].value === false) {
        returnValue = false;
        break;
      }
    }

    return returnValue;
  }

  get areAllCollisionComponentsSelected(): boolean {
    let returnValue = true;

    for (const field in this.collisionForm.controls) {
      if (this.collisionForm.controls[field].value === false) {
        returnValue = false;
        break;
      }
    }

    return returnValue;
  }

  get areAllMinimumClearanceComponentsSelected(): boolean {
    let returnValue = true;

    for (const field in this.minimumClearanceForm.controls) {
      if (this.minimumClearanceForm.controls[field].value === false) {
        returnValue = false;
        break;
      }
    }

    return returnValue;
  }

  get areAllPathTraveledComponentsSelected(): boolean {
    let returnValue = true;

    for (const field in this.pathTraveledForm.controls) {
      if (this.pathTraveledForm.controls[field].value === false) {
        returnValue = false;
        break;
      }
    }

    return returnValue;
  }

  get areAllProjectComponentsSelected(): boolean {
    let returnValue = true;

    for (const field in this.projectForm.controls) {
      if (this.projectForm.controls[field].value === false) {
        returnValue = false;
        break;
      }
    }

    return returnValue;
  }

  get areAllShipComponentsSelected(): boolean {
    let returnValue = true;

    if (this.shipModel3d) {
      if (
        this.shipForm.get('model3d.dataSource').value === false ||
        this.shipForm.get('model3d.fidelity').value === false ||
        this.shipForm.get('model3d.fileType').value === false ||
        this.shipForm.get('model3d.modelDate').value === false
      ) {
        returnValue = false;
      }
    } else if (this.shipScan) {
      if (
        this.shipForm.get('scan.dataSource').value === false ||
        this.shipForm.get('scan.fileType').value === false ||
        this.shipForm.get('scan.scanDate').value === false ||
        this.shipForm.get('scan.scanType').value === false
      ) {
        returnValue = false;
      }
    }

    if (this.shipForm.controls['image'].value === false) {
      returnValue = false;
    }

    if (this.shipForm.controls['notes'].value === false) {
      returnValue = false;
    }

    if (this.shipForm.controls['snapshots'].value === false) {
      returnValue = false;
    }

    if (this.shipForm.controls['videos'].value === false) {
      returnValue = false;
    }

    return returnValue;
  }

  get areAllSnapshotComponentsSelected(): boolean {
    let returnValue = true;

    for (const field in this.snapshotForm.controls) {
      if (this.snapshotForm.controls[field].value === false) {
        returnValue = false;
        break;
      }
    }

    return returnValue;
  }

  get areAllVehicleComponentsSelected(): boolean {
    let returnValue = true;

    if (this.vehicleModel3d) {
      if (
        this.vehicleForm.get('model3d.dataSource').value === false ||
        this.vehicleForm.get('model3d.fidelity').value === false ||
        this.vehicleForm.get('model3d.fileType').value === false ||
        this.vehicleForm.get('model3d.modelDate').value === false
      ) {
        returnValue = false;
      }
    } else if (this.vehicleScan) {
      if (
        this.vehicleForm.get('scan.dataSource').value === false ||
        this.vehicleForm.get('scan.fileType').value === false ||
        this.vehicleForm.get('scan.scanDate').value === false ||
        this.vehicleForm.get('scan.scanType').value === false
      ) {
        returnValue = false;
      }
    }

    if (
      this.vehicleForm.controls['axles'].value === false ||
      this.vehicleForm.controls['brakes'].value === false ||
      this.vehicleForm.controls['engine'].value === false ||
      this.vehicleForm.controls['generalInfo'].value === false ||
      this.vehicleForm.controls['image'].value === false ||
      this.vehicleForm.controls['notes'].value === false ||
      this.vehicleForm.controls['maneuverability'].value === false ||
      this.vehicleForm.controls['snapshots'].value === false ||
      this.vehicleForm.controls['steering'].value === false ||
      this.vehicleForm.controls['videos'].value === false
    ) {
      returnValue = false;
    }

    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  async addNote(report: Report, project: Project) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: this.currentUser,
      isNewNote: true,
      project: project,
      report: report,
    };

    const dialogRef = this.dialog.open(ReportItemDialogComponent, dialogConfig);
  }

  createModel3dFormGroup(model3dOptions) {
    const model3dFormGroup = new UntypedFormGroup({
      dataSource: new UntypedFormControl(model3dOptions ? model3dOptions.dataSource : false),
      fidelity: new UntypedFormControl(model3dOptions ? model3dOptions.fidelity : false),
      fileType: new UntypedFormControl(model3dOptions ? model3dOptions.fileType : false),
      modelDate: new UntypedFormControl(model3dOptions ? model3dOptions.modelDate : false),
    });

    return model3dFormGroup;
  }

  createScanFormGroup(scanOptions) {
    const scanFormGroup = new UntypedFormGroup({
      dataSource: new UntypedFormControl(scanOptions ? scanOptions.dataSource : false),
      fileType: new UntypedFormControl(scanOptions ? scanOptions.fileType : false),
      scanDate: new UntypedFormControl(scanOptions ? scanOptions.scanDate : false),
      scanType: new UntypedFormControl(scanOptions ? scanOptions.scanType : false),
    });

    return scanFormGroup;
  }

  async finalize(report: Report, project: Project, editorId: string): Promise<any> {
    const _this = this;

    Swal.fire({
      title: `Finalize  ${report.name} report?`,
      text: 'Are you sure your want to finalize the report?  Once the report is finalized, no changes can be made to it.',
      showCancelButton: true,
      confirmButtonText: 'Finalize',
    }).then((result) => {
      if (result.isConfirmed) {
        _this.settingsService.setIsLoading(true);
        const navigationUrl = `/projects/${project._id}`;

        _this.reportService
          .finalizeReport(
            report,
            project,
            _this.ship,
            _this.shipMod,
            _this.shipModel3d,
            _this.shipScan,
            _this.vehicle,
            _this.vehicleMod,
            _this.vehicleModel3d,
            _this.vehicleScan,
            _this.currentUser
          )
          .then(function (pdfUrl) {
            _this.isFinalizedBehaviorSubject.next(true);
            _this.form.markAsPristine();

            /* the PDF is generated and saved correctly but doesn't display in the viewer right (the margins and spacing are off)
                        so rather than displaying the PDF, take them back to the project page and the report finalized date will show.  They
                        can click to view the finalized report if they want to. */
            Swal.fire('Report Successfully Finalized', 'The report was finalized', 'success');
            _this.router.navigateByUrl(navigationUrl);
          })
          .catch(function (pdfError) {
            const errMessage = `Error finalizing reportId ${report._id}: ${pdfError}`;
            _this.errorService.handleError(errMessage);
            _this.errorText = pdfError.message;
            _this.settingsService.setIsLoading(false);
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire('Error Finalizing Report', `${pdfError.message}`, 'error');
            }
          })
          .finally(function () {
            _this.settingsService.setIsLoading(false);
          });
      }
    });
  }

  getNumberOfItemsInArray(items) {
    let returnValue = 0;

    if (items && Array.isArray(items)) {
      returnValue = items.length;
    }

    return returnValue;
  }

  getReportLevelNotes(projectNotes: Note[], reportId: string) {
    if (projectNotes && projectNotes.length > 0) {
      return projectNotes.filter((note) => note.reportId === reportId);
    }
  }

  getYesOrNo(value: boolean): string {
    return getYesOrNo(value);
  }

  getReportSection(reportSections: ReportSection[], id: string) {
    if (reportSections && reportSections.length > 0) {
      return reportSections.find((reportSection) => reportSection._id === id);
    }
  }

  navigateToProject() {
    this.settingsService.setIsLoading(true);
    const currentUser = this.userService.getCurrentUser();

    this.projectService
      .getProjectById(this.project._id, currentUser)
      .then((p: Project) => {
        const navigationUrl = `/projects/${p._id}`;
        this.router.navigateByUrl(navigationUrl);
      })
      .catch((projectError) => {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error`,
            `Error getting project information.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      })
      .finally(() => {
        this.settingsService.setIsLoading(false);
      });
  }

  onSelectAllAnalysisComponents(ob: MatSlideToggleChange) {
    for (const field in this.analysisForm.controls) {
      this.analysisForm.controls[field].setValue(ob.checked);
    }
  }

  onSelectAllCollisionComponents(ob: MatSlideToggleChange) {
    for (const field in this.collisionForm.controls) {
      this.collisionForm.controls[field].setValue(ob.checked);
    }
  }

  onSelectAllMinimumClearanceComponents(ob: MatSlideToggleChange) {
    for (const field in this.minimumClearanceForm.controls) {
      this.minimumClearanceForm.controls[field].setValue(ob.checked);
    }
  }

  onSelectAllPathTraveledComponents(ob: MatSlideToggleChange) {
    for (const field in this.pathTraveledForm.controls) {
      this.pathTraveledForm.controls[field].setValue(ob.checked);
    }
  }

  onSelectAllProjectComponents(ob: MatSlideToggleChange) {
    for (const field in this.projectForm.controls) {
      this.projectForm.controls[field].setValue(ob.checked);
    }
  }

  onSelectAllShipComponents(ob: MatSlideToggleChange) {
    if (this.shipModel3d) {
      this.shipForm.get('model3d.dataSource').setValue(ob.checked);
      this.shipForm.get('model3d.fidelity').setValue(ob.checked);
      this.shipForm.get('model3d.fileType').setValue(ob.checked);
      this.shipForm.get('model3d.modelDate').setValue(ob.checked);
    } else if (this.shipScan) {
      this.shipForm.get('scan.dataSource').setValue(ob.checked);
      this.shipForm.get('scan.fileType').setValue(ob.checked);
      this.shipForm.get('scan.scanDate').setValue(ob.checked);
      this.shipForm.get('scan.scanType').setValue(ob.checked);
    }

    this.shipForm.get('image').setValue(ob.checked);
    this.shipForm.get('notes').setValue(ob.checked);
    this.shipForm.get('snapshots').setValue(ob.checked);
    this.shipForm.get('videos').setValue(ob.checked);
  }

  onSelectAllSnapshotComponents(ob: MatSlideToggleChange) {
    for (const field in this.snapshotForm.controls) {
      this.snapshotForm.controls[field].setValue(ob.checked);
    }
  }

  onSelectAllVehicleComponents(ob: MatSlideToggleChange) {
    if (this.vehicleModel3d) {
      this.vehicleForm.get('model3d.dataSource').setValue(ob.checked);
      this.vehicleForm.get('model3d.fidelity').setValue(ob.checked);
      this.vehicleForm.get('model3d.fileType').setValue(ob.checked);
      this.vehicleForm.get('model3d.modelDate').setValue(ob.checked);
    } else if (this.vehicleScan) {
      this.vehicleForm.get('scan.dataSource').setValue(ob.checked);
      this.vehicleForm.get('scan.fileType').setValue(ob.checked);
      this.vehicleForm.get('scan.scanDate').setValue(ob.checked);
      this.vehicleForm.get('scan.scanType').setValue(ob.checked);
    }

    this.vehicleForm.get('axles').setValue(ob.checked);
    this.vehicleForm.get('brakes').setValue(ob.checked);
    this.vehicleForm.get('engine').setValue(ob.checked);
    this.vehicleForm.get('generalInfo').setValue(ob.checked);
    this.vehicleForm.get('image').setValue(ob.checked);
    this.vehicleForm.get('notes').setValue(ob.checked);
    this.vehicleForm.get('maneuverability').setValue(ob.checked);
    this.vehicleForm.get('snapshots').setValue(ob.checked);
    this.vehicleForm.get('steering').setValue(ob.checked);
    this.vehicleForm.get('videos').setValue(ob.checked);
  }

  async save(id: string, project: Project, editorId: string): Promise<any> {
    this.settingsService.setIsLoading(true);

    const reportInfo = {
      options: {
        analysis: this.analysisForm.value,
        collision: this.collisionForm.value,
        minimumClearance: this.minimumClearanceForm.value,
        pathTraveled: this.pathTraveledForm.value,
        project: this.projectForm.value,
        ship: this.shipForm.value,
        snapshot: this.snapshotForm.value,
        vehicle: this.vehicleForm.value,
      },
      editorId: editorId,
    };

    this.reportService
      .saveReport(
        id,
        reportInfo,
        project,
        this.ship,
        this.shipMod,
        this.shipModel3d,
        this.shipScan,
        this.vehicle,
        this.vehicleMod,
        this.vehicleModel3d,
        this.vehicleScan,
        this.currentUser
      )
      .then((updatedReportUrl: string) => {
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(updatedReportUrl);

        //reset notes
        const projectNotes = this.noteService.getCurrentProjectNotes();
        this.currentReportNotes = projectNotes ? projectNotes.filter((note) => note.reportId) : [];

        this.form.markAsPristine();
      })
      .catch((error) => {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Saving Report`,
            `${error}.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      })
      .finally(() => {
        this.settingsService.setIsLoading(false);
      });
  }

  viewProject() {
    const user = this.userService.getCurrentUser();

    if (this.project && user) {
      if (this.form && this.form.dirty) {
        Swal.fire({
          title: 'Save Changes?',
          icon: 'warning',
          text: 'Do you want to save the changes you made to this report?',
          confirmButtonText: 'Save',
          showCancelButton: true,
          cancelButtonText: 'Discard Changes',
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.settingsService.getIsDebugging()) {
              this.logService.logInfo(`User requested to save reportId ${this.report._id} before leaving the page`);
            }

            this.settingsService.setIsLoading(true);

            this.save(this.report._id, this.project, this.currentUser._id)
              .then((results) => {
                if (this.settingsService.getIsDebugging()) {
                  this.logService.logInfo(
                    `Successfully saved reportId ${this.report._id} before navigating to project`
                  );
                }
                this.form.markAsPristine();
                this.navigateToProject();
              })
              .catch((error) => {
                this.settingsService.setIsLoading(false);
                if (this.settingsService.getShowPopupErrorMessages()) {
                  Swal.fire(
                    `Error Saving Report`,
                    `${error}.  Please email ${environment.techSupportEmail} with any questions.`,
                    'error'
                  );
                }
              })
              .finally(() => {
                this.settingsService.setIsLoading(false);
              });
          } else {
            if (this.settingsService.getIsDebugging()) {
              this.logService.logInfo(
                `UserId ${this.currentUser._id} chose to discard changes on reportId ${this.report._id} before leaving the page`
              );
            }
            this.form.markAsPristine();
            this.navigateToProject();
          }
        });
      } else {
        this.navigateToProject();
      }
    } else {
      this.settingsService.setIsLoading(false);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error Displaying Project',
          `Unable to load project data.  Please email ${environment.techSupportEmail}`,
          'error'
        );
			}
    }
  }
}
