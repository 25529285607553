export enum UnrealUIStatesEnum {
  DYNAMIC_ASSET_CALIBRATION = '110501',
  FREE_ROAM = '110101',
  //PROJECT value was 110201, use same as free-roam for now per Alex - jane 1/24/2024
  //Unreal now updates tools based on the mods, change my code to base functionality on if there is a project.
  //If there isn't a project, save it to the ship. - jane 7/8/2024 
  /* 
   PROJECT value was 110201, use same as free-roam for now per Alex - jane 1/24/2024
   Unreal now updates tools based on the mods, change my code to base functionality on if there is a project.
   If there isn't a project, save it to the ship.  
   However, I'm leaving the separate values in here because things are so fluid, we may need it back.  - jane 7/8/2024 
   */
  PROJECT = '110101',  
}
