import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, Validators, FormGroup, UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2';

import { Vehicle, User } from '@shared/models';
import { ErrorService, LogService, SettingsService, UserService, VehicleService } from '@shared/services';
import { UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.css'],
})
export class VehicleListComponent implements OnInit {
  @Input()
  vehicles: Vehicle[];

  @ViewChild(MatSort) sort: MatSort;

  currentUser$: Observable<User>;
  dataSource;
  displayedVehicleColumns: string[] = ['name', 'designation', 'updatedAt'];
  selectedVehicleId = null;
  selectedVehicle$: Observable<Vehicle>;
  viewFormat$: Observable<string>;
  viewAsControl = new UntypedFormControl();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private vehicleService: VehicleService,
    private userService: UserService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.dataSource = new MatTableDataSource(this.vehicles);
    this.selectedVehicle$ = this.vehicleService.currentVehicle$;
    this.viewFormat$ = this.settingsService.viewFormat$;
    this.viewAsControl.setValue(this.settingsService.getViewFormat());
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  get formTitle(): String {
    let returnValue = 'Choose Vehicle';

    this.selectedVehicle$.subscribe((vehicle) => {
      if (vehicle) {
        returnValue = `Selected Vehicle: ${vehicle.name} | ${vehicle.designation}`;
      }
    });

    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  getVehicleDesignation(vehicle: Vehicle): string {
    let returnValue = '';

    if (vehicle && vehicle.designation) {
      returnValue = vehicle.designation;
    }

    return returnValue;
  }

  getVehicleDisplayClass(vehicle: Vehicle): string {
    let returnValue = '';

    if (vehicle) {
      this.selectedVehicle$.subscribe((selectedVehicle) => {
        if (selectedVehicle && selectedVehicle._id === vehicle._id) {
          returnValue = 'selected-vehicle';
        }
      });
    }

    return returnValue;
  }

  onViewFormatChange(value: string, currentUser: User) {
    this.userService.updateViewFormatPreference(currentUser._id, value);
  }

  selectVehicle(vehicle: Vehicle): void {
    this.settingsService.setIsLoading(true);

    this.vehicleService
      .getVehicleById(vehicle._id, this.userService.getCurrentUser())
      .then((vehicleWithShipData: Vehicle) => {
        //make sure table row is highlighted too, may have selected in the card view and then flip
        this.selectedVehicleId = vehicle._id;
      })
      .catch((error) => {
        this.settingsService.setIsLoading(false);
        this.selectedVehicleId = null;
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error',
            `Error selecting vehicle ${vehicle.name}.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      })
      .finally(() => {
        this.settingsService.setIsLoading(false);
      });
  }
}
