import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';

import { Manufacturer, Scanner, User } from '@shared/models';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import { 
  LogService,
  ErrorService,
  ManufacturerService,
  UserService,
  SettingsService
 } from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class ScannerService {
  private currentScannerSubject = new BehaviorSubject<Scanner>(null);
  private currentScannerManufacturerSubject = new BehaviorSubject<Manufacturer>(null);
  private manufacturerScannersSubject = new BehaviorSubject<Scanner[]>([]);
  private scannersSubject = new BehaviorSubject<Scanner[]>([]);
  private scannerErrorSubject = new BehaviorSubject<string>(null);;
  currentScanner$: Observable<Scanner> = this.currentScannerSubject.asObservable();
  currentScannerManufacturer$: Observable<Manufacturer> = this.currentScannerManufacturerSubject.asObservable();
  manufacturerScanners$: Observable<Scanner[]> = this.manufacturerScannersSubject.asObservable();
  scanners$: Observable<Scanner[]> = this.scannersSubject.asObservable();
  scannerError$: Observable<string> = this.scannerErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private manufacturerService: ManufacturerService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) {
  }

  async createNewScanner(payload: Scanner, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && payload) {
        _this.settingsService.setIsLoading(true);
        const scanners = _this.scannersSubject.getValue();
        const newScanners = scanners.slice(0);
        const url = `${environment.baseAPIUrl}scanner/create?userId=${currentUser?._id}`;
        let returnValue: Scanner;
  
        _this.httpClient
          .post(url, payload, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (newScanner: Scanner) => {
              returnValue = newScanner;
              _this.currentScannerSubject.next(newScanner);
              newScanners.push(newScanner);
              _this.scannersSubject.next(newScanners);
  
              if (newScanner.manufacturerId) {
                _this.manufacturerService
                  .getManufacturerById(newScanner.manufacturerId, currentUser)
                  .then((manufacturer: Manufacturer) => {
                    _this.currentScannerManufacturerSubject.next(manufacturer);
                  })
                  .catch((manufacturerError) => {
                    _this.errorService.handleError(
                      `Error getting manufacturerId ${newScanner.manufacturerId} for the new scanner: ${manufacturerError}`
                    );
                    _this.currentScannerManufacturerSubject.next(null);
                  })
                  .finally(() => {
                    _this.settingsService.setIsLoading(false);
                    resolve(returnValue);
                  });
              } else {
                _this.settingsService.setIsLoading(false);
                _this.currentScannerManufacturerSubject.next(null);
                resolve(returnValue);
              }
            },
            error: (error: HttpErrorResponse) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = _this.errorService.handleError(error);
              _this.scannerErrorSubject.next(errMessage);
              _this.currentScannerSubject.next(null);
              reject(error);
            },
            complete: () => {
  
            }
          });
      } else {
        const errMessage = `payload and user are required to create a scanner`;
        _this.errorService.handleError(errMessage);
        _this.scannerErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveScanner(scannerId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && scannerId) {
        _this.settingsService.setIsLoading(true);
        const scanners = _this.scannersSubject.getValue();
        const scannerIndex = scanners.findIndex((scanner) => scanner._id === scannerId);
        const newScanners = scanners.slice(0);
        const url = `${environment.baseAPIUrl}scanner/${scannerId}?userId=${currentUser?._id}`;
        let returnValue: Scanner;
  
        newScanners[scannerIndex] = {
          ...scanners[scannerIndex],
          ...changes,
        };
  
        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedScanner: Scanner) => {
              returnValue = updatedScanner;
              _this.scannersSubject.next(newScanners);
              _this.currentScannerSubject.next(updatedScanner);
  
              if (updatedScanner.manufacturerId) {
                _this.manufacturerService
                  .getManufacturerById(updatedScanner.manufacturerId, currentUser)
                  .then((manufacturer: Manufacturer) => {
                    _this.currentScannerManufacturerSubject.next(manufacturer);
                  })
                  .catch((manufacturerError) => {
                    _this.errorService.handleError(
                      `Error getting manufacturerId ${updatedScanner.manufacturerId} for the new scanner: ${manufacturerError}`
                    );
                    _this.currentScannerManufacturerSubject.next(null);
                  })
                  .finally(() => {
                    _this.settingsService.setIsLoading(false);
                    resolve(returnValue);
                  });
              } else {
                _this.settingsService.setIsLoading(false);
                _this.currentScannerManufacturerSubject.next(null);
                resolve(returnValue);
              }
            },
            error: (error: HttpErrorResponse) => {
              _this.settingsService.setIsLoading(false);
              const errMessage  = _this.errorService.handleError(error);
              _this.scannerErrorSubject.next(errMessage);
              _this.currentScannerSubject.next(null);
              reject(error);
            },
            complete: () => {
  
            }
          });
      } else {
        const errMessage = `changes, scannerId and user are required to update a scanner`;
        _this.errorService.handleError(errMessage);
        _this.scannerErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getCurrentScanner() {
    return this.currentScannerSubject.getValue();
  }

  getCurrentScannerManufacturer() {
    return this.currentScannerManufacturerSubject.getValue();
  }

  getScanners(currentUser: User): Observable<Scanner[]> {
    const scannersHttp$ = createHttpObservable(`${environment.baseAPIUrl}scanner?userId=${currentUser?._id}`, {}, true);

    scannersHttp$
      .pipe(
        catchError((err) => {
          console.error(`Error getting scanners: ${err}`);
          return of([]);
        })
      )
      .subscribe((scanners: Scanner[]) => this.scannersSubject.next(scanners));

    return scannersHttp$;
  }

  async getScannerById(scannerId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue: Scanner;

      if (currentUser && scannerId) {
        _this.scanners$
          .pipe(
            map((scanners) => scanners.find((scanner) => scanner._id === scannerId)),
            filter((scanner) => !!scanner)
          )
          .subscribe({
            next: (scanner: Scanner) => {
              returnValue = scanner;
              _this.currentScannerSubject.next(scanner);

              if (scanner.manufacturerId) {
                _this.manufacturerService
                  .getManufacturerById(scanner.manufacturerId, currentUser)
                  .then((manufacturer: Manufacturer) => {
                    _this.currentScannerManufacturerSubject.next(manufacturer);
                  })
                  .catch((manufacturerError) => {
                    _this.errorService.handleError(
                      `Error getting manufacturerId ${scanner.manufacturerId} for scanner: ${manufacturerError}`
                    );
                    _this.currentScannerManufacturerSubject.next(null);
                  })
                  .finally(() => {
                    _this.settingsService.setIsLoading(false);
                    resolve(returnValue);
                  });
              } else {
                _this.settingsService.setIsLoading(false);
                _this.currentScannerManufacturerSubject.next(null);
                resolve(returnValue);
              }
            }, 
            error: (error: any) => {
              const errMessage = `Error getting scannerId ${scannerId}: ${error.error}`;
              _this.errorService.handleError(errMessage);
              _this.scannerErrorSubject.next(errMessage);
              reject(error);
            }, 
            complete: () => {

            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        _this.currentScannerSubject.next(null);
        _this.currentScannerManufacturerSubject.next(null);
        resolve(returnValue);
      }
    });
  }

  async refreshScannersByManufacturer(manufacturerId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue: Scanner[];

      if (currentUser && manufacturerId) {
        _this.scanners$
          .pipe(map((scanners) => scanners.filter((scanner) => scanner.manufacturerId === manufacturerId)))
          .subscribe({
            next: (scanners: Scanner[]) => {
              _this.settingsService.setIsLoading(false);
              returnValue = scanners;
              _this.manufacturerScannersSubject.next(scanners);
              resolve(returnValue);
            },
            error: (error: Error) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = `Error refreshing scanners by manufacturerId ${manufacturerId}: ${error}`;
              _this.scannerErrorSubject.next(errMessage);
              reject(error);
            }, 
            complete: () => {

            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        resolve(returnValue);
      }
    });
  }
}
