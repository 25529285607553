export enum TagFileTypesEnum {
  AUDIO = 'Audio',
  IMAGE = 'Picture',
  MANUAL = 'Manual',
  MOD = 'Mod',
  MODEL3D = 'Model3d',
  OTHER = 'Other',
  REPORT = 'Report',
  SCAN = 'Scan',
  SITE = 'Site',
  SNAPSHOT = 'Snapshot',
  VIDEO = 'Video',
}
