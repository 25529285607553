import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
  FormControlDirective,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { fromEvent, noop, Observable, zip } from 'rxjs';
import { concatMap, distinctUntilChanged, exhaustMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { DataSource, Mod, Model3d, Ship, User, Vehicle, VehicleDesignation } from '@shared/models';
import {
  DataSourceService,
  ErrorService,
  FileService,
  Model3dService,
  SettingsService,
  ShipService,
  UserService,
  VehicleDesignationService,
  VehicleService,
} from '@shared/services';
import { DbCollectionsEnum, FileObjectTypesEnum, ModStatesEnum, ModTypesEnum, TagFileTypesEnum, TagTypesEnum, UserRolesEnum } from '@shared/enums';
import { createTag, getUpdates } from '@shared/utils';

import { environment } from '@environment';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
  selector: 'app-model3d-dialog',
  templateUrl: './model3d-dialog.component.html',
  styleUrls: ['./model3d-dialog.component.css'],
})
export class Model3dDialogComponent implements OnInit {
  @ViewChild('saveButton', { static: true }) saveButton: ElementRef;

  @ViewChild('cancelButton', { static: true }) cancelButton: ElementRef;

  currentUser: User;
  errorMsg: boolean;
  errorText = 'Please select a file';
  isNewModel: boolean;
  parentShip: Ship;
  parentVehicle: Vehicle;
  model3d: Model3d;
  modelFileObj: File;
  form: UntypedFormGroup;
  fileForm: UntypedFormGroup;
  model3dForm: UntypedFormGroup;
  parentForm: UntypedFormGroup;
  currentUser$: Observable<User>;
  dataSources$: Observable<DataSource[]>;
  isDebugging$: Observable<boolean>;
  vehicleDesignation$: Observable<VehicleDesignation>;
  vehicleDesignations$: Observable<VehicleDesignation[]>;
  hasModelFileName = false;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<Model3dDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    data: {
      currentUser: User;
      isNewModel: boolean;
      model3d: Model3d;
      parentShip: Ship;
      parentVehicle: Vehicle;
    },
    private dataSourceService: DataSourceService,
    private errorService: ErrorService,
    private fileService: FileService,
    private model3dService: Model3dService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private vehicleService: VehicleService,
    private vehicleDesignationService: VehicleDesignationService,
    private userService: UserService
  ) {
    this.currentUser = data.currentUser;
    this.isNewModel = data.isNewModel;
    this.model3d = data.model3d;
    this.parentShip = data.parentShip;
    this.parentVehicle = data.parentVehicle;
    this.currentUser$ = this.userService.currentUser$;
    this.dataSources$ = this.dataSourceService.dataSources$;
    this.isDebugging$ = this.settingsService.isDebugging$;
    this.vehicleDesignation$ = this.vehicleDesignationService.currentVehicleDesignation$;
    this.vehicleDesignations$ = this.vehicleDesignationService.vehicleDesignations$;

    zip(this.currentUser$, this.vehicleDesignation$, this.dataSources$)
      .pipe(untilDestroyed(this))
      .pipe(
        map(([user, vehicleDesignation, dataSources]) => ({
          user,
          vehicleDesignation,
          dataSources,
        }))
      )
      .subscribe(({ user, vehicleDesignation, dataSources }) => {
        this.currentUser = user;

        this.fileForm = new UntypedFormGroup({
          editorId: new UntypedFormControl(this.currentUser._id, Validators.required),
          fileType: new UntypedFormControl(this.model3d.fileType, Validators.required),
          modelFileName: new UntypedFormControl(this.model3d.name, Validators.required),
          modelModName: new UntypedFormControl(this.model3d.name, Validators.required),
          url: new UntypedFormControl(this.model3d.url, Validators.required),
        });

        this.parentForm = new UntypedFormGroup({
          _id: new UntypedFormControl(this.model3d.parent._id, Validators.required),
          collection: new UntypedFormControl(this.model3d.parent.collection, Validators.required),
        });

        this.model3dForm = fb.group({
          _id: [this.model3d._id, Validators.required],
          editorId: new UntypedFormControl(this.currentUser._id, Validators.required),
          name: [this.model3d.name, Validators.required],
          creatorId: [this.model3d.creatorId, Validators.required],
          classification: [this.model3d.classification || 'Unclass', Validators.required],
          modelDate: [this.model3d.modelDate, Validators.required],
          dataSourceId: [this.model3d.dataSourceId, Validators.required],
          description: [this.model3d.description],
          nameOfPersonModeling: [this.model3d.nameOfPersonModeling, Validators.required],
          modelUploader: [this.isNewModel ? this.currentUser.fullName : this.model3d.modelUploader, Validators.required],
          modId: [this.model3d.modId],
          parent: this.parentForm,
          fidelityType: [this.model3d.fidelityType, Validators.required],
        });

        this.form = fb.group({
          fileForm: this.fileForm,
          model3dForm: this.model3dForm,
        });
      });
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void { }

  get formTitle(): string {
    let returnValue = '';

    if (this.isNewModel) {
      returnValue = 'Add New 3d Model to ';
      if (this.parentShip) {
        returnValue += `Ship ${this.parentShip.name}`;
      } else if (this.parentVehicle) {
        returnValue += `Vehicle ${this.parentVehicle.name}`;
      }
    } else {
      returnValue = `Edit 3D Model ${this.model3d.name} Modeeled on ${this.model3d.modelDate ? new Date(this.model3d.modelDate).toLocaleDateString('en-US') : ''
        }`;
    }
    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  addDataSource(): void {
    const _this = this;

    Swal.fire({
      title: 'Add 3D Model Data Source',
      html: `<input type="text" id="newDataSourceName" class="swal2-input" placeholder="Organization Name" title="Organization Name" required>
      <input type="url" id="newDataSourceUrl" class="swal2-input" placeholder="http://www.example.com" pattern="https?://.+" title="Organization URL (optional)">
      <textarea id="newDataSourceDescription" class="swal2-input" placeholder="Organization Description" rows="2" cols="50" title="Organization Description (optional)">`,
      confirmButtonText: 'Add Data Source',
      showCancelButton: true,
      focusConfirm: false,
      width: '50%',
      preConfirm: () => {
        const name = Swal.getPopup().querySelector('#newDataSourceName').value;
        const description = Swal.getPopup().querySelector('#newDataSourceDescription').value;
        const url = Swal.getPopup().querySelector('#newDataSourceUrl').value;

        if (!name) {
          Swal.showValidationMessage('Please enter 3D model data source organization name');
        }
        const ds: DataSource = {
          name: name,
          creatorId: this.currentUser._id,
        };

        if (url) {
          ds.url = url;
        }

        if (description) {
          ds.description = description;
        }

        return ds;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.dataSourceService
          .createNewDataSource(result.value, _this.currentUser)
          .then((newDataSource: DataSource) => {
            console.log(`successfully created new data source: ${newDataSource.name}`);
          })
          .catch((error) => {
            _this.errorMsg = true;
            _this.errorText = error.message;
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error',
                `Unable to create new data source: ${error.message}.  Please email ${environment.techSupportEmail}`,
                'error'
              );
            }
          });
      }
    });
  }

  addVehicleDesignation(): void {
    const _this = this;

    Swal.fire({
      title: 'Add Vehicle Designation',
      html: `<input type="text" id="newVehicleDesignation" class="swal2-input" placeholder="Designation" required>
      <textarea id="newVehicleDesignationDescription" class="swal2-input" placeholder="Description" rows="2" cols="50" title="Description (optional)">`,
      confirmButtonText: 'Add Vehicle Designation',
      showCancelButton: true,
      focusConfirm: false,
      width: '50%',
      preConfirm: () => {
        const designation = Swal.getPopup().querySelector('#newVehicleDesignation').value;
        const description = Swal.getPopup().querySelector('#newVehicleDesignationDescription').value;

        if (!designation) {
          Swal.showValidationMessage('Please enter vehicle designation');
        }

        const des: VehicleDesignation = {
          designation: designation,
          creatorId: this.currentUser._id,
        };

        if (description) {
          des.description = description;
        }

        return des;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        _this.vehicleDesignationService
          .createNewVehicleDesignation(result.value, _this.currentUser)
          .then((newVehicleDesignation: VehicleDesignation) => {
            //do nothing, will auto refresh based on subscription
          })
          .catch((error) => {
            _this.errorMsg = true;
            _this.errorText = `Error creating new vehicle designation: ${error.message}`;
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error',
                `There was an error creating the new vehicle designation.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          });
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  getShowModelSaveButton(role: string) {
    let returnValue = false;

    if (this.isNewModel) {
      if (this.model3dForm.dirty && this.model3dForm.valid && this.fileForm.valid) {
        returnValue = true;
      }
    } else if (this.model3dForm.dirty && this.model3dForm.valid) {
      returnValue = true;
    }

    return returnValue;
  }

  async modelFileInputChange($event: Event): Promise<any> {
    const _this = this;
    this.errorMsg = false;

    const FILE = ($event.target as HTMLInputElement).files[0];
    this.modelFileObj = FILE;
    const fileType = this.modelFileObj.name.substring(this.modelFileObj.name.lastIndexOf('.') + 1).toUpperCase();
    console.log(this.modelFileObj);

    if (!this.modelFileObj) {
      this.errorMsg = true;
      this.errorText = 'Please select a 3D model file';
    } else if (fileType == 'STEP' || fileType == 'STP') {
      this.settingsService.setIsLoading(true);
      const tags = [];
      tags.push(createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.MODEL3D));

      if (this.parentShip) {
        this.fileService
          .uploadFile(
            this.modelFileObj,
            FileObjectTypesEnum.SHIP,
            this.parentShip._id,
            tags,
            TagFileTypesEnum.MODEL3D,
            this.currentUser
          )
          .then((results) => {
            const modId = new ObjectID().toString();
            this.fileForm.controls.url.setValue(results.locationUrl);
            this.fileForm.controls.url.markAsDirty();
            this.fileForm.controls.modelFileName.setValue(results.nameWithoutExtension);
            this.fileForm.controls.modelFileName.markAsDirty();
            this.fileForm.controls.fileType.setValue(fileType);
            this.fileForm.controls.fileType.markAsDirty();
            this.model3dForm.controls.modId.setValue(modId);
            this.model3dForm.controls.modId.markAsDirty();
            this.fileForm.controls.modelModName.setValue(`M_${modId}`);
            this.fileForm.controls.modelModName.markAsDirty();
            this.hasModelFileName = true;
            this.settingsService.setIsLoading(false);
            if (results.processing) {
              Swal.fire(
                'File Upload in Process',
                'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.',
                'info'
              );
            }
          })
          .catch((uploadError) => {
            _this.errorMsg = true;
            _this.errorText = uploadError;
            _this.settingsService.setIsLoading(false);
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error Saving Ship 3D Model',
                `${uploadError}.  Please email ${environment.techSupportEmail} with any questions.`,
                'error'
              );
            }
          });
      } else if (this.parentVehicle) {
        this.fileService
          .uploadFile(
            this.modelFileObj,
            FileObjectTypesEnum.VEHICLE,
            this.parentVehicle._id,
            tags,
            TagFileTypesEnum.MODEL3D,
            this.currentUser
          )
          .then((results) => {
            const modId = new ObjectID().toString();
            this.fileForm.controls.url.setValue(results.locationUrl);
            this.fileForm.controls.url.markAsDirty();
            this.fileForm.controls.modelFileName.setValue(results.nameWithoutExtension);
            this.fileForm.controls.modelFileName.markAsDirty();
            this.fileForm.controls.fileType.setValue(fileType);
            this.fileForm.controls.fileType.markAsDirty();
            this.model3dForm.controls.modId.setValue(modId);
            this.model3dForm.controls.modId.markAsDirty();
            this.fileForm.controls.modelModName.setValue(`M_${modId}`);
            this.fileForm.controls.modelModName.markAsDirty();
            this.hasModelFileName = true;
            this.settingsService.setIsLoading(false);
            if (results.processing) {
              Swal.fire(
                'File Upload in Process',
                'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.',
                'info'
              );
            }
          })
          .catch((uploadError) => {
           _this.errorMsg = true;
           _this.errorText = uploadError;
           _this.settingsService.setIsLoading(false);
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error Saving Vehicle 3D Model',
                `${uploadError}.  Please email ${environment.techSupportEmail} with any questions.`,
                'error'
              );
            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(`A 3D model must be tied to a parent ship or vehicle.`);
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error',
            `A 3D model must be tied to a ship or a vehicle.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      }
    } else {
      _this.errorMsg = true;
      _this.errorText = 'Only STEP (.step, .stp) files are allowed';
      _this.settingsService.setIsLoading(false);
      if (_this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire('Error - Invalid File Type', 'Only STEP (.step, .stp) files are allowed at this time.', 'error');  
      }
    }
  }

  saveModel3dChanges(currentUser): void {
    const _this = this;
    this.settingsService.setIsLoading(true);

    if (this.isNewModel) {
      const model3dInfo = {
        ...this.model3dForm.value,
        ...this.fileForm.value,
      };

      //the values are in the controls but not in the .value object?
      model3dInfo.creatorId = model3dInfo.creatorId || currentUser._id;
      model3dInfo.scanUploader = model3dInfo.scanUploader || currentUser.fullName;
      model3dInfo._id = model3dInfo._id || this.model3dForm.controls._id.value;

      const modInfo = {
        _id: model3dInfo.modId,
        name: this.fileForm.controls.modelModName.value,
        creatorId: model3dInfo.creatorId || currentUser._id,
        modSource: {
          _id: model3dInfo._id,
          collection:  DbCollectionsEnum.MODELS3D
        },
        modState: ModStatesEnum.IN_PROCESSING,
        modType: this.parentShip ? ModTypesEnum.STATIC_MODEL : ModTypesEnum.DYNAMIC_MODEL,
        parent: {
          _id: model3dInfo.parent._id,
          collection: model3dInfo.parent.collection
        }
      };

      if (this.parentShip) {
        this.shipService
          .saveNewShipModel3d(model3dInfo, modInfo, currentUser)
          .then((shipResults) => {
            this.errorMsg = false;
            this.errorText = '';
            this.model3dForm.markAsPristine();
            this.dialogRef.close();
            this.settingsService.setIsLoading(false);
          })
          .catch((shipError) => {
            this.settingsService.setIsLoading(false);
            this.errorMsg = true;
            this.errorText = shipError;
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error Saving Ship 3D Model',
                `${shipError}.  Please email ${environment.techSupportEmail} with any questions.`,
                'error'
              );
            }
          });
      } else if (this.parentVehicle) {
        this.vehicleService
          .saveNewVehicleModel3d(model3dInfo, modInfo, currentUser)
          .then((vehicleResults) => {
            this.errorMsg = false;
            this.errorText = '';
            this.model3dForm.markAsPristine();
            this.dialogRef.close();
            this.settingsService.setIsLoading(false);
          })
          .catch((vehicleError) => {
            this.settingsService.setIsLoading(false);
            this.errorMsg = true;
            this.errorText = vehicleError;
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error Saving Vehicle 3D Model',
                `${vehicleError}.  Please email ${environment.techSupportEmail} with any questions.`,
                'error'
              );
            }
          });
      } else {
        this.settingsService.setIsLoading(false);
        const errMessage = this.errorService.handleError(`A 3D model must be tied to a parent ship or vehicle.`);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error',
            `A 3D model must be tied to a ship or a vehicle.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      }
    } else {
      let changes = {
        editorId: currentUser._id
      };

      const model3dChanges = getUpdates(this.model3dForm, changes);
      const fileChanges = getUpdates(this.fileForm, changes);

      const changedKeys = Object.keys(changes);

      //started with editorId as the key, only process changes if more than that key - jane 5/10/2024
      if (changedKeys.length > 1) {
        if (this.parentShip) {
          this.shipService
            .saveShipModel3d(this.model3dForm.controls._id.value, changes, currentUser)
            .then((updatedModel3d: Model3d) => {
              this.settingsService.setIsLoading(false);
              this.dialogRef.close();
              this.model3dForm.markAsPristine();
            })
            .catch((error) => {
              this.settingsService.setIsLoading(false);
              this.dialogRef.close();
              if (this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  'Error Saving 3D Model Changes',
                  `${error}.  Please email ${environment.techSupportEmail} with any questions.`,
                  'error'
                );
              }
            });
        } else if (this.parentVehicle) {

          this.vehicleService
            .saveVehicleModel3d(this.model3dForm.controls._id.value, changes, currentUser)
            .then((updatedModel3d: Model3d) => {
              this.settingsService.setIsLoading(false);
              this.dialogRef.close();
              this.model3dForm.markAsPristine();
            })
            .catch((error) => {
              this.settingsService.setIsLoading(false);
              this.dialogRef.close();
              if (this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  'Error Saving 3D Model Changes',
                  `${error}.  Please email ${environment.techSupportEmail} with any questions.`,
                  'error'
                );
              }
            });
        } else {
          this.settingsService.setIsLoading(false);
          this.dialogRef.close();
          const errMessage = this.errorService.handleError(`A 3D model must be tied to a parent ship or vehicle.`);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              'Error',
              `A 3D model must be tied to a ship or a vehicle.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        }
      } else {
        this.settingsService.setIsLoading(false);
        this.dialogRef.close();
      }
    }
  }
}
