<div class="content" *ngIf="currentUser$ | async as currentUser">
  <mat-card appearance="outlined" class="document-card mat-elevation-z10">
    <img
      mat-card-image
      src="{{ img.displayThumbnailUrl || img.displayUrl || img.url }}"
      alt="Image for {{ img.name }}"
      (click)="viewImage(img, currentUser)"
      onerror="this.onerror=null; this.src='../../../assets/img/SDAT.png';"
    />
    <mat-card-actions>
      <button
        mat-icon-button
        color="warn"
        aria-label="Delete Image"
        matTooltip="Delete Image"
        *ngIf="isAdmin"
        (click)="deleteImage(img, currentUser)"
      >
        <img src="assets/img/TrashGray.png" alt="Delete" />
      </button>
      <button
        mat-icon-button
        color="primary"
        aria-label="Edit Image Name and Report Section"
        matTooltip="Edit Image Name and Report Section"
        (click)="editImage()"
      >
        <img src="assets/img/Edit.png" alt="Edit" />
      </button>
      <button
        mat-icon-button
        color="primary"
        aria-label="Download Image"
        matTooltip="Download {{ img.name }}"
        (click)="download(img, currentUser)"
      >
        <img src="assets/img/Download.png" alt="Download" />
      </button>
      <button
        mat-icon-button
        color="primary"
        aria-label="View Image"
        matTooltip="View Image"
        (click)="viewImage(img, currentUser)"
      >
        <img src="assets/img/Search.png" alt="View" />
      </button>
    </mat-card-actions>
  </mat-card>

  <iframe id="iframeForDownload_imageDocCard" *ngIf="safeImageUrl" [src]="safeImageUrl" style="visibility: hidden"></iframe>
</div>
