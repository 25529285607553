<div class="content">
  <mat-card appearance="outlined" class="ship-card mat-elevation-z10">
    <mat-card-header (click)="viewShip(ship)">
      <mat-card-title>{{ ship.name }}</mat-card-title>
      <mat-card-subtitle>{{ shipDesignation }}</mat-card-subtitle>
    </mat-card-header>
    <img
      mat-card-image
      src="{{ ship.displayThumbnailUrl || ship.displayUrl || ship.imageThumbnailUrl || ship.imageUrl  }}"
      alt="Image for {{ ship.name }}"
      (click)="viewShip(ship)"
      onerror="this.onerror=null; this.src='../../../../assets/img/SDAT.png';"
    />
    <mat-card-actions>
      <a mat-icon-button aria-label="Delete Ship" matTooltip="Delete Ship" *ngIf="canDelete" (click)="deleteShip(ship)">
        <img src="assets/img/TrashGray.png" alt="Delete" />
      </a>
      <a
        mat-icon-button
        color="primary"
        aria-label="View / Edit Ship"
        matTooltip="View / Edit Ship"
        (click)="viewShip(ship)"
      >
        <img src="assets/img/Search.png" alt="View" />
      </a>
    </mat-card-actions>
    <mat-card-footer (click)="viewShip(ship)">
      <p style="text-align: right; font-style: italic; font-size: smaller; margin-right: 15px">
        Last Modified: {{ ship.updatedAt | date: 'short' }}
      </p>
    </mat-card-footer>
  </mat-card>
</div>
