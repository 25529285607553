import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { SettingsService, OverlayService } from '@shared/services';

@UntilDestroy()
@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.css'],
})
export class ProgressSpinnerComponent implements OnInit {
  @ViewChild('progressSpinnerRef')
  private progressSpinnerRef: TemplateRef<any>;

  color: ThemePalette = 'accent';
  mode: ProgressSpinnerMode = 'indeterminate';
  private overlayRef: OverlayRef;
  private progressSpinnerOveralyConfig: any;

  constructor(
    private vcRef: ViewContainerRef,
    private overlayService: OverlayService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.progressSpinnerOveralyConfig = {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      positionStrategy: this.overlayService.positionGloballyCenter(),
    };

    this.overlayRef = this.overlayService.createOverlay(this.progressSpinnerOveralyConfig);

    this.settingsService.isLoading$.subscribe((isLoading: boolean) => {
      if (isLoading && !this.overlayRef.hasAttached()) {
        this.overlayService.attachTemplatePortal(this.overlayRef, this.progressSpinnerRef, this.vcRef);
      } else if (!isLoading && this.overlayRef.hasAttached()) {
        this.overlayRef.detach();
      }
    });
  }
}
