import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './guard/auth.guard';

import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ModsDashboardComponent } from './components/mods/mods-dashboard/mods-dashboard.component';
import { ProjectComponent } from './components/project/project.component';
import { ProjectsDashboardComponent } from './components/project/projects-dashboard/projects-dashboard.component';
import { ReportComponent } from './components/report/report.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ShipComponent } from './components/ship/ship.component';
import { ShipsDashboardComponent } from './components/ship/ships-dashboard/ships-dashboard.component';
import { UnrealViewerComponent } from './components/unreal-viewer/unreal-viewer.component';
import { UserComponent } from './components/user/user.component';
import { UsersDashboardComponent } from './components/user/users-dashboard/users-dashboard.component';
import { VehicleComponent } from './components/vehicle/vehicle.component';
import { VehiclesDashboardComponent } from './components/vehicle/vehicles-dashboard/vehicles-dashboard.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'assets',
    canActivate: [AuthGuard],
    component: ModsDashboardComponent,
  },
  {
    path: 'projects',
    canActivate: [AuthGuard],
    component: ProjectsDashboardComponent,
  },
  {
    path: 'projects/:id',
    canActivate: [AuthGuard],
    component: ProjectComponent,
  },
  {
    path: 'projects/:id/report/:reportId',
    canActivate: [AuthGuard],
    component: ReportComponent,
  },
  {
    path: 'projects/:id/viewer',
    canActivate: [AuthGuard],
    component: UnrealViewerComponent,
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    component: SettingsComponent,
  },
  {
    path: 'ships',
    canActivate: [AuthGuard],
    component: ShipsDashboardComponent,
  },
  {
    path: 'ships/:id',
    canActivate: [AuthGuard],
    component: ShipComponent,
  },
  {
    path: 'ships/:id/viewer',
    canActivate: [AuthGuard],
    component: UnrealViewerComponent,
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    component: UsersDashboardComponent,
  },
  {
    path: 'users/:id',
    canActivate: [AuthGuard],
    component: UserComponent,
  },
  {
    path: 'vehicles',
    canActivate: [AuthGuard],
    component: VehiclesDashboardComponent,
  },
  {
    path: 'vehicles/:id',
    canActivate: [AuthGuard],
    component: VehicleComponent,
  },
  {
    path: 'vehicles/:id/viewer',
    canActivate: [AuthGuard],
    component: UnrealViewerComponent,
  },
  {
    path: 'systemAccess',
    canActivate: [AuthGuard],
    component: AdminDashboardComponent,
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
