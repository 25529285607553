<div class="block-container-with-toolbar" *ngIf="currentUser$ | async as currentUser">
  <ng-container
    *ngIf="{
      viewFormat: viewFormat$ | async
    } as settings"
  >
    <mat-toolbar>
      <mat-button-toggle-group [formControl]="viewAsControl" aria-label="View Vehicles As">
        <mat-button-toggle
          mat-icon-button
          aria-label="View vehicles as cards"
          matTooltip="View vehicles as cards"
          value="cards"
          (change)="onViewFormatChange($event.value, currentUser)"
        >
          <mat-icon>view_module</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          mat-icon-button
          aria-label="View vehicles as list"
          matTooltip="View vehicles as list"
          value="list"
          (change)="onViewFormatChange($event.value, currentUser)"
        >
          <mat-icon>view_list</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      &nbsp;&nbsp;&nbsp;
      <button
        class="image"
        aria-label="Upload new vehicle profile"
        matTooltip="Upload new vehicle profile"
        id="btnUploadNewVehicle"
        (click)="uploadNewVehicle(currentUser)"
      >
        <span class="btnUploadNewVehicle"></span>
        UPLOAD NEW VEHICLE PROFILE
      </button>
    </mat-toolbar>
    <div *ngIf="vehicles$ | async as vehicles">
      <h2>VEHICLE LIBRARY</h2>
      <mat-divider></mat-divider>

      <div class="grid" *ngIf="settings.viewFormat === 'cards'">
        <div *ngFor="let vehicle of vehicles">
          <app-vehicle-card [vehicle]="vehicle"> </app-vehicle-card>
        </div>
      </div>
      <div *ngIf="settings.viewFormat === 'list'">
        <app-vehicle-table [vehicles]="vehicles" [showPaginator]="true" [showFilter]="true"></app-vehicle-table>
      </div>
    </div>
  </ng-container>
</div>
