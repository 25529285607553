import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of } from 'rxjs';

import { Document, User } from '@shared/models';
import { DbCollectionsEnum } from '@shared/enums';
import { getCustomHeaders } from '@shared/utils';
import { ErrorService, UserService } from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private currentProjectDocumentsSubject = new BehaviorSubject<Document[]>(null);
  private currentShipDocumentsSubject = new BehaviorSubject<Document[]>(null);
  private currentVehicleDocumentsSubject = new BehaviorSubject<Document[]>(null);
  private documentErrorSubject = new BehaviorSubject<string>(null);
  private selectedUserDocumentsSubject = new BehaviorSubject<Document[]>(null);
  currentProjectDocuments$: Observable<Document[]> = this.currentProjectDocumentsSubject.asObservable();
  currentShipDocuments$: Observable<Document[]> = this.currentShipDocumentsSubject.asObservable();
  currentVehicleDocuments$: Observable<Document[]> = this.currentVehicleDocumentsSubject.asObservable();
  selectedUserDocuments$: Observable<Document[]> = this.selectedUserDocumentsSubject.asObservable();
  documentError$: Observable<string> = this.documentErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) {
   }

  async createNewDocument(payload, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && payload && parentCollection && parentId) {
        try {
          let documents;
          const url = `${environment.baseAPIUrl}userDocument/create?userId=${currentUser?._id}`;
          let returnValue: Document;
  
          switch (parentCollection) {
            case DbCollectionsEnum.PROJECTS:
              documents = _this.currentProjectDocumentsSubject.getValue();
              break;
            case DbCollectionsEnum.SHIPS:
              documents = _this.currentShipDocumentsSubject.getValue();
              break;
            case DbCollectionsEnum.VEHICLES:
              documents = _this.currentVehicleDocumentsSubject.getValue();
              break;
            default:
              const errMessage = `${parentCollection} is not a valid document parent collection`;
              _this.errorService.handleError(errMessage);
              reject(errMessage);
              break;
          }
  
          const newDocuments = documents ? documents.slice(0) : [];
  
          _this.httpClient
            .post(url, payload, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (newDocument: Document) => {
                returnValue = newDocument;
                newDocuments.push(newDocument);
  
                switch (parentCollection) {
                  case DbCollectionsEnum.PROJECTS:
                    _this.currentProjectDocumentsSubject.next(newDocuments);
                    break;
                  case DbCollectionsEnum.SHIPS:
                    _this.currentShipDocumentsSubject.next(newDocuments);
                    break;
                  case DbCollectionsEnum.VEHICLES:
                    _this.currentVehicleDocumentsSubject.next(newDocuments);
                    break;
                }
                resolve(returnValue);
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.documentErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {
  
              }
            });
        } catch (ex) {
          const errMessage = `Error creating new document for parent ${parentCollection} parentId ${parentId}: ${ex.message}`;
          _this.errorService.handleError(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `payload, parent collection, parent id and user are required to create a document`;
        this.errorService.handleError(errMessage);
        this.documentErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async deleteDocument(documentId: string, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      let returnValue: Document[];

      if (documentId && parentCollection && parentId && currentUser) {
        try {
          const url = `${environment.baseAPIUrl}userDocument/${documentId}?userId=${currentUser._id}`;

          _this.httpClient
            .delete(url, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (deletedDocument: Document) => {
                const promises = [
                  _this.refreshDocumentsByParent(parentCollection, parentId, currentUser),
                  _this.refreshDocumentsByUser(currentUser)
                ];

                Promise.allSettled(promises)
                  .then((results) => {
                    resolve(`successfully deleted documentId ${documentId}`);
                  });
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = this.errorService.handleError(error);
                _this.documentErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {

              }
            });
        } catch (ex) {
          const errMessage = `Error removing documentId ${documentId} from ${parentCollection} ${parentId}: ${ex.message}`;
          _this.errorService.handleError(errMessage);
          _this.documentErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `documentId, parentCollection and parentId are required to delete a document`;
        _this.errorService.handleError(errMessage);
        _this.documentErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async refreshDocumentsByParent(parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      try {
        let returnValue: Document[];

        if (currentUser && parentCollection && parentId) {
          const url = `${environment.baseAPIUrl}userDocument/${parentCollection}/${parentId}?userId=${currentUser?._id}`;

          _this.httpClient
            .get(url, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (documents: Document[]) => {
                returnValue = documents;

                switch (parentCollection) {
                  case DbCollectionsEnum.PROJECTS:
                    _this.currentProjectDocumentsSubject.next(documents);
                    resolve(returnValue);
                    break;
                  case DbCollectionsEnum.SHIPS:
                    _this.currentShipDocumentsSubject.next(documents);
                    resolve(returnValue);
                    break;
                  case DbCollectionsEnum.VEHICLES:
                    _this.currentVehicleDocumentsSubject.next(documents);
                    resolve(returnValue);
                    break;
                  default:
                    const errMessage = `${parentCollection} is not a valid document parent collection`;
                    _this.documentErrorSubject.next(errMessage);
                    _this.errorService.handleError(errMessage);
                    reject(errMessage);
                    break;
                }
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = `Error getting user documents for parentCollection ${parentCollection} _id ${parentId}: ${error.error}`;
                _this.errorService.handleError(errMessage);
                _this.documentErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {

              }
            });
        } else if (parentCollection) {
          switch (parentCollection) {
            case DbCollectionsEnum.PROJECTS:
              _this.currentProjectDocumentsSubject.next(returnValue);
              resolve(returnValue);
              break;
            case DbCollectionsEnum.SHIPS:
              _this.currentShipDocumentsSubject.next(returnValue);
              resolve(returnValue);
              break;
            case DbCollectionsEnum.VEHICLES:
              _this.currentVehicleDocumentsSubject.next(returnValue);
              resolve(returnValue);
              break;
            default:
              const errMessage = `${parentCollection} is not a valid document parent collection`;
              _this.errorService.handleError(errMessage);
              _this.documentErrorSubject.next(errMessage);
              reject(errMessage);
              break;
          }
        } else {
          const errMessage = `parentCollection and parentId are required to get documents`;
          _this.errorService.handleError(errMessage);
          _this.documentErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } catch (ex) {
        const errMessage = `Error getting user documents for parentCollection ${parentCollection} _id ${parentId}: ${ex.message}`;
        _this.errorService.handleError(errMessage);
        _this.documentErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async refreshDocumentsByUser(currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      try {
        let returnValue: Document[];

        if (currentUser) {
          const url = `${environment.baseAPIUrl}userDocument/user/${currentUser._id}/overview`;

          this.httpClient
            .get(url, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (documents: Document[]) => {
                returnValue = documents;
                _this.selectedUserDocumentsSubject.next(documents);
                resolve(returnValue);
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = `Error getting documents for userId ${currentUser._id}: ${error.error}`;
                _this.errorService.handleError(errMessage);
                _this.documentErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {

              }
            });
        } else {
          _this.selectedUserDocumentsSubject.next(returnValue);
          resolve(returnValue);
        }
      } catch (ex) {
        const errMessage = `Error getting documents for userId ${currentUser._id}: ${ex.message}`;
        _this.errorService.handleError(errMessage);
        _this.documentErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveDocument(documentId: string, parentCollection: string, parentId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && documentId && parentCollection && parentId) {
        try {
          let documents: Document[];
          let validParentCollection = true;
          let returnValue: Document;
          let errMessage;
  
          switch (parentCollection) {
            case DbCollectionsEnum.PROJECTS:
              documents = _this.currentProjectDocumentsSubject.getValue();
              break;
            case DbCollectionsEnum.SHIPS:
              documents = _this.currentShipDocumentsSubject.getValue();
              break;
            case DbCollectionsEnum.VEHICLES:
              documents = _this.currentVehicleDocumentsSubject.getValue();
              break;
            default:
              validParentCollection = false;
              errMessage = `${parentCollection} is not a valid document parent collection`;
              _this.errorService.handleError(errMessage);
              _this.documentErrorSubject.next(errMessage);
              break;
          }
  
          if (validParentCollection) {
            const documentIndex = documents.findIndex((document) => document._id === documentId);
            const newDocuments = documents.slice(0);
            const url = `${environment.baseAPIUrl}userDocument/${documentId}?userId=${currentUser?._id}`;
  
            newDocuments[documentIndex] = {
              ...documents[documentIndex],
              ...changes,
            };
  
            _this.httpClient
              .put(url, changes, {
                headers: getCustomHeaders(true),
                responseType: 'json',
              })
              .subscribe({
                next: (updatedDocument: Document) => {
                  returnValue = updatedDocument;
  
                  switch (parentCollection) {
                    case DbCollectionsEnum.PROJECTS:
                      _this.currentProjectDocumentsSubject.next(newDocuments);
                      break;
                    case DbCollectionsEnum.SHIPS:
                      _this.currentShipDocumentsSubject.next(newDocuments);
                      break;
                    case DbCollectionsEnum.VEHICLES:
                      _this.currentVehicleDocumentsSubject.next(newDocuments);
                      break;
                  }
  
                  resolve(returnValue);
                },
                error: (error: HttpErrorResponse) => {
                  _this.errorService.handleError(error);
                  _this.documentErrorSubject.next(error.error);
                  reject(error);
                },
                complete: () => {
  
                }
              });
          } else {
            reject(errMessage);
          }
        } catch (ex) {
          const errMessage = `Error saving documentId ${documentId} for parentCollection ${parentCollection} _id ${parentId}: ${ex.message}`;
          _this.errorService.handleError(errMessage);
          _this.documentErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `changes, documentId, parent collection, parent id and user are required to update a document`;
        this.errorService.handleError(errMessage);
        this.documentErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }
}
