<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser">
  <h3>{{ formTitle }}</h3>
  <div
    *ngIf="{
      viewFormat: viewFormat$ | async
    } as settings"
  >
    <mat-toolbar>
      <mat-button-toggle-group [formControl]="viewAsControl" aria-label="View Projects As">
        <mat-button-toggle
          mat-icon-button
          aria-label="View vehicles as cards"
          matTooltip="View vehicles as cards"
          value="cards"
          (change)="onViewFormatChange($event.value, currentUser)"
        >
          <mat-icon>view_module</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
          mat-icon-button
          aria-label="View vehicles as list"
          matTooltip="View vehicles as list"
          value="list"
          (change)="onViewFormatChange($event.value, currentUser)"
        >
          <mat-icon>view_list</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </mat-toolbar>
    <div *ngIf="vehicles">
      <div class="grid" *ngIf="settings.viewFormat === 'cards'">
        <div *ngFor="let vehicle of vehicles">
          <mat-card appearance="outlined" class="vehicle-card mat-elevation-z10  {{ getVehicleDisplayClass(vehicle) }}">
            <mat-card-header (click)="selectVehicle(vehicle)">
              <mat-card-title>{{ vehicle.name }}</mat-card-title>
              <mat-card-subtitle>{{ getVehicleDesignation(vehicle) }}</mat-card-subtitle>
            </mat-card-header>
            <img
              mat-card-image
              src="{{ vehicle.imageUrl }}"
              alt="Image for {{ vehicle.name }}"
              (click)="selectVehicle(vehicle)"
              onerror="this.onerror=null; this.src='../../../../assets/img/SDAT.png';"
            />
            <mat-card-footer (click)="selectVehicle(vehicle)">
              <p style="text-align: right; font-style: italic; font-size: smaller; margin-right: 15px">
                Last Modified: {{ vehicle.updatedAt | date: 'short' }}
              </p>
            </mat-card-footer>
          </mat-card>
        </div>
      </div>
      <div *ngIf="settings.viewFormat === 'list'">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          matSortActive="name"
          matSortDirection="asc"
          class="mat-elevation-z8"
        >
          <!-- name column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Name</th>
            <td mat-cell *matCellDef="let vehicle" style="cursor: pointer">
              <a
                aria-label="Click to select vehicle"
                matTooltip="Click to select project"
                (click)="selectVehicle(vehicle)"
              >
                {{ vehicle.name }}
              </a>
            </td>
          </ng-container>

          <!-- designation column -->
          <ng-container matColumnDef="designation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Designation</th>
            <td mat-cell *matCellDef="let vehicle" style="cursor: pointer">
              <a
                aria-label="Click to select vehicle"
                matTooltip="Click to select vehicle"
                (click)="selectVehicle(vehicle)"
              >
                {{ getVehicleDesignation(vehicle) }}
              </a>
            </td>
          </ng-container>

          <!-- updatedAt column -->
          <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Modified</th>
            <td mat-cell *matCellDef="let vehicle" style="cursor: pointer">
              <a
                aria-label="Click to select vehicle"
                matTooltip="Click to select vehicle"
                (click)="selectVehicle(vehicle)"
              >
                {{ vehicle.updatedAt | date: 'short' }}
              </a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedVehicleColumns; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedVehicleColumns"
            [ngClass]="{ 'selected-row': selectedVehicleId == row._id }"
          ></tr>
        </table>
      </div>
    </div>
  </div>
</div>
