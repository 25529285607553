import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, Validators } from '@angular/forms';
import merge from 'lodash-es/merge';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, fromEvent, noop, Observable, zip } from 'rxjs';
import { concatMap, distinctUntilChanged, exhaustMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { ImageDoc, User } from '@shared/models';
import { ErrorService, FileService, SettingsService, UserService } from '@shared/services';
import { FileObjectTypesEnum, TagFileTypesEnum, TagTypesEnum, UserRolesEnum } from '@shared/enums';
import { createTag, findInvalidControlsRecursive, getUpdates } from '@shared/utils';
import { CustomValidators } from '@shared/classes';

import { environment } from '@environment';

@UntilDestroy()
@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.css'],
})
export class UserDialogComponent implements OnInit {
  currentUser: User;
  errorMsg: boolean;
  errorText = 'Please select a file';
  form: UntypedFormGroup;
  fileForm: UntypedFormGroup;
  imageFileObj: File;
  imageURL: string; //for previewing file
  userContactsForm: UntypedFormGroup;
  userInfoForm: UntypedFormGroup;
  userOrganizationForm: UntypedFormGroup;
  userSystemInfoForm: UntypedFormGroup;
  selectedUser: User;
  isChangingPassword = false;
  isNewUser = false;
  currentUser$: Observable<User>;
  isDebugging$: Observable<boolean>;

  @ViewChild('saveButton', { static: true }) saveButton: ElementRef;

  @ViewChild('cancelButton', { static: true }) cancelButton: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<UserDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    data: {
      currentUser: User;
      isNewUser: boolean;
      selectedUser: User;
    },
    private errorService: ErrorService,
    private fileService: FileService,
    private settingsService: SettingsService,
    private userService: UserService
  ) {
    this.currentUser = data.currentUser;
    this.isNewUser = data.isNewUser;
    this.selectedUser = data.selectedUser;

    this.currentUser$ = this.userService.currentUser$;
    this.isDebugging$ = this.settingsService.isDebugging$;

    this.fileForm = fb.group({
      editorId: [this.currentUser ? this.currentUser._id : null, Validators.required],
      imageFileName: [this.selectedUser.imageUrl || ''],
      imageUrl: [this.selectedUser.imageUrl || environment.defaultImageUrl],
      imageBase64DataUrl: [''],
      imageThumbnailUrl: [this.selectedUser.imageThumbnailUrl],
      avatar: [null],
    });

    this.userContactsForm = this.fb.group({
      email: [this.selectedUser.email, Validators.compose([Validators.email, Validators.required])],
      phoneNumber: [this.selectedUser.phoneNumber, [Validators.pattern('[A-Za-z0-9-_ ()]+')]],
    });

    this.userInfoForm = this.fb.group({
      _id: [this.selectedUser._id, Validators.required],
      firstName: [this.selectedUser.firstName, Validators.required],
      lastName: [this.selectedUser.lastName, Validators.required],
    });

    this.userOrganizationForm = this.fb.group({
      employeeId: [this.selectedUser.employeeId],
      organizationName: [this.selectedUser.organizationName],
      title: [this.selectedUser.title],
    });

    this.userSystemInfoForm = this.fb.group({
      password: [this.selectedUser.password, Validators.required],
      newPassword: [null],
      confirmPassword: [null],
      role: [this.selectedUser.role, Validators.required],
    });

    this.form = fb.group({
      fileForm: this.fileForm,
      userContactsForm: this.userContactsForm,
      userInfoForm: this.userInfoForm,
      userOrganizationForm: this.userOrganizationForm,
      userSystemInfoForm: this.userSystemInfoForm,
    });
  }

  ngOnInit(): void { }

  get confirmPasswordError(): String {
    let returnValue = '';

    if (this.userSystemInfoForm.controls.confirmPassword.hasError('required')) {
      returnValue = 'Required!';
    } else if (this.userSystemInfoForm.controls.confirmPassword.hasError('NoPasswordMatch')) {
      returnValue = 'Passwords do not match!';
    }

    return returnValue;
  }

  get existingRole(): String {
    return `User Type: ${this.selectedUser.role}`;
  }

  get formTitle(): String {
    if (this.isNewUser) {
      return 'Create New User';
    } else {
      return `Edit User: ${this.selectedUser.fullName}`;
    }
  }

  get imageFileName(): string {
    return this.fileForm.get('imageFileName').value || '';
  }

  get invalidControls(): string[] {
    return findInvalidControlsRecursive(this.form);
  }

  get isAdmin(): boolean {
    return this.currentUser && this.currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get newPasswordError(): String {
    let returnValue = '';

    if (this.userSystemInfoForm.controls.newPassword.hasError('required')) {
      returnValue = 'Required!';
    } else if (this.userSystemInfoForm.controls.newPassword.hasError('minLength')) {
      returnValue = 'Must be at least 8 characters!';
    } else if (this.userSystemInfoForm.controls.newPassword.hasError('hasNumber')) {
      returnValue = 'Must contain at least 1 number!';
    } else if (this.userSystemInfoForm.controls.newPassword.hasError('hasCapitalCase')) {
      returnValue = 'Must contain at least 1 upper case letter!';
    } else if (this.userSystemInfoForm.controls.newPassword.hasError('hasSmallCase')) {
      returnValue = 'Must contain at least 1 lower case letter!';
    } else if (this.userSystemInfoForm.controls.newPassword.hasError('hasSpecialCharacters')) {
      returnValue = 'Must contain at least 1 special character!';
    }

    return returnValue;
  }

  get saveButtonText(): String {
    if (this.isNewUser) {
      return 'Create';
    } else {
      return 'Save';
    }
  }

  get userRoles(): string[] {
    const returnValue = [UserRolesEnum.ADMIN, UserRolesEnum.USER];
    return returnValue;
  }

  cancel(): void {
    if (this.isNewUser) {
      this.settingsService.setIsLoading(true);

      this.userService.getUserById(this.currentUser._id, null).then((results) => {
        this.dialogRef.close();
        this.settingsService.setIsLoading(false);
      });
    } else {
      this.dialogRef.close();
    }
  }

  cancelChangePassword(): void {
    this.userSystemInfoForm.controls.newPassword.setValue(null);
    this.userSystemInfoForm.controls.confirmPassword.setValue(null);
    this.isChangingPassword = false;
    this.userSystemInfoForm.controls.newPassword.clearValidators();
    this.userSystemInfoForm.controls.confirmPassword.clearValidators();
    this.userSystemInfoForm.clearValidators();
    this.userSystemInfoForm.updateValueAndValidity();
  }

  changePassword(): void {
    this.isChangingPassword = true;
    this.userSystemInfoForm.controls.newPassword.setValidators(
      Validators.compose([
        Validators.required,
        // check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true,
        }),
        // check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        }),
        // check whether the entered password has a lower case letter
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        }),
        // check whether the entered password has a special character
        CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
          hasSpecialCharacters: true,
        }),
        Validators.minLength(8),
      ])
    );

    this.userSystemInfoForm.controls.confirmPassword.setValidators(Validators.compose([Validators.required]));
    this.userSystemInfoForm.setValidators([CustomValidators.passwordMatchValidator]);
    this.userSystemInfoForm.updateValueAndValidity();
  }

  close(): void {
    this.dialogRef.close();
  }

  async imageFileInputChange($event: Event): Promise<any> {
    this.errorMsg = false;
    const FILE = ($event.target as HTMLInputElement).files[0];
    this.imageFileObj = FILE;

    if (!this.imageFileObj) {
      this.errorMsg = true;
      this.errorText = 'Please select an image file';
    } else {
      this.settingsService.setIsLoading(true);
      const tags = [];
      tags.push(createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.IMAGE));

      this.fileService
        .uploadFile(
          this.imageFileObj,
          FileObjectTypesEnum.USER,
          this.selectedUser._id,
          tags,
          TagFileTypesEnum.IMAGE,
          this.currentUser
        )
        .then((results) => {
          this.fileForm.controls.imageUrl.setValue(results.locationUrl);
          this.fileForm.controls.imageUrl.markAsDirty();
          this.fileForm.controls.imageBase64DataUrl.setValue(results.locationBase64DataUrl);
          this.fileForm.controls.imageBase64DataUrl.markAsDirty();
          this.fileForm.controls.imageFileName.setValue(results.nameWithoutExtension);
          this.fileForm.controls.imageFileName.markAsDirty();
          this.fileForm.controls.imageThumbnailUrl.setValue(results.thumbnailUrl);
          this.fileForm.controls.imageThumbnailUrl.markAsDirty();

          //add image preview, see https://www.positronx.io/angular-8-show-image-preview-with-reactive-forms-tutorial/
          this.fileForm.patchValue({
            avatar: FILE,
          });
          this.fileForm.get('avatar').updateValueAndValidity();

          const reader = new FileReader();
          reader.onload = () => {
            try {
              this.imageURL = reader.result as string;
            } catch (ex) {
              this.errorService.handleError(`Error previewing image ${ex}`);
            }
          };
          reader.readAsDataURL(FILE);

          this.settingsService.setIsLoading(false);
        })
        .catch((uploadError) => {
          this.settingsService.setIsLoading(false);
          this.errorMsg = true;
          this.errorText = uploadError.message;
        });
    }
  }

  async save(isNewUser, currentUser, selectedUser): Promise<any> {
    if (isNewUser) {
      const userInfo = {
        ...this.fileForm.value,
        ...this.userContactsForm.value,
        ...this.userInfoForm.value,
        ...this.userOrganizationForm.value,
        ...this.userSystemInfoForm.value,
      };
      userInfo.creatorId = currentUser._id;

      this.userService
        .createNewUser(userInfo)
        .then((newUser: User) => {
          this.close();
          this.router.navigate([`/users/${newUser._id}`]);
        })
        .catch((error) => {
          this.close();
          this.settingsService.setIsLoading(false);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error`,
              `There was an error creating the user:
            ${error.error}
              Please email ${environment.techSupportEmail} with any questions.`,
              'error'
            );
          }
        });
    } else {
      let changes = {
        editorId: currentUser._id
      };
      const fileChanges = getUpdates(this.fileForm, changes);
      const userContactChanges = getUpdates(this.userContactsForm, changes);
      const userInfoChanges = getUpdates(this.userInfoForm, changes);
      const userOrgChanges = getUpdates(this.userOrganizationForm, changes);
      const userSystemChanges = getUpdates(this.userSystemInfoForm, changes);
      
      const changedKeys = Object.keys(changes);

      //started with editorId as the key, only process changes if more than that key - jane 5/10/2024
      if (changedKeys.length > 1) {
        const userId = selectedUser._id;

        this.userService
        .saveUser(userId, changes)
        .then((updatedUser: User) => {
          this.close();
          const navigationUrl = `/users/${userId}`;
          this.router.navigateByUrl(navigationUrl);
        })
        .catch((error) => {
          this.close();
          this.settingsService.setIsLoading(false);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error Saving User`,
              `${error}.  Please email ${environment.techSupportEmail} with any questions.`,
              'error'
            );
          }
        });
      } else {
        this.close();
        this.settingsService.setIsLoading(false);
      }
    }
  }
}
