enum VehicleEquipmentTypesEnum {
  AUTOMOTIVE_LIGHT = 'automotiveLight',
  AUTOMOTIVE_MEDIUM = 'automotiveMedium',
  AUTOMOTIVE_HEAVY = 'automotiveHeavy',
  MRAP = 'mrap',
  FORKLIFT = 'forklift',
  TRAILER = 'trailer',
  SEMI_TRAILER = 'semiTrailer',
}

interface VehicleEquipmentType {
  _id: string;
  displayName: string;
}

export const AutomativeLight: VehicleEquipmentType = {
  _id: VehicleEquipmentTypesEnum.AUTOMOTIVE_LIGHT,
  displayName: 'Automotive Light',
};

export const AutomativeMedium: VehicleEquipmentType = {
  _id: VehicleEquipmentTypesEnum.AUTOMOTIVE_MEDIUM,
  displayName: 'Automotive Medium',
};

export const AutomotiveHeavy: VehicleEquipmentType = {
  _id: VehicleEquipmentTypesEnum.AUTOMOTIVE_HEAVY,
  displayName: 'Automotive Heavy',
};

export const Forklift: VehicleEquipmentType = {
  _id: VehicleEquipmentTypesEnum.FORKLIFT,
  displayName: 'Forklift',
};

export const MRAP: VehicleEquipmentType = {
  _id: VehicleEquipmentTypesEnum.MRAP,
  displayName: 'Mine Resistant Ambush Protected',
};

export const SemiTrailer: VehicleEquipmentType = {
  _id: VehicleEquipmentTypesEnum.SEMI_TRAILER,
  displayName: 'Semi-Trailer',
};

export const Trailer: VehicleEquipmentType = {
  _id: VehicleEquipmentTypesEnum.TRAILER,
  displayName: 'Trailer',
};
