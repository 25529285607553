import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, from, throwError, of, zip, ReplaySubject } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';

import {
  AreaMeasurement,
  Document,
  Geotag,
  ImageDoc,
  Keyframe,
  Measurement,
  Mod,
  Note,
  PathTraveled,
  Project,
  ProjectOverview,
  Report,
  Ship,
  Vehicle,
  Video,
  User,
} from '@shared/models';
import { DbCollectionsEnum } from '@shared/enums';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import {
  AreaMeasurementService,
  DocumentService,
  ErrorService,
  GeotagService,
  ImageDocService,
  LogService,
  MeasurementService,
  ModService,
  Model3dService,
  NoteService,
  ReportService,
  ScanService,
  SettingsService,
  ShipService,
  UnrealServerService,
  UserService,
  VehicleService,
  VideoService
} from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private currentProjectSubject = new BehaviorSubject<Project>(null);
  private currentProjectAreaMeasurementsSubject = new BehaviorSubject<AreaMeasurement[]>(null);
  private currentProjectGeotagsSubject = new BehaviorSubject<Geotag[]>(null);
  private currentProjectImagesSubject = new BehaviorSubject<ImageDoc[]>(null);
  private currentProjectMeasurementsSubject = new BehaviorSubject<Measurement[]>(null);
  private currentProjectNotesSubject = new BehaviorSubject<Note[]>(null);
  private currentProjectReportsSubject = new BehaviorSubject<Report[]>(null);
  private currentProjectShipSubject = new BehaviorSubject<Ship>(null);
  private currentProjectVehicleSubject = new BehaviorSubject<Vehicle>(null);
  private currentProjectVideosSubject = new BehaviorSubject<Video[]>(null);
  private documentsSubject = new BehaviorSubject<Document[]>(null);
  private modsSubject = new BehaviorSubject<Mod[]>(null);
  private projectsSubject = new BehaviorSubject<ProjectOverview[]>([]);
  private recentProjectsSubject = new BehaviorSubject<ProjectOverview[]>([]);
  private selectedUserProjectsSubject = new BehaviorSubject<ProjectOverview[]>([]);
  private selectedUserProjectsCreatedSubject = new BehaviorSubject<ProjectOverview[]>([]);
  private shipsSubject = new BehaviorSubject<Ship[]>(null);
  private usersSubject = new BehaviorSubject<User[]>(null);
  private vehicleSubject = new BehaviorSubject<Vehicle[]>(null);
  private projectErrorSubject = new BehaviorSubject<string>(null);
  currentProject$: Observable<Project> = this.currentProjectSubject.asObservable();
  currentProjectAreaMeasurements$: Observable<AreaMeasurement[]> =
    this.currentProjectAreaMeasurementsSubject.asObservable();
  currentProjectGeotags$: Observable<Geotag[]> = this.currentProjectGeotagsSubject.asObservable();
  currentProjectImages$: Observable<ImageDoc[]> = this.currentProjectImagesSubject.asObservable();
  currentProjectMeasurements$: Observable<Measurement[]> = this.currentProjectMeasurementsSubject.asObservable();
  currentProjectNotes$: Observable<Note[]> = this.currentProjectNotesSubject.asObservable();
  currentProjectReports$: Observable<Report[]> = this.currentProjectReportsSubject.asObservable();
  currentProjectShip$: Observable<Ship> = this.currentProjectShipSubject.asObservable();
  currentProjectVehicle$: Observable<Vehicle> = this.currentProjectVehicleSubject.asObservable();
  currentProjectVideos$: Observable<Video[]> = this.currentProjectVideosSubject.asObservable();
  documents$: Observable<Document[]> = this.documentsSubject.asObservable();
  mods$: Observable<Mod[]> = this.modsSubject.asObservable();
  projects$: Observable<ProjectOverview[]> = this.projectsSubject.asObservable();
  recentProjects$: Observable<ProjectOverview[]> = this.recentProjectsSubject.asObservable();
  selectedUserProjects$: Observable<ProjectOverview[]> = this.selectedUserProjectsSubject.asObservable();
  selectedUserProjectsCreated$: Observable<ProjectOverview[]> = this.selectedUserProjectsCreatedSubject.asObservable();
  ships$: Observable<Ship[]> = this.shipsSubject.asObservable();
  users$: Observable<User[]> = this.usersSubject.asObservable();
  vehicles$: Observable<Vehicle[]> = this.vehicleSubject.asObservable();
  projectError$: Observable<string> = this.projectErrorSubject.asObservable();

  constructor(
    private areaMeasurementService: AreaMeasurementService,
    private documentService: DocumentService,
    private errorService: ErrorService,
    private geotagService: GeotagService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private measurementService: MeasurementService,
    private modService: ModService,
    private model3dService: Model3dService,
    private noteService: NoteService,
    private reportService: ReportService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private videoService: VideoService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.documents$ = this.documentService.currentProjectDocuments$;
    this.users$ = this.userService.users$;
    this.currentProjectAreaMeasurements$ = this.areaMeasurementService.currentProjectAreaMeasurements$;
    this.currentProjectGeotags$ = this.geotagService.currentProjectGeotags$;
    this.currentProjectMeasurements$ = this.measurementService.currentProjectMeasurements$;
    this.currentProjectNotes$ = this.noteService.currentProjectNotes$;
    this.currentProjectReports$ = this.reportService.currentProjectReports$;
    this.currentProjectShip$ = this.shipService.currentShip$;
    this.currentProjectVehicle$ = this.vehicleService.currentVehicle$;
    this.currentProjectVideos$ = this.videoService.currentProjectVideos$;
    this.mods$ = this.modService.mods$;
    this.ships$ = this.shipService.ships$;
    this.vehicles$ = this.vehicleService.vehicles$;
  }

  async addAreaMeasurement(areaMeasurement: AreaMeasurement, project: Project, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      const projects = _this.projectsSubject.getValue();
      let returnValue: AreaMeasurement;

      if (areaMeasurement && currentUser && project) {
        _this.areaMeasurementService
          .createNewAreaMeasurement(areaMeasurement, project, currentUser)
          .then((newAreaMeasurement: AreaMeasurement) => {
            returnValue = newAreaMeasurement;

            try {
              //keep the array consistent, it will be refreshed in the api automatically
              const projectIndex = projects.findIndex((p) => p._id === project._id);
              const newProjects = projects.slice(0);

              project.areas = project.areas || [];
              const idx = project.areas.indexOf(newAreaMeasurement._id);
              if (idx == -1) {
                project.areas.push(newAreaMeasurement._id);
                newProjects[projectIndex].editorId = currentUser._id;
                newProjects[projectIndex].lastUpdatedBy = currentUser.fullName;
                newProjects[projectIndex].updatedAt = new Date();
                _this.currentProjectSubject.next(project);
                _this.projectsSubject.next(newProjects);
              }
            } catch (projectEx) {
              const errMessage = _this.errorService.handleError(
                `Error adding new areaMeasurmentId ${newAreaMeasurement._id} to projectId ${project._id}: ${projectEx.message}`
              );
            }

            resolve(returnValue);
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error creating area measurement ${JSON.stringify(areaMeasurement)} for projectId ${project._id}: ${error.error
              }`
            );
            _this.projectErrorSubject.next(errMessage);
            reject(errMessage);
          });
      } else {
        const errMessage = _this.errorService.handleError(
          `A area measurement, project and user is required to add an area measurement to a project`
        );
        _this.projectErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async addDocument(doc: Document, project: Project, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const projects = _this.projectsSubject.getValue();
      let returnValue: Document;

      if (currentUser && doc && project) {
        //keep the array consistent, it will be refreshed in the api automatically
        const projectIndex = projects.findIndex((p) => p._id === project._id);
        const newProjects = projects.slice(0);

        _this.documentService
          .createNewDocument(doc, DbCollectionsEnum.PROJECTS, project._id, currentUser)
          .then((newDocument: Document) => {
            returnValue = newDocument;
            newProjects[projectIndex].editorId = currentUser._id;
            newProjects[projectIndex].lastUpdatedBy = currentUser.fullName;
            newProjects[projectIndex].updatedAt = new Date();
            _this.projectsSubject.next(newProjects);
            resolve(returnValue);
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error creating document ${JSON.stringify(doc)} for projectId ${project._id}: ${error.error}`
            );
            _this.projectErrorSubject.next(errMessage);
            reject(errMessage);
          })
          .finally(() => {
            _this.settingsService.setIsLoading(false);
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(
          `A Document object and a project are required to add a new document to the current project`
        );
        _this.projectErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async addGeotag(geotag: Geotag, project: Project, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      const projects = _this.projectsSubject.getValue();
      let returnValue: Geotag;

      if (currentUser && geotag && project) {
        //keep the array consistent, it will be refreshed in the api automatically
        const projectIndex = projects.findIndex((p) => p._id === project._id);
        const newProjects = projects.slice(0);

        _this.geotagService
          .createNewGeotag(geotag, project, currentUser)
          .then((newGeotag: Geotag) => {
            returnValue = newGeotag;

            try {
              project.geotags = project.geotags || [];
              const idx = project.geotags.indexOf(newGeotag._id);
              if (idx == -1) {
                project.geotags.push(newGeotag._id);
                newProjects[projectIndex].editorId = currentUser._id;
                newProjects[projectIndex].lastUpdatedBy = currentUser.fullName;
                newProjects[projectIndex].updatedAt = new Date();
                _this.projectsSubject.next(newProjects);
                _this.currentProjectSubject.next(project);
              }
            } catch (projectEx) {
              const errMessage = _this.errorService.handleError(
                `Error adding newGeotagId ${newGeotag._id} to projectId ${project._id}: ${projectEx.message}`
              );
            }

            resolve(returnValue);
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error creating geotag ${JSON.stringify(geotag)} for projectId ${project._id}: ${error.error}`
            );
            _this.projectErrorSubject.next(errMessage);
            reject(errMessage);
          });
      } else {
        const errMessage = _this.errorService.handleError(
          `A geotag, project and user is required to add a geotag to a project`
        );
        _this.projectErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async addImage(imageDoc: ImageDoc, project: Project, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const projects = _this.projectsSubject.getValue();
      let returnValue: ImageDoc;

      if (currentUser && imageDoc && project) {
        //keep the array consistent, it will be refreshed in the api automatically
        const projectIndex = projects.findIndex((p) => p._id === project._id);
        const newProjects = projects.slice(0);

        _this.imageDocService
          .createNewImageDoc(imageDoc, DbCollectionsEnum.PROJECTS, project._id, currentUser)
          .then((newImage: ImageDoc) => {
            returnValue = newImage;
            newProjects[projectIndex].editorId = currentUser._id;
            newProjects[projectIndex].lastUpdatedBy = currentUser.fullName;
            newProjects[projectIndex].updatedAt = new Date();
            _this.projectsSubject.next(newProjects);
            resolve(returnValue);
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error creating image ${JSON.stringify(imageDoc)} for projectId ${project._id}: ${error.error}`
            );
            _this.projectErrorSubject.next(errMessage);
            reject(errMessage);
          })
          .finally(() => {
            _this.settingsService.setIsLoading(false);
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(
          `A image doc object and a project are required to add a new image to the project`
        );
        _this.projectErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async addMeasurement(measurement: Measurement, project: Project, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      const projects = _this.projectsSubject.getValue();
      let returnValue: Measurement;

      if (currentUser && measurement && project) {
        //keep the array consistent, it will be refreshed in the api automatically
        const projectIndex = projects.findIndex((p) => p._id === project._id);
        const newProjects = projects.slice(0);

        _this.measurementService
          .createNewMeasurement(measurement, project, currentUser)
          .then((newMeasurement: Measurement) => {
            returnValue = newMeasurement;

            try {
              project.measurements = project.measurements || [];
              const idx = project.measurements.indexOf(newMeasurement._id);
              if (idx == -1) {
                project.measurements.push(newMeasurement._id);
                newProjects[projectIndex].editorId = currentUser._id;
                newProjects[projectIndex].lastUpdatedBy = currentUser.fullName;
                newProjects[projectIndex].updatedAt = new Date();
                _this.projectsSubject.next(newProjects);
                _this.currentProjectSubject.next(project);
              }
            } catch (projectEx) {
              const errMessage = _this.errorService.handleError(
                `Error adding newMeasurrementId ${newMeasurement._id} to projectId ${project._id}: ${projectEx.message}`
              );
            }

            resolve(returnValue);
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error creating measurement ${JSON.stringify(measurement)} for projectId ${project._id}: ${error.error}`
            );
            _this.projectErrorSubject.next(errMessage);
            reject(errMessage);
          });
      } else {
        const errMessage = _this.errorService.handleError(
          `A measurement, project and user is required to add a measurement to a project`
        );
        _this.projectErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async addNewPathTraveled(
    project: Project,
    newPathTraveled: PathTraveled,
    keyframes: Keyframe[],
    currentUser: User
  ): Promise<any> {
    const _this = this;

    return new Promise(async (resolve, reject) => {
      if (currentUser && project && newPathTraveled) {
        const projects = this.projectsSubject.getValue();
        const projectIndex = projects.findIndex((p) => p._id === project._id);
        const newProjects = projects.slice(0);
        const url = `${environment.baseAPIUrl}project/${project._id}/create/pathTraveled?userId=${currentUser?._id}`;
        let returnValue: Project;

        _this.httpClient
          .post(
            url,
            {
              pathTraveled: newPathTraveled,
              keyframes: keyframes,
            },
            {
              headers: getCustomHeaders(true),
              responseType: 'json',
            }
          )
          .subscribe({
            next: (updatedProject: Project) => {
              returnValue = updatedProject;
              _this.currentProjectSubject.next(returnValue);
              newProjects[projectIndex].editorId = currentUser._id;
              newProjects[projectIndex].lastUpdatedBy = currentUser.fullName;
              newProjects[projectIndex].updatedAt = new Date();
              _this.projectsSubject.next(newProjects);
              _this.currentProjectSubject.next(updatedProject);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = this.errorService.handleError(
                `Error adding path traveled ${JSON.stringify(newPathTraveled)} to projectId ${project._id}: ${error.error
                }`
              );
              this.projectErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {

            }
          });
      } else {
        const errMessage = _this.errorService.handleError(
          `projectId, userId, a newPathTraveled object and an array of keyframes are required to add a new path traveled`
        );
        reject(errMessage);
      }
    });
  }

  async addNote(note: Note, project: Project, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const projects = _this.projectsSubject.getValue();
      let returnValue: Note;

      if (currentUser && note && project) {
        //keep the array consistent, it will be refreshed in the api automatically
        const projectIndex = projects.findIndex((p) => p._id === project._id);
        const newProjects = projects.slice(0);

        _this.noteService
          .createNewNote(note, DbCollectionsEnum.PROJECTS, project._id, currentUser)
          .then((newNote: Note) => {
            returnValue = newNote;
            newProjects[projectIndex].editorId = currentUser._id;
            newProjects[projectIndex].lastUpdatedBy = currentUser.fullName;
            newProjects[projectIndex].updatedAt = new Date();
            _this.projectsSubject.next(newProjects);
            resolve(returnValue);
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error creating note ${JSON.stringify(note)} for projectId ${project._id}: ${error.error}`
            );
            _this.projectErrorSubject.next(errMessage);
            reject(errMessage);
          })
          .finally(() => {
            _this.settingsService.setIsLoading(false);
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(
          `A Note object is required to add a new note to the current project`
        );
        _this.projectErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async addReport(report: Report, project: Project, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const projects = _this.projectsSubject.getValue();
      let returnValue: Report;

      if (currentUser && report && project) {
        //keep the array consistent, it will be refreshed in the api automatically
        const projectIndex = projects.findIndex((p) => p._id === project._id);
        const newProjects = projects.slice(0);

        _this.reportService
          .createNewReport(
            report,
            project,
            _this.shipService.getCurrentShip(),
            _this.model3dService.getCurrentShipModel3d(),
            _this.scanService.getCurrentShipScan(),
            _this.modService.getCurrentShipMod(),
            _this.vehicleService.getCurrentVehicle(),
            _this.model3dService.getCurrentVehicleModel3d(),
            _this.scanService.getCurrentVehicleScan(),
            _this.modService.getCurrentVehicleMod(),
            currentUser
          )
          .then((newReport: Report) => {
            returnValue = newReport;
            newProjects[projectIndex].editorId = currentUser._id;
            newProjects[projectIndex].lastUpdatedBy = currentUser.fullName;
            newProjects[projectIndex].updatedAt = new Date();
            _this.projectsSubject.next(newProjects);
            resolve(newReport);
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error creating report ${JSON.stringify(report)} to projectId ${project._id}: ${error.error}`
            );
            _this.projectErrorSubject.next(errMessage);
            reject(errMessage);
          })
          .finally(() => {
            _this.settingsService.setIsLoading(false);
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(
          `A Report object, project and userId are required to add a new report to the current project`
        );
        _this.projectErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async addVideo(video: Video, project: Project, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      const projects = _this.projectsSubject.getValue();
      let returnValue: Video;

      if (currentUser && video && project) {
        //keep the array consistent, it will be refreshed in the api automatically
        const projectIndex = projects.findIndex((p) => p._id === project._id);
        const newProjects = projects.slice(0);

        _this.videoService
          .createNewVideo(video, DbCollectionsEnum.PROJECTS, project._id, currentUser)
          .then((newVideo: Video) => {
            returnValue = newVideo;
            newProjects[projectIndex].editorId = currentUser._id;
            newProjects[projectIndex].lastUpdatedBy = currentUser.fullName;
            newProjects[projectIndex].updatedAt = new Date();
            _this.projectsSubject.next(newProjects);
            resolve(returnValue);
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error creating video ${JSON.stringify(video)} for projectId ${project._id}: ${error.error}`
            );
            _this.projectErrorSubject.next(errMessage);
            reject(errMessage);
          });
      } else {
        const errMessage = _this.errorService.handleError(
          `A video object and a project are required to add a new video to the project`
        );
        _this.projectErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async createNewProject(payload: Project, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue: Project;

      const projects = _this.projectsSubject.getValue();
      const newProjects = projects.slice(0);
      const url = `${environment.baseAPIUrl}project/create?userId=${currentUser?._id}`;

      _this.httpClient
        .post(url, payload, {
          headers: getCustomHeaders(true),
          responseType: 'json',
        })
        .subscribe({
          next: (newProject: Project) => {
            returnValue = newProject;

            _this.updateChildData(newProject, currentUser)
              .then((projectWithChildData: Project) => {
                returnValue = projectWithChildData;
              })
              .catch((childError) => {
                _this.projectErrorSubject.next(childError.message);
              })
              .finally(() => {
                _this.currentProjectSubject.next(returnValue);

                const newProjectOverview: ProjectOverview = {
                  _id: returnValue._id,
                  createdAt: returnValue.createdAt,
                  creatorId: returnValue.creatorId,
                  creatorName: currentUser.fullName,
                  description: returnValue.description,
                  hasValidPanoMod: returnValue.hasValidPanoMod,
                  imageThumbnailUrl: returnValue.imageThumbnailUrl,
                  imageUrl: returnValue.imageUrl,
                  isModelAnalysisProject: returnValue.isModelAnalysisProject,
                  name: returnValue.name
                };

                newProjects.push(newProjectOverview);
                _this.projectsSubject.next(newProjects);
                _this.refreshRecentProjects(currentUser);
                _this.settingsService.setIsLoading(false);
                resolve(returnValue);
              });
          },
          error: (error: HttpErrorResponse) => {
            const errMessage = _this.errorService.handleError(error);
            _this.projectErrorSubject.next(errMessage);

            _this.updateChildData(null, currentUser)
              .then((projectWithChildData: Project) => {
                returnValue = projectWithChildData;
              })
              .catch((childError) => {
                _this.projectErrorSubject.next(childError.message);
              })
              .finally(() => {
                _this.currentProjectSubject.next(returnValue);
                _this.settingsService.setIsLoading(false);
                reject(error);
              });
          },
          complete: () => {

          }
        });
    });
  }

  //TODO...does this work with the current structure?  Test when figure mods out - jane 9/24/2021
  async deleteMod(modId: string, parentCollection: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const currentProject = _this.currentProjectSubject.getValue();
      const projects = _this.projectsSubject.getValue();
      let returnValue: Project;

      if (currentUser && projects && currentProject && modId && parentCollection) {
        const projectIndex = projects.findIndex((project) => project._id === currentProject._id);
        const newProjects = projects.slice(0);
        let url = `${environment.baseAPIUrl}project/${currentProject._id}?userId=${currentUser?._id}`;

        switch (parentCollection) {
          case DbCollectionsEnum.SHIPS:
            url += '/ship/';
            break;
          case DbCollectionsEnum.VEHICLES:
            url += '/vehicle';
            break;
        }

        url += `${modId}?userId=${currentUser._id}`;

        _this.httpClient
          .delete(url, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedProject: Project) => {
              returnValue = updatedProject;

              _this.updateChildData(updatedProject, currentUser)
                .then((projectWithChildData: Project) => {
                  returnValue = projectWithChildData;
                })
                .catch((childError) => {
                  _this.projectErrorSubject.next(childError.message);
                })
                .finally(() => {
                  _this.currentProjectSubject.next(returnValue);
                  newProjects[projectIndex].editorId = currentUser._id;
                  newProjects[projectIndex].lastUpdatedBy = currentUser.fullName;
                  newProjects[projectIndex].updatedAt = new Date();
                  _this.projectsSubject.next(newProjects);
                  _this.settingsService.setIsLoading(false);
                  resolve(returnValue);
                });
            },
            error: (error: HttpErrorResponse) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = _this.errorService.handleError(error);
              _this.projectErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {

            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(
          `A modId and userId are required to remove a mod from the current project`
        );
        _this.projectErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async deleteProject(projectId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const projects = _this.projectsSubject.getValue();
      const currentProject = _this.currentProjectSubject.getValue();
      let returnValue: ProjectOverview[];

      if (currentUser && projectId && projects) {
        const projectIndex = projects.findIndex((project) => project._id === projectId);
        projects.splice(projectIndex, 1);
        const url = `${environment.baseAPIUrl}project/${projectId}?userId=${currentUser._id}`;

        _this.httpClient
          .delete(url, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (deletedProject: Project) => {
              returnValue = projects;
              _this.projectsSubject.next(projects);

              if (currentProject && currentProject._id === projectId) {
                _this.updateChildData(null, currentUser)
                  .then((projectWithChildData: Project) => {
                    _this.currentProjectSubject.next(null);
                  })
                  .catch((childError) => {
                    _this.projectsSubject.next(childError.message);
                  })
                  .finally(() => {
                    _this.settingsService.setIsLoading(false);
                    resolve(projects);
                  });
              } else {
                _this.settingsService.setIsLoading(false);
                resolve(projects);
              }
            },
            error: (error: HttpErrorResponse) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = _this.errorService.handleError(error);
              _this.projectErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {

            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(
          `A project and user are required to delete the current project`
        );
        _this.projectErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async deletePathTraveled(projectId: string, pathTraveledId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise(async (resolve, reject) => {
      if (currentUser && projectId && pathTraveledId) {
        const projects = this.projectsSubject.getValue();
        const projectIndex = projects.findIndex((project) => project._id === projectId);
        const newProjects = projects.slice(0);
        const url = `${environment.baseAPIUrl}project/${projectId}/pathTraveled/${pathTraveledId}?userId=${currentUser._id}`;
        let returnValue: Project;

        _this.httpClient
          .delete(url, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next:  (updatedProject: Project) => {
              returnValue = updatedProject;
              _this.currentProjectSubject.next(returnValue);
              newProjects[projectIndex].editorId = currentUser._id;
              newProjects[projectIndex].lastUpdatedBy = currentUser.fullName;
              newProjects[projectIndex].updatedAt = new Date();
              _this.currentProjectSubject.next(updatedProject);
              _this.projectsSubject.next(newProjects);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = this.errorService.handleError(error);
              this.projectErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {}
          });
      } else {
        const errMessage = _this.errorService.handleError(
          `projectId, pathTraveledId and userId are required to remove a project path traveled`
        );
        reject(errMessage);
      }
    });
  }

  getProjects(currentUser: User): Observable<ProjectOverview[]> {
    if (currentUser) {
      const projectsHttp$ = createHttpObservable(`${environment.baseAPIUrl}project/user/${currentUser._id}/overview`, {}, true);

      projectsHttp$
        .pipe(
          catchError((err) => {
            const errMessage = `Error getting projects for userId ${currentUser._id}: ${err}`;
            this.errorService.handleError(errMessage);
            this.projectErrorSubject.next(errMessage);
            return of([]);
          })
        )
        .subscribe((projects: ProjectOverview[]) => {
          this.projectsSubject.next(projects);
        });
    }

    return this.projects$;
  }

  refreshRecentProjects(currentUser: User): Observable<ProjectOverview[]> {
    if (currentUser) {
      const recentProjectsHttp$ = createHttpObservable(
        `${environment.baseAPIUrl}project/user/${currentUser._id}/overview?userId=${currentUser._id}&limit=3`,
        {},
        true
      );

      recentProjectsHttp$
        .pipe(
          catchError((err) => {
            const errMessage = this.errorService.handleError(err);
            this.projectErrorSubject.next(errMessage);
            return of([]);
          })
        )
        .subscribe((projects: ProjectOverview[]) => this.recentProjectsSubject.next(projects));
    }

    return this.recentProjects$;
  }

  refreshProjectsByUser(currentUser: User): Observable<ProjectOverview[]> {
    if (currentUser) {
      const recentUserProjectsHttp$ = createHttpObservable(
        `${environment.baseAPIUrl}project/user/${currentUser._id}/overview`,
        {},
        true
      );

      recentUserProjectsHttp$
        .pipe(
          catchError((err) => {
            const errMessage = this.errorService.handleError(err);
            this.projectErrorSubject.next(errMessage);
            return of([]);
          })
        )
        .subscribe((projects: ProjectOverview[]) => {
          this.selectedUserProjectsSubject.next(projects);
          this.selectedUserProjectsCreatedSubject.next(projects.filter((p) => p.creatorId === currentUser._id));
        });
    }

    return this.selectedUserProjects$;
  }

  async saveProject(projectId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (projectId && changes && currentUser) {
        const projects = _this.projectsSubject.getValue();
        const projectIndex = projects.findIndex((project) => project._id === projectId);
        const newProjects = projects.slice(0);
        const url = `${environment.baseAPIUrl}project/${projectId}?userId=${currentUser?._id}`;
        let returnValue: Project;
  
        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedProject: Project) => {
              returnValue = updatedProject;
  
              _this.updateChildData(updatedProject, currentUser)
                .then((projectWithChildData: Project) => {
                  returnValue = projectWithChildData;
                  _this.currentProjectSubject.next(returnValue);
                  newProjects[projectIndex].editorId = currentUser
                    ? currentUser._id
                    : newProjects[projectIndex].editorId;
                  newProjects[projectIndex].lastUpdatedBy = currentUser
                    ? currentUser.fullName
                    : newProjects[projectIndex].lastUpdatedBy;
                  newProjects[projectIndex].updatedAt = returnValue.updatedAt;
                  newProjects[projectIndex].name = returnValue.name;
                  newProjects[projectIndex].shipName = returnValue.shipDetails
                    ? returnValue.shipDetails.name
                    : newProjects[projectIndex].shipName;
                  newProjects[projectIndex].vehicleName = returnValue.vehicleDetails
                    ? returnValue.vehicleDetails.name
                    : newProjects[projectIndex].vehicleName;
                  _this.projectsSubject.next(newProjects);
                  _this.refreshRecentProjects(currentUser);
                  resolve(returnValue);
                })
                .catch((childError) => {
                  _this.projectErrorSubject.next(childError.message);
                  reject(childError.message);
                });
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.projectErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
  
            }
          });
      } else {
        const errMessage = _this.errorService.handleError(`projectId, changes and user are required to update a project`);
        _this.projectErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getCurrentProject() {
    return this.currentProjectSubject.getValue();
  }

  getCurrentProjectAreaMeasurements() {
    return this.currentProjectAreaMeasurementsSubject.getValue();
  }

  getCurrentProjectGeotags() {
    return this.currentProjectGeotagsSubject.getValue();
  }

  getCurrentProjectImages() {
    return this.currentProjectImagesSubject.getValue();
  }

  getCurrentProjectMeasurements() {
    return this.currentProjectMeasurementsSubject.getValue();
  }

  getCurrentProjectNotes() {
    return this.currentProjectNotesSubject.getValue();
  }

  getCurrentProjectVideos() {
    return this.currentProjectVideosSubject.getValue();
  }

  async getProjectById(projectId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue: Project;
      const promises = [];

      if (currentUser && projectId) {
        //pull the project from the API to get data needed for the viewer too
        const url = `${environment.baseAPIUrl}project/${projectId}?userId=${currentUser._id}`;

        _this.httpClient
          .get(url, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: async (project: Project) => {
              returnValue = project;
              _this.currentProjectSubject.next(project);

              //TODO...remove if
              if (_this.areaMeasurementService?.refreshAreaMeasurementsByProject) {
                promises.push(
                  _this.areaMeasurementService.refreshAreaMeasurementsByProject(project ? project._id : null, currentUser)
                );
              }

              promises.push(
                _this.documentService.refreshDocumentsByParent(DbCollectionsEnum.PROJECTS, project ? project._id : null, currentUser)
              );

              promises.push(_this.geotagService.refreshGeotagsByProject(project ? project._id : null, currentUser));
              promises.push(
                _this.imageDocService.refreshImagesByParent(
                  DbCollectionsEnum.PROJECTS,
                  project ? project._id : null,
                  false,
                  currentUser
                )
              );
              promises.push(_this.measurementService.refreshMeasurementsByProject(project ? project._id : null, currentUser));
              promises.push(
                _this.noteService.refreshNotesByParent(DbCollectionsEnum.PROJECTS, project ? project._id : null, currentUser)
              );
              promises.push(_this.reportService.refreshReportsByProject(project ? project._id : null, currentUser));
              promises.push(
                _this.videoService.refreshVideosByParent(DbCollectionsEnum.PROJECTS, project ? project._id : null, currentUser)
              );
              promises.push(_this.updateChildData(project, currentUser));

              await Promise.allSettled(promises).then((results) => {
                const projectResult = results[results.length - 1];
                if (projectResult.status === 'fulfilled') {
                  returnValue = projectResult.value;
                  _this.currentProjectSubject.next(returnValue);
                }
                _this.settingsService.setIsLoading(false);
                resolve(returnValue);
              });
            },
            error: (error: HttpErrorResponse) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = _this.errorService.handleError(
                `Error getting projectId ${projectId}: ${error.error}`
              );
              reject(error);
            },
            complete: () => {
            }
          });
      } else {
        _this.currentProjectSubject.next(null);

        //TODO...remove if
        if (_this.areaMeasurementService?.refreshAreaMeasurementsByProject) {
          promises.push(_this.areaMeasurementService.refreshAreaMeasurementsByProject(null, currentUser));
        }

        promises.push(_this.documentService.refreshDocumentsByParent(DbCollectionsEnum.PROJECTS, null, currentUser));
        promises.push(_this.geotagService.refreshGeotagsByProject(null, currentUser));
        promises.push(_this.imageDocService.refreshImagesByParent(DbCollectionsEnum.PROJECTS, null, false, currentUser));
        promises.push(_this.measurementService.refreshMeasurementsByProject(null, currentUser));
        promises.push(_this.noteService.refreshNotesByParent(DbCollectionsEnum.PROJECTS, null, currentUser));
        promises.push(_this.reportService.refreshReportsByProject(null, currentUser));
        promises.push(_this.videoService.refreshVideosByParent(DbCollectionsEnum.PROJECTS, null, currentUser));
        promises.push(_this.updateChildData(null, currentUser));

        Promise.allSettled(promises).then((results) => {
          _this.settingsService.setIsLoading(false);
          resolve(returnValue);
        });
      }
    });
  }

  async updateChildData(project: Project, currentUser: User): Promise<any> {
    return new Promise((resolve, reject) => {
      zip(this.mods$, this.ships$, this.users$, this.vehicles$)
        .pipe(
          map(([mods, ships, users, vehicles]) => ({
            mods,
            ships,
            users,
            vehicles,
          }))
        )
        .subscribe({
          next: ({ mods, ships, users, vehicles }) => {
            let shipMod: Mod;
            let vehicleMod: Mod;

            if (project) {
              const promises = [];
              promises.push(this.shipService.getShipById(project.ship ? project.ship._id : null, currentUser));
              promises.push(this.vehicleService.getVehicleById(project.vehicle ? project.vehicle._id : null, currentUser));
              promises.push(
                this.modService.getModById(
                  project.ship ? project.ship.modId : null,
                  DbCollectionsEnum.SHIPS,
                  project.ship ? project.ship._id : null,
                  currentUser
                )
              );
              promises.push(
                this.modService.getModById(
                  project.vehicle ? project.vehicle.modId : null,
                  DbCollectionsEnum.VEHICLES,
                  project.vehicle ? project.vehicle._id : null,
                  currentUser
                )
              );
              promises.push(this.reportService.refreshReportsByProject(project._id, currentUser));

              Promise.allSettled(promises).then((results) => {
                const promises2 = [];
                const shipModResults = results[2];
                const vehicleModResults = results[3];

                if (shipModResults.status === 'fulfilled') {
                  shipMod = shipModResults.value;

                  if (shipMod && shipMod.modSource) {
                    switch (shipMod.modSource.collection) {
                      case DbCollectionsEnum.MODELS3D:
                        promises2.push(
                          this.model3dService.getModel3dById(
                            shipMod.modSource._id,
                            shipMod.parent.collection,
                            shipMod.parent._id,
                            currentUser
                          )
                        );
                        break;
                      case DbCollectionsEnum.SCANS:
                        promises2.push(
                          this.scanService.getScanById(
                            shipMod.modSource._id,
                            shipMod.parent.collection,
                            shipMod.parent._id,
                            currentUser
                          )
                        );
                        break;
                    }
                  }
                }

                if (vehicleModResults.status === 'fulfilled') {
                  vehicleMod = vehicleModResults.value;

                  if (vehicleMod && vehicleMod.modSource) {
                    switch (vehicleMod.modSource.collection) {
                      case DbCollectionsEnum.MODELS3D:
                        promises2.push(
                          this.model3dService.getModel3dById(
                            vehicleMod.modSource._id,
                            vehicleMod.parent.collection,
                            vehicleMod.parent._id,
                            currentUser
                          )
                        );
                        break;
                      case DbCollectionsEnum.SCANS:
                        promises2.push(
                          this.scanService.getScanById(
                            vehicleMod.modSource._id,
                            vehicleMod.parent.collection,
                            vehicleMod.parent._id,
                            currentUser
                          )
                        );
                        break;
                    }
                  }
                }

                Promise.allSettled(promises2).then((results2) => {
                  resolve(project);
                });
              });
            } else {
              const promises = [];
              promises.push(this.shipService.getShipById(null, currentUser));
              promises.push(this.vehicleService.getVehicleById(null, currentUser));
              promises.push(this.model3dService.getModel3dById(null, DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(this.model3dService.getModel3dById(null, DbCollectionsEnum.VEHICLES, null, currentUser));
              promises.push(this.scanService.getScanById(null, DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(this.scanService.getScanById(null, DbCollectionsEnum.VEHICLES, null, currentUser));
              promises.push(this.reportService.refreshReportsByProject(null, currentUser));

              Promise.allSettled(promises).then((results) => {
                resolve(project);
              });
            }
          },
          error: (error: Error) => {
            const errMessage = this.errorService.handleError(`Error updating project child data: ${error}`);
            this.projectErrorSubject.next(errMessage);
            reject(errMessage);
          },
          complete: () => {

          }
        });
    });
  }
}
