<div class="content" *ngIf="currentUser$ | async as currentUser">
  <div *ngIf="showFilter">
    <mat-form-field class="table-filter">
      <mat-label>Search Users</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search"
        aria-label="Type search criteria to filter results" matTooltip="Type search criteria to filter results"
        #tableFilter />
    </mat-form-field>
  </div>

  <table mat-table [dataSource]="dataSource" matSort matSortActive="subscriptionType" matSortDirection="asc"
    class="mat-elevation-z8">
    <ng-container matColumnDef="subscriptionType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Subscription Type</th>
      <td mat-cell *matCellDef="let subscription" style="cursor: pointer">
        <a aria-label="Click to view" matTooltip="Click to View {{ getSubscriptionTypeDisplayName(subscription.subscriptionType) }}"
          (click)="viewSubscribedObject(subscription._id)">
          {{ getSubscriptionTypeDisplayName(subscription.subscriptionType) }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let subscription" style="cursor: pointer">
        <a aria-label="Click to view" matTooltip="Click to view {{ getSubscriptionTypeDisplayName(subscription.subscriptionType) }}"
          (click)="viewSubscribedObject(subscription._id)">
          {{ subscription.name }}
        </a>
      </td>
    </ng-container>

    <!-- actions column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Subscribed?</th>
      <td mat-cell *matCellDef="let subscription">
        <mat-checkbox aria-label="Toggle Subscription" matTooltip="Toggle Subscription"
          [checked]="subscription.subscribed" (change)="toggleSubscription($event.checked, subscription)">
        </mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedSubscriptionsColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedSubscriptionsColumns" [ngClass]="getRowClass(row._id)"></tr>

    <!-- Row shown when there is no matching data -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">*** No matching user subscriptions ***</td>
    </tr>
  </table>

  <mat-paginator *ngIf="showPaginator">
    [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons aria-label="Select page of user
    subscriptions"
  </mat-paginator>
</div>