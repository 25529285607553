<div class="content">
  <div
    *ngIf="{
    } as data"
  >
    <div *ngIf="showFilter">
      <mat-form-field class="table-filter">
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search"
          aria-label="Type search criteria to filter results"
          matTooltip="Type search criteria to filter results"
          #tableFilter
        />
      </mat-form-field>
    </div>

    <table
      mat-table
      #table
      [dataSource]="dataSource"
      matSort
      matSortActive="name"
      matSortDirection="asc"
      class="mat-elevation-z8 model3dTable"
    >
      <!-- name column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Model Name</th>
        <td mat-cell *matCellDef="let model3d" style="cursor: pointer">
          <a aria-label="Click to edit 3D model" matTooltip="Click to edit 3D model" (click)="edit3dModel(model3d)">{{
            model3d.name
          }}</a>
        </td>
      </ng-container>

      <!-- modelDate column -->
      <ng-container matColumnDef="modelDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Model Date</th>
        <td mat-cell *matCellDef="let model3d" style="cursor: pointer">
          <a aria-label="Click to edit 3D model" matTooltip="Click to edit 3D model" (click)="edit3dModel(model3d)">{{
            model3d.modelDate | date: 'shortDate'
          }}</a>
        </td>
      </ng-container>

      <!-- classification column -->
      <ng-container matColumnDef="classification">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Classification</th>
        <td mat-cell *matCellDef="let model3d" style="cursor: pointer">
          <a aria-label="Click to edit 3D model" matTooltip="Click to edit 3D model" (click)="edit3dModel(model3d)">{{
            model3d.classification
          }}</a>
        </td>
      </ng-container>

      <!-- scanType column -->
      <ng-container matColumnDef="fidelityType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fidelity Type</th>
        <td mat-cell *matCellDef="let model3d" style="cursor: pointer">
          <a aria-label="Click to edit 3D model" matTooltip="Click to edit 3D model" (click)="edit3dModel(model3d)">{{
            model3d.fidelityType
          }}</a>
        </td>
      </ng-container>

      <!-- dataSourceName column -->
      <ng-container matColumnDef="dataSourceName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization</th>
        <td mat-cell *matCellDef="let model3d" style="cursor: pointer">
          <a aria-label="Click to edit 3D model" matTooltip="Click to edit 3D model" (click)="edit3dModel(model3d)">{{
            model3d.dataSourceName
          }}</a>
        </td>
      </ng-container>

      <!-- name of person modeling column -->
      <ng-container matColumnDef="nameOfPersonModeling">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name of Person Modeling</th>
        <td mat-cell *matCellDef="let model3d" style="cursor: pointer">
          <a aria-label="Click to edit 3D model" matTooltip="Click to edit 3D model" (click)="edit3dModel(model3d)">{{
            model3d.nameOfPersonModeling
          }}</a>
        </td>
      </ng-container>

      <!-- description column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let model3d" style="cursor: pointer">
          <a aria-label="Click to edit 3D model" matTooltip="Click to edit 3D model" (click)="edit3dModel(model3d)">{{
            model3d.description
          }}</a>
        </td>
      </ng-container>

      <!-- actions column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let model3d">
          <mat-toolbar class="actionButtonBar">
            <a
              mat-icon-button
              aria-label="Delete 3D Model"
              matTooltip="Delete 3D Model"
              (click)="deleteModel3d(model3d)"
              *ngIf="canDelete(model3d)"
            >
              <img src="assets/img/TrashGray.png" alt="Delete" />
            </a>
            <div *ngIf="getHasValidMod(model3d)">
              <a
                mat-icon-button
                aria-label="Open Viewer"
                matTooltip="{{ freeRoamTooltip }}"
                (click)="openViewer(model3d)"
              >
                <img src="assets/img/UEViewer.png" alt="Open {{ unrealViewerName }}" />
              </a>
            </div>
            <a
              mat-icon-button
              aria-label="Edit 3D Model Data"
              matTooltip="Edit 3D Model Data"
              (click)="edit3dModel(model3d)"
            >
              <img src="assets/img/Edit.png" alt="Edit" />
            </a>
          </mat-toolbar>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedModel3dColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedModel3dColumns"></tr>

      <!-- Row shown when there is no matching data -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">*** No matching 3D models ***</td>
      </tr>
    </table>

    <mat-paginator *ngIf="showPaginator">
      [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons aria-label="Select page of 3D models"
    </mat-paginator>
  </div>
</div>
