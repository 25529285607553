import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';

import { Manufacturer, Scanner, User } from '@shared/models';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import { ErrorService, LogService, SettingsService, UserService } from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class ManufacturerService {
  private currentManufacturerSubject = new BehaviorSubject<Manufacturer>(null);
  private currentManufacturerScannersSubject = new BehaviorSubject<Scanner[]>([]);
  private manufacturersSubject = new BehaviorSubject<Manufacturer[]>([]);
  private manufacturerErrorSubject = new BehaviorSubject<string>(null);
  currentManufacturer$: Observable<Manufacturer> = this.currentManufacturerSubject.asObservable();
  currentManufacturerScanners$: Observable<Scanner[]> = this.currentManufacturerScannersSubject.asObservable();
  manufacturers$: Observable<Manufacturer[]> = this.manufacturersSubject.asObservable();
  manufacturerError$: Observable<string> = this.manufacturerErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) {
  }

  async createNewManufacturer(payload: Manufacturer, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && payload) {
        const manufacturers = _this.manufacturersSubject.getValue();
        const newManufacturers = manufacturers.slice(0);
        const url = `${environment.baseAPIUrl}manufacturer/create?userId=${currentUser?._id}`;
        let returnValue: Manufacturer;
  
        _this.httpClient
          .post(url, payload, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (newManufacturer: Manufacturer) => {
              returnValue = newManufacturer;
              _this.currentManufacturerSubject.next(newManufacturer);
              newManufacturers.push(newManufacturer);
              _this.manufacturersSubject.next(newManufacturers);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.manufacturerErrorSubject.next(errMessage);
              _this.currentManufacturerSubject.next(null);
              reject(error);
            },
            complete: () => {
  
            }
          });
      } else {
        const errMessage = `payload and user are required to create a manufacturer`;
        _this.errorService.handleError(errMessage);
        _this.manufacturerErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveManufacturer(manufacturerId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && manufacturerId) {
        const manufacturers = _this.manufacturersSubject.getValue();
        const manufacturerIndex = manufacturers.findIndex((manufacturer) => manufacturer._id === manufacturerId);
        const newManufacturers = manufacturers.slice(0);
        const url = `${environment.baseAPIUrl}manufacturer/${manufacturerId}?userId=${currentUser?._id}`;
        let returnValue: Manufacturer;
  
        newManufacturers[manufacturerIndex] = {
          ...manufacturers[manufacturerIndex],
          ...changes,
        };
  
        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedManufacturer: Manufacturer) => {
              returnValue = updatedManufacturer;
              _this.currentManufacturerSubject.next(updatedManufacturer);
              _this.manufacturersSubject.next(newManufacturers);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.manufacturerErrorSubject.next(errMessage);
              _this.currentManufacturerSubject.next(null);
              reject(error);
            },
            complete: () => {
  
            }
          });
      } else {
        const errMessage = `changes, manufacturerId and user are required to update a manufacturer`;
        _this.errorService.handleError(errMessage);
        _this.manufacturerErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getCurrentManufacturer() {
    return this.currentManufacturerSubject.getValue();
  }

  getManufacturers(currentUser: User): Observable<Manufacturer[]> {
    const manufacturersHttp$ = createHttpObservable(`${environment.baseAPIUrl}manufacturer?userId=${currentUser?._id}`, {}, true);

    manufacturersHttp$
      .pipe(
        catchError((err) => {
          console.error(`Error getting manufacturers: ${err}`);
          return of([]);
        })
      )
      .subscribe((manufacturers: Manufacturer[]) => this.manufacturersSubject.next(manufacturers));

    return manufacturersHttp$;
  }

  getCurrentManufacturerScanners() {
    return this.currentManufacturerScannersSubject.getValue();
  }

  async getManufacturerById(manufacturerId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      let returnValue: Manufacturer;

      if (currentUser && manufacturerId) {
        _this.manufacturers$
          .pipe(
            map((manufacturers) => manufacturers.find((manufacturer) => manufacturer._id === manufacturerId)),
            filter((manufacturer) => !!manufacturer)
          )
          .subscribe({
            next: (manufacturer: Manufacturer) => {
              returnValue = manufacturer;
              _this.currentManufacturerSubject.next(manufacturer);
              resolve(returnValue);
            },
            error: (error: Error) => {
              const errMessage = _this.errorService.handleError(error);
              reject(errMessage);
            },
            complete: () => {

            }
          });
      } else {
        _this.currentManufacturerSubject.next(null);
        _this.currentManufacturerScannersSubject.next([]);
        resolve(returnValue);
      }
    });
  }
}
