import { Component, ElementRef, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, forkJoin, take } from 'rxjs';
import { createTag, getBaseDomainFromUrl, getIsRunningLocally, getUrlPort, getUrlProtocol, removeUndefinedAndEmptyStringProps } from '@shared/utils';
import difference from 'lodash-es/difference';
import isEqual from 'lodash-es/isEqual';
import Swal from 'sweetalert2';

import {
  CollisionTypeSummary,
  ControlSchemeTypesEnum,
  DbCollectionsEnum,
  FileObjectTypesEnum,
  FileStorageTypesEnum,
  GeotagContentTypesEnum,
  KeyframeCollisionStatusEnum,
  MessageTypesEnum,
  MouseButtonsEnum,
  MouseButtonsMaskEnum,
  ReportSectionIdsEnum,
  SpecialKeysEnum,
  SpecialKeyCodesEnum,
  TagFileTypesEnum,
  TagTypesEnum,
  ToClientMessageTypesEnum,
  UnrealUIStatesEnum,
  UnrealScenesEnum,
  ModStatesEnum,
} from '@shared/enums';
import {
  AreaMeasurement,
  Geotag,
  ImageDoc,
  Keyframe,
  Measurement,
  Mod,
  Note,
  PathTraveled,
  Project,
  Ship,
  UnrealServer,
  User,
  Vehicle,
  Video,
} from '@shared/models';
import {
  AreaMeasurementService,
  ErrorService,
  FileService,
  GeotagService,
  ImageDocService,
  LogService,
  MeasurementService,
  ModService,
  NoteService,
  ProjectService,
  SettingsService,
  ShipService,
  UnrealServerService,
  UserService,
  VehicleService,
  VideoService,
} from '@shared/services';

import { environment } from '@environment';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
  selector: 'app-unreal-viewer',
  templateUrl: './unreal-viewer.component.html',
  styleUrls: ['./unreal-viewer.component.css'],
})
export class UnrealViewerComponent implements OnInit, AfterViewInit {
  currentUser$: Observable<User>;
  selectedProject: Project | undefined;
  selectedProjectAreaMeasurements: AreaMeasurement[];
  selectedProjectGeotags: Geotag[];
  selectedProjectMeasurements: Measurement[];
  selectedProjectScreenshots: ImageDoc[];
  selectedProjectVideos: Video[];
  selectedShip: Ship;
  selectedShipMod: Mod;
  selectedShipScreenshots: ImageDoc[];
  selectedShipVideos: Video[];
  selectedVehicle: Vehicle;
  selectedVehicleMod: Mod;
  selectedVehicleScreenshots: ImageDoc[];
  selectedVehicleVideos: Video[];
  defaultEventData: any;
  defaultClearance = 0;
  defaultAreas = {
    NumAreas: 0,
  };
  defaultGeotags = {
    NumGeotags: 0,
  };
  defaultKeyframes = {
    NumKeyframes: 0,
  };
  defaultMeasures = {
    NumMeasures: 0,
  };
  defaultCKColor = [0, 0, 0, 0];
  defaultVector = [0, 0, 0];
  fullProjectFileName: string = '';
  fullSiteFileName: string = '';
  fullVehicleFileName: string = '';
  Areas: any;
  BLContact = this.defaultVector;
  BRContact = this.defaultVector;
  FLContact = this.defaultVector;
  FRContact = this.defaultVector;
  ImagePaths: any = {};
  LoadScene: string;
  Geotags: any;
  Keyframes: any;
  Measures: any;
  NegativeEncroachment = this.defaultVector;
  PanoOnly = false;
  PointcloudName: string = '';
  PositiveEncroachment = this.defaultVector;
  ProjectFileName: string = '';
  SiteFileName: string = '';
  VehicleFileName: string = '';
  VideoPaths: any = {};
  PCName: string = '';
  RotCent = this.defaultVector;
  UIState: string;
  DynamicAssetLocation = this.defaultVector;
  DynamicAssetModelOffset = this.defaultVector;
  DynamicAssetModelRotation = this.defaultVector;
  DynamicAssetName: string = '';
  DynamicAssetRotation = this.defaultVector;
  Videos: any;
  WheelClearance = this.defaultClearance;
  isVehicleCalibrationScene = false;
  isDebugging = false;
  responseEventListeners = new Map();
  unrealEventName = 'handle_unreal_events';
  unrealIsLoading = false;
  unrealIsReady = false;
  unrealReadyMessage = { status: 'unreal is ready' };
  safeSrc: SafeResourceUrl;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private areaMeasurementService: AreaMeasurementService,
    private errorService: ErrorService,
    private geotagService: GeotagService,
    private fileService: FileService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private measurementService: MeasurementService,
    private modService: ModService,
    private noteService: NoteService,
    private projectService: ProjectService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private videoService: VideoService
  ) { }

  ngOnInit(): void {
    const _this = this;
    this.settingsService.setIsLoading(true);
    this.currentUser$ = this.userService.currentUser$;
    this.isDebugging = this.settingsService.getIsDebugging();

    this.defaultEventData = {
      createTag: createTag,
      areaMeasurementService: this.areaMeasurementService,
      currentUser: this.userService.getCurrentUser(),
      defaultClearance: this.defaultClearance,
      defaultVector: this.defaultVector,
      environment: environment,
      errorService: this.errorService,
      fileService: this.fileService,
      geotagService: this.geotagService,
      imageDocService: this.imageDocService,
      location: this.location,
      logService: this.logService,
      measurementService: this.measurementService,
      modService: this.modService,
      noteService: this.noteService,
      projectService: this.projectService,
      settingsService: this.settingsService,
      shipService: this.shipService,
      vehicleService: this.vehicleService,
      videoService: this.videoService,
      userService: this.userService,
      DbCollectionsEnum: DbCollectionsEnum,
      FileObjectTypesEnum: FileObjectTypesEnum,
      FileStorageTypesEnum: FileStorageTypesEnum,
      GeotagContentTypesEnum: GeotagContentTypesEnum,
      KeyframeCollisionStatusEnum: KeyframeCollisionStatusEnum,
      ReportSectionIdsEnum: ReportSectionIdsEnum,
      TagFileTypesEnum: TagFileTypesEnum,
      TagTypesEnum: TagTypesEnum,
      Swal: Swal,
      UnrealUIStatesEnum: UnrealUIStatesEnum,
      UnrealScenesEnum: UnrealScenesEnum,
    };

    //bind context to my windows message handler
    this.myHandleUnrealResponseFunction.bind(this);
    this.myProcessScreenshotsFunction.bind(this);
    this.myProcessVideosFunction.bind(this);

    try {
      this.route.queryParams
        .pipe(take(1))
        .subscribe((params) => {
          this.logService.logInfo(`${environment.unreal.viewerName} params: ${JSON.stringify(params)}`);

          this.LoadScene = params.sceneName || '';
          this.PanoOnly = typeof params.panoOnly != 'undefined' && params.panoOnly === 'true' ? true : false;
          this.PCName = params.pcName || '';
          this.UIState = params.uiState || '';
          this.DynamicAssetName = params.vehicleName || '';
          this.defaultEventData.UIState = this.UIState;

          if (this.LoadScene === UnrealScenesEnum.CALIBRATION) {
            this.isVehicleCalibrationScene = true;
          }

          this.modService.currentShipMod$
            .pipe(take(1))
            .subscribe((shipMod: Mod) => {
              this.selectedShipMod = shipMod;
              if (shipMod) {
                this.PCName = shipMod.name;
                this.PointcloudName = shipMod.name;
              }
            });

          this.modService.currentVehicleMod$
            .pipe(take(1))
            .subscribe((vehicleMod: Mod) => {
              this.selectedVehicleMod = vehicleMod;
              if (vehicleMod) {
                this.BLContact = this.getVectorValue(
                  vehicleMod.jsonConfiguration ? vehicleMod.jsonConfiguration.BLContact : this.defaultVector
                );
                this.BRContact = this.getVectorValue(
                  vehicleMod.jsonConfiguration ? vehicleMod.jsonConfiguration.BRContact : this.defaultVector
                );
                this.FLContact = this.getVectorValue(
                  vehicleMod.jsonConfiguration ? vehicleMod.jsonConfiguration.FLContact : this.defaultVector
                );
                this.FRContact = this.getVectorValue(
                  vehicleMod.jsonConfiguration ? vehicleMod.jsonConfiguration.FRContact : this.defaultVector
                );
                this.RotCent = this.getVectorValue(
                  vehicleMod.jsonConfiguration ? vehicleMod.jsonConfiguration.RotCent : this.defaultVector
                );
                this.DynamicAssetModelOffset = this.getVectorValue(
                  vehicleMod.jsonConfiguration ? vehicleMod.jsonConfiguration.DynamicAssetModelOffset : this.defaultVector
                );
                this.DynamicAssetModelRotation = this.getVectorValue(
                  vehicleMod.jsonConfiguration ? vehicleMod.jsonConfiguration.DynamicAssetModelRotation : this.defaultVector
                );
                this.DynamicAssetName = vehicleMod.name;
                this.WheelClearance = vehicleMod.jsonConfiguration
                  ? vehicleMod.jsonConfiguration.WheelClearance
                  : this.defaultClearance;

                this.VehicleFileName = `M_${vehicleMod._id.toString()}.json`;
                this.fullVehicleFileName = `${environment.unreal.baseAppPath}${environment.unreal.vehicleFilePath}${this.VehicleFileName}`;
              }
            });

          this.shipService.currentShip$
            .pipe(take(1))
            .subscribe((ship: Ship) => {
              this.selectedShip = ship;
              if (this.selectedProject) {
                this.ProjectFileName = `PR_${this.selectedProject._id.toString()}_${this.userService.getCurrentUser()._id.toString()}.json`;
              } else {
                this.ProjectFileName = `${this.selectedShipMod.name}_${this.userService.getCurrentUser()._id.toString()}.json`;
              }
              this.fullProjectFileName = `${environment.unreal.baseAppPath}${environment.unreal.projectAndShipFilePath}${this.ProjectFileName}`;
              this.SiteFileName = this.selectedShipMod?.scanDetails?.siteFileName || 'MissingShipMod.json';
              this.fullSiteFileName = `${environment.unreal.baseAppPath}${environment.unreal.siteFilePath}${this.SiteFileName}`;
            });

          this.vehicleService.currentVehicle$
            .pipe(take(1))
            .subscribe((vehicle: Vehicle) => {
              this.selectedVehicle = vehicle;

              if (vehicle) {
                this.VehicleFileName = _this.selectedVehicleMod ? `M_${_this.selectedVehicleMod._id.toString()}.json` : vehicle ? `M_${vehicle._id.toString()}.json` : ``;
                this.fullVehicleFileName = `${environment.unreal.baseAppPath}${environment.unreal.vehicleFilePath}${this.VehicleFileName}`;
              }
            });

          this.projectService.currentProject$
            .pipe(take(1))
            .subscribe((project: Project) => {
              this.selectedProject = project;

              if (project) {
                this.Areas = project.Areas || this.defaultAreas;
                this.Geotags = project.Geotags || this.defaultGeotags;
                this.Keyframes = project.Keyframes || this.defaultKeyframes;
                this.Measures = project.Measures || this.defaultMeasures;
                this.NegativeEncroachment = this.getVectorValue(
                  project.jsonConfiguration ? project.jsonConfiguration.NegativeEncroachment : this.defaultVector
                );
                this.PositiveEncroachment = this.getVectorValue(
                  project.jsonConfiguration ? project.jsonConfiguration.PositiveEncroachment : this.defaultVector
                );
                this.DynamicAssetLocation = this.getVectorValue(
                  project.jsonConfiguration ? project.jsonConfiguration.DynamicAssetLocation : this.defaultVector
                );
                this.DynamicAssetRotation = this.getVectorValue(
                  project.jsonConfiguration ? project.jsonConfiguration.DynamicAssetRotation : this.defaultVector
                );
                this.ProjectFileName = `PR_${project._id.toString()}_${this.userService.getCurrentUser()._id.toString()}.json`;
                this.fullProjectFileName = `${environment.unreal.baseAppPath}${environment.unreal.projectAndShipFilePath}${this.ProjectFileName}`;
                this.SiteFileName = this.selectedShipMod.scanDetails.siteFileName;
                this.fullSiteFileName = `${environment.unreal.baseAppPath}${environment.unreal.siteFilePath}${this.SiteFileName}`;
              }
            });

          this.areaMeasurementService.currentProjectAreaMeasurements$
            .pipe(untilDestroyed(this))
            .subscribe(
              (areaMeasurements: AreaMeasurement[]) => (this.selectedProjectAreaMeasurements = areaMeasurements || [])
            );

          this.imageDocService.currentProjectImages$
            .pipe(untilDestroyed(this))
            .subscribe((screenshots: ImageDoc[]) => {
              this.selectedProjectScreenshots = screenshots || [];
              this.selectedProjectScreenshots.map((i) => {
                _this.ImagePaths[i.name] = i.displayUrl || i.url;
              });
            });

          this.geotagService.currentProjectGeotags$
            .pipe(untilDestroyed(this))
            .subscribe((geotags: Geotag[]) => (this.selectedProjectGeotags = geotags || []));

          this.measurementService.currentProjectMeasurements$
            .pipe(untilDestroyed(this))
            .subscribe((measurements: Measurement[]) => (this.selectedProjectMeasurements = measurements || []));

          this.videoService.currentProjectVideos$
            .pipe(untilDestroyed(this))
            .subscribe((videos: Video[]) => {
              this.selectedProjectVideos = this.getVideosForUnreal(videos);
              //this.selectedProjectVideos = videos || [];
              this.selectedProjectVideos.map((v) => {
                _this.VideoPaths[v.name] = v.displayUrl || v.url;
              });
            });

          this.imageDocService.currentShipImages$
            .pipe(untilDestroyed(this))
            .subscribe((screenshots: ImageDoc[]) => {
              this.selectedShipScreenshots = screenshots || [];
              this.selectedShipScreenshots.map((i) => {
                _this.ImagePaths[i.name] = i.displayUrl || i.url;
              });
            });

          this.videoService.currentShipVideos$
            .pipe(untilDestroyed(this))
            .subscribe((videos: Video[]) => {
              this.selectedShipVideos = this.getVideosForUnreal(videos);
              //this.selectedShipVideos = videos || [];
              this.selectedShipVideos.map((v) => {
                _this.VideoPaths[v.name] = v.displayUrl || v.url;
              });
            });

          this.imageDocService.currentVehicleImages$
            .pipe(untilDestroyed(this))
            .subscribe((screenshots: ImageDoc[]) => {
              this.selectedVehicleScreenshots = screenshots || [];
              this.selectedVehicleScreenshots.map((i) => {
                _this.ImagePaths[i.name] = i.displayUrl || i.url;
              });
            });

          this.videoService.currentVehicleVideos$
            .pipe(untilDestroyed(this))
            .subscribe((videos: Video[]) => {
              this.selectedVehicleVideos = this.getVideosForUnreal(videos);
              //this.selectedVehicleVideos = videos || [];
              this.selectedVehicleVideos.map((v) => {
                _this.VideoPaths[v.name] = v.displayUrl || v.url;
              });
            });
        });
    } catch (ex) {
      this.settingsService.setIsLoading(false);
      const errMessage = this.errorService.handleError(`Error loading ${environment.unreal.viewerName}: ${ex.message}`);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error',
          `There was an error loading the ${environment.unreal.viewerName}.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
      }
      this.location.back();
    } finally {
      //generate files before continuing so they are there when unreal loads - jane 2/1/2024
      try {
        const promises = [];
        //We need to create a file for projects, ships and vehicles.  The project data is too large to pass in the descriptor.
        const vehicleJsonData = {
          BLContact: _this.getVectorValue(_this.BLContact),
          BRContact: _this.getVectorValue(_this.BRContact),
          FLContact: _this.getVectorValue(_this.FLContact),
          FRContact: _this.getVectorValue(_this.FRContact),
          RotCent: _this.getVectorValue(_this.RotCent),
          DynamicAssetModelOffset: _this.getVectorValue(_this.DynamicAssetModelOffset),
          DynamicAssetModelRotation: _this.getVectorValue(_this.DynamicAssetModelRotation),
          DynamicAssetName: _this.DynamicAssetName || '',
          WheelClearance: !isNaN(_this.WheelClearance) ? _this.WheelClearance : _this.defaultClearance
        };

        const projectAndShipJsonData = {
          SiteName: _this.SiteFileName ? _this.SiteFileName.replace('.json', '') : '',
          Areas: _this.Areas || _this.defaultAreas,
          Geotags: _this.Geotags || _this.defaultGeotags,
          Keyframes: _this.Keyframes || _this.defaultKeyframes,
          Measures: _this.Measures || _this.defaultMeasures,
          NegativeEncroachment: _this.getVectorValue(_this.NegativeEncroachment),
          PanoOnly: _this.PanoOnly,
          PointcloudName: _this.PCName || '',
          PositiveEncroachment: _this.getVectorValue(_this.PositiveEncroachment),
          DynamicAssetLocation: _this.getVectorValue(_this.DynamicAssetLocation),
          DynamicAssetName: _this.DynamicAssetName || '',
          DynamicAssetRotation: _this.getVectorValue(_this.DynamicAssetRotation),
        };

        if (_this.isDebugging) {
          _this.logService.logInfo(
            `Creating JSON data ship / project file ${_this.fullProjectFileName}`
          );
        }

        if ((_this.UIState === UnrealUIStatesEnum.PROJECT || _this.UIState === UnrealUIStatesEnum.FREE_ROAM) && _this.fullProjectFileName && _this.fullProjectFileName !== '') {
          promises.push(_this.fileService.createServerFile(FileObjectTypesEnum.PROJECT, _this.fullProjectFileName, projectAndShipJsonData, _this.defaultEventData.currentUser));
        }

        //vehicles are used in both calibration and project scenes
        if (this.fullVehicleFileName && _this.fullVehicleFileName !== '') {
          if (_this.isDebugging) {
            _this.logService.logInfo(
              `Creating JSON data vehicle file ${_this.fullVehicleFileName}`
            );
          }

          promises.push(_this.fileService.createServerFile(FileObjectTypesEnum.VEHICLE, _this.fullVehicleFileName, vehicleJsonData, _this.defaultEventData.currentUser));
        }

        Promise.allSettled(promises).then((fileResults) => {
          fileResults.forEach((result) => {
            if (result.status === 'rejected') {
              const fileErrorMessage = _this.errorService.handleError(
                `Error writing ${environment.unreal.viewerName} file.  Loading will be continued with defaults.  Error: ${result.reason}`
              );
            }
          });
        })
      } catch (ex) {
        _this.errorService.handleError(`Error loading files needed for ${environment.unreal.viewerName}: ${ex.message}`);
      } finally {
        this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(environment.unreal.matchmakerUrl);
        this.addResponseEventListener(this.unrealEventName, this.myHandleUnrealResponseFunction);
        this.settingsService.setIsLoading(false);
      }
    }
  }

  ngAfterViewInit() {
    this.myWindowMessageListenerFunction = this.myWindowMessageListenerFunction.bind(this);
    window.addEventListener('message', this.myWindowMessageListenerFunction);
  }

  async ngOnDestroy(): Promise<any> {
    const _this = this;
    _this.settingsService.setIsLoading(true);

    return new Promise(async (resolve, reject) => {
      try {
        _this.unrealIsReady = false;
        _this.unrealIsLoading = false;
        _this.removeResponseEventListener(_this.unrealEventName);
        window.removeEventListener('message', this.myWindowMessageListenerFunction);

        if (_this.UIState === UnrealUIStatesEnum.PROJECT && _this.selectedProject) {
          await _this.projectService.getProjectById(null, this.defaultEventData?.currentUser);
        } else if (_this.UIState === UnrealUIStatesEnum.FREE_ROAM && _this.selectedShip) {
          await _this.shipService.getShipById(null, this.defaultEventData?.currentUser);
        } else if (_this.isVehicleCalibrationScene) {
          await _this.vehicleService.getVehicleById(null, _this.defaultEventData?.currentUser);
        }
      } catch (ex) {
        _this.errorService.handleError(
          `Error destroying unreal-viewer.component and clearing data: ${ex.message}`
        );
      } finally {
        _this.settingsService.setIsLoading(false);
        resolve('closed the unreal-viewer.component');
      }
    });
  }

  addResponseEventListener(name, listener) {
    this.responseEventListeners.set(name, listener);
  }

  getVectorValue(variableValue) {
    let returnValue = this.defaultVector;

    if (variableValue && Array.isArray(variableValue) && variableValue.length == 3) {
      returnValue = variableValue;
    }

    return returnValue;
  }

  async loadScene(dataForEvent) {
    const _this = this;
    const sceneData = JSON.parse(JSON.stringify(dataForEvent));
    const errors = [];

    return new Promise((resolve, reject) => {
      try {
        _this.settingsService.setIsLoading(true);

        if (!this.unrealIsLoading) {
          this.unrealIsLoading = true;

          let descriptorValues = {
            DynamicAssetName: _this.DynamicAssetName || '',
            ImagePaths: _this.ImagePaths || {},
            LoadScene: _this.LoadScene,
            PanoOnly: _this.PanoOnly,
            ProjectFileName: _this.ProjectFileName ? _this.ProjectFileName.replace('.json', '') : '',
            UIState: _this.UIState || '',
            VideoPaths: _this.VideoPaths || []
          };

          const descriptor = removeUndefinedAndEmptyStringProps(descriptorValues);

          if (_this.isDebugging) {
            _this.logService.logInfo(`calling emitUIInteraction with scene info ${JSON.stringify(descriptor)}`);
          }

          _this.sendInputData(descriptor);
          _this.unrealIsReady = true;
        };
      } catch (ex) {
        const errMessage2 = _this.errorService.handleError(
          `Error loading initial scene for ${environment.unreal.viewerName}: ${ex.message}`
        );
        _this.unrealIsReady = false;
        reject(errMessage2);
      } finally {
        _this.unrealIsLoading = false;
        _this.settingsService.setIsLoading(false);
        resolve('Loaded scene')
      }
    });
  }

  getVideosForUnreal(videos: Video[]): Video[] {
    if (!Array.isArray(videos)) {
      videos = [];
    }

    const returnValue = videos.filter((v) => {
      return v.url.endsWith('.mp4');
    });

    return returnValue;
  }

  async myWindowMessageListenerFunction(event) {
    //Use the domain(hostname) from the matchmakerUrl to see if the event should be passed on
    //The matchmaker spins up a new instance with the same domain but different subdomain - jane mccleary 10/13/2023
    const baseAppDomain = getBaseDomainFromUrl(environment.baseAppURL);
    const eventOriginDomain = getBaseDomainFromUrl(event.origin);
    const unrealMatchmakerDomain = getBaseDomainFromUrl(environment.unreal.matchmakerUrl);
    const eventData = JSON.parse(JSON.stringify(event.data));
    const isRunningLocally = getIsRunningLocally(baseAppDomain);

    const domainData = {
      baseAppDomain: baseAppDomain,
      eventOriginDomain: eventOriginDomain,
      unrealMatchmakerDomain: unrealMatchmakerDomain,
      isRunningLocally: isRunningLocally,
      unrealIsReady: this.unrealIsReady,
      unrealIsLoading: this.unrealIsLoading,
      eventDataStatus: eventData.status,
      unrealReadyMessage: this.unrealReadyMessage.status
    };

    this.logService.logInfo(`unreal-viewer.component domain info: ${JSON.stringify(domainData)}`);

    if (eventOriginDomain == unrealMatchmakerDomain || isRunningLocally) {
      try {
        if (!this.unrealIsReady && !this.unrealIsLoading && eventData.status === this.unrealReadyMessage.status) {

          if (this.settingsService.getIsDebugging()) {
            this.logService.logInfo(`calling unreal-viewer.component.loadScene`);
          }

          await this.loadScene(eventData);
        } else if (this.unrealIsReady) {
          if (this.settingsService.getIsDebugging()) {
            this.logService.logInfo(`calling unreal-viewer.component.myHandleUnrealResponseFunction`);
          }

          const dataToProcess = {
            ...this.defaultEventData,
            data: JSON.parse(JSON.stringify(event.data)),
            processScreenshots: this.myProcessScreenshotsFunction,
            processVideos: this.myProcessVideosFunction,
            selectedProject: this.selectedProject,
            selectedProjectGeotags: this.geotagService.getCurrentProjectGeotags(),
            selectedProjectImages: this.imageDocService.getCurrentProjectImages(),
            selectedProjectMeasurements: this.measurementService.getCurrentProjectMeasurements(),
            selectedProjectVideos: this.videoService.getCurrentProjectVideos(),
            selectedShip: this.selectedShip,
            selectedShipImages: this.imageDocService.getCurrentShipImages(),
            selectedShipVideos: this.videoService.getCurrentShipVideos(),
            selectedVehicle: this.selectedVehicle,
            selectedVehicleImages: this.imageDocService.getCurrentVehicleImages(),
            selectedVehicleMod: this.selectedVehicleMod,
            selectedVehicleVideos: this.videoService.getCurrentVehicleVideos(),
            LoadScene: this.LoadScene,
          };

          this.myHandleUnrealResponseFunction(dataToProcess);
        }
      } catch (ex) {
        const errMessage = this.errorService.handleError(
          `Error processing message (${JSON.stringify(event.data)}) in unreal-viewer.component from ${environment.unreal.programName}: ${ex.message}`
        );
      }
    } else {
      this.errorService.handleError(`Received message from invalid origin ${event.origin} in unreal-viewer.component, ignoring.  Event domain is ${eventOriginDomain}, ${environment.unreal.programName} matchmaker domain is ${unrealMatchmakerDomain}`);
      return;
    }
  }

  async myHandleUnrealResponseFunction(sceneData) {
    const _this = this;
    let results;

    return new Promise(async (resolve, reject) => {
      try {
        const errors = [];

        //remove await calls so viewer continues functioning, API will log errors - jane 8/28/2024
        const changes = {
          editorId: sceneData.currentUser._id,
          jsonConfiguration: null,
          latestPathTraveledId: null,
          modState: null
        };
        const promises = [];
        let madeProjectChanges = false;

        if (typeof sceneData.data === 'string' && sceneData.data !== '') {
          try {
            sceneData.data = JSON.parse(sceneData.data);
          } catch (ex) {
            const errMessage = sceneData.errorService.handleError(
              `Error processing data ${sceneData.data} received from ${environment.unreal.viewerName}: ${ex.message}`
            );
            errors.push(errMessage);
            sceneData.data = {};
          }
        } else {
          sceneData.data = {};
        }

        //update project or vehicle as needed.  Vehicles are modified in the calibration scene
        if (sceneData.LoadScene == UnrealScenesEnum.CALIBRATION) {
          if (sceneData.selectedVehicleMod) {
            changes.jsonConfiguration = {
              BLContact: sceneData.data?.BLContact ? sceneData.data?.BLContact : sceneData.selectedVehicleMod.jsonConfiguration.BLContact,
              BRContact: sceneData.data?.BRContact ? sceneData.data?.BRContact : sceneData.selectedVehicleMod.jsonConfiguration.BRContact,
              FLContact: sceneData.data?.FLContact ? sceneData.data?.FLContact : sceneData.selectedVehicleMod.jsonConfiguration.FLContact,
              FRContact: sceneData.data?.FRContact ? sceneData.data?.FRContact : sceneData.selectedVehicleMod.jsonConfiguration.FRContact,
              RotCent: sceneData.data?.RotCent
                ? sceneData.data?.RotCent
                : sceneData.selectedVehicleMod.jsonConfiguration.RotCent,
              DynamicAssetModelOffset: sceneData.data?.DynamicAssetModelOffset
                ? sceneData.data?.DynamicAssetModelOffset
                : sceneData.selectedVehicleMod.jsonConfiguration.DynamicAssetModelOffset,
              DynamicAssetModelRotation: sceneData.data?.DynamicAssetModelRotation
                ? sceneData.data?.DynamicAssetModelRotation
                : sceneData.selectedVehicleMod.jsonConfiguration.DynamicAssetModelRotation,
              WheelClearance: !isNaN(sceneData.data?.WheelClearance)
                ? sceneData.data?.WheelClearance
                : sceneData.selectedVehicleMod.jsonConfiguration.WheelClearance
            };
            changes.modState = ModStatesEnum.VALID;

            promises.push(sceneData.modService.saveMod(
              sceneData.selectedVehicleMod._id,
              sceneData.selectedVehicleMod.parent.collection,
              sceneData.selectedVehicleMod.parent._id,
              changes,
              sceneData.currentUser
            ));
          } //have vehicle mod

          //Screenshots are not passed back into the viewer so just check for new 
          if (sceneData.data?.Screenshots && sceneData.data?.Screenshots.NumShots > 0 && sceneData.selectedVehicle && sceneData.processScreenshots) {
            promises.push(sceneData.processScreenshots(sceneData.data?.Screenshots, sceneData.LoadScene, sceneData.UIState,
              sceneData.selectedProject, sceneData.selectedShip, sceneData.selectedVehicle, sceneData.selectedProjectImages,
              sceneData.selectedShipImages, sceneData.selectedVehicleImages, sceneData.currentUser));
          } //have Screenshots

          //Videos are not passed back to the viewer so just check for new
          if (sceneData.data?.Videos && sceneData.data?.Videos.NumVids > 0 && sceneData.processVideos) {
            promises.push(sceneData.processVideos(sceneData.data?.Videos, sceneData.LoadScene, sceneData.UIState,
              sceneData.selectedProject, sceneData.selectedShip, sceneData.selectedVehicle, sceneData.selectedProjectVideos,
              sceneData.selectedShipVideos, sceneData.selectedVehicleVideos, sceneData.currentUser));
          } //have vehicle videos
        } else if (sceneData.LoadScene == UnrealScenesEnum.VIEWER) {
          if (sceneData.selectedProject) {
            const existingAreaMeasurementNames = [];
            const existingGeotagNames = Object.keys(sceneData.selectedProject.Geotags || {});
            const existingKeyframeNames = Object.keys(sceneData.selectedProject.Keyframes || {});
            const existingMeasurementNames = [];

            changes.jsonConfiguration = {
              NegativeEncroachment:
                sceneData && sceneData.data?.NegativeEncroachment
                  ? sceneData.data?.NegativeEncroachment
                  : sceneData.selectedProject.jsonConfiguration.NegativeEncroachment,
              PositiveEncroachment:
                sceneData && sceneData.data?.PositiveEncroachment
                  ? sceneData.data?.PositiveEncroachment
                  : sceneData.selectedProject.jsonConfiguration.PositiveEncroachment,
              DynamicAssetLocation:
                sceneData && sceneData.data?.DynamicAssetLocation
                  ? sceneData.data?.DynamicAssetLocation
                  : sceneData.selectedProject.jsonConfiguration.DynamicAssetLocation,
              DynamicAssetRotation:
                sceneData && sceneData.data?.DynamicAssetRotation
                  ? sceneData.data?.DynamicAssetRotation
                  : sceneData.selectedProject.jsonConfiguration.DynamicAssetRotation,
            };

            sceneData.latestPathTraveledId = sceneData.selectedProject.latestPathTraveledId;

            if (
              sceneData && sceneData.data?.NegativeEncroachment &&
              !isEqual(
                sceneData.data?.NegativeEncroachment,
                sceneData.selectedProject.jsonConfiguration.NegativeEncroachment
              )
            ) {
              madeProjectChanges = true;
            }

            if (
              sceneData && sceneData.data?.PositiveEncroachment &&
              !isEqual(
                sceneData.data?.PositiveEncroachment,
                sceneData.selectedProject.jsonConfiguration.PositiveEncroachment
              )
            ) {
              madeProjectChanges = true;
            }

            if (
              sceneData && sceneData.data?.DynamicAssetLocation &&
              !isEqual(sceneData.data?.DynamicAssetLocation, sceneData.selectedProject.jsonConfiguration.DynamicAssetLocation)
            ) {
              madeProjectChanges = true;
            }

            if (
              sceneData && sceneData.data?.DynamicAssetRotation &&
              !isEqual(sceneData.data?.DynamicAssetRotation, sceneData.selectedProject.jsonConfiguration.DynamicAssetRotation)
            ) {
              madeProjectChanges = true;
            }

            if (sceneData.data?.Keyframes && sceneData.data.Keyframes.NumKeyframes > 0 && !sceneData.selectedProject.Keyframes) {
              madeProjectChanges = true;
            } else if (sceneData && !sceneData.data?.Keyframes && sceneData.selectedProject.Keyframes) {
              madeProjectChanges = true;
            }

            if (sceneData.data?.Areas) {
              const areaMeasurementsMatch = isEqual(sceneData.selectedProject.Areas, sceneData.data?.Areas);
              if (!sceneData.selectedProjectAreaMeasurements) {
                sceneData.selectedProjectAreaMeasurements = [];
              }

              for (let x = 0; x < sceneData.selectedProjectAreaMeasurements.length; x++) {
                existingAreaMeasurementNames.push(sceneData.selectedProjectAreaMeasurements[x].name);
              }

              if (!areaMeasurementsMatch) {
                const sceneAreaMeasurementKeys = Object.keys(sceneData.data?.Areas);
                const filteredSceneAreaMeasurementKeys = sceneAreaMeasurementKeys.filter((k) => k != 'NumAreas');
                const newSceneAreaMeasurementKeys = difference(
                  filteredSceneAreaMeasurementKeys,
                  existingAreaMeasurementNames
                );
                const removedSceneAreaMeasurementKeys = difference(
                  existingAreaMeasurementNames,
                  sceneAreaMeasurementKeys
                );

                if (newSceneAreaMeasurementKeys.length > 0) {
                  for (let x = 0; x < newSceneAreaMeasurementKeys.length; x++) {
                    const newKey = newSceneAreaMeasurementKeys[x];

                    const newAreaMeasurement: AreaMeasurement = {
                      _id: new ObjectID().toString(),
                      creatorId: sceneData.currentUser._id,
                      editorId: sceneData.currentUser._id,
                      name: newKey,
                      points: [],
                      projectId: sceneData.selectedProject._id,
                      NumPts: sceneData.data?.Areas[newKey].NumPts,
                      WorldLocation: sceneData.data?.Areas[newKey].WorldLocation,
                    };

                    const areaMeasurementKeys = Object.keys(sceneData.data?.Areas[newKey]);
                    const areaKeysToExclude = ['NumPts', 'WorldLocation'];

                    for (let p = 0; p < areaMeasurementKeys.length; p++) {
                      const areaKey = areaMeasurementKeys[p];
                      const idx = areaKeysToExclude.indexOf(areaKey);

                      if (idx === -1) {
                        const point = {};
                        point[areaKey] = sceneData.data?.Areas[newKey][areaKey];
                        newAreaMeasurement.points.push(point);
                      }
                    }

                    try {
                      promises.push(sceneData.projectService.addAreaMeasurement(
                        newAreaMeasurement,
                        sceneData.selectedProject,
                        sceneData.currentUser
                      ));

                      madeProjectChanges = true;
                      sceneData.selectedProjectAreaMeasurements.push(newAreaMeasurement);
                    } catch (areaMeasurementEx) {
                      const errMessage = sceneData.errorService.handleError(
                        `Error creating new area measurement ${JSON.stringify(newAreaMeasurement)} for projectId: ${sceneData.selectedProject._id
                        }: ${areaMeasurementEx.message}`
                      );
                      errors.push(errMessage);
                    }
                  } //loop thru new area measurements
                } //have new area measurements

                if (removedSceneAreaMeasurementKeys.length > 0) {
                  for (let x = 0; x < removedSceneAreaMeasurementKeys.length; x++) {
                    const removedKey = removedSceneAreaMeasurementKeys[x];
                    const matching = sceneData.selectedProjectAreaMeasurements.find(
                      (m: AreaMeasurement) => m.name === removedKey
                    );

                    if (matching) {
                      try {
                        promises.push(sceneData.areaMeasurementService.deleteAreaMeasurement(
                          matching._id,
                          sceneData.selectedProject._id,
                          sceneData.currentUser
                        ));

                        madeProjectChanges = true;
                        const cleanedArray = sceneData.selectedProjectAreaMeasurements.filter(
                          (m: AreaMeasurement) => m.name !== removedKey
                        );
                        sceneData.selectedProjectAreaMeasurements = JSON.parse(JSON.stringify(cleanedArray));
                      } catch (areaMeasurementEx) {
                        const errMessage = sceneData.errorService.handleError(
                          `Error removing areaMeasurementId ${matching._id} for projectId: ${sceneData.selectedProject._id}: ${areaMeasurementEx.message}`
                        );
                        errors.push(errMessage);
                      }
                    } //found matching area measurement
                  } //loop thru removed area measurements
                } //have removed area measurements

                //update the sceneData.selectedProject data now that we have synced
                sceneData.selectedProject.Areas = JSON.parse(JSON.stringify(sceneData.data?.Areas));
              } //area measurements don't match
            } //have Areas

            if (sceneData.data?.Geotags) {
              const geotagsMatch = isEqual(sceneData.selectedProject.Geotags, sceneData.data?.Geotags);
              if (!sceneData.selectedProjectGeotags) {
                sceneData.selectedProjectGeotags = [];
              }

              for (let x = 0; x < sceneData.selectedProjectGeotags.length; x++) {
                const idx = existingGeotagNames.indexOf(sceneData.selectedProjectGeotags[x].name);
                if (idx === -1) {
                  existingGeotagNames.push(sceneData.selectedProjectGeotags[x].name);
                }
              }

              if (!geotagsMatch) {
                const sceneGeotagKeys = Object.keys(sceneData.data?.Geotags);
                const filteredSceneGeotagKeys = sceneGeotagKeys.filter((k) => k != 'NumGeotags');
                const newSceneGeotagKeys = difference(filteredSceneGeotagKeys, existingGeotagNames);
                const removedSceneGeotagKeys = difference(existingGeotagNames, sceneGeotagKeys);

                if (newSceneGeotagKeys.length > 0) {
                  for (let x = 0; x < newSceneGeotagKeys.length; x++) {
                    const newKey = newSceneGeotagKeys[x];
                    let okToAdd = true;
                    let matching;

                    if (sceneData.data?.Geotags[newKey].ContentType === sceneData.GeotagContentTypesEnum.IMAGE) {
                      matching = sceneData.selectedProjectImages.find((i) => i.displayUrl === sceneData.data?.Geotags[newKey].ContentURL);
                      if (!matching) {
                        okToAdd = false;
                        const errMessage = sceneData.errorService.handleError(
                          `Error creating new geotag for ${JSON.stringify(sceneData.data?.Geotags[newKey])} for projectId: ${sceneData.selectedProject._id
                          }: The ContentURL is not a valid project image.`
                        );
                        errors.push(errMessage);
                      }
                    } else if (sceneData.data?.Geotags[newKey].ContentType === sceneData.GeotagContentTypesEnum.VIDEO) {
                      matching = sceneData.selectedProjectVideos.find((i) => i.displayUrl === sceneData.data?.Geotags[newKey].ContentURL);
                      if (!matching) {
                        okToAdd = false;
                        const errMessage = sceneData.errorService.handleError(
                          `Error creating new geotag for ${JSON.stringify(sceneData.data?.Geotags[newKey])} for projectId: ${sceneData.selectedProject._id
                          }: The ContentURL is not a valid project video.`
                        );
                        errors.push(errMessage);
                      }
                    } else if (sceneData.data?.Geotags[newKey].ContentType === sceneData.GeotagContentTypesEnum.TEXT && !sceneData.data?.Geotags[newKey].ContentText) {
                      okToAdd = false;
                      const errMessage = sceneData.errorService.handleError(
                        `Error creating new geotag for ${JSON.stringify(sceneData.data?.Geotags[newKey])} for projectId: ${sceneData.selectedProject._id
                        }: No text was provided for the geotag.`
                      );
                      errors.push(errMessage);
                    }

                    if (okToAdd) {
                      const newGeotag: Geotag = {
                        _id: new ObjectID().toString(),
                        creatorId: sceneData.currentUser._id,
                        editorId: sceneData.currentUser._id,
                        name: newKey,
                        projectId: sceneData.selectedProject._id,
                        url: matching ? matching.url : '',
                        Billboarding: sceneData.data?.Geotags[newKey].ThreeDimensional ? sceneData.data?.Geotags[newKey].Billboarding : false,
                        ButtonLocation: sceneData.data?.Geotags[newKey].ButtonLocation,
                        CKColor: sceneData.data?.Geotags[newKey].IsCK ? sceneData.data?.Geotags[newKey].CKColor : sceneData.defaultCKColor,
                        ContentText: sceneData.data?.Geotags[newKey].ContentType === sceneData.GeotagContentTypesEnum.TEXT ? sceneData.data?.Geotags[newKey].ContentText : '',
                        ContentTitle: sceneData.data?.Geotags[newKey].ContentTitle || matching?.name || '',
                        ContentType: sceneData.data?.Geotags[newKey].ContentType,
                        ContentURL: sceneData.data?.Geotags[newKey].ContentType === sceneData.GeotagContentTypesEnum.IMAGE || sceneData.data?.Geotags[newKey].ContentType === sceneData.GeotagContentTypesEnum.VIDEO ?
                          sceneData.data?.Geotags[newKey].ContentURL : '',
                        IsCK: sceneData.data?.Geotags[newKey].IsCK,
                        ThreeDimensional: sceneData.data?.Geotags[newKey].ThreeDimensional,
                        TDimLocation: sceneData.data?.Geotags[newKey].ThreeDimensional ? sceneData.data?.Geotags[newKey].TDimLocation : sceneData.default3Dvector,
                        TDimRotation: sceneData.data?.Geotags[newKey].ThreeDimensional ? sceneData.data?.Geotags[newKey].TDimRotation : sceneData.default3Dvector,
                        TDimScale: sceneData.data?.Geotags[newKey].ThreeDimensional ? sceneData.data?.Geotags[newKey].TDimScale : sceneData.default3Dvector,
                        WorldLocation: sceneData.data?.Geotags[newKey].WorldLocation,
                      };

                      if (sceneData.data?.Geotags[newKey].ContentType === sceneData.GeotagContentTypesEnum.IMAGE) {
                        newGeotag.imageDocId = matching._id;
                      } else if (sceneData.data?.Geotags[newKey].ContentType === sceneData.GeotagContentTypesEnum.VIDEO) {
                        newGeotag.videoId = matching._id;
                      }

                      promises.push(sceneData.projectService.addGeotag(
                        newGeotag,
                        sceneData.selectedProject,
                        sceneData.currentUser
                      ));
                      madeProjectChanges = true;
                      sceneData.selectedProjectGeotags.push(newGeotag);
                    }
                  } //loop thru new geotags
                } //have new geotags

                if (removedSceneGeotagKeys.length > 0) {
                  for (let x = 0; x < removedSceneGeotagKeys.length; x++) {
                    const removedKey = removedSceneGeotagKeys[x];
                    const matching = sceneData.selectedProjectGeotags.find(
                      (m: Geotag) => m.name === removedKey
                    );

                    if (matching) {
                      promises.push(sceneData.geotagService.deleteGeotag(
                        matching._id,
                        sceneData.selectedProject._id,
                        sceneData.currentUser
                      ));

                      const cleanedArray = sceneData.selectedProjectGeotags.filter(
                        (m: Geotag) => m.name !== removedKey
                      );
                      sceneData.selectedProjectGeotags = JSON.parse(JSON.stringify(cleanedArray));

                    } //found matching geotag
                  } //loop thru removed geotags
                } //have removed geotags

                //update the sceneData.selectedProject data now that we have synced
                sceneData.selectedProject.Geotags = JSON.parse(JSON.stringify(sceneData.data?.Geotags));
              } //geotags don't match
            } //have Geotags

            if (sceneData.data?.Measures) {
              const measurementsMatch = isEqual(sceneData.selectedProject.Measures, sceneData.data?.Measures);
              if (!sceneData.selectedProjectMeasurements) {
                sceneData.selectedProjectMeasurements = [];
              }

              for (let x = 0; x < sceneData.selectedProjectMeasurements.length; x++) {
                existingMeasurementNames.push(sceneData.selectedProjectMeasurements[x].name);
              }

              if (!measurementsMatch) {
                const sceneMeasurementKeys = Object.keys(sceneData.data?.Measures);
                const filteredSceneMeasurementKeys = sceneMeasurementKeys.filter((k) => k != 'NumMeasures');
                const newSceneMeasurementKeys = difference(filteredSceneMeasurementKeys, existingMeasurementNames);
                const removedSceneMeasurementKeys = difference(existingMeasurementNames, sceneMeasurementKeys);

                if (newSceneMeasurementKeys.length > 0) {
                  for (let x = 0; x < newSceneMeasurementKeys.length; x++) {
                    const newKey = newSceneMeasurementKeys[x];

                    const newMeasurement: Measurement = {
                      _id: new ObjectID().toString(),
                      creatorId: sceneData.currentUser._id,
                      editorId: sceneData.currentUser._id,
                      name: newKey,
                      projectId: sceneData.selectedProject._id,
                      PtA: sceneData.data?.Measures[newKey].PtA,
                      PtB: sceneData.data?.Measures[newKey].PtB,
                    };

                    promises.push(sceneData.projectService.addMeasurement(
                      newMeasurement,
                      sceneData.selectedProject,
                      sceneData.currentUser
                    ));

                    madeProjectChanges = true;
                    sceneData.selectedProjectMeasurements.push(newMeasurement);
                  } //loop thru new measurements
                } //have new measurements

                if (removedSceneMeasurementKeys.length > 0) {
                  for (let x = 0; x < removedSceneMeasurementKeys.length; x++) {
                    const removedKey = removedSceneMeasurementKeys[x];
                    const matching = sceneData.selectedProjectMeasurements.find(
                      (m: Measurement) => m.name === removedKey
                    );

                    if (matching) {
                      promises.push(sceneData.measurementService.deleteMeasurement(
                        matching._id,
                        sceneData.selectedProject._id,
                        sceneData.currentUser
                      ));

                      madeProjectChanges = true;
                      const cleanedArray = sceneData.selectedProjectMeasurements.filter(
                        (m: Measurement) => m.name !== removedKey
                      );
                      sceneData.selectedProjectMeasurements = JSON.parse(JSON.stringify(cleanedArray));
                    } //found matching measurement
                  } //loop thru removed measurements
                } //have removed measurements

                //update the sceneData.selectedProject data now that we have synced
                sceneData.selectedProject.Measures = JSON.parse(JSON.stringify(sceneData.data?.Measures));
              } //measurements don't match
            } //have Measures

            //Screenshots are not passed back into the viewer so just check for new
            if (sceneData.data?.Screenshots && sceneData.data?.Screenshots.NumShots > 0 && sceneData.processScreenshots) {
              promises.push(sceneData.processScreenshots(sceneData.data?.Screenshots, sceneData.LoadScene, sceneData.UIState,
                sceneData.selectedProject, sceneData.selectedShip, sceneData.selectedVehicle, sceneData.selectedProjectImages,
                sceneData.selectedShipImages, sceneData.selectedVehicleImages, sceneData.currentUser));
            } //have project Screenshots

            //Videos are not passed back to the viewer so just check for new
            if (sceneData.data?.Videos && sceneData.data?.Videos.NumVids > 0 && sceneData.processVideos) {
              promises.push(sceneData.processVideos(sceneData.data?.Videos, sceneData.LoadScene, sceneData.UIState,
                sceneData.selectedProject, sceneData.selectedShip, sceneData.selectedVehicle, sceneData.selectedProjectVideos,
                sceneData.selectedShipVideos, sceneData.selectedVehicleVideos, sceneData.currentUser));
            } //have project videos

            if (sceneData.data?.Keyframes && sceneData.data?.Keyframes.NumKeyframes > 0) {
              let pathTraveledChanged = false;

              if (
                !sceneData.selectedProject.Keyframes || (
                  typeof sceneData.selectedProject.Keyframes === 'object' &&
                  sceneData.selectedProject.Keyframes['NumKeyframes'] &&
                  sceneData.selectedProject.Keyframes['NumKeyframes'] != sceneData.data?.Keyframes.NumKeyframes
                )
              ) {
                pathTraveledChanged = true;
              } else {
                const filteredExistingKeyframeKeys = existingKeyframeNames.filter((k) => k !== 'NumKeyframes');
                const sceneKeyframeKeys = Object.keys(sceneData.data?.Keyframes);
                const filteredSceneKeyframeKeys = sceneKeyframeKeys.filter((k) => k != 'NumKeyframes');
                const keyframesMatch = isEqual(filteredExistingKeyframeKeys, filteredSceneKeyframeKeys);
                const newSceneKeyframeKeys = difference(filteredSceneKeyframeKeys, filteredExistingKeyframeKeys);
                const removedSceneKeyframeKeys = difference(filteredExistingKeyframeKeys, filteredSceneKeyframeKeys);

                if (!keyframesMatch || newSceneKeyframeKeys.length > 0 || removedSceneKeyframeKeys.length > 0) {
                  pathTraveledChanged = true;
                }
              }

              if (pathTraveledChanged) {
                try {
                  const newPathTraveledId = new ObjectID().toString();
                  changes.latestPathTraveledId = newPathTraveledId;
                  const newKeyframes = [];
                  const sceneKeyframeKeys = Object.keys(sceneData.data?.Keyframes);
                  const filteredSceneKeyframeKeys = sceneKeyframeKeys.filter((k) => k != 'NumKeyframes');

                  for (let x = 0; x < filteredSceneKeyframeKeys.length; x++) {
                    const newKey = filteredSceneKeyframeKeys[x];

                    const newKeyframe: Keyframe = {
                      _id: new ObjectID().toString(),
                      creatorId: sceneData.currentUser._id,
                      editorId: sceneData.currentUser._id,
                      name: newKey,
                      projectId: sceneData.selectedProject._id,
                      pathTraveledId: newPathTraveledId,
                      EncMaxPt: sceneData.data?.Keyframes[newKey].EncMaxPt || [],
                      EncMinPt: sceneData.data?.Keyframes[newKey].EncMinPt || [],
                      HardColLoc: sceneData.data?.Keyframes[newKey].HardColLoc || [],
                      HardColRot: sceneData.data?.Keyframes[newKey].HardColRot || [],
                      KeyframeColStatus: sceneData.data?.Keyframes[newKey].KeyframeColStatus,
                      Location: sceneData.data?.Keyframes[newKey].Location,
                      MinDistModelLocationArray: sceneData.data?.Keyframes[newKey].MinDistModelLocationArray,
                      MinDistPointLocationArray: sceneData.data?.Keyframes[newKey].MinDistPointLocationArray,
                      MinDistanceStatus: sceneData.data?.Keyframes[newKey].MinDistanceStatus,
                      NumSegments: sceneData.data?.Keyframes[newKey].NumSegments,
                      Rotation: sceneData.data?.Keyframes[newKey].Rotation,
                      VoxScale: sceneData.data?.Keyframes[newKey].VoxScale || [],
                    };

                    newKeyframes.push(newKeyframe);
                  } //loop thru the keyframes

                  const encroachmentViolations = newKeyframes.filter(
                    (keyframe) =>
                      keyframe.KeyframeColStatus === KeyframeCollisionStatusEnum.ENCROACHMENT_VIOLATION
                  );
                  const hardCollisions = newKeyframes.filter(
                    (keyframe) => keyframe.KeyframeColStatus === KeyframeCollisionStatusEnum.HARD_COLLISION
                  );
                  const hardCollisionKeyframeIds =
                    hardCollisions.length > 0 ? hardCollisions.map((hardCollision) => hardCollision._id) : [];
                  const hardCollisionKeyframeNames =
                    hardCollisions.length > 0 ? hardCollisions.map((hardCollision) => hardCollision.name) : [];

                  const encroachmentViolation = {
                    _id: KeyframeCollisionStatusEnum.ENCROACHMENT_VIOLATION,
                    numberOfCollisions: encroachmentViolations.length,
                  };

                  const hardCollision = {
                    _id: KeyframeCollisionStatusEnum.HARD_COLLISION,
                    numberOfCollisions: hardCollisions.length,
                  };

                  const newPathTraveled: PathTraveled = {
                    _id: newPathTraveledId,
                    collisionSummary: {
                      collisionsByType: [encroachmentViolation, hardCollision],
                      hardCollisionKeyframeIds: hardCollisionKeyframeIds,
                      hardCollisionKeyframeNames: hardCollisionKeyframeNames,
                    },
                    creatorId: sceneData.currentUser._id,
                    editorId: sceneData.currentUser._id,
                    numberOfKeyframes: sceneData.data?.Keyframes.NumKeyframes,
                    projectId: sceneData.selectedProject._id,
                    projectPathTraveledNumber: 0, //will be automatically set on save in the API based on the path count
                  };

                  promises.push(sceneData.projectService.addNewPathTraveled(
                    sceneData.selectedProject,
                    newPathTraveled,
                    newKeyframes,
                    sceneData.currentUser
                  ));

                  //update the sceneData.selectedProject data now that we have synced
                  sceneData.selectedProject.latestPathTraveledId = newPathTraveledId;
                  sceneData.selectedProject.Keyframes = JSON.parse(JSON.stringify(sceneData.data?.Keyframes));
                } catch (pathTraveledEx) {
                  const errMessage = sceneData.errorService.handleError(
                    `Error adding new path traveled to projectId ${sceneData.selectedProject._id}: ${pathTraveledEx.message}`
                  );
                  errors.push(errMessage);
                }
              } //pathTraveled changed
            } //have Keyframes

          } else if (sceneData.selectedShip) {
            //Screenshots are not passed back into the viewer so just check for new
            if (sceneData.data?.Screenshots && sceneData.data?.Screenshots.NumShots > 0 && sceneData.processScreenshots) {
              promises.push(sceneData.processScreenshots(sceneData.data?.Screenshots, sceneData.LoadScene, sceneData.UIState,
                sceneData.selectedProject, sceneData.selectedShip, sceneData.selectedVehicle, sceneData.selectedProjectImages,
                sceneData.selectedShipImages, sceneData.selectedVehicleImages, sceneData.currentUser));
            } //have ship Screenshots

            //Videos are not passed back to the viewer so just check for new
            if (sceneData.data?.Videos && sceneData.data?.Videos.NumVids > 0 && sceneData.processVideos) {
              promises.push(sceneData.processVideos(sceneData.data?.Videos, sceneData.LoadScene, sceneData.UIState,
                sceneData.selectedProject, sceneData.selectedShip, sceneData.selectedVehicle, sceneData.selectedProjectVideos,
                sceneData.selectedShipVideos, sceneData.selectedVehicleVideos, sceneData.currentUser));
            } //have ship videos
          } //have ship
        } //project or free-roam viewer

        if (errors.length > 0) {
          if (_this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              'Error(s) Occurred',
              `There were one or more errors processing the updates you made in the ${environment.unreal.programName}.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        }

        //return to viewer while saves are processing - jane 8/29/2024
        resolve(results);

        if (promises.length > 0) {
          Promise.allSettled(promises)
            .then((results) => {
              if (madeProjectChanges) {
                sceneData.projectService.saveProject(sceneData.selectedProject._id, changes, _this.defaultEventData?.currentUser)
                  .then((projectResult) => {
                    if (sceneData.isDebugging) {
                      sceneData.logService.logInfo(
                        `results of updating projectId ${sceneData.selectedProject._id} in ${environment.unreal.viewerName
                        }: ${JSON.stringify(projectResult)}`
                      );
                    }
                  })
                  .catch((projectEx) => {
                    const errMessage = sceneData.errorService.handleError(
                      `Error saving ${environment.unreal.viewerName} changes for projectId ${sceneData.selectedProject._id}: ${projectEx.message}`
                    );
                    errors.push(errMessage);
                  });
              }
            });
        }

      } catch (ex) {
        const errMessage = _this.errorService.handleError(
          `Error updating database with scene changes: ${ex.message}`
        );
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error`,
            `There was an error updating the database with the changes.  Please email ${environment.techSupportEmail}`,
            'error'
          );
        }

        reject(errMessage);
      }
    });
  }


  async myProcessScreenshotsFunction(screenshotsFromSceneData, unrealScene, unrealUIState, project, ship, vehicle, projectImages, shipImages, vehicleImages, user): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      let images;

      if (screenshotsFromSceneData && screenshotsFromSceneData.NumShots > 0 && user) {
        const currentUser = user
        //continue with viewer, don't show await and show loading - jane 8/28/2024
        const sceneScreenshotKeys = Object.keys(screenshotsFromSceneData);
        const filteredSceneScreenshotKeys = sceneScreenshotKeys.filter((k) => k != 'NumShots');
        let dbCollectionToUse, fileObjectTypeToUse, objectToUse, objectServiceToUse, reportSectionToUse, tagNameToUse;
        let validData = true;

        switch (unrealScene) {
          case UnrealScenesEnum.CALIBRATION:
            if (vehicle) {
              dbCollectionToUse = DbCollectionsEnum.VEHICLES;
              fileObjectTypeToUse = FileObjectTypesEnum.VEHICLE;
              images = vehicleImages || [];
              objectToUse = vehicle;
              objectServiceToUse = _this.vehicleService;
              reportSectionToUse = ReportSectionIdsEnum.VEHICLE;
              tagNameToUse = vehicle.name;
            } else {
              validData = false;
              const errMessage = _this.errorService.handleError(`Unable to save vehicle snapshots, vehicle was not provided`);
            }
            break;
          case UnrealScenesEnum.VIEWER:
            //UIState is now the same for projects and ships, save based on the objects we have - jane 7/8/2024
            if (project) {
              dbCollectionToUse = DbCollectionsEnum.PROJECTS;
              fileObjectTypeToUse = FileObjectTypesEnum.PROJECT;
              images = projectImages || [];
              objectToUse = project;
              objectServiceToUse = _this.projectService;
              reportSectionToUse = ReportSectionIdsEnum.SNAPSHOT;
              tagNameToUse = project.name;
            } else if (ship) {
              dbCollectionToUse = DbCollectionsEnum.SHIPS;
              fileObjectTypeToUse = FileObjectTypesEnum.SHIP;
              images = shipImages || [];
              objectToUse = ship;
              objectServiceToUse = _this.shipService;
              reportSectionToUse = ReportSectionIdsEnum.SHIP;
              tagNameToUse = ship.name;
            } else {
              validData = false;
              const errMessage = _this.errorService.handleError(`Unable to save snapshots, project or ship was not provided`);
            }
            break;
        }

        if (validData && filteredSceneScreenshotKeys.length > 0) {
          for (let x = 0; x < filteredSceneScreenshotKeys.length; x++) {
            const screenshot = screenshotsFromSceneData[filteredSceneScreenshotKeys[x]];

            try {
              //each time a snapshot is taken the full list of snapshots taken is returned, only upload the new ones
              const fileName = screenshot.Path.substring(screenshot.Path.lastIndexOf('/') + 1);
              let matching;

              try {
                matching = images.find(
                  (screenshot: ImageDoc) => screenshot.name == fileName
                );
              } catch (matchingEx) {
                _this.errorService.handleError(`Error finding matching screenshot: ${matchingEx.message}`);
              }

              if (!matching) {
                const tags = [
                  TagFileTypesEnum.SNAPSHOT,
                  dbCollectionToUse,
                  tagNameToUse
                ];

                _this.fileService.uploadAppFileFromServer(
                  screenshot.Path,
                  fileObjectTypeToUse,
                  objectToUse._id,
                  TagFileTypesEnum.SNAPSHOT,
                  currentUser
                ).then((uploadResults) => {
                  const imageDoc: ImageDoc = {
                    _id: new ObjectID().toString(),
                    isMainImage: false,
                    isSnapshot: true,
                    name: filteredSceneScreenshotKeys[x],
                    parent: {
                      _id: objectToUse._id,
                      collection: dbCollectionToUse
                    },
                    reportSectionId: reportSectionToUse,
                    url: uploadResults.locationUrl,
                    thumbnailUrl: uploadResults.thumbnailUrl || uploadResults.locationUrl,
                    creatorId: currentUser._id,
                    tags: tags,
                  };

                  objectServiceToUse.addImage(imageDoc, objectToUse, currentUser)
                    .then((objectServiceResults) => {
                      if (_this.settingsService.getIsDebugging()) {
                        _this.logService.logInfo(
                          `Successfully added snapshotId ${imageDoc._id} to ${objectToUse._id}`
                        );
                      }

                      images.push(imageDoc);

                      if (screenshot.Note && screenshot.Note !== '') {
                        const note: Note = {
                          creatorId: currentUser._id,
                          editorId: currentUser._id,
                          imageId: imageDoc._id,
                          noteText: screenshot.Note,
                          parent: {
                            _id: objectToUse._id,
                            collection: dbCollectionToUse,
                          },
                          position: 0, //will be updated in the service
                          reportSectionId: reportSectionToUse,
                        };

                        objectServiceToUse.addNote(note, objectToUse, currentUser)
                          .then((noteResults) => {
                            if (_this.settingsService.getIsDebugging()) {
                              _this.logService.logInfo(
                                `Successfully added note ${JSON.stringify(note)} to snapshotId ${imageDoc._id
                                } for ${objectToUse._id}`
                              );
                            }
                          })
                          .catch((noteAddError) => {
                            _this.errorService.handleError(
                              `Error adding note ${JSON.stringify(note)} to snapshotId ${imageDoc._id
                              } for ${objectToUse._id}: ${noteAddError}`
                            );
                          });
                      } //have note for screenshot
                    })
                    .catch((imageAddError) => {
                      _this.errorService.handleError(`Error adding snapshotId ${imageDoc._id} to ${objectToUse._id}: ${imageAddError}`);
                    });
                })
                  .catch((uploadError) => {
                    _this.errorService.handleError(`Error uploading screenshots received from the ${environment.unreal.viewerName}: ${uploadError}`);
                  });

              } // is new screenshot to save
            } catch (ex) {
              const errMessage = _this.errorService.handleError(`Error processing screenshots received from the ${environment.unreal.viewerName}: ${ex}`);
            }
          } //loop thru screenshots
        } //have valid data and screnshots

        //_this.settingsService.setIsLoading(false);
      } //have screenshots to process

      resolve(images);
    });
  }

  async myProcessVideosFunction(videosFromSceneData, unrealScene, unrealUIState, project, ship, vehicle, projectVideos, shipVideos, vehicleVideos, user): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      let videos;

      //Videos are not passed back to the viewer so just check for new
      if (videosFromSceneData && videosFromSceneData.NumVids > 0 && user) {
        const currentUser = user;
        const sceneVideoKeys = Object.keys(videosFromSceneData);
        const filteredSceneVideoKeys = sceneVideoKeys.filter((k) => k != 'NumVids');
        let dbCollectionToUse, fileObjectTypeToUse, objectToUse, objectServiceToUse, reportSectionToUse, tagNameToUse;
        let validData = true;

        switch (unrealScene) {
          case UnrealScenesEnum.CALIBRATION:
            if (vehicle) {
              dbCollectionToUse = DbCollectionsEnum.VEHICLES;
              fileObjectTypeToUse = FileObjectTypesEnum.VEHICLE;
              videos = vehicleVideos || [];
              objectToUse = vehicle;
              objectServiceToUse = _this.vehicleService;
              reportSectionToUse = ReportSectionIdsEnum.VEHICLE;
              tagNameToUse = vehicle.name;
            } else {
              validData = false;
              const errMessage = _this.errorService.handleError(`Unable to save vehicle videos, vehicle was not provided`);
            }
            break;
          case UnrealScenesEnum.VIEWER:
            //UIState is now the same for projects and ships, save based on the objects we have - jane 7/8/2024
            if (project) {
              dbCollectionToUse = DbCollectionsEnum.PROJECTS;
              fileObjectTypeToUse = FileObjectTypesEnum.PROJECT;
              videos = projectVideos || [];
              objectToUse = project;
              objectServiceToUse = _this.projectService;
              reportSectionToUse = ReportSectionIdsEnum.SNAPSHOT;
              tagNameToUse = project.name;
            } else if (ship) {
              dbCollectionToUse = DbCollectionsEnum.SHIPS;
              fileObjectTypeToUse = FileObjectTypesEnum.SHIP;
              videos = shipVideos || [];
              objectToUse = ship;
              objectServiceToUse = _this.shipService;
              reportSectionToUse = ReportSectionIdsEnum.SHIP;
              tagNameToUse = ship.name;
            } else {
              validData = false;
              const errMessage = _this.errorService.handleError(`Unable to save videos, project or ship was not provided`);
            }
            break;
        }

        if (validData && filteredSceneVideoKeys.length > 0) {
          for (let x = 0; x < filteredSceneVideoKeys.length; x++) {
            const videoKey = filteredSceneVideoKeys[x];
            const videoPath = videosFromSceneData[videoKey];
            try {
              //each time a video is taken the full list of videos taken is returned, only upload the new ones
              const fileName = videoPath.substring(videoPath.lastIndexOf('/') + 1);
              let matching;

              try {
                matching = videos.find((video: Video) => video.name == fileName);
              } catch (matchingEx) {
                _this.errorService.handleError(`Error finding matching video: ${matchingEx.message}`);
              }

              if (!matching) {
                const tags = [
                  TagFileTypesEnum.VIDEO,
                  dbCollectionToUse,
                  objectToUse.name,
                ];

                _this.fileService
                  .uploadAppFileFromServer(
                    videoPath,
                    fileObjectTypeToUse,
                    objectToUse._id.toString(),
                    TagFileTypesEnum.VIDEO,
                    currentUser
                  )
                  .then((uploadResults) => {
                    const video: Video = {
                      _id: new ObjectID().toString(),
                      encodedUrl: uploadResults.encodedVideoUrl,
                      name: fileName,
                      parent: {
                        _id: objectToUse._id,
                        collection: dbCollectionToUse,
                      },
                      url: uploadResults.locationUrl,
                      creatorId: currentUser._id,
                      tags: tags,
                    };

                    _this.projectService
                      .addVideo(video, objectToUse, currentUser)
                      .then((projectResults) => {
                        if (_this.settingsService.getIsDebugging()) {
                          _this.logService.logInfo(
                            `Successfully added videoId ${video._id} for ${dbCollectionToUse} ${objectToUse._id}`
                          );
                        }

                        videos.push(video);
                      })
                      .catch((saveError) => {
                        _this.errorService.handleError(
                          `Error saving video ${JSON.stringify(video)} to ${dbCollectionToUse} ${objectToUse._id
                          }: ${saveError}`
                        );
                      });
                  })
                  .catch((uploadError) => {
                    _this.errorService.handleError(
                      `Error uploading video from ${environment.unreal.viewerName} to ${dbCollectionToUse} ${objectToUse._id}: ${uploadError}`
                    );
                  });
              }
            } catch (videoEx) {
              _this.errorService.handleError(
                `Error processing ${environment.unreal.viewerName} video: ${videoEx}`
              );
            }
          } //loop thru new videos and save
        } //hvae valid data and videos
      } //have videes to process

      resolve(videos);
    });
  }

  //View[0] === 255 -> play button available
  //View[0] === 7 -> initialSettings 
  sendInputData(data): void {
    try {
      const parentUrl = window.location !== window.parent.location ? document.referrer : document.location.href;
      const parentDomain = getBaseDomainFromUrl(parentUrl);
      const protocolToUse = getUrlProtocol(parentUrl);
      const isRunningLocally = getIsRunningLocally(parentDomain);
      let portToUse = getUrlPort(parentUrl);;
      this.logService.logInfo(`iframe parent url ${parentUrl}, domain ${parentDomain}, dataToPost ${JSON.stringify(data)}`);

      const destinationiFrame = document.getElementById('unrealViewer') as HTMLIFrameElement;
      if (destinationiFrame) {
        const destination = destinationiFrame.contentWindow;
        this.logService.logInfo(`Posting message to iframe content window from unreal-viewer.component: ${JSON.stringify(data)}`)
        destination.postMessage(data, "*");
      }
    } catch (ex) {
      this.errorService.handleError(`Error sending input data ${JSON.stringify(data)} to ${environment.unreal.viewerName}: ${ex.message}`);
    }
  }

  removeResponseEventListener(name): void {
    this.responseEventListeners.delete(name);
  }
}
