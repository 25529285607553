<div class="content">
  <div *ngIf="showFilter">
    <mat-form-field class="table-filter">
      <mat-label>Search</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Search"
        aria-label="Type search criteria to filter results"
        matTooltip="Type search criteria to filter results"
        #tableFilter
      />
    </mat-form-field>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="name"
    matSortDirection="asc"
    class="mat-elevation-z8 shipTable"
  >
    <!-- name column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ship Name</th>
      <td mat-cell *matCellDef="let ship" style="cursor: pointer">
        <a aria-label="Click to view ship" matTooltip="Click to view ship" (click)="viewShip(ship)">
          {{ ship.name }}
        </a>
      </td>
    </ng-container>

    <!-- designationWithNullNumber column -->
    <ng-container matColumnDef="designationWithHullNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Designation</th>
      <td mat-cell *matCellDef="let ship">
        <a aria-label="Click to view ship" matTooltip="Click to view ship" (click)="viewShip(ship)">
          {{ ship.designationWithHullNumber }}
        </a>
      </td>
    </ng-container>

    <!-- updatedAt column -->
    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Modified</th>
      <td mat-cell *matCellDef="let ship">
        <a aria-label="Click to view ship" matTooltip="Click to view ship" (click)="viewShip(ship)">
          {{ ship.updatedAt | date: 'short' }}
        </a>
      </td>
    </ng-container>

    <!-- actions column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let ship">
        <mat-toolbar class="actionButtonBar">
          <a
            mat-icon-button
            color="warn"
            aria-label="Delete Ship"
            matTooltip="Delete Ship"
            (click)="deleteShip(ship)"
            *ngIf="canDelete(ship)"
          >
            <img src="assets/img/TrashGray.png" alt="Delete" />
          </a>
          <a mat-icon-button color="primary" aria-label="View Ship" matTooltip="View Ship" (click)="viewShip(ship)">
            <img src="assets/img/Search.png" alt="View" />
          </a>
        </mat-toolbar>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedShipColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedShipColumns"></tr>

    <!-- Row shown when there is no matching data -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">*** No matching ships ***</td>
    </tr>
  </table>

  <mat-paginator *ngIf="showPaginator">
    [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons aria-label="Select page of ships"
  </mat-paginator>
</div>
