<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser">
  <h2 mat-dialog-title>{{ formTitle }}</h2>

  <mat-dialog-content>
    <form [formGroup]="form">
      <mat-horizontal-stepper [linear]="true" #stepper>
        <mat-step [stepControl]="model3dForm" errorMessage="3D Model Overview is required">
          <div [formGroup]="model3dForm">
            <ng-template matStepLabel>3D Model Overview</ng-template>
            <h3>3D Model Overview</h3>
            <input type="text" formControlName="creatorId" hidden ng-disabled="true" />

            <mat-form-field appearance="fill">
              <mat-label>Model Name</mat-label>
              <input matInput placeholder="Model Name" formControlName="name" />
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Data Source</mat-label>
              <mat-select formControlName="dataSourceId">
                <mat-option *ngFor="let dataSource of dataSources$ | async as dataSources" [value]="dataSource._id">
                  {{ dataSource.name }}
                </mat-option>
              </mat-select>
              <button
                matSuffix
                mat-icon-button
                aria-label="Add 3D Model Data Source"
                matTooltip="Add 3D Model Data Source"
                (click)="addDataSource()"
              >
                <mat-icon>add</mat-icon>
              </button>
            </mat-form-field>

            <table style="width: 100%">
              <tr>
                <td style="width: 50%">
                  <mat-form-field>
                    <mat-label>Model Date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="modelDate" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </td>
                <td style="width: 50%">
                  <mat-form-field>
                    <input
                      matInput
                      type="text"
                      placeholder="Name of Person Who Modeled"
                      formControlName="nameOfPersonModeling"
                    />
                  </mat-form-field>
                </td>
              </tr>
            </table>

            <br>

            <mat-radio-group name="classification" formControlName="classification"
              >3D Model Classification:&nbsp;&nbsp;
              <mat-radio-button value="Unclass">Unclass</mat-radio-button>
              <!-- per Ivan, onlyb allow Unclass for now, jane 4/7/20201
                            <mat-radio-button value="FOUO">FOUO</mat-radio-button>
                            <mat-radio-button value="Secret">Secret</mat-radio-button>
                            <mat-radio-button value="TopSecret">Top Secret</mat-radio-button> -->
            </mat-radio-group>

            <br><br>

            <mat-radio-group name="fidelityType" formControlName="fidelityType"
              >Fidelity Type:&nbsp;&nbsp;
              <mat-radio-button value="Engineering">Engineering</mat-radio-button>
              <mat-radio-button value="Visual">Visual</mat-radio-button>
            </mat-radio-group>

            <br>

            <mat-form-field>
              <mat-label>3D Model Uploader</mat-label>
              <input matInput placeholder="3D Model Uploader" formControlName="modelUploader" ng-disabled="true" />
            </mat-form-field>

            <br>

            <mat-form-field>
              <textarea matInput rows="2" placeholder="3D Model Description" formControlName="description"> </textarea>
            </mat-form-field>

            <br>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="close()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                color="primary"
                aria-label="Next step"
                matTooltip="Next step"
                *ngIf="model3dForm.valid"
                matStepperNext
              >
                Next
              </button>
            </div>

            <div *ngIf="isDebugging$ | async">
              <pre>_id: {{ model3dForm.get('_id').valid }}</pre>
              <pre>editorId: {{ model3dForm.get('editorId').valid }}</pre>
              <pre>name: {{ model3dForm.get('name').valid }}</pre>
              <pre>creatorId: {{ model3dForm.get('creatorId').valid }}</pre>
              <pre>classification: {{ model3dForm.get('classification').valid }}</pre>
              <pre>modelDate: {{ model3dForm.get('modelDate').valid }}</pre>
              <pre>dataSourceId: {{ model3dForm.get('dataSourceId').valid }}</pre>
              <pre>description: {{ model3dForm.get('description').valid }}</pre>
              <pre>nameOfPersonModeling: {{ model3dForm.get('nameOfPersonModeling').valid }}</pre>
              <pre>modelUploader: {{ model3dForm.get('modelUploader').valid }}</pre>
              <pre>modId: {{ model3dForm.get('modId').valid }}</pre>
              <pre>parent: {{ model3dForm.get('parent').valid }}</pre>
              <pre>fildelityType: {{ model3dForm.get('fidelityType').valid }}</pre>
            </div>
          </div>
        </mat-step>

        <mat-step [stepControl]="fileForm" *ngIf="isNewModel">
          <ng-template matStepLabel>Vehicle Model File</ng-template>
          <div [formGroup]="fileForm">
            <p>
              <span>
                <button (click)="modelFileInput.click()" [disabled]="model3dForm.invalid">
                  <mat-icon>library_add</mat-icon>
                  <span>Select Model File (.STEP, .STP)</span>
                  <input
                    #modelFileInput
                    type="file"
                    (change)="modelFileInputChange($event)"
                    accept=".step,.stp"
                    style="display: none"
                  />
                </button>
                &nbsp;&nbsp;
                <input
                  type="text"
                  formControlName="modelFileName"
                  readonly
                  *ngIf="hasModelFileName"
                  class="fileNameConfirmationInput"
                />
              </span>
            </p>
            <br>

            <div class="button-row">
              <button
                mat-raised-button
                aria-label="Close without saving"
                matTooltip="Close without saving"
                (click)="close()"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Back to previous step"
                matTooltip="Back to previous step"
                matStepperPrevious
              >
                Back
              </button>
              <button
                mat-raised-button
                color="primary"
                aria-label="Next step"
                matTooltip="Next step"
                matStepperNext
                *ngIf="fileForm.valid"
              >
                Next
              </button>
            </div>
          </div>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          <div class="button-row">
            <button
              mat-raised-button
              aria-label="Close without saving"
              matTooltip="Close without saving"
              (click)="close()"
            >
              Cancel
            </button>
            <button
              mat-raised-button
              color="primary"
              aria-label="Save and close"
              matTooltip="Save and close"
              *ngIf="getShowModelSaveButton(currentUser.role)"
              color="primary"
              (click)="saveModel3dChanges(currentUser)"
            >
              Save
            </button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </mat-dialog-content>
</div>
