import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Project, ProjectOverview, Scan, UnrealServer, User } from '@shared/models';
import {
  ErrorService,
  LogService,
  ModService,
  ProjectService,
  ScanService,
  SettingsService,
  ShipService,
  UnrealServerService,
  UserService,
  VehicleService,
} from '@shared/services';
import { ModStatesEnum, UnrealScenesEnum, UnrealUIStatesEnum, UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.css'],
})
export class ProjectCardComponent implements OnInit {
  @Input()
  project: ProjectOverview;

  currentUser$: Observable<User>;
  private projectErrorSubject = new BehaviorSubject<string>(null);
  projectError$: Observable<string> = this.projectErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private modService: ModService,
    private projectService: ProjectService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
  }

  get canDelete(): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === this.project.creatorId) {
      returnValue = true;
    }

    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get showViewerLite(): boolean {
    return environment.unreal.showViewerLite === true;
  }

  get unrealViewerName(): string {
    return environment.unreal.viewerName;
  }

  get unrealViewerNameLite(): string {
    return environment.unreal.viewerNameLite;
  }

  deleteProject(project: ProjectOverview): void {
    const currentUser = this.userService.getCurrentUser();

    this.projectService
      .getProjectById(project._id, currentUser)
      .then((p: Project) => {
        Swal.fire({
          title: `Delete Project:  ${project.name}?`,
          showCancelButton: true,
          confirmButtonText: 'Delete',
        }).then((result) => {
          if (result.isConfirmed) {
            this.projectService
              .deleteProject(project._id, currentUser)
              .then((projects: Project[]) => {
                this.router.navigate(['/projects']);
              })
              .catch((error) => {
                if (this.settingsService.getShowPopupErrorMessages()) {
                  Swal.fire(
                    `Error Deleting Project`,
                    `There was an error deleting the project.  Please email ${environment.techSupportEmail}.`,
                    'error'
                  );
                }
              });
          }
        });
      })
      .catch((error) => {
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error`,
            `Unable to get the project to delete.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      });
  }

  async openProjectViewer(project: ProjectOverview, openViewerLite: boolean) {
    this.settingsService.setIsLoading(true);
    this.settingsService.setLoadingId(project._id);
    const user = this.userService.getCurrentUser();

    if (project && user) {
      this.projectService
        .getProjectById(project._id, user)
        .then(async (p: Project) => {
          let shipMod; 
          const vehicleMod = this.modService.getCurrentVehicleMod();
          let navigationUrl = `/projects/${project._id}/viewer?sceneName=${UnrealScenesEnum.VIEWER}&uiState=${UnrealUIStatesEnum.PROJECT}`;

          if (openViewerLite) {
            const panoScan = this.scanService.getCurrentShipPanoramicScan();
            shipMod = await this.modService.getModById(panoScan?.modId, panoScan.parent?.collection, panoScan.parent?._id, user);
            navigationUrl += `&panoOnly=true`;
          } else {
            shipMod = this.modService.getCurrentShipMod();
            navigationUrl += `&panoOnly=false`;
          }

          if (shipMod) {
            navigationUrl += `&pcName=${shipMod.name}`;
          }

          if (vehicleMod) {
            navigationUrl += `&vehicleName=${vehicleMod.name}`;
          }

          this.router
            .navigateByUrl(navigationUrl)
            .then(() => {
              this.logService.logInfo(`successfully navigated to ${navigationUrl}`);
            })
            .catch((unrealError) => {
              this.settingsService.setIsLoading(false);
              const errMessage = this.errorService.handleError(
                `Error loading ${environment.unreal.viewerName} at ${navigationUrl}: ${unrealError.message}`
              );
              if (this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  `Error Opening ${environment.unreal.viewerName}`,
                  `${unrealError}.  Please email ${environment.techSupportEmail}.`,
                  'error'
                );
              }
            })
            .finally(() => {
              this.settingsService.setIsLoading(false);
              this.settingsService.setLoadingId(null);
            });
        })
        .catch((error) => {
          this.settingsService.setIsLoading(false);
          this.settingsService.setLoadingId(null);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error`,
              `Unable to retrieve project information needed for the ${environment.unreal.viewerName}.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        });
    } else {
      this.settingsService.setIsLoading(false);
      this.settingsService.setLoadingId(null);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          `Error Opening ${environment.unreal.viewerName}`,
          `Project and user are required.  Please email ${environment.techSupportEmail}`,
          'error'
        );
			}
    }
  }

  viewProject(project: ProjectOverview): void {
    const user = this.userService.getCurrentUser();

    if (project && user) {
      this.projectService
        .getProjectById(project._id, user)
        .then((p: Project) => {
          const navigationUrl = `/projects/${project._id}`;
          this.router.navigateByUrl(navigationUrl);
        })
        .catch((error) => {
          this.settingsService.setIsLoading(false);
          if (this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              `Error`,
              `Error getting project information.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        })
        .finally(() => {
          this.settingsService.setIsLoading(false);
        });
    } else {
      this.settingsService.setIsLoading(false);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error Displaying Project',
          `Unable to load project data.  Please email ${environment.techSupportEmail}`,
          'error'
        );
			}
    }
  }
}
