<div style="justify-content: center; align-items: center; height: 95%">
  <table style="width: 100%; height: auto" class="child">
    <tr>
      <td style="width: 49%; text-align: center">
        <p id="logo">SDAT</p>
      </td>
      <td style="width: 1px; text-align: center; vertical-align: middle; background-color: white">
        <mat-divider [vertical]="true"></mat-divider>
      </td>
      <td style="justify-content: center; align-items: center">
        <h3>System Login</h3>
        <form (ngSubmit)="onSubmit(form)" #form="ngForm">
          <p>
            <mat-form-field>
              <input
                matInput
                type="email"
                id="email"
                name="email"
                placeholder="username"
                class="form-control"
                ngModel
                required
                #nameCtrl="ngModel"
              />
              <mat-error *ngIf="nameCtrl.invalid && nameCtrl.touched">Please enter your username! </mat-error>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field>
              <input
                matInput
                type="password"
                id="password"
                name="password"
                placeholder="password"
                class="form-control"
                ngModel
                required
                #pwCtrl="ngModel"
              />
              <mat-error *ngIf="pwCtrl.invalid && pwCtrl.touched">Please enter your password!</mat-error>
            </mat-form-field>
          </p>
          <div *ngIf="userError$ | async as userError">
            <p style="color: red; text-align: center">{{ userError }}</p>
          </div>
          <button mat-stroked-button color="primary" id="loginButton" type="submit" [disabled]="form.invalid">
            <mat-icon>login</mat-icon>
          </button>
          <br><br>
          <a (click)="forgotPassword()">Forgot Password?</a>
        </form>
      </td>
    </tr>
  </table>
</div>

<footer style="text-align: center; height: 5%">
  <span>
    <a (click)="goToPrivacyPolicy()">Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;
    <a (click)="goToFAQs()">FAQs</a>&nbsp;&nbsp;|&nbsp;&nbsp;
    <a (click)="goToSupport()">Support</a>&nbsp;&nbsp;|&nbsp;&nbsp;
    <a (click)="goToAgreements()">Agreements</a>&nbsp;&nbsp;|&nbsp;&nbsp;
    <a (click)="goToVersion()">Version</a>
  </span>
</footer>
