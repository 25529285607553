import { HttpHeaders } from '@angular/common/http';
import { FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { isIP } from 'is-ip';
import isEqual from 'lodash-es/isEqual';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { Tag } from '@shared/models';
import {
  AllWheelDrive,
  AutomativeLight,
  AutomativeMedium,
  AutomotiveHeavy,
  Forklift,
  FrontWheelDrive,
  UnknownSteeringType,
  MRAP,
  NoWheelDrive,
  RearWheelDrive,
  SemiTrailer,
  TrackedVehicleMovement,
  Trailer,
  WheeledVehicleMovement,
} from '@shared/enums';

export function convertModIdentifierToAzureFileShareName(modIdentifier) {
  let returnValue = modIdentifier;

  if (modIdentifier && typeof modIdentifier == 'string' && modIdentifier !== '') {
    try {
      returnValue = modIdentifier.toString().trim();
      returnValue = returnValue.replaceAll('_', '-');
      returnValue = returnValue.toLowerCase();
    } catch (ex) {
      console.error(`Error covnerting modIdentifier ${modIdentifier} to valid Azure file share name: ${ex.message}`);
    }
  }

  return returnValue;
}

export function createHttpObservable(url: string, httpOptions: object = {}, addAuthHeader: boolean): Observable<any> {
  return new Observable((observer) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const headerObj: any = {};
    headerObj.headers = {
      'content-type': 'application/json',
    };

    if (addAuthHeader) {
      const token = localStorage.getItem('Token');
      headerObj.headers.authorization = `Bearer ${token}`;
    }

    let options: object;

    if (Object.keys(httpOptions).length > 0) {
      options = {
        ...httpOptions,
        ...headerObj,
        ...signal,
      };
    } else {
      options = {
        ...headerObj,
        ...signal,
      };
    }

    fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          observer.error('Request failed with status code: ' + response.status);
        }
      })
      .then((body) => {
        observer.next(body);

        observer.complete();
      })
      .catch((err) => {
        observer.error(err);
      });

    return () => controller.abort();
  });
}

export function createHttpRequestWithHeaders(
  url: string,
  httpOptions: object = {},
  addAuthHeader: boolean
): Promise<any> {
  return new Promise((promise) => {
    const headerObj: any = {};
    headerObj.headers = {
      'content-type': 'application/json',
    };

    if (addAuthHeader) {
      const token = localStorage.getItem('Token');
      headerObj.headers.authorization = `Bearer ${token}`;
    }

    let options: object;

    if (Object.keys(httpOptions).length > 0) {
      options = {
        ...httpOptions,
        ...headerObj,
      };
    } else {
      options = {
        ...headerObj,
      };
    }

    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Request ${url} failed with status code: ${response.status}`);
        }
      })
      .then((body) => {
        return body;
      })
      .catch((err) => {
        throw new Error(`Error executing request ${url}: ${err}`);
      });
  });
}

export function getCustomHeaders(addAuthHeader): any {
  const headerObj: any = {};
  headerObj.headers = {
    'content-type': 'application/json',
  };

  if (addAuthHeader) {
    const token = localStorage.getItem('Token');
    if (token) {
      headerObj.headers.authorization = `Bearer ${token}`;
    }
  }

  return headerObj.headers;
}

export function createTag(key, value): Tag {
  if (key) {
    const tag: Tag = {
      key: key,
      value: value || '',
    };

    return tag;
  } else {
    throw new Error(`key is required to create a new tag`);
  }
}

/*
   Returns an array of invalid control/group names, or a zero-length array if
   no invalid controls/groups where found
   see https://newbedev.com/how-to-find-the-invalid-controls-in-angular-4-reactive-form
*/
export function findInvalidControlsRecursive(formToInvestigate: UntypedFormGroup | UntypedFormArray): string[] {
  const invalidControls: string[] = [];
  let recursiveFunc = (form: UntypedFormGroup | UntypedFormArray) => {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (control.invalid) {
        invalidControls.push(field);
      }

      if (control instanceof UntypedFormGroup) {
        recursiveFunc(control);
      } else if (control instanceof UntypedFormArray) {
        recursiveFunc(control);
      }
    });
  };
  recursiveFunc(formToInvestigate);
  return invalidControls;
}

export function getBaseDomainFromUrl(urlToUse: string): string {
  let returnValue = '';

  try {
    const fullDomain = (new URL(urlToUse)).hostname;
    const domainIsIp = isIP(fullDomain);

    if (domainIsIp) {
      returnValue= fullDomain;
    } else {
      const baseStartIdx = fullDomain.indexOf('.');
      if (baseStartIdx > -1) {
        returnValue = fullDomain.substring(baseStartIdx + 1);
      } else {
        returnValue = fullDomain;
      }
    }
  } catch (ex) {
    console.error(`Error getting base domain for URL ${urlToUse}: ${ex.message}`)
  }

  return returnValue;
}

//see https://www.usefuldev.com/post/Angular%20Forms:%20how%20to%20get%20only%20the%20changed%20values
export function getUpdates(formItem: FormGroup | FormArray | FormControl, updatedValues: any, name?: string ) {
  if (formItem instanceof FormControl) {
    if (name && formItem.dirty) {   
      updatedValues[name] = formItem.value;
    }
  } else {
    for (const formControlName in formItem.controls) {
      if (formItem.controls.hasOwnProperty(formControlName)) {
        const formControl = formItem.controls[formControlName];

        if (formControl instanceof FormControl) {
          getUpdates(formControl, updatedValues, formControlName);
        } else if (
          formControl instanceof FormArray &&
          formControl.dirty &&
          formControl.controls.length > 0
        ) {
          updatedValues[formControlName] = [];
          getUpdates(formControl, updatedValues[formControlName]);
        } else if (formControl instanceof FormGroup && formControl.dirty) {
          updatedValues[formControlName] = {};
          getUpdates(formControl, updatedValues[formControlName]);
        }
      }
    }
  }

  return updatedValues;
}

export function getDisplayName(dataKey, _id) {
  let displayName = '';
  let matching;
  const equipmentTypes = getVehicleEquipmentTypes();
  const movementTypes = getVehicleMovementTypes();
  const steeringTypes = getVehicleSteeringTypes();

  if (dataKey && _id) {
    switch (dataKey) {
      case 'equipmentType':
        matching = equipmentTypes.find((t) => t._id === _id);
        break;
      case 'movementType':
        matching = movementTypes.find((t) => t._id === _id);
        break;
      case 'steeringType':
        matching = steeringTypes.find((t) => t._id === _id);
        break;
    }

    displayName = matching && matching.displayName ? matching.displayName : '';
  }

  return displayName;
}

export function getIsRunningLocally(domainToUse) {
  let returnValue = false;

  try {
    returnValue = domainToUse === 'localhost' || domainToUse === '127.0.0.1' ? true : false;
  } catch (ex) {
    console.error(`Error determining if we are running locally from domain ${domainToUse}: ${ex.message}`);
  }

  return returnValue;
}

export function getUrlPort(urlToUse: string): string {
  let returnValue = '';

  try {
    returnValue = (new URL(urlToUse)).port;
  } catch (ex) {
    console.error(`Error getting port for URL ${urlToUse}: ${ex.message}`)
  }

  return returnValue;
}

export function getUrlProtocol(urlToUse: string): string {
  let returnValue = '';

  try {
    returnValue = (new URL(urlToUse)).protocol;
  } catch (ex) {
    console.error(`Error getting protocol for URL ${urlToUse}: ${ex.message}`)
  }

  return returnValue;
}

export function getVehicleEquipmentTypes() {
  const returnValue = [];
  returnValue.push(AutomativeLight);
  returnValue.push(AutomativeMedium);
  returnValue.push(AutomotiveHeavy);
  returnValue.push(MRAP);
  returnValue.push(Forklift);
  returnValue.push(Trailer);
  returnValue.push(SemiTrailer);
  return returnValue;
}

export function getVehicleMovementTypes() {
  const returnValue = [];
  returnValue.push(TrackedVehicleMovement);
  returnValue.push(WheeledVehicleMovement);
  return returnValue;
}

export function getVehicleSteeringTypes() {
  const returnValue = [];
  returnValue.push(FrontWheelDrive);
  returnValue.push(RearWheelDrive);
  returnValue.push(AllWheelDrive);
  returnValue.push(NoWheelDrive);
  returnValue.push(UnknownSteeringType);
  return returnValue;
}

export function getYesOrNo(value: boolean) {
  if (value) {
    return 'Yes';
  } else {
    return 'No';
  }
}

export function removeUndefinedAndEmptyStringProps(obj: Object) {
  const copy = { ...obj };
  Object.keys(copy).forEach((key) => {
      const curValue = copy[key];

      const isObject =
          curValue === Object(curValue) &&
          !Array.isArray(curValue) &&
          typeof curValue !== 'function';
      if (isObject) {
          copy[key] = removeUndefinedProps(curValue);
      } else if (copy[key] === undefined || copy[key] === null || copy[key] === '') {
          delete copy[key];
      }
  });
  return copy;
}

export function removeUndefinedProps(obj: Object) {
  const copy = { ...obj };
  Object.keys(copy).forEach((key) => {
      const curValue = copy[key];

      const isObject =
          curValue === Object(curValue) &&
          !Array.isArray(curValue) &&
          typeof curValue !== 'function';
      if (isObject) {
          copy[key] = removeUndefinedProps(curValue);
      } else if (copy[key] === undefined || copy[key] === null) {
          delete copy[key];
      }
  });
  return copy;
}
