<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser">
  <ng-container *ngIf="{
      isDebugging: isDebugging$ | async,
      viewFormat: viewFormat$ | async
    } as settings">
    <div class="toolbar-action-buttons mat-toolbar-single-row">
      <mat-button-toggle-group [formControl]="viewAsControl" aria-label="View Users As">
        <mat-button-toggle mat-icon-button aria-label="View users as cards" matTooltip="View users as cards"
          value="cards" (change)="onViewFormatChange($event.value, currentUser)">
          <mat-icon>view_module</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle mat-icon-button aria-label="View users as list" matTooltip="View users as list" value="list"
          (change)="onViewFormatChange($event.value, currentUser)">
          <mat-icon>view_list</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <a mat-stroked-button *ngIf="isAdmin" aria-label="Add New User" matTooltip="Add New User" id="btnCreateUser"
        (click)="createUser(currentUser)">
        <img src="assets/img/New.png" alt="Add New User" />
        <span>&nbsp;&nbsp;ADD NEW USER</span>
      </a>

      <a mat-stroked-button *ngIf="isAdmin && isSelectedUsers" aria-label="Edit Selected Users"
        matTooltip="Edit Selected Users" id="btnEditSelectedUsers" (click)="editSelectedUsers()">
        <img src="assets/img/Edit.png" alt="Edit Selected Users" />
        <span>&nbsp;&nbsp;EDIT SELECTED USERS</span>
      </a>
    </div>
    <!-- <mat-toolbar>
      <mat-button-toggle-group [formControl]="viewAsControl" aria-label="View Users As">
        <mat-button-toggle mat-icon-button aria-label="View users as cards" matTooltip="View users as cards"
          value="cards" (change)="onViewFormatChange($event.value, currentUser)">
          <mat-icon>view_module</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle mat-icon-button aria-label="View users as list" matTooltip="View users as list" value="list"
          (change)="onViewFormatChange($event.value, currentUser)">
          <mat-icon>view_list</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
      &nbsp;&nbsp;&nbsp;
      <button *ngIf="isAdmin" class="image" aria-label="Add new user" matTooltip="Add new user" id="btnCreateUser"
        (click)="createUser(currentUser)">
        <span class="btnCreateUser"></span>
        ADD NEW USER
      </button>
      &nbsp;&nbsp;&nbsp;
      <button *ngIf="isAdmin && isSelectedUsers" class="image" aria-label="Edit Selected Users" matTooltip="Edit Selected Users" id="btnEditSelectedUsers"
        (click)="editSelectedUsers()">
        <span class="btnEditSelectedUsers"></span>
        EDIT SELECTED USERS
      </button>
    </mat-toolbar> -->
    <div class="content">
      <div *ngIf="users$ | async as users">
        <h2>USER MANAGEMENT</h2>
        <mat-divider></mat-divider>
        <div class="grid" *ngIf="settings.viewFormat === 'cards'">
          <div *ngFor="let user of users">
            <app-user-card [user]="user"> </app-user-card>
          </div>
        </div>
        <div *ngIf="settings.viewFormat === 'list'">
          <app-user-table [users]="users" [showPaginator]="true" [showFilter]="true"></app-user-table>
        </div>
      </div>
    </div>
  </ng-container>
</div>