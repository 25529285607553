import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, config, from, Observable, of, zip } from 'rxjs';
import { concatAll, catchError, map, switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import {
  DataSource,
  Document,
  ImageDoc,
  Manufacturer,
  Mod,
  Model3d,
  Note,
  ReportSection,
  Scan,
  Scanner,
  UnrealServer,
  User,
  Vehicle,
  VehicleDesignation,
  VehicleModel,
  Video
} from '@shared/models';
import {
  DataSourceService,
  DocumentService,
  ErrorService,
  FileService,
  ImageDocService,
  LogService,
  ManufacturerService,
  ModService,
  Model3dService,
  NoteService,
  ReportService,
  ScannerService,
  ScanService,
  SettingsService,
  UnrealServerService,
  UserService,
  VehicleService,
  VehicleDesignationService,
  VehicleModelService,
  VideoService
} from '@shared/services';
import {
  FileObjectTypesEnum,
  DbCollectionsEnum,
  ReportSectionIdsEnum,
  ScanDisplayTypesEnum,
  ScanTypesEnum,
  TagFileTypesEnum,
  TagTypesEnum,
  TrackedVehicleMovement,
  UnrealScenesEnum,
  UnrealUIStatesEnum,
  UserRolesEnum,
  WheeledVehicleMovement,
} from '@shared/enums';
import { createTag, getDisplayName, getYesOrNo } from '@shared/utils';

import { environment } from '@environment';

import { Model3dDialogComponent } from '../model3d/model3d-dialog/model3d-dialog.component';
import { ReportItemDialogComponent } from '../report/report-item-dialog/report-item-dialog.component';
import { ScanDialogComponent } from '../scan/scan-dialog/scan-dialog.component';
import { VehicleDialogComponent } from './vehicle-dialog/vehicle-dialog.component';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css'],
})
export class VehicleComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  private isMotorizedVehicleSubject = new BehaviorSubject<boolean>(true);
  private isWheeledVehicleSubject = new BehaviorSubject<boolean>(false);
  private isTrackedVehicleSubject = new BehaviorSubject<boolean>(false);
  private hasBrakesSubject = new BehaviorSubject<boolean>(true);
  activeVideoIndex: number;
  currentVideo: Video;
  errorMsg: boolean;
  errorText = 'Please select a file';
  documentFileObj: File;
  imageFileObj: File;
  snapshotFileObj: File;
  videoFileObj: File;
  allowVehicleModels3d$: Observable<boolean>;
  allowVehicleScans$: Observable<boolean>;
  currentUser$: Observable<User>;
  dataSources$: Observable<DataSource[]>;
  isMotorizedVehicle$: Observable<boolean> = this.isMotorizedVehicleSubject.asObservable();
  isWheeledVehicle$: Observable<boolean> = this.isWheeledVehicleSubject.asObservable();
  isTrackedVehicle$: Observable<boolean> = this.isTrackedVehicleSubject.asObservable();
  hasBrakes$: Observable<boolean> = this.hasBrakesSubject.asObservable();
  manufacturer$: Observable<Manufacturer>;
  manufacturers$: Observable<Manufacturer[]>;
  model3d$: Observable<Model3d>;
  models3d$: Observable<Model3d[]>;
  modelUploader$: Observable<User>;
  reportSections$: Observable<ReportSection[]>;
  scan$: Observable<Scan>;
  scans$: Observable<Scan[]>;
  scanner$: Observable<Scanner>;
  scanners$: Observable<Scanner[]>;
  scanUploader$: Observable<User>;
  updatedModel3dId$: Observable<string>;
  vehicle$: Observable<Vehicle>;
  vehicleDesignation$: Observable<VehicleDesignation>;
  vehicleDesignations$: Observable<VehicleDesignation[]>;
  vehicleDocuments$: Observable<Document[]>;
  vehicleImages$: Observable<ImageDoc[]>;
  vehicleModel$: Observable<VehicleModel>;
  vehicleModels$: Observable<VehicleModel[]>;
  vehicleModels3d$: Observable<Model3d[]>;
  vehicleMod$: Observable<Mod>;
  vehicleMods$: Observable<Mod[]>;
  vehicleNotes$: Observable<Note[]>;
  vehicleScans$: Observable<Scan[]>;
  vehicleVideos$: Observable<Video[]>;
  decimationLevelForm: UntypedFormGroup;
  model3dForm: UntypedFormGroup;
  registrationErrorForm: UntypedFormGroup;
  scanForm: UntypedFormGroup;
  vehicleForm: UntypedFormGroup;
  vehicleDocumentation: UntypedFormArray;
  safeSrc: SafeResourceUrl;
  currentUser: User;
  isReadOnly: boolean;
  isOpeningUnrealViewer: boolean;
  equipmentTypeName: string;
  movementTypeName: string;
  steeringTypeName: string;
  events: string[] = [];
  videoData: any;
  displayedModel3dColumns: string[] = [
    'name',
    'modelDate',
    'classification',
    'fidelityType',
    'dataSourceName',
    'nameOfPersonModeling',
    'description',
    'actions',
  ];
  displayedScanColumns: string[] = [
    'name',
    'scanDate',
    'classification',
    'scanType',
    'dataSourceName',
    'nameOfPersonScanning',
    'decimationLevel',
    'registrationError',
    'description',
    'actions',
  ];

  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private dataSourceService: DataSourceService,
    private documentService: DocumentService,
    private errorService: ErrorService,
    private fileService: FileService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private manufacturerService: ManufacturerService,
    private modService: ModService,
    private model3dService: Model3dService,
    private noteService: NoteService,
    private reportService: ReportService,
    private scannerService: ScannerService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private vehicleDesignationService: VehicleDesignationService,
    private vehicleModelService: VehicleModelService,
    private videoService: VideoService,
  ) { }

  ngOnInit(): void {
    this.settingsService.setIsLoading(true);
    this.allowVehicleModels3d$ = this.vehicleService.allowVehicleModel3ds$;
    this.allowVehicleScans$ = this.vehicleService.allowVehicleScans$;
    this.currentUser$ = this.userService.currentUser$;
    this.dataSources$ = this.dataSourceService.dataSources$;
    this.manufacturer$ = this.scanService.currentVehicleScanManufacturer$;
    this.manufacturers$ = this.manufacturerService.manufacturers$;
    this.model3d$ = this.model3dService.currentVehicleModel3d$;
    this.models3d$ = this.model3dService.currentVehicleModels3d$;
    this.modelUploader$ = this.model3dService.currentVehicleModel3dCreator$;
    this.reportSections$ = this.reportService.reportSections$;
    this.scan$ = this.scanService.currentVehicleScan$;
    this.scans$ = this.scanService.scans$;
    this.scanUploader$ = this.scanService.currentVehicleScanCreator$;
    this.scanner$ = this.scanService.currentVehicleScanScanner$;
    this.scanners$ = this.scannerService.manufacturerScanners$;
    this.updatedModel3dId$ = this.model3dService.updatedModel3dId$;
    this.vehicle$ = this.vehicleService.currentVehicle$;
    this.vehicleDesignation$ = this.vehicleService.currentVehicleDesignation$;
    this.vehicleDocuments$ = this.documentService.currentVehicleDocuments$;
    this.vehicleImages$ = this.imageDocService.currentVehicleImages$;
    this.vehicleModel$ = this.vehicleModelService.currentVehicleModel$;
    this.vehicleModels3d$ = this.vehicleService.currentVehicleModels3d$;
    this.vehicleMod$ = this.modService.currentVehicleMod$;
    this.vehicleMods$ = this.modService.currentVehicleMods$;
    this.vehicleNotes$ = this.noteService.currentVehicleNotes$;
    this.vehicleScans$ = this.vehicleService.currentVehicleScans$;
    this.vehicleVideos$ = this.vehicleService.currentVehicleVideos$;
    this.isOpeningUnrealViewer = false;

    // see https://www.thinktecture.com/en/angular/rxjs-antipattern-1-nested-subs/
    zip(
      this.currentUser$,
      this.model3d$,
      this.vehicle$,
      this.modelUploader$,
      this.scan$,
      this.scanUploader$,
      this.scanner$,
      this.scanners$,
      this.manufacturer$,
      this.manufacturers$,
      this.vehicleDesignation$,
      this.vehicleDocuments$,
      this.vehicleImages$,
      this.vehicleModel$,
      this.vehicleModels3d$,
      this.vehicleMod$,
      this.vehicleMods$,
      this.vehicleNotes$,
      this.vehicleScans$,
      this.dataSources$,
      this.reportSections$,
      this.updatedModel3dId$,
      this.allowVehicleModels3d$,
      this.allowVehicleScans$
    )
      .pipe(untilDestroyed(this))
      .pipe(
        map(
          ([
            user,
            model3d,
            vehicle,
            modelUploader,
            scan,
            scanUploader,
            scanner,
            scanners,
            manufacturer,
            manufacturers,
            vehicleDesignation,
            vehicleDocuments,
            vehicleImages,
            vehicleModel,
            vehicleModels3d,
            vehicleMod,
            vehicleMods,
            vehicleNotes,
            vehicleScans,
            dataSources,
            reportSections,
            updatedModel3dId,
            allowVehicleModels3d,
            allowVehicleScans,
          ]) => ({
            user,
            model3d,
            vehicle,
            modelUploader,
            scan,
            scanUploader,
            scanner,
            scanners,
            manufacturer,
            manufacturers,
            vehicleDesignation,
            vehicleDocuments,
            vehicleImages,
            vehicleModel,
            vehicleModels3d,
            vehicleMod,
            vehicleMods,
            vehicleNotes,
            vehicleScans,
            dataSources,
            reportSections,
            updatedModel3dId,
            allowVehicleModels3d,
            allowVehicleScans,
          })
        )
      )
      .subscribe(
        ({
          user,
          model3d,
          vehicle,
          modelUploader,
          scan,
          scanUploader,
          scanner,
          scanners,
          manufacturer,
          manufacturers,
          vehicleDesignation,
          vehicleDocuments,
          vehicleImages,
          vehicleModel,
          vehicleModels3d,
          vehicleMod,
          vehicleMods,
          vehicleNotes,
          vehicleScans,
          dataSources,
          reportSections,
          updatedModel3dId,
          allowVehicleModels3d,
          allowVehicleScans,
        }) => {
          this.currentUser = user;
          const isAdmin = user && user.role === UserRolesEnum.ADMIN;
          this.isReadOnly = isAdmin ? false : true;
          this.hasBrakesSubject.next(vehicle && vehicle.hasBrakes ? true : false);
          this.isMotorizedVehicleSubject.next(vehicle && vehicle.isMotorized ? true : false);
          this.isWheeledVehicleSubject.next(
            vehicle && vehicle.movementTypeId === WheeledVehicleMovement._id ? true : false
          );
          this.isTrackedVehicleSubject.next(
            vehicle && vehicle.movementTypeId === TrackedVehicleMovement._id ? true : false
          );
          this.equipmentTypeName = vehicle ? getDisplayName('equipmentType', vehicle.equipmentTypeId) : '';
          this.movementTypeName = vehicle ? getDisplayName('movementType', vehicle.movementTypeId) : '';
          this.steeringTypeName = vehicle ? getDisplayName('steeringType', vehicle.steeringTypeId) : '';

          if (allowVehicleScans) {
            this.decimationLevelForm = new UntypedFormGroup({
              value: new UntypedFormControl(scan && scan.decimationLevel ? scan.decimationLevel.value : 0,
                Validators.required
              ),
              units: new UntypedFormControl(scan && scan.decimationLevel ? scan.decimationLevel.units : 'mm',
                Validators.required
              ),
            });

            this.registrationErrorForm = new UntypedFormGroup({
              value: new UntypedFormControl(scan && scan.registrationError ? scan.registrationError.value : 0,
                Validators.required
              ),
              units: new UntypedFormControl(scan && scan.registrationError ? scan.registrationError.units : 'mm',
                Validators.required
              ),
            });

            this.scanForm = this.fb.group({
              _id: [scan ? scan._id : '', Validators.required],
              classification: [scan ? scan.classification : 'Unclass', Validators.required],
              creatorId: [scan ? scan.creatorId : '', Validators.required],
              dataSourceId: [scan ? scan.dataSourceId : '', Validators.required],
              decimationLevel: this.decimationLevelForm,
              description: [scan ? scan.description : ''],
              editorId: [this.currentUser ? this.currentUser._id : '', Validators.required],
              manufacturerId: [manufacturer ? manufacturer._id : '', Validators.required],
              nameOfPersonScanning: [scan ? scan.nameOfPersonScanning : '', Validators.required],
              registrationError: this.registrationErrorForm,
              scanDate: [scan && scan.scanDate ? scan.scanDate : '', Validators.required],
              scanUploader: [scanUploader ? scanUploader.fullName : '', Validators.required],
              scannerId: [scan ? scan.scannerId : '', Validators.required],
              scanType: [scan ? scan.scanType : '', Validators.required],
            });
          } //scans are allowed

          if (allowVehicleModels3d) {
            this.model3dForm = this.fb.group({
              _id: [model3d ? model3d._id : '', Validators.required],
              classification: [model3d ? model3d.classification : 'Unclass', Validators.required],
              creatorId: [model3d ? model3d.creatorId : '', Validators.required],
              dataSourceId: [model3d ? model3d.dataSourceId : '', Validators.required],
              description: [model3d ? model3d.description : ''],
              editorId: [this.currentUser ? this.currentUser._id : '', Validators.required],
              fidelityType: [model3d ? model3d.fidelityType : ''],
              nameOfPersonModeling: [model3d ? model3d.nameOfPersonModeling : '', Validators.required],
              modelDate: [model3d && model3d.modelDate ? model3d.modelDate : '', Validators.required],
              modelUploader: [modelUploader ? modelUploader.fullName : '', Validators.required],
            });
          }

          this.vehicleDocumentation = new UntypedFormArray([]);
          this.vehicleForm = this.fb.group({
            _id: [vehicle ? vehicle._id : '', Validators.required],
            documentation: this.vehicleDocumentation,
            editorId: [this.currentUser ? this.currentUser._id : '', Validators.required],
          });

          if (vehicleDocuments && Array.isArray(vehicleDocuments) && vehicleDocuments.length > 0) {
            for (let x = 0; x < vehicleDocuments.length; x++) {
              this.vehicleDocumentation.push(new UntypedFormControl(vehicleDocuments[x]));
            }
          }

          this.settingsService.setIsLoading(false);
        }
      );
  }

  get hasImageFileName(): boolean {
    let returnValue = false;

    if (this.imageFileObj && this.imageFileObj.name !== null) {
      returnValue = true;
    }

    return returnValue;
  }

  get hasVideoFileName(): boolean {
    let returnValue = false;

    if (this.videoFileObj && this.videoFileObj.name !== null) {
      returnValue = true;
    }

    return returnValue;
  }

  get isWheeledVehicle(): boolean {
    return this.isWheeledVehicleSubject.getValue();
  }

  async addNote(vehicle: Vehicle) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: this.currentUser,
      isNewNote: true,
      vehicle: vehicle,
    };

    const dialogRef = this.dialog.open(ReportItemDialogComponent, dialogConfig);
  }

  async addSnapshotNote(vehicle: Vehicle, imgDoc: ImageDoc) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: this.currentUser,
      imageDoc: imgDoc,
      isNewNote: true,
      vehicle: vehicle,
    };

    const dialogRef = this.dialog.open(ReportItemDialogComponent, dialogConfig);
  }

  createModel3d(vehicle: Vehicle, currentUser: User): void {
    const model3d: Model3d = {
      _id: new ObjectID().toString(),
      classification: 'Unclass',
      creatorId: currentUser._id,
      fidelityType: 'Engineering',
      modelUploader: currentUser.fullName,
      name: '',
      nameOfPersonModeling: '',
      parent: {
        _id: vehicle._id,
        collection: DbCollectionsEnum.VEHICLES,
      },
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '600px';

    dialogConfig.data = {
      currentUser: currentUser,
      isNewModel: true,
      model3d: model3d,
      parentVehicle: vehicle,
    };

    const dialogRef = this.dialog.open(Model3dDialogComponent, dialogConfig);
  }

  createScan(vehicle: Vehicle, currentUser: User): void {
    const scan: Scan = {
      _id: new ObjectID().toString(),
      name: '',
      creatorId: currentUser._id,
      editorId: currentUser._id,
      scanUploader: currentUser.fullName,
      parent: {
        _id: vehicle._id,
        collection: DbCollectionsEnum.VEHICLES,
      },
      scanDisplayType: ScanDisplayTypesEnum.POINT_CLOUD,
      scanType: ScanTypesEnum.RAW,
      siteFileName: ''
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: currentUser,
      isNewScan: true,
      scan: scan,
      parentVehicle: vehicle,
    };

    const dialogRef = this.dialog.open(ScanDialogComponent, dialogConfig);
  }

  async deleteVideo(video: Video, vehicle: Vehicle): Promise<any> {
    const _this = this;
    const title = `Do you want to delete the ${video.name} video?`;

    Swal.fire({
      title: title,
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        this.videoService
          .deleteVideo(video._id, DbCollectionsEnum.VEHICLES, vehicle._id, _this.currentUser)
          .then(function (deleteResults) {
            console.log(`deleted videoId ${video._id}`);
          })
          .catch(function (deleteError) {
            if (_this.settingsService.getShowPopupErrorMessages()) {
              let errorTitle = 'Error Deleting Video';
              Swal.fire(errorTitle, deleteError.message, 'error');
            }
          });
      }
    });
  }

  async documentFileInputChange($event: Event): Promise<any> {
    const _this = this;
    _this.errorMsg = false;
    const fileEl = $event.target as HTMLInputElement;
    const vehicle = _this.vehicleService.getCurrentVehicle();

    if (fileEl?.files.length > 0 && vehicle) {
      const errors = [];
      const promises = [];
      const documentTypes = [];
      const fileTags = [];
      const docTags = [];
      const files = fileEl.files;

      for (let x = 0; x < files.length; x++) {
        const FILE = files[x];
        const fileType1 = FILE.type;
        let documentType = '';

        //get document type
        if (fileType1.indexOf('audio') > -1) {
          documentType = TagFileTypesEnum.AUDIO;
        } else if (fileType1.indexOf('image') > -1) {
          documentType = TagFileTypesEnum.IMAGE;
        } else if (fileType1.indexOf('video') > -1) {
          documentType = TagFileTypesEnum.VIDEO;
        } else {
          const inputOptions = {
            Picture: 'Image',
            Manual: 'Manual',
            Other: 'Other',
          };

          const { value: docType } = await Swal.fire({
            title: `Select Document Type for file: ${FILE.name}`,
            input: 'radio',
            inputOptions: inputOptions,
            inputValidator: (value) => {
              if (!value) {
                return 'Please select the document type';
              }
            },
          });

          if (docType) {
            documentType = docType;
          }
        }

        documentTypes.push(documentType || '');

        const tag = createTag(TagTypesEnum.FILE_TYPE, documentType);
        fileTags.push({
          fileName: FILE.name,
          tags: tag
        });
        docTags.push({
          fileName: FILE.name,
          tags: [tag.value, 'vehicle', vehicle.name]
        });

        promises.push(_this.fileService.uploadFile(FILE, FileObjectTypesEnum.VEHICLE, vehicle._id, fileTags[x].tags, documentType, _this.currentUser))
      }

      _this.settingsService.setIsLoading(true);
      await Promise.allSettled(promises)
        .then(async (results) => {
          const vehiclePromises = [];
          for (let y = 0; y < results.length; y++) {
            const result = results[y];

            if (result.status === 'rejected') {
              errors.push(result.reason);
            } else {

              const doc: Document = {
                _id: new ObjectID().toString(),
                name: result.value.nameWithoutExtension,
                documentType: documentTypes[y],
                encodedVideoUrl: result.value.encodedVideoUrl,
                parent: {
                  _id: vehicle._id,
                  collection: DbCollectionsEnum.VEHICLES,
                },
                url: result.value.locationUrl,
                thumbnailUrl: result.value.thumbnailUrl || environment.defaultThumbnailImageUrl,
                creatorId: this.currentUser._id,
                tags: docTags[y].tags,
              };

              vehiclePromises.push(_this.vehicleService.addDocument(doc, vehicle, _this.currentUser));
            }

            vehiclePromises.push(_this.documentService.refreshDocumentsByParent(DbCollectionsEnum.VEHICLES, vehicle._id, _this.currentUser));
          }

          await Promise.allSettled(vehiclePromises)
            .then((projectResults) => {
              let isProcessing = false;
              projectResults.forEach((pResult) => {
                if (pResult.status === 'rejected') {
                  errors.push(pResult.reason);
                } else {
                  if (pResult.value.processing) {
                    isProcessing = true;
                  }
                }
              });

              if (errors.length > 0) {
                _this.errorService.handleError(`Error uploading documents for vehicleId ${vehicle._id}:  ${errors.join(',')}`);
              }

              _this.settingsService.setIsLoading(false);
              if (isProcessing) {
                let message = 'Due to the large file size, your files are being uploaded in the background.  You will be emailed a success or failure message upon completion.';

                if (errors.length > 0) {
                  message += `\n\nPlease note that there were some errors processing your files.  Contact ${environment.techSupportEmail} with any questions.`
                }

                Swal.fire(
                  'File Upload in Process',
                  message,
                  'info'
                );
              } else if (errors.length > 0 && _this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  'Error Uploading Vehicle Document(s)',
                  `There were one or more error processing your documents.  Please email ${environment.techSupportEmail}`,
                  'error'
                );
              }
            });
        });
    }
  }

  getIsGettingNotifications(vehicle: Vehicle): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (vehicle && user) {
      const idx = vehicle.userIdsToSendChangeNotificationsTo.indexOf(user._id);
      if (idx != -1) {
        returnValue = true;
      }
    }

    return returnValue;
  }

  getNumberOfItemsInArray(items) {
    let returnValue = 0;

    if (items && Array.isArray(items)) {
      returnValue = items.length;
    }

    return returnValue;
  }

  getVideoNotes(vehicleNotes: Note[], videoId: string) {
    if (vehicleNotes && vehicleNotes.length > 0) {
      return vehicleNotes.filter((note) => note.videoId === videoId);
    }
  }

  async imageFileInputChange($event: Event): Promise<any> {
    const _this = this;
    _this.errorMsg = false;
    const fileEl = $event.target as HTMLInputElement;
    const vehicle = this.vehicleService.getCurrentVehicle();

    if (fileEl?.files.length > 0 && vehicle) {
      _this.settingsService.setIsLoading(true);
      const errors = [];
      const promises = [];
      const files = fileEl.files;
      let imgType = TagFileTypesEnum.IMAGE;
      const tags = [];
      const imgTags = [];
      const tag = createTag(TagTypesEnum.FILE_TYPE, imgType);
      tags.push(tag);
      imgTags.push(tag.value);
      imgTags.push('vehicle');
      imgTags.push(vehicle.name);

      for (let x = 0; x < files.length; x++) {
        const FILE = files[x];
        promises.push(_this.fileService.uploadFile(FILE, FileObjectTypesEnum.VEHICLE, vehicle._id, tags, imgType, this.currentUser))
      };

      await Promise.allSettled(promises)
        .then(async (results) => {
          const vehiclePromises = [];
          results.forEach((result) => {
            if (result.status === 'rejected') {
              errors.push(result.reason);
            } else {
              const imageDoc: ImageDoc = {
                _id: new ObjectID().toString(),
                displayThumbnailUrl: result.value.thumbnailDisplayUrl,
                displayUrl: result.value.locationDisplayUrl,
                isMainImage: false,
                isSnapshot: false,
                name: result.value.nameWithoutExtension,
                parent: {
                  _id: vehicle._id,
                  collection: DbCollectionsEnum.VEHICLES,
                },
                url: result.value.locationUrl,
                thumbnailUrl: result.value.thumbnailUrl || environment.defaultThumbnailImageUrl,
                creatorId: this.currentUser._id,
                tags: imgTags,
              };

              vehiclePromises.push(_this.vehicleService.addImage(imageDoc, vehicle, _this.currentUser));
            }

            vehiclePromises.push(_this.imageDocService.refreshImagesByParent(DbCollectionsEnum.VEHICLES, vehicle._id, false, _this.currentUser))
          });

          await Promise.allSettled(vehiclePromises)
            .then((vehicleResults) => {
              let isProcessing = false;
              vehicleResults.forEach((pResult) => {
                if (pResult.status === 'rejected') {
                  errors.push(pResult.reason);
                } else {
                  if (pResult.value.processing) {
                    isProcessing = true;
                  }
                }
              });

              if (errors.length > 0) {
                _this.errorService.handleError(`Error uploading images for vehicleId ${vehicle._id}:  ${errors.join(',')}`);
              }

              _this.settingsService.setIsLoading(false);
              if (isProcessing) {
                let message = 'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.';

                if (errors.length > 0) {
                  message += `\n\nPlease note that there were some errors processing your files.  Contact ${environment.techSupportEmail} with any questions.`
                }

                Swal.fire(
                  'File Upload in Process',
                  message,
                  'info'
                );
              } else if (errors.length > 0 && _this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  'Error Uploading Vehicle Image(s)',
                  `There were one or more error processing your images.  Please email ${environment.techSupportEmail}`,
                  'error'
                );
              }
            });
        });

    } else {
      _this.errorMsg = true;
      _this.errorText = 'Please select an image';
    }
  }

  async snapshotFileInputChange($event: Event): Promise<any> {
    const _this = this;
    this.errorMsg = false;
    const FILE = ($event.target as HTMLInputElement).files[0];
    this.snapshotFileObj = FILE;
    const reportSections = this.reportService.getReportSections();

    if (!this.snapshotFileObj) {
      this.errorMsg = true;
      this.errorText = 'Please select a snapshot';
    } else if (!reportSections || reportSections.length == 0) {
      _this.errorMsg = true;
      _this.errorText = 'Unable to locate report sections to add snapshot to';
      _this.errorService.handleError(this.errorText);
      if (_this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire('Error Adding Snapshot', `${this.errorText}.  Please email ${environment.techSupportEmail}`, 'error');
      }
    } else {
      this.settingsService.setIsLoading(true);
      const fileType1 = this.snapshotFileObj.type;
      const fileType = this.snapshotFileObj.name
        .substring(this.snapshotFileObj.name.lastIndexOf('.') + 1)
        .toUpperCase();
      const vehicle = this.vehicleService.getCurrentVehicle();
      let imgType = TagFileTypesEnum.SNAPSHOT;
      let validReportSections = this.reportService.getReportSectionsUserCanAddNoteTo(DbCollectionsEnum.VEHICLES);
      let reportSectionId, reportSection;

      const tags = [];
      const imgTags = [];
      const tag = createTag(TagTypesEnum.FILE_TYPE, imgType);
      tags.push(tag);
      imgTags.push(tag.value);
      imgTags.push('vehicle');
      imgTags.push(vehicle.name);

      if (validReportSections.length > 0) {
        if (validReportSections.length === 1) {
          reportSectionId = validReportSections[0]._id;
        } else {
          const reportSectionOptions = {};
          validReportSections.map((reportSection) => {
            reportSectionOptions[reportSection._id] = reportSection.titleCase;
          });

          const { value: selectedReportSection } = await Swal.fire({
            title: 'Select Report Section for the Snapshot',
            input: 'select',
            inputOptions: reportSectionOptions,
            inputPlaceholder: 'Select a report section',
            showCancelButton: true,
          });

          if (selectedReportSection) {
            reportSectionId = selectedReportSection;
          }
        }

        reportSectionId = reportSectionId || ReportSectionIdsEnum.SNAPSHOT;
        reportSection = reportSections.find((reportSection) => reportSection._id === reportSectionId);

        this.fileService
          .uploadFile(this.snapshotFileObj, FileObjectTypesEnum.VEHICLE, vehicle._id, tags, imgType, this.currentUser)
          .then((results) => {
            this.settingsService.setIsLoading(true);

            const imageDoc: ImageDoc = {
              _id: new ObjectID().toString(),
              displayThumbnailUrl: results.thumbnailDisplayUrl,
              displayUrl: results.locationDisplayUrl,
              isMainImage: false,
              isSnapshot: true,
              name: results.nameWithoutExtension,
              parent: {
                _id: vehicle._id,
                collection: DbCollectionsEnum.VEHICLES,
              },
              reportSectionId: reportSectionId,
              url: results.locationUrl,
              thumbnailUrl: results.thumbnailUrl || environment.defaultThumbnailImageUrl,
              creatorId: this.currentUser._id,
              tags: imgTags,
            };

            this.vehicleService
              .addImage(imageDoc, vehicle, this.currentUser)
              .then((imageResults) => {
                this.errorMsg = false;
                this.errorText = '';
                this.settingsService.setIsLoading(false);

                if (results.processing) {
                  Swal.fire(
                    'File Upload in Process',
                    'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.',
                    'info'
                  );
                }
              })
              .catch((imageError) => {
                _this.settingsService.setIsLoading(false);
                _this.errorMsg = true;
                _this.errorText = `${imageError}`;
                if (_this.settingsService.getShowPopupErrorMessages()) {
                  Swal.fire(
                    'Error Saving Vehicle Snapshot',
                    `${imageError}.  Please email ${environment.techSupportEmail} with any questions.`,
                    'error'
                  );
                }
              });
          })
          .catch((uploadError) => {
            _this.settingsService.setIsLoading(false);
            _this.errorService.handleError(`Error uploading vehicle snapshot: ${uploadError}`);
            _this.errorMsg = true;
            _this.errorText = `${uploadError.message}`;
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error Uploading Vehicle Snapshot',
                `There was an error uploading the snapshot:  ${uploadError.message}.  Please email ${environment.techSupportEmail}`,
                'error'
              );
            }
          });
      } else {
        const errMessage = _this.errorService.handleError(
          `Report sections cannot be found so a snapshot cannot be added.`
        );
        _this.errorMsg = true;
        _this.errorText = errMessage;
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Adding Snapshot`,
            `Invalid note report section.  Please email ${environment.techSupportEmail}`,
            'error'
          );
        }
      }
    }
  }

  getReportSectionId(reportSection: string) {
    return ReportSectionIdsEnum[reportSection];
  }

  getReportSectionTitleCase(reportSections: ReportSection[], reportSectionId: string) {
    let returnValue = '';
    let matching;

    if (Array.isArray(reportSections) && reportSections.length > 0 && reportSectionId) {
      matching = reportSections.find((reportSection) => reportSection._id === reportSectionId);
    }

    returnValue = matching ? matching.titleCase : '';
    return returnValue;
  }

  getReportSectionsUserCanAddNoteTo(reportSections: ReportSection[], parentCollection: string) {
    return reportSections.filter((reportSection) => {
      let returnValue = true;
      const idx = reportSection.parentCollections.indexOf(parentCollection);

      if (!reportSection.userCanAddNotes || idx === -1) {
        returnValue = false;
      }

      return returnValue;
    });
  }

  getNonSnapshotImages(vehicleImages: ImageDoc[]) {
    if (vehicleImages && vehicleImages.length > 0) {
      return vehicleImages.filter((img) => !img.isSnapshot);
    }
  }

  getNonSnapshotNotes(vehicleNotes: Note[]) {
    if (vehicleNotes && vehicleNotes.length > 0) {
      return vehicleNotes.filter((note) => !note.imageId);
    }
  }

  getSnapshotImages(vehicleImages: ImageDoc[]) {
    if (vehicleImages && vehicleImages.length > 0) {
      return vehicleImages.filter((img) => img.isSnapshot);
    }
  }

  getSnapshotNotes(vehicleNotes: Note[], imageId: string) {
    if (vehicleNotes && vehicleNotes.length > 0) {
      return vehicleNotes.filter((note) => note.imageId === imageId);
    }
  }

  getYesOrNo(value: boolean) {
    return getYesOrNo(value);
  }

  editVehicle(vehicle: Vehicle, currentUser: User) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      allowVehicleModels3d: this.vehicleService.getAllowVehicleModels3d(),
      allowVehicleScans: this.vehicleService.getAllowVehicleScans(),
      currentUser: currentUser,
      model3d: null,
      vehicle: vehicle,
      isNewVehicle: false,
    };

    const dialogRef = this.dialog.open(VehicleDialogComponent, dialogConfig);
  }

  initVideo() {
    this.videoData.play();
  }

  nextVideo(): void {
    const vehicleVideos = this.vehicleService.getCurrentVehicleVideos();
    this.activeVideoIndex += 1;
    if (this.activeVideoIndex == vehicleVideos.length) {
      this.activeVideoIndex = 0;
    }
    this.currentVideo = vehicleVideos[this.activeVideoIndex];
  }

  startVideoPlaylist(video: Video, index: number): void {
    this.activeVideoIndex = index;
    this.currentVideo = video;
  }

  openVehicleViewer(vehicle: Vehicle, model3d: Model3d, scan: Scan) {
    this.settingsService.setIsLoading(true);
    const promises = [];

    if (vehicle && this.currentUser && (model3d || scan)) {
      promises.push(this.vehicleService.setVehicleModel3d(vehicle, model3d, this.currentUser));
      promises.push(this.vehicleService.setVehicleScan(vehicle, scan, this.currentUser));
      promises.push(
        this.modService.getModById(model3d ? model3d.modId : null, DbCollectionsEnum.VEHICLES, vehicle._id, this.currentUser)
      );
      promises.push(this.modService.getModById(scan ? scan.modId : null, DbCollectionsEnum.VEHICLES, vehicle._id, this.currentUser));

      Promise.allSettled(promises).then((results) => {
        const modSourceId = model3d ? model3d._id : scan._id;
        const source = model3d ? DbCollectionsEnum.MODELS3D : DbCollectionsEnum.SCANS;
        let navigationUrl = `/vehicles/${vehicle._id}/viewer?sceneName=${UnrealScenesEnum.CALIBRATION}`;
        this.isOpeningUnrealViewer = true;

        const vehicleMod = this.modService.getCurrentVehicleMod();

        if (vehicleMod) {
          navigationUrl += `&vehicleName=${vehicleMod.name}`;
        }

        this.router
          .navigateByUrl(navigationUrl)
          .then(() => {
            this.logService.logInfo(`successfully navigated to ${navigationUrl}`);
          })
          .catch((unrealError) => {
            this.settingsService.setIsLoading(false);
            const errMessage = this.errorService.handleError(
              `Error loading ${environment.unreal.viewerName} at ${navigationUrl}: ${unrealError.message}`
            );
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                `Error Opening ${environment.unreal.viewerName}`,
                `${unrealError}.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          })
          .finally(() => {
            this.settingsService.setIsLoading(false);
            this.settingsService.setLoadingId(null);
          });
      });
    } else {
      this.settingsService.setIsLoading(false);
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          `Error Opening ${environment.unreal.viewerName}`,
          `Vehicle, 3D model or scan and user are required.  Please email ${environment.techSupportEmail}`,
          'error'
        );
      }
    }
  }

  updateNotifications(getNotifications: boolean, vehicle: Vehicle) {
    if (this.currentUser && vehicle) {
      const idx = vehicle.userIdsToSendChangeNotificationsTo.indexOf(this.currentUser._id);
      let changes = {
        editorId: this.currentUser._id,
        userIdsToSendChangeNotificationsTo: vehicle.userIdsToSendChangeNotificationsTo
      };
      let madeChanges = false;

      if (getNotifications && idx === -1) {
        madeChanges = true;
        changes.userIdsToSendChangeNotificationsTo.push(this.currentUser._id);
      } else if (!getNotifications && idx > -1) {
        madeChanges = true;
        delete changes.userIdsToSendChangeNotificationsTo[idx];
      }

      if (madeChanges) {
        this.settingsService.setIsLoading(true);
        this.vehicleService
          .saveVehicle(vehicle._id, changes, this.currentUser)
          .then((updatedVehicle: Vehicle) => {
            this.settingsService.setIsLoading(false);
          })
          .catch((error) => {
            this.settingsService.setIsLoading(false);
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error',
                `There was an error updating your notifications preference.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          });
      }
    }
  }

  async videoFileInputChange($event: Event): Promise<any> {
    const _this = this;
    _this.errorMsg = false;
    const fileEl = $event.target as HTMLInputElement;
    const vehicle = this.vehicleService.getCurrentVehicle();

    if (fileEl?.files.length > 0 && vehicle) {
      _this.settingsService.setIsLoading(true);
      const errors = [];
      const promises = [];
      const files = fileEl.files;
      const tags = [];
      const videoTags = [];
      const tag = createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.VIDEO);
      tags.push(tag);
      videoTags.push(tag.value);
      videoTags.push('vehicle');
      videoTags.push(vehicle.name);

      for (let x = 0; x < files.length; x++) {
        const FILE = files[x];
        promises.push(_this.fileService.uploadFile(FILE, FileObjectTypesEnum.VEHICLE, vehicle._id, tags, TagFileTypesEnum.VIDEO, _this.currentUser))
      };

      await Promise.allSettled(promises)
        .then(async (results) => {
          const vehiclePromises = [];
          results.forEach((result) => {
            if (result.status === 'rejected') {
              errors.push(result.reason);
            } else {
              const video: Video = {
                _id: new ObjectID().toString(),
                encodedUrl: result.value.encodedVideoUrl,
                name: result.value.nameWithoutExtension,
                parent: {
                  _id: vehicle._id,
                  collection: DbCollectionsEnum.VEHICLES,
                },
                url: result.value.locationUrl,
                creatorId: this.currentUser._id,
                tags: videoTags,
              };

              vehiclePromises.push(_this.vehicleService.addVideo(video, vehicle, _this.currentUser));
            }

            vehiclePromises.push(_this.videoService.refreshVideosByParent(DbCollectionsEnum.VEHICLES, vehicle._id, _this.currentUser));
          });

          await Promise.allSettled(vehiclePromises)
            .then((vehicleResults) => {
              let isProcessing = false;
              vehicleResults.forEach((pResult) => {
                if (pResult.status === 'rejected') {
                  errors.push(pResult.reason);
                } else {
                  if (pResult.value.processing) {
                    isProcessing = true;
                  }
                }
              });

              if (errors.length > 0) {
                _this.errorService.handleError(`Error uploading videos for vehicleId ${vehicle._id}:  ${errors.join(',')}`);
              }

              _this.settingsService.setIsLoading(false);
              if (isProcessing) {
                let message = 'Due to the large file size, your files are being uploaded in the background.  You will be emailed a success or failure message upon completion.';

                if (errors.length > 0) {
                  message += `\n\nPlease note that there were some errors processing your files.  Contact ${environment.techSupportEmail} with any questions.`
                }

                Swal.fire(
                  'File Upload in Process',
                  message,
                  'info'
                );
              } else if (errors.length > 0 && _this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  'Error Uploading Vehicle Video(s)',
                  `There were one or more error processing your videos.  Please email ${environment.techSupportEmail}`,
                  'error'
                );
              }
            });
        });
    } else {
      _this.errorMsg = true;
      _this.errorText = 'Please select a video';
    }
  }

  //see https://www.positronx.io/angular-video-player-using-ngx-videogular-with-customized-controls-example/
  videoPlayerInit(vData: any) {
    this.videoData = vData;
    this.videoData.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.initVideo.bind(this));
    this.videoData.getDefaultMedia().subscriptions.ended.subscribe(this.nextVideo.bind(this));
  }
}
