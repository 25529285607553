import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, from, throwError, of, zip } from 'rxjs';
import { catchError, filter, map, switchMap, tap, toArray } from 'rxjs/operators';

import { DataSource, Manufacturer, Mod, Scan, Scanner, User } from '@shared/models';
import { DbCollectionsEnum, ScanDisplayTypesEnum } from '@shared/enums';
import { getCustomHeaders } from '@shared/utils';

import {
  DataSourceService,
  ErrorService,
  LogService,
  ManufacturerService,
  ModService,
  ScannerService,
  SettingsService,
  UserService
} from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class ScanService {
  private currentShipPanoramicScanSubject = new BehaviorSubject<Scan>(null);
  private currentShipScanSubject = new BehaviorSubject<Scan>(null);
  private currentShipPanoramicScansSubject = new BehaviorSubject<Scan[]>(null);
  private currentShipPointCloudScansSubject = new BehaviorSubject<Scan[]>(null);
  private currentShipScansSubject = new BehaviorSubject<Scan[]>(null);
  private currentShipScansWithModsSubject = new BehaviorSubject<Scan[]>(null);
  private currentShipScanCreatorSubject = new BehaviorSubject<User>(null);
  private currentShipScanDataSourceSubject = new BehaviorSubject<DataSource>(null);
  private currentShipScanScannerSubject = new BehaviorSubject<Scanner>(null);
  private currentShipScanManufacturerSubject = new BehaviorSubject<Manufacturer>(null);
  private currentVehicleScanSubject = new BehaviorSubject<Scan>(null);
  private currentVehicleScansSubject = new BehaviorSubject<Scan[]>(null);
  private currentVehicleScansWithModsSubject = new BehaviorSubject<Scan[]>(null);
  private currentVehicleScanCreatorSubject = new BehaviorSubject<User>(null);
  private currentVehicleScanDataSourceSubject = new BehaviorSubject<DataSource>(null);
  private currentVehicleScanScannerSubject = new BehaviorSubject<Scanner>(null);
  private currentVehicleScanManufacturerSubject = new BehaviorSubject<Manufacturer>(null);
  private modsSubject = new BehaviorSubject<Mod[]>(null);
  private scansSubject = new BehaviorSubject<Scan[]>([]);
  private scanErrorSubject = new BehaviorSubject<string>(null);
  private shipScansSubject = new BehaviorSubject<Scan[]>(null);
  private updatedScanIdSubject = new BehaviorSubject<string>(null);
  private vehicleScansSubject = new BehaviorSubject<Scan[]>(null);
  currentShipPanoramicScan$: Observable<Scan> = this.currentShipPanoramicScanSubject.asObservable();
  currentShipScan$: Observable<Scan> = this.currentShipScanSubject.asObservable();
  currentShipPanoramicScans$: Observable<Scan[]> = this.currentShipPanoramicScansSubject.asObservable();
  currentShipPointCloudScans$: Observable<Scan[]> = this.currentShipPointCloudScansSubject.asObservable();
  currentShipScans$: Observable<Scan[]> = this.currentShipScansSubject.asObservable();
  currentShipScansWithMods$: Observable<Scan[]> = this.currentShipScansWithModsSubject.asObservable();
  currentShipScanCreator$: Observable<User> = this.currentShipScanCreatorSubject.asObservable();
  currentShipScanDataSource$: Observable<DataSource> = this.currentShipScanDataSourceSubject.asObservable();
  currentShipScanScanner$: Observable<Scanner> = this.currentShipScanScannerSubject.asObservable();
  currentShipScanManufacturer$: Observable<Manufacturer> = this.currentShipScanManufacturerSubject.asObservable();
  currentVehicleScan$: Observable<Scan> = this.currentVehicleScanSubject.asObservable();
  currentVehicleScans$: Observable<Scan[]> = this.currentVehicleScansSubject.asObservable();
  currentVehicleScansWithMods$: Observable<Scan[]> = this.currentVehicleScansWithModsSubject.asObservable();
  currentVehicleScanCreator$: Observable<User> = this.currentVehicleScanCreatorSubject.asObservable();
  currentVehicleScanDataSource$: Observable<DataSource> = this.currentVehicleScanDataSourceSubject.asObservable();
  currentVehicleScanScanner$: Observable<Scanner> = this.currentVehicleScanScannerSubject.asObservable();
  currentVehicleScanManufacturer$: Observable<Manufacturer> = this.currentVehicleScanManufacturerSubject.asObservable();
  mods$: Observable<Mod[]> = this.modsSubject.asObservable();
  scans$: Observable<Scan[]> = this.scansSubject.asObservable();
  scanError$: Observable<string> = this.scanErrorSubject.asObservable();
  shipScans$: Observable<Scan[]> = this.shipScansSubject.asObservable();
  updatedScanId$: Observable<string> = this.updatedScanIdSubject.asObservable();
  vehicleScans$: Observable<Scan[]> = this.vehicleScansSubject.asObservable();

  constructor(
    private dataSourceService: DataSourceService,
    private errorService: ErrorService,
    private logService: LogService,
    private manufacturerService: ManufacturerService,
    private modService: ModService,
    private settingsService: SettingsService,
    private scannerService: ScannerService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.mods$ = this.modService.mods$;
  }

  async createNewScan(scanPayload: Scan, modPayload: Mod, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && modPayload && parentCollection && parentId && scanPayload) {
        _this.settingsService.setIsLoading(true);
        const url = `${environment.baseAPIUrl}scan/create?userId=${currentUser?._id}`;
        let scans;
        let returnValue: Scan;
        let isValidParentCollection = true;

        switch (parentCollection) {
          case DbCollectionsEnum.SHIPS:
            scans = _this.currentShipScansSubject.getValue();
            break;
          case DbCollectionsEnum.VEHICLES:
            scans = _this.currentVehicleScansSubject.getValue();
            break;
          default:
            isValidParentCollection = false;
            break;
        }

        if (isValidParentCollection) {
          const newScans = scans ? scans.slice(0) : [];

          const payload = {
            scan: scanPayload,
            mod: modPayload
          };

          _this.httpClient
            .post(url, payload, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (newScan: Scan) => {
                returnValue = newScan;
                const promises = [];

                promises.push(_this.updateChildData(newScan, parentCollection, parentId, currentUser));
                promises.push(_this.modService.refreshModsByParent(parentCollection, parentId, currentUser));

                Promise.allSettled(promises).then((results) => {
                  const childResults = results[0];
                  const modResults = results[1];

                  if (childResults.status === 'fulfilled') {
                    returnValue = childResults.value;
                    newScans.push(returnValue);
                    _this.scanErrorSubject.next('');
                  } else {
                    const errMessage = _this.errorService.handleError(
                      `Error updating child data for scans ${parentCollection} id ${parentId}: ${childResults.reason}`
                    );
                    _this.scanErrorSubject.next(errMessage);
                  }

                  if (modResults.status === 'rejected') {
                    const errMessage = _this.errorService.handleError(
                      `Error refreshing mods for scans parent ${parentCollection} id ${parentId}: ${modResults.reason}`
                    );
                  }

                  switch (parentCollection) {
                    case DbCollectionsEnum.SHIPS:
                      _this.currentShipScanSubject.next(returnValue);
                      _this.currentShipScansSubject.next(newScans);
                      break;
                    case DbCollectionsEnum.VEHICLES:
                      _this.currentVehicleScanSubject.next(returnValue);
                      _this.currentVehicleScansSubject.next(newScans);
                      break;
                  }

                  _this.updatedScanIdSubject.next(newScan._id);
                  resolve(returnValue);
                });
              },
              error: (error: HttpErrorResponse) => {
                _this.settingsService.setIsLoading(false);
                const errMessage = _this.errorService.handleError(error);
                _this.scanErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {

              }
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(`${parentCollection} is not a valid scan parent collection`);
          _this.scanErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `modPayload, scanPayload, parentCollection, parentId and user are required to create a scan`;
        _this.errorService.handleError(errMessage);
        _this.scanErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async deleteScan(scanId: string, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && parentCollection && parentId && scanId) {
        _this.settingsService.setIsLoading(true);
        let returnValue: Scan[];
        let isValidParentCollection = true;

        if (scanId && parentCollection && parentId && currentUser) {
          const url = `${environment.baseAPIUrl}scan/${scanId}?userId=${currentUser._id}`;

          switch (parentCollection) {
            case DbCollectionsEnum.SHIPS:
              returnValue = _this.currentShipScansSubject.getValue();
              break;
            case DbCollectionsEnum.VEHICLES:
              returnValue = _this.currentVehicleScansSubject.getValue();
              break;
            default:
              isValidParentCollection = false;
              break;
          }

          if (isValidParentCollection) {
            _this.httpClient
              .delete(url, {
                headers: getCustomHeaders(true),
                responseType: 'json',
              })
              .subscribe({
                next: (deletedScan: Scan) => {
                  const promises = [];
                  promises.push(_this.refreshScansByParent(parentCollection, parentId, currentUser));
                  promises.push(_this.modService.refreshModsByParent(parentCollection, parentId, currentUser));

                  Promise.allSettled(promises).then((results) => {
                    const scanResults = results[0];
                    if (scanResults.status === 'fulfilled') {
                      returnValue = scanResults.value;

                      switch (parentCollection) {
                        case DbCollectionsEnum.SHIPS:
                          _this.currentShipScansSubject.next(returnValue);
                          break;
                        case DbCollectionsEnum.VEHICLES:
                          _this.currentVehicleScansSubject.next(returnValue);
                          break;
                      }

                      _this.updatedScanIdSubject.next(`deleted-${scanId}`);
                      resolve(returnValue);
                    } else {
                      const errMessage = _this.errorService.handleError(
                        `Error deleting scanId ${scanId}: ${scanResults.reason}`
                      );
                      _this.scanErrorSubject.next(errMessage);
                      reject(errMessage);
                    }
                  });
                },
                error: (error: HttpErrorResponse) => {
                  _this.settingsService.setIsLoading(false);
                  const errMessage = _this.errorService.handleError(`Error deleting scanId ${scanId}: ${error.error}`);
                  _this.scanErrorSubject.next(errMessage);
                  reject(error);
                },
                complete: () => {

                }
              });
          } else {
            _this.settingsService.setIsLoading(false);
            const errMessage = _this.errorService.handleError(`${parentCollection} is not a valid scan parent collection`);
            _this.scanErrorSubject.next(errMessage);
            reject(errMessage);
          }
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(
            `scanId, parentCollection and parentId are required to delete a scan`
          );
          _this.scanErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `parentCollection, parentId, scanId and user are required to delete a scan`;
        _this.errorService.handleError(errMessage);
        _this.scanErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  generateSiteFileName(pcScan: Scan, panoScan: Scan): string {
    let returnValue = 'default.json';

    try {
      if (pcScan && pcScan.scanDisplayType === ScanDisplayTypesEnum.POINT_CLOUD) {
        returnValue = `PC_${pcScan.modId.toString()}`;

        if (panoScan) {
          returnValue += `$_P_${pcScan.modId.toString()}`;
        }

        returnValue += '.json';
      } else if (panoScan) {
        returnValue = `P_${panoScan.modId.toString()}.json`;
      }
    } catch (ex) {
      const errMessage = this.errorService.handleError(
        `Error generating site file name for pointCloudId ${pcScan?._id}, panoramicScanId ${panoScan?._id}: ${ex.message}`
      );
      returnValue = 'default.json';
    }

    return returnValue;
  }

  getCurrentShipPanoramicScan() {
    return this.currentShipPanoramicScanSubject.getValue();
  }

  getCurrentShipPanoramicScans() {
    return this.currentShipPanoramicScansSubject.getValue();
  }

  getCurrentShipScan() {
    return this.currentShipScanSubject.getValue();
  }

  getCurrentShipPointCloudScans() {
    return this.currentShipPointCloudScansSubject.getValue();
  }

  getCurrentShipScans() {
    return this.currentShipScansSubject.getValue();
  }

  getCurrentShipScanCreator() {
    return this.currentShipScanCreatorSubject.getValue();
  }

  getCurrentShipScanDataSource() {
    return this.currentShipScanDataSourceSubject.getValue();
  }

  getCurrentShipScanManufacturer() {
    return this.currentShipScanManufacturerSubject.getValue();
  }

  getCurrentShipScanScanner() {
    return this.currentShipScanScannerSubject.getValue();
  }

  getCurrentVehicleScan() {
    return this.currentVehicleScanSubject.getValue();
  }

  getCurrentVehicleScans() {
    return this.currentVehicleScansSubject.getValue();
  }

  getCurrentVehicleScanCreator() {
    return this.currentVehicleScanCreatorSubject.getValue();
  }

  getCurrentVehicleScanDataSource() {
    return this.currentVehicleScanDataSourceSubject.getValue();
  }

  getCurrentVehicleScanManufacturer() {
    return this.currentVehicleScanManufacturerSubject.getValue();
  }

  getCurrentVehicleScanScanner() {
    return this.currentVehicleScanScannerSubject.getValue();
  }

  async getScanById(scanId: string, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      let isValidParentCollection = true;

      if (parentCollection) {
        switch (parentCollection) {
          case DbCollectionsEnum.SHIPS:
          case DbCollectionsEnum.VEHICLES:
            break;
          default:
            isValidParentCollection = false;
            break;
        }

        if (isValidParentCollection) {
          _this.settingsService.setIsLoading(true);
          let scans: Scan[];
          let returnValue: Scan;

          if (currentUser && parentId) {
            switch (parentCollection) {
              case DbCollectionsEnum.SHIPS:
                scans = _this.currentShipScansSubject.getValue();
                if (scanId && scans && scans.length > 0) {
                  returnValue = scans.find((scan) => scan._id === scanId);
                }
                _this.currentShipScanSubject.next(returnValue);

                if (returnValue && returnValue.panoramic?.scanId) {
                  const panoScan = scans.find((scan) => scan._id === returnValue.panoramic.scanId);
                  _this.currentShipPanoramicScanSubject.next(panoScan);
                } else {
                  _this.currentShipPanoramicScanSubject.next(null);
                }
                break;
              case DbCollectionsEnum.VEHICLES:
                scans = _this.currentVehicleScansSubject.getValue();
                if (scanId && scans && scans.length > 0) {
                  returnValue = scans.find((scan) => scan._id === scanId);
                }
                _this.currentVehicleScanSubject.next(returnValue);
                break;
            }
          } else {
            switch (parentCollection) {
              case DbCollectionsEnum.SHIPS:
                _this.currentShipScanSubject.next(returnValue);
                break;
              case DbCollectionsEnum.VEHICLES:
                _this.currentVehicleScanSubject.next(returnValue);
                break;
            }
          }

          _this
            .updateChildData(returnValue, parentCollection, parentId, currentUser)
            .then((updatedScan: Scan) => {
              returnValue = updatedScan;
              _this.scanErrorSubject.next('');
            })
            .catch((error) => {
              const errMessage = _this.errorService.handleError(
                `Error updating child data for scans ${parentCollection} id ${parentId}: ${error}`
              );
              _this.scanErrorSubject.next(errMessage);
            })
            .finally(() => {
              switch (parentCollection) {
                case DbCollectionsEnum.SHIPS:
                  _this.currentShipScanSubject.next(returnValue);
                  break;
                case DbCollectionsEnum.VEHICLES:
                  _this.currentVehicleScanSubject.next(returnValue);
                  break;
              }

              _this.settingsService.setIsLoading(false);
              resolve(returnValue);
            });
        } else {
          const errMessage = _this.errorService.handleError(`${parentCollection} is not a valid scan parent collection`);
          _this.scanErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `parentCollection is required to get a scan`;
        _this.errorService.handleError(errMessage);
        _this.scanErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async refreshScansByParent(parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (parentCollection) {
        _this.settingsService.setIsLoading(true);
        let returnValue: Scan[];
        let panoramicScans: Scan[];
        let pointCloudScans: Scan[];
        let scansWithMods: Scan[];
        let isValidParentCollection = true;

        switch (parentCollection) {
          case DbCollectionsEnum.SHIPS:
          case DbCollectionsEnum.VEHICLES:
            break;
          default:
            isValidParentCollection = false;
            break;
        }

        if (isValidParentCollection) {
          if (currentUser && parentId) {
            const url = `${environment.baseAPIUrl}scan/${parentCollection}/${parentId}?userId=${currentUser?._id}`;

            this.httpClient
              .get(url, {
                headers: getCustomHeaders(true),
                responseType: 'json',
              })
              .subscribe({
                next: (scans: Scan[]) => {
                  returnValue = scans;
                  const promises = [];

                  promises.push(_this.updateChildData(null, parentCollection, parentId, currentUser));
                  promises.push(_this.modService.refreshModsByParent(parentCollection, parentId, currentUser));

                  Promise.allSettled(promises).then((results) => {
                    const childResults = results[0];
                    const modResults = results[1];

                    if (childResults.status === 'fulfilled') {
                      this.scanErrorSubject.next('');
                    } else {
                      const errMessage = _this.errorService.handleError(
                        `Error updating child data for scans ${parentCollection} id ${parentId}: ${childResults.reason}`
                      );
                      this.scanErrorSubject.next(errMessage);
                    }

                    if (modResults.status === 'rejected') {
                      const errMessage = _this.errorService.handleError(
                        `Error refreshing mods for scans parent ${parentCollection} id ${parentId}: ${modResults.reason}`
                      );
                    }

                    switch (parentCollection) {
                      case DbCollectionsEnum.SHIPS:
                        _this.currentShipScansSubject.next(returnValue);

                        if (returnValue.length > 0) {
                          panoramicScans = returnValue.filter((scan) => scan.scanDisplayType === ScanDisplayTypesEnum.PANORAMIC_IMAGES);
                          pointCloudScans = returnValue.filter((scan) => scan.scanDisplayType === ScanDisplayTypesEnum.POINT_CLOUD);
                          scansWithMods = returnValue.filter((scan) => scan.modDetails && scan.modDetails.url != null);
                        }

                        this.currentShipPanoramicScansSubject.next(panoramicScans);
                        this.currentShipPointCloudScansSubject.next(pointCloudScans);
                        this.currentShipScansWithModsSubject.next(scansWithMods);
                        break;
                      case DbCollectionsEnum.VEHICLES:
                        _this.currentVehicleScansSubject.next(returnValue);

                        if (returnValue.length > 0) {
                          scansWithMods = returnValue.filter((scan) => scan.modDetails && scan.modDetails.url != null);
                        }

                        this.currentVehicleScansWithModsSubject.next(scansWithMods);
                        break;
                    }

                    resolve(returnValue);
                  });
                },
                error: (error: HttpErrorResponse) => {
                  const errMessage = this.errorService.handleError(error);
                  this.scanErrorSubject.next(errMessage);
                  reject(error);
                },
                complete: () => {
                  this.settingsService.setIsLoading(false);
                }
              });
          } else {
            switch (parentCollection) {
              case DbCollectionsEnum.SHIPS:
                _this.currentShipScansSubject.next(returnValue);
                break;
              case DbCollectionsEnum.VEHICLES:
                _this.currentVehicleScansSubject.next(returnValue);
                break;
            }

            _this.settingsService.setIsLoading(false);
            resolve(returnValue);
          }
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(`${parentCollection} is not a valid scan parent collection`);
          _this.scanErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `parentCollection is required to refresh scans by parent`;
        _this.errorService.handleError(errMessage);
        _this.scanErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveScan(scanId: string, parentCollection: string, parentId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && parentCollection && parentId && scanId) {
        _this.settingsService.setIsLoading(true);
        let scans: Scan[];
        let validParentCollection = true;
        let returnValue: Scan;

        switch (parentCollection) {
          case DbCollectionsEnum.SHIPS:
            scans = _this.currentShipScansSubject.getValue();
            break;
          case DbCollectionsEnum.VEHICLES:
            scans = _this.currentVehicleScansSubject.getValue();
            break;
          default:
            validParentCollection = false;
            break;
        }

        if (validParentCollection) {
          const scanIndex = scans.findIndex((scan) => scan._id === scanId);
          const newScans = scans.slice(0);
          const url = `${environment.baseAPIUrl}scan/${scanId}?userId=${currentUser?._id}`;

          _this.httpClient
            .put(url, changes, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (updatedScan: Scan) => {
                returnValue = updatedScan;

                _this.updateChildData(updatedScan, parentCollection, parentId, currentUser)
                  .then((scanWithChildData: Scan) => {
                    returnValue = scanWithChildData;
                    newScans[scanIndex] = scanWithChildData;
                  })
                  .catch((childError) => {
                    _this.scanErrorSubject.next(childError.message);
                  })
                  .finally(() => {
                    switch (parentCollection) {
                      case DbCollectionsEnum.SHIPS:
                        _this.currentShipScanSubject.next(returnValue);
                        _this.currentShipScansSubject.next(newScans);
                        break;
                      case DbCollectionsEnum.VEHICLES:
                        _this.currentVehicleScanSubject.next(returnValue);
                        _this.currentVehicleScansSubject.next(newScans);
                        break;
                    }

                    _this.updatedScanIdSubject.next(returnValue._id);
                    _this.settingsService.setIsLoading(false);
                    resolve(returnValue);
                  });
              },
              error: (error: HttpErrorResponse) => {
                _this.settingsService.setIsLoading(false);
                const errMessage = _this.errorService.handleError(error);
                _this.scanErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {

              }
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(`${parentCollection} is not a valid scan parent collection`);
          _this.scanErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `changes, parentCollection, parentId, scanId and user are required to update a scan`;
        _this.errorService.handleError(errMessage);
        _this.scanErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async updateChildData(scan: Scan, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      let returnValue: Scan;

      zip(
        _this.userService.users$,
        _this.scannerService.scanners$,
        _this.manufacturerService.manufacturers$,
        _this.mods$,
        _this.dataSourceService.dataSources$
      )
        .pipe(
          map(([users, scanners, manufacturers, mods, dataSources]) => ({
            users,
            scanners,
            manufacturers,
            mods,
            dataSources,
          }))
        )
        .subscribe({
          next: ({ users, scanners, manufacturers, mods, dataSources }) => {
            let user, dataSource, manufacturer, scanner;
            returnValue = scan;

            if (scan) {
              if (scan.creatorId) {
                user = users.find((user) => user._id === scan.creatorId);
              }

              if (scan.dataSourceId) {
                dataSource = dataSources.find((dataSource) => dataSource._id === scan.dataSourceId);
              }

              if (scan.scannerId) {
                scanner = scanners.find((scanner) => scanner._id === scan.scannerId);

                if (scanner && scanner.manufacturerId) {
                  //refresh the list of scanners for this manufacturer
                  this.scannerService.refreshScannersByManufacturer(scanner.manufacturerId, currentUser);
                  manufacturer = manufacturers.find((manufacturer) => manufacturer._id === scanner.manufacturerId);
                }
              }

              scan.scanUploader = user ? user.fullName : null;
              scan.dataSourceName = dataSource ? dataSource.name : '';
              scan.scannerName = scanner ? scanner.name : '';
              scan.scannerManufacturerName = manufacturer ? manufacturer.name : '';

              returnValue = scan;

              switch (parentCollection) {
                case DbCollectionsEnum.SHIPS:
                  _this.currentShipScanCreatorSubject.next(user);
                  _this.currentShipScanDataSourceSubject.next(dataSource);
                  _this.currentShipScanScannerSubject.next(scanner);
                  _this.currentShipScanManufacturerSubject.next(manufacturer);
                  break;
                case DbCollectionsEnum.VEHICLES:
                  _this.currentVehicleScanCreatorSubject.next(user);
                  _this.currentVehicleScanDataSourceSubject.next(dataSource);
                  _this.currentVehicleScanScannerSubject.next(scanner);
                  _this.currentVehicleScanManufacturerSubject.next(manufacturer);
                  break;
              }
            } else {
              switch (parentCollection) {
                case DbCollectionsEnum.SHIPS:
                  this.currentShipScanCreatorSubject.next(null);
                  this.currentShipScanDataSourceSubject.next(null);
                  this.currentShipScanScannerSubject.next(null);
                  this.currentShipScanManufacturerSubject.next(null);
                  break;
                case DbCollectionsEnum.VEHICLES:
                  this.currentVehicleScanCreatorSubject.next(null);
                  this.currentVehicleScanDataSourceSubject.next(null);
                  this.currentVehicleScanScannerSubject.next(null);
                  this.currentVehicleScanManufacturerSubject.next(null);
                  break;
              }
            }

            resolve(returnValue);
          },
          error: (error: any) => {
            const errMessage = this.errorService.handleError(
              `Error subscribing to child data for scanId ${scan?._id}: ${error.error}`
            );
            this.scanErrorSubject.next(errMessage);
            reject(errMessage);
          },
          complete: () => {

          }
        });
    });
  }
}
