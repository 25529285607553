<div class="content">
  <div *ngIf="showFilter">
    <mat-form-field class="table-filter">
      <mat-label>Search Reports</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Search"
        aria-label="Type search criteria to filter results"
        matTooltip="Type search criteria to filter results"
        #tableFilter
      />
    </mat-form-field>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="name"
    matSortDirection="asc"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Report Name</th>
      <td mat-cell *matCellDef="let reportOverview" style="cursor: pointer">
        <a aria-label="Click to view report" matTooltip="Click to view report" (click)="viewReport(reportOverview)">{{
          reportOverview.name
        }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="finalizedDate">
      <th mat-header-cell *matHeaderCellDef>Finalized Date</th>
      <td mat-cell *matCellDef="let reportOverview" style="cursor: pointer">
        <a aria-label="Click to view report" matTooltip="Click to view report" (click)="viewReport(reportOverview)">{{
          reportOverview.finalizedDate | date: 'MM/dd/yyyy'
        }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="projectName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Project</th>
      <td mat-cell *matCellDef="let reportOverview" style="cursor: pointer">
        <a aria-label="Click to view report" matTooltip="Click to view report" (click)="viewReport(reportOverview)">
          {{ reportOverview.projectName }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastUpdatedBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Modified By</th>
      <td mat-cell *matCellDef="let reportOverview" style="cursor: pointer">
        <a aria-label="Click to view report" matTooltip="Click to view report" (click)="viewReport(reportOverview)">
          {{ reportOverview.lastUpdatedBy }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastUpdatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Modified</th>
      <td mat-cell *matCellDef="let reportOverview" style="cursor: pointer">
        <a aria-label="Click to view report" matTooltip="Click to view report" (click)="viewReport(reportOverview)">
          {{ reportOverview.lastUpdatedAt | date: 'short' }}
        </a>
      </td>
    </ng-container>

    <!-- actions column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let reportOverview">
        <span>
          <button
            mat-icon-button
            color="primary"
            aria-label="View Report"
            matTooltip="View Report"
            (click)="viewReport(reportOverview)"
          >
            <img src="assets/img/Search.png" alt="View" />
          </button>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedReportOverviewColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedReportOverviewColumns"></tr>

    <!-- Row shown when there is no matching data -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">*** No matching reports ***</td>
    </tr>
  </table>

  <mat-paginator *ngIf="showPaginator">
    [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons aria-label="Select page of reports"
  </mat-paginator>
</div>
