<div class="content">
  <mat-card appearance="outlined" class="vehicle-card mat-elevation-z10">
    <mat-card-header (click)="viewVehicle(vehicle)">
      <mat-card-title>{{ vehicle.name }}</mat-card-title>
      <mat-card-subtitle>{{ vehicleDesignation }}</mat-card-subtitle>
    </mat-card-header>
    <img
      mat-card-image
      src="{{ vehicle.displayThumbnailUrl || vehicle.displayUrl || vehicle.imageThumbnailUrl || vehicle.imageUrl  }}"
      alt="Image for {{ vehicle.name }}"
      (click)="viewVehicle(vehicle)"
      onerror="this.onerror=null; this.src='../../../../assets/img/SDAT.png';"
    />
    <mat-card-actions>
      <a
        mat-icon-button
        color="warn"
        aria-label="Delete Vehicle"
        matTooltip="Delete Vehicle"
        *ngIf="canDelete"
        (click)="deleteVehicle(vehicle)"
      >
        <img src="assets/img/TrashGray.png" alt="Delete" />
      </a>
      <a
        mat-icon-button
        color="primary"
        aria-label="View / Edit Vehicle"
        matTooltip="View / Edit Vehicle"
        (click)="viewVehicle(vehicle)"
      >
        <img src="assets/img/Search.png" alt="View" />
      </a>
    </mat-card-actions>
    <mat-card-footer (click)="viewVehicle(vehicle)">
      <p style="text-align: right; font-style: italic; font-size: smaller; margin-right: 15px">
        Last Modified: {{ vehicle.updatedAt | date: 'short' }}
      </p>
    </mat-card-footer>
  </mat-card>
</div>
