import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Document, User } from '@shared/models';
import { DocumentService, ErrorService, FileService, LogService, SettingsService, UserService } from '@shared/services';
import { UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html',
  styleUrls: ['./document-table.component.css'],
})
export class DocumentTableComponent implements OnInit {
  @Input()
  documents: Document[];

  @Input()
  showPaginator: boolean;

  @Input()
  showFilter: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentUser$: Observable<User>;
  displayedDocumentColumns: string[] = ['name', 'documentType', 'createdAt', 'actions'];
  private documentErrorSubject = new BehaviorSubject<string>(null);
  private documentSafeUrlSubject = new BehaviorSubject<SafeResourceUrl>(null);
  documentError$: Observable<string> = this.documentErrorSubject.asObservable();
  documentSafeUrl$: Observable<SafeResourceUrl> = this.documentSafeUrlSubject.asObservable();
  userDocuments$: Observable<Document[]>;
  dataSource;

  constructor(
    private documentService: DocumentService,
    private domSanitizer: DomSanitizer,
    private errorService: ErrorService,
    private fileService: FileService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.userDocuments$ = this.documentService.selectedUserDocuments$;
    this.dataSource = new MatTableDataSource(this.documents);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    //table isn't refreshing when documents are deleted
    this.userDocuments$.subscribe((docs) => {
      this.dataSource = new MatTableDataSource(docs);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get safeDocumentUrl(): SafeResourceUrl {
    return this.documentSafeUrlSubject.getValue();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  canDelete(doc: Document): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === doc.creatorId) {
      returnValue = true;
    }

    return returnValue;
  }

  async deleteDocument(doc: Document, currentUser: User): Promise<any> {
    const _this = this;

    if (doc?.parent?.collection) {
      Swal.fire({
        title: `Do you want to delete the ${doc.name} document?`,
        showCancelButton: true,
        confirmButtonText: 'Delete',
      }).then((result) => {
        if (result.isConfirmed) {
          this.documentService
            .deleteDocument(doc._id, doc.parent.collection, doc.parent._id, currentUser)
            .then(function (deleteResults) {
              console.log(deleteResults);
            })
            .catch(function (deleteError) {
              _this.errorService.handleError(`Error deleting docId ${doc._id}:  ${deleteError}`);
              if (_this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire('Error Deleting Document', deleteError.message, 'error');
              }
            });
        }
      });
    } else {
      _this.errorService.handleError(`Unable to delete docId ${doc._id} because it does not have a valid parent.`);
      if (_this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error Deleting Document',
          `A document must be tied to a valid parent.  Please email ${environment.techSupportEmail}`,
          'error'
        );
      }
    }
  }

  async download(doc: Document, currentUser: User) {
    if (doc?.url && currentUser) {
      try {
        this.settingsService.setIsLoading(true);
        const downloadUrl = await this.fileService.getPresignedUrl(doc.url, doc.name, currentUser, false);
        this.documentSafeUrlSubject.next(this.domSanitizer.bypassSecurityTrustResourceUrl(downloadUrl));
        if (downloadUrl) {
          const element = document.getElementById('iframeForDownload') as HTMLElement;
          if (element) {
            element.click();
          }
        }
      } catch (ex) {
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Downloading Document ${doc.name}`,
            `There was an error downloading the file.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      } finally {
        this.settingsService.setIsLoading(false);
      }
    } else {
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error',
          `No document was provided to download.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
			}
    }
  }

  async viewDocument(doc: Document, currentUser: User) {
    if (doc?.url && currentUser) {
      try {
        this.settingsService.setIsLoading(true);
        const downloadUrl = await this.fileService.getPresignedUrl(doc.url, doc.name, currentUser, true);
        if (downloadUrl) {
          window.open(downloadUrl, '_blank');
        }
      } catch (ex) {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Viewing Document ${doc.name}`,
            `There was an error viewing the file.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      } finally {
        this.settingsService.setIsLoading(false);
      }
    } else {
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error',
          `No document was provided to view.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
			}
    }
  }

  viewDocument_old(url: string): void {
    window.open(url, '_blank');
  }
}
