import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2';

import { Mod, User } from '@shared/models';
import { ErrorService, LogService, ModService, SettingsService, UserService } from '@shared/services';
import { ModStatesEnum, UserRolesEnum } from '@shared/enums';

@UntilDestroy()
@Component({
  selector: 'app-mods-dashboard',
  templateUrl: './mods-dashboard.component.html',
  styleUrls: ['./mods-dashboard.component.css'],
})
export class ModsDashboardComponent implements OnInit {
  currentUser$: Observable<User>;
  shipModsInProcessing$: Observable<Mod[]>;
  shipPointCloudModsInProcessing$: Observable<Mod[]>;
  vehicleModsInProcessing$: Observable<Mod[]>;
  vehicleModsNeedingAdjustment$: Observable<Mod[]>;

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private modService: ModService,
    private settingsService: SettingsService,
    private userService: UserService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.settingsService.setIsLoading(true);
    this.currentUser$ = this.userService.currentUser$;
    this.shipModsInProcessing$ = this.modService.shipModsInProcessing$;
    this.shipPointCloudModsInProcessing$ = this.modService.shipPointCloudModsInProcessing$;
    this.vehicleModsInProcessing$ = this.modService.vehicleModsInProcessing$;
    this.vehicleModsNeedingAdjustment$ = this.modService.vehicleModsNeedingAdjustment$;
  }

  ngAfterViewInit(): void {
    this.settingsService.setIsLoading(false);
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }
}
