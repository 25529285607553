<div class="content">
  <div
    *ngIf="{
    } as data"
  >
    <div *ngIf="showFilter">
      <mat-form-field class="table-filter">
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          aria-label="Type search criteria to filter results"
          matTooltip="Type search criteria to filter results"
          placeholder="Search"
          #tableFilter
        />
      </mat-form-field>
    </div>

    <table
      mat-table
      #table
      [dataSource]="dataSource"
      matSort
      matSortActive="name"
      matSortDirection="asc"
      class="mat-elevation-z8 scanTable"
    >
      <!-- name column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Scan Name</th>
        <td mat-cell *matCellDef="let scan" style="cursor: pointer">
          <a aria-label="Click to edit scan" matTooltip="Click to edit scan" (click)="editScan(scan)">{{
            scan.name
          }}</a>
        </td>
      </ng-container>

      <!-- scanDate column -->
      <ng-container matColumnDef="scanDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Scan Date</th>
        <td mat-cell *matCellDef="let scan" style="cursor: pointer">
          <a aria-label="Click to edit scan" matTooltip="Click to edit scan" (click)="editScan(scan)">{{
            scan.scanDate | date: 'shortDate'
          }}</a>
        </td>
      </ng-container>

      <!-- classification column -->
      <ng-container matColumnDef="classification">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Classification</th>
        <td mat-cell *matCellDef="let scan" style="cursor: pointer">
          <a aria-label="Click to edit scan" matTooltip="Click to edit scan" (click)="editScan(scan)">{{
            scan.classification
          }}</a>
        </td>
      </ng-container>

      <!-- scanType column -->
      <ng-container matColumnDef="scanType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Scan Type</th>
        <td mat-cell *matCellDef="let scan" style="cursor: pointer">
          <a aria-label="Click to edit scan" matTooltip="Click to edit scan" (click)="editScan(scan)">{{
            scan.scanType
          }}</a>
        </td>
      </ng-container>

      <!-- dataSourceName column -->
      <ng-container matColumnDef="dataSourceName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization</th>
        <td mat-cell *matCellDef="let scan" style="cursor: pointer">
          <a aria-label="Click to edit scan" matTooltip="Click to edit scan" (click)="editScan(scan)">{{
            scan.dataSourceName
          }}</a>
        </td>
      </ng-container>

      <!-- name of person scanning column -->
      <ng-container matColumnDef="nameOfPersonScanning">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name of Person Scanning</th>
        <td mat-cell *matCellDef="let scan" style="cursor: pointer">
          <a aria-label="Click to edit scan" matTooltip="Click to edit scan" (click)="editScan(scan)">{{
            scan.nameOfPersonScanning
          }}</a>
        </td>
      </ng-container>

      <!-- decimationLevel column -->
      <ng-container matColumnDef="decimationLevel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Decimation Level</th>
        <td mat-cell *matCellDef="let scan" style="cursor: pointer">
          <a aria-label="Click to edit scan" matTooltip="Click to edit scan" (click)="editScan(scan)"
            >{{ scan.decimationLevel.value }}{{ scan.decimationLevel.units }}</a
          >
        </td>
      </ng-container>

      <!-- registrationError column -->
      <ng-container matColumnDef="registrationError">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Registration Error</th>
        <td mat-cell *matCellDef="let scan" style="cursor: pointer">
          <a aria-label="Click to edit scan" matTooltip="Click to edit scan" (click)="editScan(scan)"
            >{{ scan.registrationError.value }}{{ scan.registrationError.units }}</a
          >
        </td>
      </ng-container>

      <!-- description column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let scan" style="cursor: pointer">
          <a aria-label="Click to edit scan" matTooltip="Click to edit scan" (click)="editScan(scan)">{{
            scan.description
          }}</a>
        </td>
      </ng-container>

      <!-- actions column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let scan">
          <mat-toolbar class="actionButtonBar">
            <a
              mat-icon-button
              aria-label="Delete Scan"
              matTooltip="Delete Scan"
              (click)="deleteScan(scan)"
              *ngIf="canDelete(scan)"
            >
              <img src="assets/img/TrashGray.png" alt="Delete" />
            </a>
            <div *ngIf="getHasValidMod(scan)">
              <a
                mat-icon-button
                aria-label="Open Viewer"
                matTooltip="{{ freeRoamTooltip }}"
                (click)="openViewer(scan)"
              >
                <img src="assets/img/UEViewer.png" alt="Open {{ unrealViewerName }}" />
              </a>
            </div>
            <div *ngIf="showViewerLite && getHasValidPanoMod(scan)">
              <a
                mat-icon-button
                aria-label="Open Viewer Lite"
                matTooltip="{{ freeRoamTooltipLite }}"
                (click)="openViewerLite(scan)"
              >
                <img src="assets/img/UEViewerLite.png" alt="Open {{ unrealViewerNameLite }}" />
              </a>
            </div>
            <a
              mat-icon-button
              aria-label="Edit Scan Data"
              matTooltip="Edit Scan Data"
              (click)="editScan(scan)"
              *ngIf="isAdmin"
            >
              <img src="assets/img/Edit.png" alt="Edit" />
            </a>
          </mat-toolbar>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedScanColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedScanColumns"></tr>

      <!-- Row shown when there is no matching data -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">*** No matching scans ***</td>
      </tr>
    </table>

    <mat-paginator *ngIf="showPaginator">
      [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons aria-label="Select page of scans"
    </mat-paginator>
  </div>
</div>
