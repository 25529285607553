import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { Mod, Ship, UnrealServer, User, Vehicle } from '@shared/models';
import {
  ErrorService,
  FileService,
  LogService,
  ModService,
  Model3dService,
  ScanService,
  SettingsService,
  ShipService,
  UnrealServerService,
  UserService,
  VehicleService,
} from '@shared/services';
import { DbCollectionsEnum, ModStatesEnum, UnrealScenesEnum, UserRolesEnum } from '@shared/enums';

import { ModDialogComponent } from '../mod-dialog/mod-dialog.component';

import { environment } from '@environment';

@UntilDestroy()
@Component({
  selector: 'app-mod-table',
  templateUrl: './mod-table.component.html',
  styleUrls: ['./mod-table.component.css'],
})
export class ModTableComponent implements OnInit {
  @Input()
  isModsNeedingAdjustment: boolean;

  @Input()
  mods: Mod[];

  @Input()
  isVehicleMods: boolean;

  @Input()
  showPaginator: boolean;

  @Input()
  showFilter: boolean;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  allowShipModels3d = true;
  allowShipScans = true;
  allowVehicleModels3d = true;
  allowVehicleScans = true;
  currentUser$: Observable<User>;
  displayedModColumns: string[];
  errorMsg: boolean = false;
  errorText: string = '';
  private modErrorSubject = new BehaviorSubject<string>(null);
  private modSafeUrlSubject = new BehaviorSubject<SafeResourceUrl>(null);
  modError$: Observable<string> = this.modErrorSubject.asObservable();
  modSafeUrl$: Observable<SafeResourceUrl> = this.modSafeUrlSubject.asObservable();
  dataSource;

  constructor(
    private errorService: ErrorService,
    private fileService: FileService,
    private logService: LogService,
    private modService: ModService,
    private model3dService: Model3dService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.dataSource = new MatTableDataSource(this.mods);
    this.allowShipModels3d = this.shipService.getAllowShipModels3d();
    this.allowShipScans = this.shipService.getAllowShipScans();
    this.allowVehicleModels3d = this.vehicleService.getAllowVehicleModels3d();
    this.allowVehicleScans = this.vehicleService.getAllowVehicleScans();

    if (this.isVehicleMods) {
      this.displayedModColumns = ['modName', 'vehicleName', 'vehicleDesignation'];

      if (this.allowVehicleModels3d) {
        this.displayedModColumns.push('model3dName');
      }

      if (this.allowVehicleScans) {
        this.displayedModColumns.push('scanName');
      }

      this.displayedModColumns.push('actions');
    } else {
      this.displayedModColumns = ['modName', 'shipName', 'shipDesignationWithHullNumber'];

      if (this.allowShipModels3d) {
        this.displayedModColumns.push('model3dName');
      }

      if (this.allowShipScans) {
        this.displayedModColumns.push('scanName');
      }

      this.displayedModColumns.push('actions');
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  get unrealViewerName(): string {
    return environment.unreal.viewerName;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async download(mod: Mod, currentUser: User) {
    const _this = this;
    if (mod && (mod.model3dDetails || mod.scanDetails) && currentUser) {
      try {
        _this.settingsService.setIsLoading(true);
        const fileResults = await _this.fileService.downloadFile(
          mod.model3dDetails ? mod.model3dDetails.url : mod.scanDetails.url,
          mod.name,
          currentUser,
          false
        );
        _this.modSafeUrlSubject.next(_this.domSanitizer.bypassSecurityTrustResourceUrl(fileResults.signedUrl));

        if (_this.modSafeUrlSubject.getValue()) {
          const element = document.getElementById('iframeForDownload') as HTMLElement;
          if (element) {
            element.click();
          }
        }
      } catch (ex) {
        _this.settingsService.setIsLoading(false);
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Downloading Asset file for ${mod.name}`,
            `There was an error downloading the file.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      }
    } else {
      if (_this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire('Error', `No asset was provided to download.  Please email ${environment.techSupportEmail}.`, 'error');
      }
    }
  }

  getModHasModel3dDetails(mod: Mod): boolean {
    let returnValue = false;

    if (mod && mod.model3dDetails) {
      returnValue = true;
    }

    return returnValue;
  }

  getModHasScanDetails(mod: Mod): boolean {
    let returnValue = false;

    if (mod && mod.scanDetails) {
      returnValue = true;
    }

    return returnValue;
  }

  getModHasShipDetails(mod: Mod): boolean {
    let returnValue = false;

    if (mod && mod.shipDetails) {
      returnValue = true;
    }

    return returnValue;
  }

  getModHasVehicleDetails(mod: Mod): boolean {
    let returnValue = false;

    if (mod && mod.vehicleDetails) {
      returnValue = true;
    }

    return returnValue;
  }

  async openViewer(mod: Mod) {
    const currentUser = this.userService.getCurrentUser();
    this.settingsService.setIsLoading(true);
    const promises = [];
    let navigationUrl, parentVehicle, model3d, scan;

    if (
      currentUser && 
      mod.modSource &&
      mod.modSource.collection &&
      mod.modSource._id &&
      mod.parent &&
      mod.parent.collection === DbCollectionsEnum.VEHICLES &&
      mod.parent._id
    ) {
      parentVehicle = await this.vehicleService.getVehicleById(mod.parent._id, currentUser);

      if (parentVehicle) {
        switch (mod.modSource.collection) {
          case DbCollectionsEnum.MODELS3D:
            model3d = await this.model3dService.getModel3dById(
              mod.modSource._id,
              mod.parent.collection,
              mod.parent._id, 
              currentUser
            );
            break;
          case DbCollectionsEnum.SCANS:
            scan = await this.scanService.getScanById(mod.modSource._id, mod.parent.collection, mod.parent._id, currentUser);
            break;
        }
      }

      if (parentVehicle && (model3d || scan)) {
        navigationUrl = `/vehicles/${parentVehicle._id}/viewer?sceneName=${UnrealScenesEnum.CALIBRATION}`;

        this.router
          .navigateByUrl(navigationUrl)
          .then(() => {
            this.logService.logInfo(`successfully navigated to ${navigationUrl}`);
          })
          .catch((unrealError) => {
            this.settingsService.setIsLoading(false);
            const errMessage = this.errorService.handleError(
              `Error loading ${environment.unreal.viewerName} at ${navigationUrl}: ${unrealError.message}`
            );
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                `Error Opening ${environment.unreal.viewerName}`,
                `${unrealError}.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          })
          .finally(() => {
            this.settingsService.setIsLoading(false);
            this.settingsService.setLoadingId(null);
          });
      } else {
        this.settingsService.setIsLoading(false);
        const allowModels3d = this.vehicleService.getAllowVehicleModels3d();
        const allowScans = this.vehicleService.getAllowVehicleScans();
        let errMessage = 'Vehicle and ';

        if (allowModels3d && allowScans) {
          errMessage += `3D model or scan `;
        } else if (allowModels3d) {
          errMessage += '3D model ';
        } else if (allowScans) {
          errMessage += `scan `;
        }

        errMessage += `are required.  Please email ${environment.techSupportEmail}.`;
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(`Error Opening ${environment.unreal.viewerName}`, errMessage, 'error');
        }
      }
    } else {
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error',
          `Unable to open ViPr Viewer as the mod is missing source or parent information.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
      }
    }
  }

  async upload(mod: Mod, currentUser: User) {
    const _this = this;
    const dialogConfig = new MatDialogConfig();

    if (mod || !mod.modSource._id || !mod.modSource.collection) {
      _this.settingsService.setIsLoading(true);
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      let refreshResults;

      dialogConfig.data = {
        currentUser: currentUser,
        isVehicle: mod.parent.collection === DbCollectionsEnum.VEHICLES ? true : false,
        mod: mod,
        model3d: null,
        scan: null,
      };

      switch (mod.modSource.collection) {
        case DbCollectionsEnum.MODELS3D:
          refreshResults = await _this.model3dService.refreshModels3dByParent(mod.parent.collection, mod.parent._id, dialogConfig.data.currentUser);
          dialogConfig.data.model3d = await _this.model3dService.getModel3dById(
            mod.modSource._id,
            mod.parent.collection,
            mod.parent._id,
            dialogConfig.data.currentUser
          );
          break;
        case DbCollectionsEnum.SCANS:
          refreshResults = await _this.scanService.refreshScansByParent(mod.parent.collection, mod.parent._id, dialogConfig.data.currentUser);
          dialogConfig.data.scan = await _this.scanService.getScanById(
            mod.modSource._id,
            mod.parent.collection,
            mod.parent._id,
            dialogConfig.data.currentUser
          );

          if (dialogConfig.data.scan && dialogConfig.data.scan.panoramic?.scanId) {
            dialogConfig.data.panoMod = await _this.modService.getModById(dialogConfig.data.scan.panoramic.modId, mod.parent.collection, mod.parent._id, dialogConfig.data.currentUser);
            dialogConfig.data.panoScan = await _this.scanService.getScanById(
              dialogConfig.data.scan.panoramic.scanId,
              mod.parent.collection,
              mod.parent._id,
              dialogConfig.data.currentUser
            );
          }
          break;
      }

      if (dialogConfig.data.model3d || dialogConfig.data.scan) {
        _this.settingsService.setIsLoading(false);
        const dialogRef = _this.dialog.open(ModDialogComponent, dialogConfig);
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(`Unable to find a valid mod source for modId ${mod._id}`);
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error',
            `The source is invalid for this mod.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      }
    } else {
      _this.settingsService.setIsLoading(false);
      if (_this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          `Error`,
          `The asset to upload the processed file to was not provided.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
      }
    }
  }
}
