import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of, zip } from 'rxjs';
import { catchError, filter, map, switchMap, tap, toArray } from 'rxjs/operators';
import findIndex from 'lodash-es/findIndex';

import { Video, User } from '@shared/models';
import { DbCollectionsEnum } from '@shared/enums';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import {
  ErrorService,
  LogService,
  SettingsService,
  UserService
} from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  private currentVideoSubject = new BehaviorSubject<Video>(null);
  private currentProjectVideosSubject = new BehaviorSubject<Video[]>(null);
  private currentShipVideosSubject = new BehaviorSubject<Video[]>(null);
  private currentVehicleVideosSubject = new BehaviorSubject<Video[]>(null);
  private selectedVideosSubject = new BehaviorSubject<Video[]>([]);
  private videoErrorSubject = new BehaviorSubject<string>(null);
  currentVideo$: Observable<Video> = this.currentVideoSubject.asObservable();
  currentProjectVideos$: Observable<Video[]> = this.currentProjectVideosSubject.asObservable();
  currentShipVideos$: Observable<Video[]> = this.currentShipVideosSubject.asObservable();
  currentVehicleVideos$: Observable<Video[]> = this.currentVehicleVideosSubject.asObservable();
  selectedVideos$: Observable<Video[]> = this.selectedVideosSubject.asObservable();
  videoError$: Observable<string> = this.videoErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) { }

  init(): void {
  }

  clearSelectedVideos(): void {
    this.selectedVideosSubject.next([]);
  }

  async createNewVideo(payload, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && parentCollection && parentId && payload) {
        try {
          let videos;
          const url = `${environment.baseAPIUrl}video/create?userId=${currentUser?._id}`;
          let returnValue: Video;

          switch (parentCollection) {
            case DbCollectionsEnum.PROJECTS:
              videos = _this.currentProjectVideosSubject.getValue();
              break;
            case DbCollectionsEnum.SHIPS:
              videos = _this.currentShipVideosSubject.getValue();
              break;
            case DbCollectionsEnum.VEHICLES:
              videos = _this.currentVehicleVideosSubject.getValue();
              break;
            default:
              const errMessage = `${parentCollection} is not a valid video doc collection`;
              _this.errorService.handleError(errMessage);
              reject(errMessage);
              break;
          }

          const newVideos = videos ? videos.slice(0) : [];

          _this.httpClient
            .post(url, payload, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (newVideo: Video) => {
                returnValue = newVideo;
                newVideos.push(newVideo);

                switch (parentCollection) {
                  case DbCollectionsEnum.PROJECTS:
                    _this.currentProjectVideosSubject.next(newVideos);
                    break;
                  case DbCollectionsEnum.SHIPS:
                    _this.currentShipVideosSubject.next(newVideos);
                    break;
                  case DbCollectionsEnum.VEHICLES:
                    _this.currentVehicleVideosSubject.next(newVideos);
                    break;
                }

                resolve(returnValue);
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.videoErrorSubject.next(error.message);
                reject(error);
              },
              complete: () => {

              }
            });
        } catch (ex) {
          const errMessage = `Error creating new video for parent ${parentCollection} parentId ${parentId}: ${ex.message}`;
          _this.errorService.handleError(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `payload, parentColletion, parentId and user are required to create video`;
        _this.errorService.handleError(errMessage);
        _this.videoErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async deleteVideo(videoId: string, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && parentCollection && parentId && videoId) {
        _this.settingsService.setIsLoading(true);
        let isValidParentCollection = true;
        let returnValue: Video[];

        const url = `${environment.baseAPIUrl}video/${videoId}?userId=${currentUser._id}`;

        switch (parentCollection) {
          case DbCollectionsEnum.PROJECTS:
            returnValue = _this.currentProjectVideosSubject.getValue();
            break;
          case DbCollectionsEnum.SHIPS:
            returnValue = _this.currentShipVideosSubject.getValue();
            break;
          case DbCollectionsEnum.VEHICLES:
            returnValue = _this.currentVehicleVideosSubject.getValue();
            break;
          default:
            isValidParentCollection = false;
            break;
        }

        if (isValidParentCollection) {
          _this.httpClient
            .delete(url, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (deletedVideo: Video) => {
                console.log(`successfully deleted videoId ${videoId}`);

                _this.refreshVideosByParent(parentCollection, parentId, currentUser)
                  .then((videos) => {
                    returnValue = videos;
                    switch (parentCollection) {
                      case DbCollectionsEnum.PROJECTS:
                        _this.currentProjectVideosSubject.next(videos);
                        break;
                      case DbCollectionsEnum.SHIPS:
                        _this.currentShipVideosSubject.next(videos);
                        break;
                      case DbCollectionsEnum.VEHICLES:
                        _this.currentVehicleVideosSubject.next(videos);
                        break;
                    }
                  })
                  .catch((videosError) => {
                    const errMessage = _this.errorService.handleError(
                      `Error refreshing videos for parent ${parentCollection} with id ${parentId}: ${videosError}`
                    );
                    _this.videoErrorSubject.next(errMessage);
                    reject(errMessage);
                  })
                  .finally(() => {
                    _this.settingsService.setIsLoading(false);
                    resolve(returnValue);
                  });
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.videoErrorSubject.next(errMessage);
                _this.settingsService.setIsLoading(false);
                reject(error);
              },
              complete: () => {

              }
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = `${parentCollection} is not a valid video parent collection`;
          _this.errorService.handleError(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `parentCollection, parentId, videoId and user are required to delete a video`;
        _this.errorService.handleError(errMessage);
        _this.videoErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getCurrentVideo() {
    return this.currentVideoSubject.getValue();
  }

  getCurrentProjectVideos() {
    return this.currentProjectVideosSubject.getValue();
  }

  getCurrentShipVideos() {
    return this.currentShipVideosSubject.getValue();
  }

  getCurrentVehicleVideos() {
    return this.currentVehicleVideosSubject.getValue();
  }

  getSelectedVideos() {
    return this.selectedVideosSubject.getValue();
  }

  async refreshVideosByParent(parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (parentCollection) {
        _this.settingsService.setIsLoading(true);

        try {
          let returnValue: Video[];
  
          if (currentUser && parentId && parentCollection) {
            const url = `${environment.baseAPIUrl}video/${parentCollection}/${parentId}?userId=${currentUser?._id}`;
  
            _this.httpClient
              .get(url, {
                headers: getCustomHeaders(true),
                responseType: 'json',
              })
              .subscribe({
                next: (videos: Video[]) => {
                  returnValue = videos;
  
                  switch (parentCollection) {
                    case DbCollectionsEnum.PROJECTS:
                      _this.currentProjectVideosSubject.next(videos);
                      break;
                    case DbCollectionsEnum.SHIPS:
                      _this.currentShipVideosSubject.next(videos);
                      break;
                    case DbCollectionsEnum.VEHICLES:
                      _this.currentVehicleVideosSubject.next(videos);
                      break;
                  }
  
                  resolve(returnValue);
                },
                error: (error: HttpErrorResponse) => {
                  const errMessage = _this.errorService.handleError(error);
                  _this.videoErrorSubject.next(errMessage);
  
                  switch (parentCollection) {
                    case DbCollectionsEnum.PROJECTS:
                      _this.currentProjectVideosSubject.next(returnValue);
                      break;
                    case DbCollectionsEnum.SHIPS:
                      _this.currentShipVideosSubject.next(returnValue);
                      break;
                    case DbCollectionsEnum.VEHICLES:
                      _this.currentVehicleVideosSubject.next(returnValue);
                      break;
                  }
  
                  reject(error);
                },
                complete: () => {
  
                }
              });
          } else {
            switch (parentCollection) {
              case DbCollectionsEnum.PROJECTS:
                _this.currentProjectVideosSubject.next(returnValue);
                break;
              case DbCollectionsEnum.SHIPS:
                _this.currentShipVideosSubject.next(returnValue);
                break;
              case DbCollectionsEnum.VEHICLES:
                _this.currentVehicleVideosSubject.next(returnValue);
                break;
            }
  
            resolve(returnValue);
          };
        } catch (ex) {
          const errMessage = `Error refreshing videos for parentCollection ${parentCollection} _id ${parentId}: ${ex.message}`;
          _this.errorService.handleError(errMessage);
          _this.videoErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `parentCollection is required to refresh videos by parent`;
        _this.errorService.handleError(errMessage);
        _this.videoErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveVideo(videoId: string, parentCollection: string, parentId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && parentCollection && parentId && videoId) {
        _this.settingsService.setIsLoading(true);
        let videos: Video[];
        let validParentCollection = true;
        let returnValue: Video;
  
        switch (parentCollection) {
          case DbCollectionsEnum.PROJECTS:
            videos = _this.currentProjectVideosSubject.getValue();
            break;
          case DbCollectionsEnum.SHIPS:
            videos = _this.currentShipVideosSubject.getValue();
            break;
          case DbCollectionsEnum.VEHICLES:
            videos = _this.currentVehicleVideosSubject.getValue();
            break;
          default:
            validParentCollection = false;
            break;
        }
  
        if (validParentCollection) {
          const videoIndex = videos.findIndex((video) => video._id === videoId);
          const newVideos = videos.slice(0);
          const url = `${environment.baseAPIUrl}video/${videoId}?userId=${currentUser?._id}`;
  
          newVideos[videoIndex] = {
            ...videos[videoIndex],
            ...changes,
          };
  
          _this.httpClient
            .put(url, changes, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (updatedVideo: Video) => {
                returnValue = updatedVideo;
                _this.currentVideoSubject.next(updatedVideo);
  
                switch (parentCollection) {
                  case DbCollectionsEnum.PROJECTS:
                    _this.currentProjectVideosSubject.next(newVideos);
                    break;
                  case DbCollectionsEnum.SHIPS:
                    _this.currentShipVideosSubject.next(newVideos);
                    break;
                  case DbCollectionsEnum.VEHICLES:
                    _this.currentVehicleVideosSubject.next(newVideos);
                    break;
                }
  
                _this.settingsService.setIsLoading(false);
                resolve(returnValue);
              },
              error: (error: HttpErrorResponse) => {
                _this.settingsService.setIsLoading(false);
                const errMessage = _this.errorService.handleError(error);
                _this.videoErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {
  
              }
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(`${parentCollection} is not a valid video parent collection`);
          _this.videoErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `parentCollection, parentId, videoId and user are required to update a video`;
        _this.errorService.handleError(errMessage);
        _this.videoErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  toggleSelectedVideo(selected: boolean, video: Video): void {
    const selectedVideos = this.selectedVideosSubject.getValue().slice(0);

    if (selected) {
      selectedVideos.push(video);
    } else {
      const idx = findIndex(selectedVideos, { _id: video._id });

      if (idx !== -1) {
        selectedVideos.splice(idx, 1);
      }
    }

    this.selectedVideosSubject.next(selectedVideos);
  }
}
