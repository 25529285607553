<div class="content">
  <table mat-table [dataSource]="notes" class="mat-elevation-z8 noteTable">
    <div *ngIf="isSnapshotOrVideoNotes; then snapshotBlock; else nonSnapshotBlock"></div>
    <ng-template #snapshotBlock>
      <!-- noteText column -->
      <ng-container matColumnDef="noteText">
        <th mat-header-cell *matHeaderCellDef>Note</th>
        <td mat-cell *matCellDef="let note">{{ note.noteText }}</td>
      </ng-container>

      <!-- editNote column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="actionTableButton">Actions</th>
        <td mat-cell *matCellDef="let note">
          <mat-toolbar class="matButtonBar actionButtonBar">
            <a
              mat-icon-button
              color="warn"
              aria-label="Delete Note"
              matTooltip="Delete Note"
              (click)="deleteNote(note)"
              *ngIf="canDelete(note)"
            >
              <img src="assets/img/TrashGray.png" alt="Delete" />
            </a>
            <a
              mat-icon-button
              color="primary"
              aria-label="Edit Note"
              matTooltip="Edit Note"
              (click)="editNote(note)"
              *ngIf="canEdit(note)"
            >
              <img src="assets/img/Edit.png" alt="Edit" />
            </a>
          </mat-toolbar>
        </td>
      </ng-container>
    </ng-template>

    <ng-template #nonSnapshotBlock>
      <!-- reportSection column -->
      <ng-container matColumnDef="reportSection">
        <th mat-header-cell *matHeaderCellDef>Report Section</th>
        <td mat-cell *matCellDef="let note">{{ getReportSectionTitleCase(note.reportSectionId) }}</td>
      </ng-container>

      <!-- noteText column -->
      <ng-container matColumnDef="noteText">
        <th mat-header-cell *matHeaderCellDef>Note</th>
        <td mat-cell *matCellDef="let note">{{ note.noteText }}</td>
      </ng-container>

      <!-- actions column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let note">
          <mat-toolbar class="actionButtonBar">
            <a
              mat-icon-button
              color="warn"
              aria-label="Delete Note"
              matTooltip="Delete Note"
              (click)="deleteNote(note)"
              *ngIf="canDelete(note)"
            >
              <img src="assets/img/TrashGray.png" alt="Delete" />
            </a>
            <a
              mat-icon-button
              color="primary"
              aria-label="Edit Note"
              matTooltip="Edit Note"
              (click)="editNote(note)"
              *ngIf="canEdit(note)"
            >
              <img src="assets/img/Edit.png" alt="Edit" />
            </a>
          </mat-toolbar>
        </td>
      </ng-container>
    </ng-template>

    <tr mat-header-row *matHeaderRowDef="displayedNoteColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedNoteColumns"></tr>
  </table>
</div>
