<div class="content" *ngIf="currentUser$ | async as currentUser">
  <div *ngIf="showFilter">
    <mat-form-field class="table-filter">
      <mat-label>Search Users</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Search"
        aria-label="Type search criteria to filter results"
        matTooltip="Type search criteria to filter results"
        #tableFilter
      />
    </mat-form-field>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="fullName"
    matSortDirection="asc"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
      <td mat-cell *matCellDef="let user" style="cursor: pointer">
        <a aria-label="Click to view user" matTooltip="Click to view user" (click)="viewUser(user)">{{
          user.fullName
        }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let user" style="cursor: pointer">
        <a aria-label="Click to view user" matTooltip="Click to view user" (click)="viewUser(user)">
          {{ user.email }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Permission</th>
      <td mat-cell *matCellDef="let user" style="cursor: pointer">
        <a aria-label="Click to view user" matTooltip="Click to view user" (click)="viewUser(user)">
          {{ user.role }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="projectCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Projects</th>
      <td mat-cell *matCellDef="let user" style="cursor: pointer">
        <a aria-label="Click to view user" matTooltip="Click to view user" (click)="viewUser(user)">
          {{ user.projectCount }}
        </a>
      </td>
    </ng-container>

    <!-- actions column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let user">
        <div class="toolbar-action-buttons mat-toolbar-single-row">
          <button
            mat-icon-button
            color="warn"
            aria-label="Delete User"
            matTooltip="Delete User"
            (click)="deleteUser(user)"
            *ngIf="canDelete(user)"
          >
            <img src="assets/img/TrashGray.png" alt="Delete" />
          </button>
          <button
            mat-icon-button
            color="primary"
            aria-label="View User"
            matTooltip="View User"
            (click)="viewUser(user)"
          >
            <img src="assets/img/Search.png" alt="View" />
          </button>
          <mat-checkbox
            *ngIf="currentUser._id !== user._id"
            aria-label="Toggle Select User for Group Edit"
            matTooltip="Toggle Select User for Group Edit"
            (change)="toggleUserInEditGroup($event.checked, user)"
          >
          </mat-checkbox>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedUserColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedUserColumns" [ngClass]="getRowClass(row._id)"></tr>

    <!-- Row shown when there is no matching data -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">*** No matching users ***</td>
    </tr>
  </table>

  <mat-paginator *ngIf="showPaginator">
    [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons aria-label="Select page of users"
  </mat-paginator>
</div>
