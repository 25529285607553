<div *ngIf="{
  currentUser: currentUser$ | async,
  reportSections: reportSections$ | async
} as data">
  <h2 mat-dialog-title>{{ formTitle }}</h2>

  <mat-dialog-content>
    <form [formGroup]="form">
      <mat-form-field appearance="fill" [ngStyle]="{ 'width.px': 325 }" *ngIf="canHaveReportSectionId">
        <mat-label>Report Section</mat-label>
        <mat-select formControlName="reportSectionId">
          <mat-option>Choose Report Section</mat-option>
          <mat-option *ngFor="let reportSection of validReportSections" [value]="reportSection._id">
            {{ reportSection.titleCase }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <br *ngIf="canHaveReportSectionId">

      <mat-form-field *ngIf="showNameField" appearance="fill" [ngStyle]="{ 'width.px': 325 }">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="name" />
      </mat-form-field>

      <br *ngIf="showNameField">

      <mat-checkbox *ngIf="showIsMainImageField" appearance="fill" class="checkbox-margin"
        formControlName="isMainImage">{{ mainImageTitle }}</mat-checkbox>

      <br *ngIf="showIsMainImageField">

      <mat-form-field *ngIf="showNoteTextField" appearance="fill" [ngStyle]="{ 'width.px': 325 }">
        <mat-label>Note Text</mat-label>
        <textarea matInput placeholder="Note Text" formControlName="noteText" rows="4"></textarea>
      </mat-form-field>

      <br>

      <div *ngIf="isDebugging$ | async">
        <pre>editorId: {{ form.get('editorId').valid }}</pre>
        <pre>isMainImage: {{ form.get('isMainImage').valid }}</pre>
        <pre>name: {{ form.get('name').valid }}</pre>
        <pre  *ngIf="canHaveReportSectionId">reportSectionId: {{ form.get('reportSectionId').valid }}</pre>
        <pre>noteText: {{ form.get('noteText').valid }}</pre>
      </div>

      <div class="button-row">
        <button mat-raised-button aria-label="Close without saving" matTooltip="Close without saving" (click)="close()">
          Cancel
        </button>
        <button mat-raised-button color="primary" aria-label="Save and close" matTooltip="Save and close"
          *ngIf="getShowSaveButton()" color="primary" (click)="saveChanges()">
          Save
        </button>
      </div>
    </form>
  </mat-dialog-content>
</div>