import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormControl,
  FormControlDirective,
  UntypedFormArray,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import keys from 'lodash-es/keys';
import * as moment from 'moment';
import { BehaviorSubject, from, fromEvent, noop, Observable } from 'rxjs';
import { concatMap, distinctUntilChanged, exhaustMap, filter, mergeMap, tap } from 'rxjs/operators';
import sortBy from 'lodash-es/sortBy';
import Swal from 'sweetalert2/src/sweetalert2';

import {
  DataSource,
  ImageDoc,
  Manufacturer,
  Model3d,
  Scan,
  Scanner,
  Vehicle,
  VehicleDesignation,
  VehicleModel,
  VehiclePurpose,
  User,
  WheelbaseMeasurement,
} from '@shared/models';
import {
  DataSourceService,
  ErrorService,
  FileService,
  ManufacturerService,
  Model3dService,
  ScanService,
  ScannerService,
  SettingsService,
  UserService,
  VehicleDesignationService,
  VehicleModelService,
  VehiclePurposeService,
  VehicleService,
} from '@shared/services';
import {
  FileObjectTypesEnum,
  DbCollectionsEnum,
  TagFileTypesEnum,
  TagTypesEnum,
  TrackedVehicleMovement,
  UserRolesEnum,
  WheeledVehicleMovement,
} from '@shared/enums';
import {
  createTag,
  getUpdates,
  getVehicleEquipmentTypes,
  getVehicleMovementTypes,
  getVehicleSteeringTypes,
  removeUndefinedProps
} from '@shared/utils';

import { environment } from '@environment';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
  selector: 'app-vehicle-dialog',
  templateUrl: './vehicle-dialog.component.html',
  styleUrls: ['./vehicle-dialog.component.css'],
})
export class VehicleDialogComponent implements AfterViewInit {
  private isMotorizedVehicleSubject = new BehaviorSubject<boolean>(true);
  private isWheeledVehicleSubject = new BehaviorSubject<boolean>(false);
  private isTrackedVehicleSubject = new BehaviorSubject<boolean>(false);
  private hasBrakesSubject = new BehaviorSubject<boolean>(true);
  errorMsg: boolean;
  errorText = 'Please select a file';
  imageFileObj: File;
  imageURL: string; //for previewing file
  model3dFileObj: File;
  scanFileObj: File;
  centerOfGravityForm: UntypedFormGroup;
  decimationLevelForm: UntypedFormGroup;
  form: UntypedFormGroup;
  fileForm: UntypedFormGroup;
  model3dForm: UntypedFormGroup;
  model3dParentForm: UntypedFormGroup;
  registrationErrorForm: UntypedFormGroup;
  scanForm: UntypedFormGroup;
  scanParentForm: UntypedFormGroup;
  overallDimensionsForm: UntypedFormGroup;
  tireRadiusForm: UntypedFormGroup;
  wheelbaseForm: UntypedFormGroup;
  vehicleInfoForm: UntypedFormGroup;
  vehicleManeuverabilityInfoForm: UntypedFormGroup;
  currentUser: User;
  model3d: Model3d;
  scan: Scan;
  vehicle: Vehicle;
  currentUser$: Observable<User>;
  dataSources$: Observable<DataSource[]>;
  isDebugging$: Observable<boolean>;
  isMotorizedVehicle$: Observable<boolean> = this.isMotorizedVehicleSubject.asObservable();
  isWheeledVehicle$: Observable<boolean> = this.isWheeledVehicleSubject.asObservable();
  isTrackedVehicle$: Observable<boolean> = this.isTrackedVehicleSubject.asObservable();
  hasBrakes$: Observable<boolean> = this.hasBrakesSubject.asObservable();
  manufacturers$: Observable<Manufacturer[]>;
  models3d$: Observable<Model3d[]>;
  scanners$: Observable<Scanner[]>;
  vehicleDesignations$: Observable<VehicleDesignation[]>;
  vehicleModels$: Observable<VehicleModel[]>;
  vehiclePurposes$: Observable<VehiclePurpose[]>;
  axleCount = 0;
  allowVehicleModels3d = true;
  allowVehicleScans = true;
  hasModel3dFileName = false;
  hasScanFileName = false;
  isNewVehicle = false;
  equipmentTypes = getVehicleEquipmentTypes();
  movementTypes = getVehicleMovementTypes();
  steeringTypes = getVehicleSteeringTypes();
  displayedWheelbaseMeasurementColumns: string[] = ['fromAxlePosition', 'toAxlePosition', 'value'];

  @ViewChild('saveButton', { static: true }) saveButton: ElementRef;

  @ViewChild('cancelButton', { static: true }) cancelButton: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<VehicleDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    data: {
      allowVehicleModels3d: boolean;
      allowVehicleScans: boolean;
      currentUser: User;
      isNewVehicle: boolean;
      model3d: Model3d;
      scan: Scan;
      vehicle: Vehicle;
    },
    private dataSourceService: DataSourceService,
    private errorService: ErrorService,
    private fileService: FileService,
    private manufacturerService: ManufacturerService,
    private model3dService: Model3dService,
    private scanService: ScanService,
    private scannerService: ScannerService,
    private settingsService: SettingsService,
    private userService: UserService,
    private vehicleDesignationService: VehicleDesignationService,
    private vehicleModelService: VehicleModelService,
    private vehiclePurposeService: VehiclePurposeService,
    private vehicleService: VehicleService
  ) {
    this.allowVehicleModels3d = data.allowVehicleModels3d;
    this.allowVehicleScans = data.allowVehicleScans;
    this.currentUser = data.currentUser;
    this.model3d = data.model3d;
    this.scan = data.scan;
    this.vehicle = data.vehicle;
    this.isNewVehicle = data.isNewVehicle;
    this.currentUser$ = this.userService.currentUser$;
    this.dataSources$ = this.dataSourceService.dataSources$;
    this.isDebugging$ = this.settingsService.isDebugging$;
    this.manufacturers$ = this.manufacturerService.manufacturers$;
    this.models3d$ = this.model3dService.models3d$;
    this.scanners$ = this.scannerService.manufacturerScanners$;
    this.vehicleDesignations$ = this.vehicleDesignationService.vehicleDesignations$;
    this.vehicleModels$ = this.vehicleModelService.vehicleModels$;
    this.vehiclePurposes$ = this.vehiclePurposeService.vehiclePurposes$;
    this.axleCount = data.vehicle.numberOfAxles;

    this.hasBrakesSubject.next(this.vehicle.hasBrakes);
    this.isMotorizedVehicleSubject.next(this.vehicle.isMotorized);
    this.isWheeledVehicleSubject.next(this.vehicle.movementTypeId === WheeledVehicleMovement._id ? true : false);
    this.isTrackedVehicleSubject.next(this.vehicle.movementTypeId === TrackedVehicleMovement._id ? true : false);

    this.centerOfGravityForm = fb.group({
      horizontal: this.createGeneralMeasurementFormGroup(this.vehicle.centerOfGravity.horizontal, 'cm', false),
      vertical: this.createGeneralMeasurementFormGroup(this.vehicle.centerOfGravity.vertical, 'cm', false),
    });

    this.decimationLevelForm = fb.group({
      value: new UntypedFormControl(this.scan && this.scan.decimationLevel ? this.scan.decimationLevel.value : 0),
      units: new UntypedFormControl(this.scan && this.scan.decimationLevel ? this.scan.decimationLevel.units : 'mm'),
    });

    this.fileForm = fb.group({
      imageFileName: new UntypedFormControl(''),
      imageUrl: new UntypedFormControl(this.vehicle.imageUrl || environment.defaultImageUrl),
      imageBase64DataUrl: new UntypedFormControl(''),
      imageThumbnailUrl: new UntypedFormControl(this.vehicle.imageThumbnailUrl || environment.defaultThumbnailImageUrl),
      avatar: [null],
    });

    this.overallDimensionsForm = fb.group({
      vehicleHeight: this.createGeneralMeasurementFormGroup(this.vehicle.overallDimensions.vehicleHeight, 'm', false),
      vehicleLength: this.createGeneralMeasurementFormGroup(this.vehicle.overallDimensions.vehicleLength, 'm', false),
      vehicleWidth: this.createGeneralMeasurementFormGroup(this.vehicle.overallDimensions.vehicleWidth, 'm', false),
    });

    this.wheelbaseForm = fb.group({
      units: [this.vehicle.wheelbase ? this.vehicle.wheelbase.units : 'm', Validators.required],
      measurements: new UntypedFormArray([]),
    });

    if (this.vehicle.numberOfAxles > 1) {
      const measurementArray = this.wheelbaseForm.get('measurements') as UntypedFormArray;
      const _this = this;
      this.vehicle.wheelbase.measurements.map(function (measurement) {
        measurementArray.push(_this.createGeneralWheelbaseMeasurementFormGroup(measurement));
      });
    }

    this.vehicleInfoForm = fb.group({
      _id: [this.vehicle._id, Validators.required],
      braking: [this.vehicle.braking],
      creatorId: [this.vehicle.creatorId, Validators.required],
      description: [this.vehicle.description],
      designation: [this.vehicle.designation, Validators.required],
      documentation: [this.vehicle.documentation],
      editorId: [this.currentUser._id, Validators.required],
      engine: [this.vehicle.engine],
      equipmentTypeId: [this.vehicle.equipmentTypeId, Validators.required],
      isMotorized: [this.vehicle.isMotorized, Validators.required],
      movementTypeId: [this.vehicle.movementTypeId, Validators.required],
      hasBrakes: [this.vehicle.hasBrakes, Validators.required],
      maximumHorsepower: this.createGeneralMeasurementFormGroup(this.vehicle.maximumHorsepower, 'KW', false),
      name: [this.vehicle.name, Validators.required],
      modelName: [this.vehicle.modelName, Validators.required],
      purpose: [this.vehicle.purpose, Validators.required],
      maximumTorque: this.createGeneralMeasurementFormGroup(this.vehicle.maximumTorque, 'N*m', false),
      vehicleDesignationId: [this.vehicle.vehicleDesignationId, Validators.required],
      vehicleModelId: [this.vehicle.vehicleModelId, Validators.required],
      vehiclePurposeId: [this.vehicle.vehiclePurposeId, Validators.required],
    });

    this.vehicleManeuverabilityInfoForm = fb.group({
      angleOfApproach: [this.vehicle.angleOfApproach],
      angleOfDeparture: [this.vehicle.angleOfDeparture],
      centerOfGravity: this.centerOfGravityForm,
      curbWeight: this.createGeneralMeasurementFormGroup(this.vehicle.curbWeight, 'kg', false),
      grossVehicleWeight: this.createGeneralMeasurementFormGroup(this.vehicle.grossVehicleWeight, 'kg', false),
      groundClearance: this.createGeneralMeasurementFormGroup(this.vehicle.groundClearance, 'cm', false),
      overallDimensions: this.overallDimensionsForm,
      rampBreakoverAngle: [this.vehicle.rampBreakoverAngle],
      maximumSteeringAngle: [this.vehicle.maximumSteeringAngle],
      minimumTurningRadius: this.createGeneralMeasurementFormGroup(this.vehicle.minimumTurningRadius, 'm', false),
      steeringTypeId: [this.vehicle.steeringTypeId],
      numberOfAxles: [this.vehicle.numberOfAxles],
      numberOfWheels: [this.vehicle.numberOfWheels],
      tireRadius: this.createGeneralMeasurementFormGroup(this.vehicle.tireRadius, 'cm', false),
      wheelbase: this.wheelbaseForm,
      wheelTrack: this.createGeneralMeasurementFormGroup(this.vehicle.wheelTrack, 'm', false),
    });

    this.form = fb.group({
      fileForm: this.fileForm,
      vehicleInfoForm: this.vehicleInfoForm,
      vehicleManeuverabilityInfoForm: this.vehicleManeuverabilityInfoForm,
    });
  }

  ngAfterViewInit(): void { }

  get formTitle(): String {
    if (this.isNewVehicle) {
      return 'Create New Vehicle';
    } else {
      return `Edit Vehicle: ${this.vehicle.name}`;
    }
  }

  get imageFileName(): string {
    return this.fileForm.get('imageFileName').value || '';
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get measurements(): UntypedFormArray {
    return this.wheelbaseForm.get('measurements') as UntypedFormArray;
  }

  get measurementsDataSource(): WheelbaseMeasurement[] {
    return this.vehicle.wheelbase.measurements;
  }

  get saveButtonText(): String {
    if (this.isNewVehicle) {
      return 'Create';
    } else {
      return 'Save';
    }
  }

  addDataSource(): void {
    const _this = this;
    const currentUser = this.userService.getCurrentUser();
    Swal.fire({
      title: 'Add 3D Model Data Source',
      html: `<input type="text" id="newDataSourceName" class="swal2-input" placeholder="Organization Name" title="Organization Name" required>
      <input type="url" id="newDataSourceUrl" class="swal2-input" placeholder="http://www.example.com" pattern="https?://.+" title="Organization URL (optional)">
      <textarea id="newDataSourceDescription" class="swal2-input" placeholder="Organization Description" rows="2" cols="50" title="Organization Description (optional)">`,
      confirmButtonText: 'Add Data Source',
      showCancelButton: true,
      focusConfirm: false,
      width: '50%',
      preConfirm: () => {
        const name = Swal.getPopup().querySelector('#newDataSourceName').value;
        const description = Swal.getPopup().querySelector('#newDataSourceDescription').value;
        const url = Swal.getPopup().querySelector('#newDataSourceUrl').value;

        if (!name) {
          Swal.showValidationMessage('Please enter scan data source organization name');
        }
        const ds: DataSource = {
          name: name,
          creatorId: currentUser._id,
        };

        if (url) {
          ds.url = url;
        }

        if (description) {
          ds.description = description;
        }

        return ds;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.dataSourceService
          .createNewDataSource(result.value, currentUser)
          .then((newDataSource: DataSource) => {
            console.log(`successfully created new data source: ${newDataSource.name}`);
          })
          .catch((error) => {
            _this.errorMsg = true;
            _this.errorText = error.message;
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error',
                `Unable to create new data source: ${error.message}.  Please email ${environment.techSupportEmail}`,
                'error'
              );
            }
          });
      }
    });
  }

  addManufacturer(): void {
    const _this = this;

    Swal.fire({
      title: 'Add Scanner Manufacturer',
      html: `<input type="text" id="newManufacturerName" class="swal2-input" placeholder="Organization Name" required>
      <input type="url" id="newManufacturerUrl" class="swal2-input" placeholder="http://www.example.com" pattern="https?://.+" title="Organization URL (optional)">`,
      confirmButtonText: 'Add Scanner Manufacturer',
      showCancelButton: true,
      focusConfirm: false,
      width: '50%',
      preConfirm: () => {
        const name = Swal.getPopup().querySelector('#newManufacturerName').value;
        const url = Swal.getPopup().querySelector('#newManufacturerUrl').value;

        if (!name) {
          Swal.showValidationMessage('Please enter scanner manufacturer organization name');
        }
        const manufacturer: Manufacturer = {
          name: name,
          creatorId: _this.currentUser._id,
        };

        if (url) {
          manufacturer.url = url;
        }

        return manufacturer;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.manufacturerService
          .createNewManufacturer(result.value, _this.currentUser)
          .then((manufacturer) => {
            this.errorMsg = false;
            this.errorText = '';
          })
          .catch((error) => {
            _this.errorMsg = true;
            _this.errorText = error.message;
            _this.errorService.handleError(
              `Error creating new manufacturer ${JSON.stringify(result.value)}: ${error.message}`
            );
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                `Error Creating Manufacturer`,
                `${error}.  Please email ${environment.techSupportEmail} with any questions.`,
                'error'
              );
            }
          });
      }
    });
  }

  addScanner(): void {
    const _this = this;
    const manufacturerId = this.scanForm.controls.manufacturerId.value;

    this.manufacturerService
      .getManufacturerById(manufacturerId, _this.currentUser)
      .then((manufacturer) => {
        if (manufacturer) {
          Swal.fire({
            title: 'Add Scanner',
            html: `<input type="text" id="newScannerName" class="swal2-input" placeholder="Scanner Name" required>
        <input type="text" id="newScannerModelNumber" class="swal2-input" placeholder="Model number" required>
        <input type="number" id="newScannerAccuracy" class="swal2-input" placeholder="Positional Accuracy (mm)" min="0" pattern="[0-9]" step="1" required>
        <input type="number" id="newScannerPositionalDistance" class="swal2-input" placeholder="Positional Distance (m)" min="0" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" required>
        <input type="url" id="newScannerUrl" class="swal2-input" placeholder="http://www.example.com/scanner" pattern="https?://.+" title="Scanner URL (optional)">`,
            confirmButtonText: 'Add Scanner',
            showCancelButton: true,
            focusConfirm: false,
            width: '50%',
            preConfirm: () => {
              const name = Swal.getPopup().querySelector('#newScannerName').value;
              const modelNumber = Swal.getPopup().querySelector('#newScannerModelNumber').value;
              const accuracy = parseInt(Swal.getPopup().querySelector('#newScannerAccuracy').value);
              const distance = parseFloat(Swal.getPopup().querySelector('#newScannerPositionalDistance').value);
              const url = Swal.getPopup().querySelector('#newScannerUrl').value;

              if (!name || !modelNumber || isNaN(accuracy) || isNaN(distance)) {
                Swal.showValidationMessage('Scanner name, model number, accuracy and distance are required');
              }

              const scanner: Scanner = {
                name: name,
                manufacturerId: manufacturerId,
                modelNumber: modelNumber,
                positionalError: {
                  accuracy: accuracy,
                  accuracyUnits: 'mm',
                  distance: distance,
                  distanceUnits: 'm',
                },
                creatorId: _this.currentUser._id,
              };

              if (url) {
                scanner.url = url;
              }

              return scanner;
            },
          }).then((result) => {
            if (result.isConfirmed) {
              this.scannerService
                .createNewScanner(result.value, _this.currentUser)
                .then((scanner: Scanner) => {
                  this.errorMsg = false;
                  this.errorText = '';
                })
                .catch((scannerError) => {
                  _this.errorMsg = true;
                  _this.errorText = scannerError.message;
                  _this.errorService.handleError(
                    `Error creating scanner ${JSON.stringify(result.value)}: ${scannerError.message}`
                  );
                  if (_this.settingsService.getShowPopupErrorMessages()) {
                    Swal.fire(
                      `Error Creating Scanner`,
                      `${scannerError}.  Please email ${environment.techSupportEmail} with any questions.`,
                      'error'
                    );
                  }
                });
            }
          });
        } else {
          if (_this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              'Scanner Manufacturer is Required',
              'You must select a scanner manufacturer before adding a new scanner.',
              'error'
            );
          }
        }
      })
      .catch((error) => {
        _this.errorMsg = true;
        _this.errorText = error.message;
        _this.errorService.handleError(`Error getting scanner manufacturerId ${manufacturerId}: ${error.message}`);
        if (_this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            'Error Getting Scanner Manufacturer',
            `There was an error getting the scanner manufacturer: ${error.message}.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      });
  }

  addVehicleDesignation(): void {
    const _this = this;

    Swal.fire({
      title: 'Add TAMCN (i.e. D08507K)',
      html: `<input type="text" id="newVehicleDesignation" class="swal2-input" placeholder="TAMCN" required>
      <textarea id="newVehicleDesignationDescription" class="swal2-input" placeholder="Description" rows="2" cols="50" title="Description (optional)">`,
      confirmButtonText: 'Add Vehicle Designation',
      showCancelButton: true,
      focusConfirm: false,
      width: '50%',
      preConfirm: () => {
        const designation = Swal.getPopup().querySelector('#newVehicleDesignation').value;
        const description = Swal.getPopup().querySelector('#newVehicleDesignationDescription').value;

        if (!designation) {
          Swal.showValidationMessage('Please enter vehicle TAMCN (i.e. D08507K)');
        }

        const des: VehicleDesignation = {
          designation: designation,
          creatorId: _this.currentUser._id,
        };

        if (description) {
          des.description = description;
        }

        return des;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.vehicleDesignationService
          .createNewVehicleDesignation(result.value, _this.currentUser)
          .then((newVehicleDesignation: VehicleDesignation) => {
            //do nothing, will auto refresh based on subscription
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error creating new vehicle designation: ${error.message}`
            );
            _this.errorMsg = true;
            _this.errorText = errMessage;
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error',
                `There was an error creating the new vehicle designation.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          });
      }
    });
  }

  addVehicleModel(): void {
    const _this = this;

    Swal.fire({
      title: 'Add Model Number (i.e. M101A3)',
      html: `<input type="text" id="newVehicleModel" class="swal2-input" placeholder="Model Number" required>
      <textarea id="newVehicleModelDescription" class="swal2-input" placeholder="Description" rows="2" cols="50" title="Description (optional)">`,
      confirmButtonText: 'Add Vehicle Model',
      showCancelButton: true,
      focusConfirm: false,
      width: '50%',
      preConfirm: () => {
        const modelName = Swal.getPopup().querySelector('#newVehicleModel').value;
        const description = Swal.getPopup().querySelector('#newVehicleModelDescription').value;

        if (!modelName) {
          Swal.showValidationMessage('Please enter model number (i.e. M101A3)');
        }

        const vehicleModel: VehicleModel = {
          name: modelName,
          creatorId: _this.currentUser._id,
        };

        if (description) {
          vehicleModel.description = description;
        }

        return vehicleModel;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.vehicleModelService
          .createNewVehicleModel(result.value, _this.currentUser)
          .then((newVehicleModel: Model3d) => {
            //do nothing, subscription will refresh
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(`Error creating new vehicle model: ${error.message}`);
            _this.errorMsg = true;
            _this.errorText = errMessage;
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error',
                `Error creating new vehicle model.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          });
      }
    });
  }

  addVehiclePurpose(): void {
    const _this = this;

    Swal.fire({
      title: 'Add Vehicle Purpose',
      html: `<input type="text" id="newVehiclePurpose" class="swal2-input" placeholder="Purpose" required>
      <textarea id="newVehiclePurposeDescription" class="swal2-input" placeholder="Description" rows="2" cols="50" title="Description (optional)">`,
      confirmButtonText: 'Add Vehicle Purpose',
      showCancelButton: true,
      focusConfirm: false,
      width: '50%',
      preConfirm: () => {
        const purpose = Swal.getPopup().querySelector('#newVehiclePurpose').value;
        const description = Swal.getPopup().querySelector('#newVehiclePurposeDescription').value;

        if (!purpose) {
          Swal.showValidationMessage('Please enter vehicle purpose');
        }

        const vehiclePurpose: VehiclePurpose = {
          name: purpose,
          creatorId: _this.currentUser._id,
        };

        if (description) {
          vehiclePurpose.description = description;
        }

        return vehiclePurpose;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.vehiclePurposeService
          .createNewVehiclePurpose(result.value, _this.currentUser)
          .then((newVehicePurpose: VehiclePurpose) => {
            //do nothing, subscription will update
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(`Error creating new vehicle purpose: ${error.message}`);
            _this.errorMsg = true;
            _this.errorText = errMessage;
            if (_this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                'Error',
                `There was an error creating the new vehicle purpose.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          });
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  createGeneralMeasurementFormGroup(measurementProperty, unitsOfMeasurement, isRequired) {
    let gmFormGroup;

    if (isRequired) {
      gmFormGroup = new UntypedFormGroup({
        value: new UntypedFormControl(measurementProperty ? measurementProperty.value : 0, Validators.required),
        units: new UntypedFormControl(
          measurementProperty ? measurementProperty.units : unitsOfMeasurement,
          Validators.required
        ),
      });
    } else {
      gmFormGroup = new UntypedFormGroup({
        value: new UntypedFormControl(measurementProperty ? measurementProperty.value : 0),
        units: new UntypedFormControl(measurementProperty ? measurementProperty.units : unitsOfMeasurement),
      });
    }
    return gmFormGroup;
  }

  createGeneralWheelbaseMeasurementFormGroup(measurement) {
    const mmFormGroup = new UntypedFormGroup({
      fromAxlePosition: new UntypedFormControl(measurement && measurement.fromAxlePosition ? measurement.fromAxlePosition : 1,
        Validators.required
      ),
      toAxlePosition: new UntypedFormControl(measurement && measurement.toAxlePosition ? measurement.toAxlePosition : 2,
        Validators.required
      ),
      value: new UntypedFormControl(measurement && measurement.value ? measurement.value : 0, Validators.required),
    });

    return mmFormGroup;
  }

  getShowVehicleSaveButton(role: string) {
    if (this.form.dirty && this.form.valid) {
      return true;
    } else {
      return false;
    }
  }

  async imageFileInputChange($event: Event): Promise<any> {
    const _this = this;
    this.errorMsg = false;
    const FILE = ($event.target as HTMLInputElement).files[0];
    this.imageFileObj = FILE;

    if (!this.imageFileObj) {
      this.errorMsg = true;
      this.errorText = 'Please select an image file';
    } else {
      this.settingsService.setIsLoading(true);
      const tags = [];
      tags.push(createTag(TagTypesEnum.FILE_TYPE, TagFileTypesEnum.IMAGE));

      this.fileService
        .uploadFile(
          this.imageFileObj,
          FileObjectTypesEnum.VEHICLE,
          this.vehicle._id,
          tags,
          TagFileTypesEnum.IMAGE,
          this.currentUser
        )
        .then((results) => {
          this.settingsService.setIsLoading(false);

          //only user interaction sets the form as dirty, force it see https://github.com/angular/angular/issues/9768
          this.fileForm.markAsDirty();

          //add image preview, see https://www.positronx.io/angular-8-show-image-preview-with-reactive-forms-tutorial/
          this.fileForm.patchValue({
            avatar: FILE,
          });
          this.fileForm.get('avatar').updateValueAndValidity();

          const reader = new FileReader();
          reader.onload = () => {
            try {
              this.imageURL = reader.result as string;
            } catch (ex) {
              this.errorService.handleError(`Error previewing image ${ex}`);
            }
          };
          reader.readAsDataURL(FILE);

          const imageDoc: ImageDoc = {
            _id: results._id,
            creatorId: this.currentUser._id,
            displayThumbnailUrl: results.thumbnailDisplayUrl,
            displayUrl: results.locationDisplayUrl,
            isMainImage: true,
            isSnapshot: false,
            name: results.nameWithoutExtension,
            parent: {
              _id: this.vehicle._id,
              collection: DbCollectionsEnum.VEHICLES,
            },
            thumbnailUrl: results.thumbnailUrl || environment.defaultThumbnailImageUrl,
            url: results.locationUrl,
          };

          this.vehicleService
            .addImage(imageDoc, this.vehicle, this.currentUser)
            .then((vehicleResults) => {
              this.errorMsg = false;
              this.errorText = '';
              this.settingsService.setIsLoading(false);

              this.fileForm.controls.imageUrl.setValue(results.locationUrl);
              this.fileForm.controls.imageUrl.markAsDirty();
              this.fileForm.controls.imageBase64DataUrl.setValue(results.locationBase64DataUrl);
              this.fileForm.controls.imageBase64DataUrl.markAsDirty();
              this.fileForm.controls.imageFileName.setValue(this.imageFileObj.name);
              this.fileForm.controls.imageFileName.markAsDirty();
              this.fileForm.controls.imageThumbnailUrl.setValue(results.thumbnailUrl);
              this.fileForm.controls.imageThumbnailUrl.markAsDirty();

              if (results.processing) {
                Swal.fire(
                  'File Upload in Process',
                  'Due to the large file size, your file is being uploaded in the background.  You will be emailed a success or failure message upon completion.',
                  'info'
                );
              }
            })
            .catch((vehicleError) => {
              _this.settingsService.setIsLoading(false);
              _this.errorMsg = true;
              _this.errorText = vehicleError.message;
              const errMessage = _this.errorService.handleError(
                `Error adding vehicle image ${imageDoc._id} to vehicle ${this.vehicle._id}: ${vehicleError}`
              );
              if (_this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  'Error Adding Image',
                  `There was an error adding the image: ${vehicleError.message}.  Please email ${environment.techSupportEmail}.`,
                  'error'
                );
              }
            });
        })
        .catch((uploadError) => {
          _this.settingsService.setIsLoading(false);
          _this.errorMsg = true;
          _this.errorText = uploadError.message;
          const errMessage = _this.errorService.handleError(`Error uploading vehicle image: ${uploadError}`);
          if (_this.settingsService.getShowPopupErrorMessages()) {
            Swal.fire(
              'Error Uploading Image',
              `There was an error uploading the image: ${uploadError.message}.  Please email ${environment.techSupportEmail}.`,
              'error'
            );
          }
        });
    }
  }

  onHasBrakesChanged(ob: MatSlideToggleChange) {
    console.log(`hasBrakes: ${ob.checked}`);
    this.hasBrakesSubject.next(ob.checked);

    /* Removed requirements per Ivan, keeping code for reference in case they change their minds
    if (ob.checked) {
      this.vehicleInfoForm.controls.braking.setValidators([Validators.required]);
    } else {
      this.vehicleInfoForm.controls.braking.setValidators([]);
    }
    */
  }

  onIsMotorizedChanged(ob: MatSlideToggleChange) {
    console.log(`isMotorized: ${ob.checked}`);
    this.isMotorizedVehicleSubject.next(ob.checked);

    /* Removed requirements per Ivan, keeping code for reference in case they change their minds
    if (ob.checked) {
      this.vehicleInfoForm.controls.engine.setValidators([Validators.required]);
      this.vehicleInfoForm.controls.maximumHorsepower.setValidators([Validators.required]);
      this.vehicleInfoForm.controls.maximumTorque.setValidators([Validators.required]);
    } else {
      this.vehicleInfoForm.controls.engine.setValidators([]);
      this.vehicleInfoForm.controls.maximumHorsepower.setValidators([]);
      this.vehicleInfoForm.controls.maximumTorque.setValidators([]);
    }
    */
  }

  onManufacturerChange(value, currentUser: User) {
    this.scannerService.refreshScannersByManufacturer(value, currentUser);
  }

  onNumberOfAxlesChanged(newNumberOfAxles: number) {
    this.vehicle.wheelbase.measurements = sortBy(this.vehicle.wheelbase.measurements, [
      'fromAxlePosition',
      'toAxlePosition',
    ]);
    const measurementArray = this.wheelbaseForm.get('measurements') as UntypedFormArray;
    const currentNumberOfMeasurements = this.vehicle.wheelbase.measurements.length;
    let highestFromAxleNumber = this.vehicle.wheelbase.measurements[currentNumberOfMeasurements - 1].fromAxlePosition;
    let highestToAxleNumber = this.vehicle.wheelbase.measurements[currentNumberOfMeasurements - 1].toAxlePosition;
    console.log(`current number of axles: ${this.axleCount}`);
    console.log(`new number of axles: ${newNumberOfAxles}`);
    console.log(`current measurements: ${JSON.stringify(this.vehicle.wheelbase.measurements)}`);

    if (newNumberOfAxles > this.axleCount) {
      const axlesToAdd = newNumberOfAxles - this.axleCount;
      for (let x = 0; x < axlesToAdd; x++) {
        const newM: WheelbaseMeasurement = {
          fromAxlePosition: highestToAxleNumber,
          toAxlePosition: highestToAxleNumber + 1,
          value: 0,
        };

        highestToAxleNumber += 1;

        this.vehicle.wheelbase.measurements.push(newM);
        measurementArray.push(this.createGeneralWheelbaseMeasurementFormGroup(newM));
      }
    } else if (newNumberOfAxles < this.axleCount) {
      const axlesToRemove = this.axleCount - newNumberOfAxles;
      const existingMeasurements = JSON.parse(JSON.stringify(this.vehicle.wheelbase.measurements));
      measurementArray.clear();

      for (let x = 1; x < newNumberOfAxles; x++) {
        const newM: WheelbaseMeasurement = {
          fromAxlePosition: x,
          toAxlePosition: x + 1,
          value: existingMeasurements[x - 1] ? existingMeasurements[x - 1].value : 0,
        };

        if (x === 1) {
          this.vehicle.wheelbase.measurements = [newM];
        } else {
          this.vehicle.wheelbase.measurements.push(newM);
        }

        measurementArray.push(this.createGeneralWheelbaseMeasurementFormGroup(newM));
      }
    }

    this.axleCount = newNumberOfAxles;
  }

  onSelectedVehicleDesignation(value) {
    this.vehicleDesignations$.subscribe((designations) => {
      const matching = designations.find((vm) => vm._id === value);
      this.vehicleInfoForm.controls.designation.setValue(matching ? matching.designation : '');
    });
  }

  onSelectedVehicleMovementType(value) {
    const matching = this.movementTypes.find((t) => t._id === value);
    this.isWheeledVehicleSubject.next(value === WheeledVehicleMovement._id ? true : false);
    this.isTrackedVehicleSubject.next(value === TrackedVehicleMovement._id ? true : false);
  }

  onSelectedVehiclePurpose(value) {
    this.vehiclePurposes$.subscribe((purposes) => {
      const matching = purposes.find((vm) => vm._id === value);
      this.vehicleInfoForm.controls.purpose.setValue(matching ? matching.name : '');
    });
  }

  onSelectedVehicleModel(value) {
    this.vehicleModels$.subscribe((vehicleModels) => {
      const matching = vehicleModels.find((vm) => vm._id === value);
      this.vehicleInfoForm.controls.modelName.setValue(matching ? matching.name : '');
    });
  }

  save(currentUser): void {
    this.settingsService.setIsLoading(false);

    try {
      if (this.isNewVehicle) {
        const mFormArray = this.wheelbaseForm.get('measurements') as UntypedFormArray;
        const measurements: WheelbaseMeasurement[] = [];

        for (let x = 0; x < mFormArray.controls.length; x++) {
          const mg = mFormArray.controls[x] as UntypedFormGroup;
          const measurement: WheelbaseMeasurement = {
            fromAxlePosition: mg.controls.fromAxlePosition.value,
            toAxlePosition: mg.controls.toAxlePosition.value,
            value: mg.controls.value.value,
          };

          measurements.push(measurement);
        }

        let vehicleInfo = {
          ...this.fileForm.value,
          ...this.vehicleInfoForm.value,
          ...this.vehicleManeuverabilityInfoForm.value,
        };

        delete vehicleInfo.wheelbase.measurements;
        vehicleInfo.wheelbase.measurements = JSON.parse(JSON.stringify(measurements));

        this.vehicleService
          .createNewVehicle(vehicleInfo, currentUser)
          .then((newVehicle: Vehicle) => {
            this.errorMsg = false;
            this.errorText = '';
            this.settingsService.setIsLoading(false);
            this.close();
            this.router.navigate([`/vehicles/${newVehicle._id}`]);
          })
          .catch((error) => {
            this.settingsService.setIsLoading(false);
            const errMessage = this.errorService.handleError(`Error creating new vehicle: ${error.message}`);
            this.errorMsg = true;
            this.errorText = errMessage;
            if (this.settingsService.getShowPopupErrorMessages()) {
              Swal.fire(
                `Error`,
                `There was an error creating the new vehicle.  Please email ${environment.techSupportEmail}.`,
                'error'
              );
            }
          });
      } else {
        let changes: any = {
          editorId: currentUser._id
        };

        const fileChanges = getUpdates(this.fileForm, changes);
        const vehicleInfoChanges = getUpdates(this.vehicleInfoForm, changes);
        const vehicleManuverabilityChanges = getUpdates(this.vehicleManeuverabilityInfoForm, changes);

        const changedKeys = keys(changes);

        //started with editorId as the key, only process changes if more than that key - jane 5/10/2024
        if (changedKeys.length > 1) {
          if (changes.wheelbase) {
            const mFormArray = this.wheelbaseForm.get('measurements') as UntypedFormArray;
            const measurements: WheelbaseMeasurement[] = [];

            for (let x = 0; x < mFormArray.controls.length; x++) {
              const mg = mFormArray.controls[x] as UntypedFormGroup;
              const measurement: WheelbaseMeasurement = {
                fromAxlePosition: mg.controls.fromAxlePosition.value,
                toAxlePosition: mg.controls.toAxlePosition.value,
                value: mg.controls.value.value,
              };

              measurements.push(measurement);
            }

            delete changes.wheelbase.measurements;
            changes.wheelbase.measurements = JSON.parse(JSON.stringify(measurements));
          }

          this.vehicleService
            .saveVehicle(this.vehicleInfoForm.controls._id.value, changes, currentUser)
            .then((updatedVehicle: Vehicle) => {
              this.close();
              const navigationUrl = `/vehicles/${this.vehicleInfoForm.controls._id.value}`;
              this.router.navigateByUrl(navigationUrl);
            })
            .catch((error) => {
              this.settingsService.setIsLoading(false);
              const errMessage = this.errorService.handleError(`Error updating vehicleId ${this.vehicleInfoForm.controls._id.value}: ${error.message}`);
              this.errorMsg = true;
              this.errorText = errMessage;
              if (this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(
                  `Error`,
                  `There was an error updating the vehicle.  Please email ${environment.techSupportEmail}.`,
                  'error'
                );
              }
            })
        } else {
          this.settingsService.setIsLoading(false);
          this.close();
        }
      }
    } catch (ex) {
      this.settingsService.setIsLoading(false);
      const errMessage = this.errorService.handleError(`Error getting vehicle information to save: {$ex.message}`);
      this.errorMsg = true;
      this.errorText = ex.message;
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          `Error`,
          `Error processing the vehicle information.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
			}
    }
  }
}
