import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import findIndex from 'lodash-es/findIndex';

import { UserSubscriptionTypesEnum } from '@shared/enums';
import { ActivitySubscription, Document, ImageDoc, UsedInProject, User } from '@shared/models';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import {
  AreaMeasurementService,
  DataSourceService,
  DocumentService,
  ErrorService,
  LogService,
  ManufacturerService,
  ModService,
  Model3dService,
  ReportService,
  ScanService,
  ScannerService,
  SettingsService,
  ShipClassService,
  ShipDesignationService,
  UnrealInteractionService,
  UnrealServerService,
  VehicleDesignationService,
  VehicleModelService,
  VehiclePurposeService,
} from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>(null);
  private selectedUsersSubject = new BehaviorSubject<User[]>([]);
  private selectedUserActivitySubscriptionSubject = new BehaviorSubject<ActivitySubscription[]>([]);
  private selectedUserModifiedActivitySubscriptionIdsSubject = new BehaviorSubject<string[]>([]);
  private selectedUserSubject = new BehaviorSubject<User>(null);
  private selectedUserDocumentsSubject = new BehaviorSubject<Document[]>([]);
  private selectedUserImagesSubject = new BehaviorSubject<ImageDoc[]>([]);
  private selectedUserProjectSubscriptionsSubject = new BehaviorSubject<UsedInProject[]>([]);
  private selectedUserShipSubscriptionsSubject = new BehaviorSubject<UsedInProject[]>([]);
  private selectedUserVehicleSubscriptionsSubject = new BehaviorSubject<UsedInProject[]>([]);
  private usersSubject = new BehaviorSubject<User[]>([]);
  private userErrorSubject = new BehaviorSubject<string>(null);
  private userLoggedInSubject = new BehaviorSubject<boolean>(false);
  currentUser$: Observable<User> = this.currentUserSubject.asObservable();
  selectedUsers$: Observable<User[]> = this.selectedUsersSubject.asObservable();
  selectedUser$: Observable<User> = this.selectedUserSubject.asObservable();
  selectedUserActivitySubscriptions$: Observable<ActivitySubscription[]> = this.selectedUserActivitySubscriptionSubject.asObservable();
  selectedUserModifiedActivitySubscriptionIds$: Observable<string[]> = this.selectedUserModifiedActivitySubscriptionIdsSubject.asObservable();
  selectedUserDocuments$: Observable<Document[]> = this.selectedUserDocumentsSubject.asObservable();
  selectedUserImages$: Observable<ImageDoc[]> = this.selectedUserImagesSubject.asObservable();
  selectedUserProjectSubscriptions$: Observable<UsedInProject[]> = this.selectedUserProjectSubscriptionsSubject.asObservable();
  selectedUserShipSubscriptions$: Observable<UsedInProject[]> = this.selectedUserShipSubscriptionsSubject.asObservable();
  selectedUserVehicleSubscriptions$: Observable<UsedInProject[]> = this.selectedUserVehicleSubscriptionsSubject.asObservable();
  users$: Observable<User[]> = this.usersSubject.asObservable();
  userError$: Observable<string> = this.userErrorSubject.asObservable();
  userLoggedIn$: Observable<boolean> = this.userLoggedInSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private httpClient: HttpClient,
    private router: Router
  ) { }
  
  async changePassword(userId, oldPassword, newPassword): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const url = `${environment.baseAPIUrl}user/${userId}/changePassword`;
      const currentUser = _this.getCurrentUser();
      let returnValue;

      //oldPassword is hashed
      _this.httpClient
        .post(
          url,
          {
            editorId: currentUser._id,
            oldPassword: oldPassword,
            newPassword: newPassword,
          },
          {
            responseType: 'json',
          }
        )
        .subscribe({
          next: (result: User) => {
            returnValue = result;
            _this.userErrorSubject.next(null);
            resolve(result);
          },
          error: (error: HttpErrorResponse) => {
            const errMessage = _this.errorService.handleError(error);
            _this.userErrorSubject.next(errMessage);
            reject(error);
          },
          complete: () => {
            _this.settingsService.setIsLoading(false);
          }
        });
    });
  }

  async createNewUser(payload): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const users = _this.usersSubject.getValue();
      const newUsers = users.slice(0);
      const url = `${environment.baseAPIUrl}user/register`;
      const currentUser = _this.getCurrentUser();
      let returnValue: User;

      _this.httpClient
        .post(url, payload, {
          headers: getCustomHeaders(true),
          responseType: 'json',
        })
        .subscribe({
          next: (newUser: User) => {
            returnValue = newUser;
            newUsers.push(newUser);
            _this.usersSubject.next(newUsers);

            //set as selected user
            _this.getUserById(newUser._id, true)
              .then((u: User) => {
                _this.userErrorSubject.next(null);
                _this.settingsService.setIsLoading(false);
                resolve(newUser);
              })
              .catch((err) => {
                const errMessage = _this.errorService.handleError(
                  `New user was created but could not be displayed: ${err.message}`
                );
                _this.userErrorSubject.next(errMessage);
                _this.settingsService.setIsLoading(false);
                reject(errMessage);
              });
          },
          error: (error: HttpErrorResponse) => {
            const errMessage = _this.errorService.handleError(error);
            _this.userErrorSubject.next(errMessage);
            reject(error);
          },
          complete: () => {
            _this.settingsService.setIsLoading(false);
          }
        });
    });
  }

  clearSelectedUsers(): void {
    this.selectedUsersSubject.next([]);
    this.populateSelectedUserActivityNotifications(null);
  }

  async deleteUser(userId: string): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const currentUser = _this.currentUserSubject.getValue();
      let returnValue: User[] = _this.usersSubject.getValue();
      const idx = findIndex(returnValue, { _id: userId });

      if (userId && currentUser) {
        const url = `${environment.baseAPIUrl}user/${userId}?userId=${currentUser._id}`;

        _this.httpClient
          .delete(url, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (deletedUser: User) => {
              console.log(`Successfully deleted userId ${userId}`);

              if (idx !== -1) {
                returnValue.splice(idx, 1);
                _this.usersSubject.next(returnValue);
              }

              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.userErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(`userId and currentUser are required to delete a user`);
        _this.userErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getUsers(): Observable<User[]> {
    const usersHttp$ = createHttpObservable(`${environment.baseAPIUrl}user/`, {}, true);

    usersHttp$
      .pipe(
        catchError((err) => {
          console.error(`Error getting users: ${err}`);
          return of([]);
        })
      )
      .subscribe((users: User[]) => this.usersSubject.next(users));

    return this.users$;
  }

  getCurrentUser() {
    return this.currentUserSubject.getValue();
  }

  getSelectedUser() {
    return this.selectedUserSubject.getValue();
  }

  getSelectedUsers() {
    return this.selectedUsersSubject.getValue();
  }

  getSelectedUserActivityNotifications(subscriptionType?: UserSubscriptionTypesEnum) {
    const subscriptions = this.selectedUserActivitySubscriptionSubject.getValue();
    let returnValue = subscriptions;

    if (subscriptionType) {
      returnValue = subscriptions.filter((s) => s.subscriptionType === subscriptionType)
    }

    return returnValue;
  }

  getSelectedUserModifiedActivitySubscriptionIds() {
    return this.selectedUserModifiedActivitySubscriptionIdsSubject.getValue();
  }

  getSelectedUserProjectSubscriptions() {
    return this.selectedUserProjectSubscriptionsSubject.getValue();
  }

  getSelectedUserShipSubscriptions() {
    return this.selectedUserShipSubscriptionsSubject.getValue();
  }

  getSelectedUserVehicleSubscriptions() {
    return this.selectedUserVehicleSubscriptionsSubject.getValue();
  }

  async getUserById(userId: string, makeSelectedUser: boolean): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue: User;

      if (userId) {
        _this.users$
          .pipe(
            map((users) => users.find((user) => user._id === userId)),
            filter((user) => !!user)
          )
          .subscribe({
            next: (user: User) => {
              returnValue = user;

              if (makeSelectedUser) {
                user = _this.populateSelectedUserActivityNotifications(user);
                _this.selectedUserSubject.next(user);
              } else {
                _this.currentUserSubject.next(user);
              }

              _this.settingsService.setIsLoading(false);
              resolve(returnValue);
            },
            error: (error: any) => {
              const errMessage = _this.errorService.handleError(`Error getting userId ${userId}: ${error.error}`);
              _this.userErrorSubject.next(errMessage);
              reject(errMessage);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        if (makeSelectedUser) {
          _this.selectedUserSubject.next(returnValue);
          _this.populateSelectedUserActivityNotifications(returnValue);
        } else {
          _this.currentUserSubject.next(returnValue);
        }

        _this.settingsService.setIsLoading(false);
        resolve(returnValue);
      }
    });
  }

  getUserName(userId: string): string {
    let returnValue = '';

    if (userId) {
      this.users$
        .pipe(
          map((users) => users.find((user) => user._id === userId)),
          filter((user) => !!user)
        )
        .subscribe((user) => {
          returnValue = user ? user.fullName : '';
        });
    }

    return returnValue;
  }

  async loginUser(payload): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      const url = `${environment.baseAPIUrl}user/login`;
      let returnValue: User;

      // clear any token that might be saved
      localStorage.removeItem('Token');

      _this.httpClient
        .post(
          url,
          {
            email: payload.email,
            password: payload.password,
          },
          {
            responseType: 'json',
          }
        )
        .subscribe({
          next: (data: any) => {
            const token = data.token;
            localStorage.setItem('Token', token);
            _this.currentUserSubject.next(data.user);
            _this.userLoggedInSubject.next(true);
            _this.selectedUserSubject.next(null);
            _this.populateSelectedUserActivityNotifications(null);

            if (data.user.preferences.viewFormat) {
              _this.settingsService.setViewFormat(data.user.preferences.viewFormat);
            }

            returnValue = data.user;
            _this.getUsers();
            resolve(returnValue);
          },
          error: (error: HttpErrorResponse) => {
            const errMessage = _this.errorService.handleError(error);
            localStorage.removeItem('Token');
            _this.userErrorSubject.next(errMessage);
            _this.userLoggedInSubject.next(false);
            _this.selectedUserSubject.next(null);
            _this.populateSelectedUserActivityNotifications(null);
            reject(error);
          },
          complete: () => {

          }
        });
    });
  }

  async logoutUser(currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser) {
        const url = `${environment.baseAPIUrl}user/${currentUser._id}/logout`;

        _this.httpClient.get(url).subscribe({
          next: (data: any) => {
            _this.currentUserSubject.next(null);
            _this.selectedUserSubject.next(null);
            _this.selectedUserProjectSubscriptionsSubject.next(null);
            _this.selectedUserShipSubscriptionsSubject.next(null);
            _this.selectedUserVehicleSubscriptionsSubject.next(null);
            _this.userErrorSubject.next(null);
            _this.userLoggedInSubject.next(false);
            localStorage.removeItem('Token');
            resolve('logged out');
          },
          error: (error: HttpErrorResponse) => {
            const errMessage = _this.errorService.handleError(error);
            _this.userErrorSubject.next(errMessage);
            _this.userLoggedInSubject.next(true);
            reject(errMessage);
          },
          complete: () => {

          }
        });
      } else {
        resolve('logged out');
      }
    });
  }

  public onModifiedUserSubscription(id: string): void {
    const existingIds = this.selectedUserModifiedActivitySubscriptionIdsSubject.getValue() || [];
    const idx = existingIds.indexOf(id);
    if (idx === -1) {
      existingIds.push(id);
      this.selectedUserModifiedActivitySubscriptionIdsSubject.next(existingIds);
    }
  }

  populateSelectedUserActivityNotifications(user: User): User {
    const _this = this;

    if (user) {
      user.userActivitySubscriptions = new Array<ActivitySubscription>();

      if (Array.isArray(user?.userSubscriptions?.projects) && user.userSubscriptions.projects.length > 0) {
        _this.selectedUserProjectSubscriptionsSubject.next(user.userSubscriptions.projects);
        user.userSubscriptions.projects.map((a) => {
          const actSub: ActivitySubscription = {
            _id: a._id,
            deleted: a.deleted,
            name: a.name,
            subscribed: true,
            subscriptionType: UserSubscriptionTypesEnum.PROJECTS
          };

          user.userActivitySubscriptions.push(actSub);
        });
      } else {
        _this.selectedUserProjectSubscriptionsSubject.next([]);
      }

      if (Array.isArray(user?.userSubscriptions?.ships) && user.userSubscriptions.ships.length > 0) {
        _this.selectedUserShipSubscriptionsSubject.next(user.userSubscriptions.ships);
        user.userSubscriptions.ships.map((a) => {
          const actSub: ActivitySubscription = {
            _id: a._id,
            deleted: a.deleted,
            name: a.name,
            subscribed: true,
            subscriptionType: UserSubscriptionTypesEnum.SHIPS
          };

          user.userActivitySubscriptions.push(actSub);
        });
      } else {
        _this.selectedUserShipSubscriptionsSubject.next([]);
      }

      if (Array.isArray(user?.userSubscriptions?.vehicles) && user.userSubscriptions.vehicles.length > 0) {
        _this.selectedUserVehicleSubscriptionsSubject.next(user.userSubscriptions.vehicles);
        user.userSubscriptions.projects.map((a) => {
          const actSub: ActivitySubscription = {
            _id: a._id,
            deleted: a.deleted,
            name: a.name,
            subscribed: true,
            subscriptionType: UserSubscriptionTypesEnum.VEHICLES
          };

          user.userActivitySubscriptions.push(actSub);
        });
      } else {
        _this.selectedUserVehicleSubscriptionsSubject.next([]);
      }

      _this.selectedUserActivitySubscriptionSubject.next(user.userActivitySubscriptions);
      _this.selectedUserModifiedActivitySubscriptionIdsSubject.next([]);
    } else {
      _this.selectedUserActivitySubscriptionSubject.next([]);
      _this.selectedUserProjectSubscriptionsSubject.next([]);
      _this.selectedUserShipSubscriptionsSubject.next([]);
      _this.selectedUserVehicleSubscriptionsSubject.next([]);
      _this.selectedUserModifiedActivitySubscriptionIdsSubject.next([]);
    }

    return user;
  }

  toggleSelectedUser(selected: boolean, user: User): void {
    const selectedUsers = this.selectedUsersSubject.getValue().slice(0);

    if (selected) {
      selectedUsers.push(user);
    } else {
      const idx = findIndex(selectedUsers, { _id: user._id });

      if (idx !== -1) {
        selectedUsers.splice(idx, 1);
      }
    }

    this.selectedUsersSubject.next(selectedUsers);
  }

  toggleUserSubscription(selected: boolean, subscriptionToToggle: ActivitySubscription): void {
    const existingModifiedSubscriptionsIds = this.getSelectedUserModifiedActivitySubscriptionIds() || [];
    const selectedUserActivitySubscriptions = this.selectedUserActivitySubscriptionSubject.getValue().slice(0);
    const idx = existingModifiedSubscriptionsIds.indexOf(subscriptionToToggle._id);
    if (idx === -1) {
      existingModifiedSubscriptionsIds.push(subscriptionToToggle._id);
      this.selectedUserModifiedActivitySubscriptionIdsSubject.next(existingModifiedSubscriptionsIds);
    }

    const matching = selectedUserActivitySubscriptions.find((s) => s._id === subscriptionToToggle._id);

    if (matching) {
      matching.subscribed = selected;
    }

    this.selectedUserActivitySubscriptionSubject.next(selectedUserActivitySubscriptions);
  }

  async updateSelectedUserSubscriptionsToMatchActivitySubscriptions(editorId: string, userIdToModify: string, updatedActivitySubscriptions: ActivitySubscription[]): Promise<any> {
    const _this = this;

    return new Promise(async (resolve, reject) => {
      try {
        const changes = {
          editorId: editorId,
          userSubscriptions: {
            projects: [],
            ships: [],
            vehicles: []
          }
        };

        if (Array.isArray(updatedActivitySubscriptions) && updatedActivitySubscriptions.length > 0) {
          updatedActivitySubscriptions.map((a) => {
            //only send thru the subscribed ones.  The API will clean up unsubscribed as part of the process.
            if (a.subscribed) {
              switch (a.subscriptionType) {
                case UserSubscriptionTypesEnum.PROJECTS:
                  changes.userSubscriptions.projects.push({
                    _id: a._id,
                    deleted: a.deleted,
                    name: a.name
                  });
                  break;
                case UserSubscriptionTypesEnum.SHIPS:
                  changes.userSubscriptions.ships.push({
                    _id: a._id,
                    deleted: a.deleted,
                    name: a.name
                  });
                  break;
                case UserSubscriptionTypesEnum.VEHICLES:
                  changes.userSubscriptions.vehicles.push({
                    _id: a._id,
                    deleted: a.deleted,
                    name: a.name
                  });
                  break;
              };
            }
          });
        }

        const updatedUser = await _this.saveUser(userIdToModify, changes);
        _this.selectedUserSubject.next(updatedUser);
        resolve(updatedUser);
      } catch (ex) {
        const errMessage = _this.errorService.handleError(`Error matching userId ${userIdToModify} subscription notifications with the updated activity notifications:  ${ex.message}`);
        _this.userErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  updateViewFormatPreference(userId: string, formatToUse: string): any {
    this.settingsService.setViewFormat(formatToUse);
    return this.saveUser(userId, {
      preferences: {
        viewFormat: formatToUse,
      },
      editorId: userId,
    });
  }

  async saveUser(userId: string, changes): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const users = _this.usersSubject.getValue();
      const userIndex = users.findIndex((user) => user._id === userId);
      const newUsers = users.slice(0);
      const currentUser = _this.currentUserSubject.getValue();
      const selectedUser = _this.selectedUserSubject.getValue();
      const url = `${environment.baseAPIUrl}user/${userId}`;
      let returnValue: User;

      _this.httpClient
        .put(url, changes, {
          headers: getCustomHeaders(true),
          responseType: 'json',
        })
        .subscribe({
          next: (updatedUser: User) => {
            returnValue = updatedUser;

            if (currentUser && currentUser._id === userId) {
              _this.currentUserSubject.next(updatedUser);
            } else if (selectedUser && selectedUser._id === userId) {
              updatedUser = _this.populateSelectedUserActivityNotifications(updatedUser);
              _this.selectedUserSubject.next(updatedUser);
            }

            newUsers[userIndex] = updatedUser;
            _this.usersSubject.next(newUsers);
            resolve(updatedUser);
          },
          error: (error: HttpErrorResponse) => {
            const errMessage = _this.errorService.handleError(error);
            _this.userErrorSubject.next(errMessage);
            reject(error);
          },
          complete: () => {
            _this.settingsService.setIsLoading(false);
          }
        });
    });
  }
}
