import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2';

import { Measurement, Project, ProjectOverview, User } from '@shared/models';
import {
  ErrorService,
  LogService,
  ModService,
  ProjectService,
  SettingsService,
  ShipService,
  UnrealServerService,
  UserService,
  VehicleService,
} from '@shared/services';
import { UserRolesEnum } from '@shared/enums';

import { ModelAnalysisProjectDialogComponent } from '../model-analysis-project-dialog/model-analysis-project-dialog.component';
import { ProjectDialogComponent } from '../project-dialog/project-dialog.component';

const ObjectID = require('bson-objectid');

@UntilDestroy()
@Component({
  selector: 'app-projects-dashboard',
  templateUrl: './projects-dashboard.component.html',
  styleUrls: ['./projects-dashboard.component.css'],
})
export class ProjectsDashboardComponent implements OnInit {
  currentUser$: Observable<User>;
  userProjects$: Observable<ProjectOverview[]>;
  recentProjects$: Observable<ProjectOverview[]>;
  viewFormat$: Observable<string>;
  viewAsControl = new UntypedFormControl();

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private errorService: ErrorService,
    private logService: LogService,
    private modService: ModService,
    private projectService: ProjectService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private router: Router,
    private location: Location,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.userProjects$ = this.projectService.projects$;
    this.recentProjects$ = this.projectService.recentProjects$;
    this.viewFormat$ = this.settingsService.viewFormat$;
    this.viewAsControl.setValue(this.settingsService.getViewFormat());

    this.refresh();
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  createProject(currentUser: User): any {
    //allow projects to be created without vehicles per Alex - jane 8/16/2024
    const _this = this;
    let isModelAnalysisProject = false;

    Swal.fire({
      title: 'Is this a Model Analysis Project?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        isModelAnalysisProject = true;
      }

      this.settingsService.setIsLoading(true);

      const promises = [];
      promises.push(this.projectService.getProjectById(null, currentUser));
      promises.push(this.shipService.getShipById(null, currentUser));
      promises.push(this.vehicleService.getVehicleById(null, currentUser));
      let dialogRef;

      Promise.allSettled(promises).then((results) => {
        const project: Project = {
          _id: new ObjectID().toString(),
          areas: [],
          creatorId: currentUser._id,
          documentation: [],
          editorId: currentUser._id,
          geotags: [],
          hasValidPanoMod: false,
          images: [],
          isModelAnalysisProject: isModelAnalysisProject,
          measurements: [],
          minimumClearance: isModelAnalysisProject ? {
            lateral: {
              left: {
                value: 0,
                units: 'cm',
              },
              right: {
                value: 0,
                units: 'cm',
              },
            },
            longitudinal: {
              back: {
                value: 0,
                units: 'cm',
              },
              front: {
                value: 0,
                units: 'cm',
              },
            },
            vertical: {
              ground: {
                value: 0,
                units: 'cm',
              },
              roof: {
                value: 0,
                units: 'cm',
              },
            },
          } : null,
          name: null,
          notes: [],
          reports: [],
          ship: {
            _id: null,
            modId: null,
          },
          userIdsToSendChangeNotificationsTo: [currentUser._id],
          vehicle: isModelAnalysisProject ? {
            _id: null,
            modId: null
          } : null,
          videos: [],
        };

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
          currentUser: currentUser,
          isNewProject: true,
          project: project,
        };

        this.settingsService.setIsLoading(false);

        if (isModelAnalysisProject) {
          dialogRef = this.dialog
            .open(ModelAnalysisProjectDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
              this.refresh();
            });
        } else {
          dialogRef = this.dialog
            .open(ProjectDialogComponent, dialogConfig)
            .afterClosed()
            .subscribe((result) => {
              this.refresh();
            });
        }
      });
    });
  }

  onViewFormatChange(value: string, currentUser: User) {
    this.userService.updateViewFormatPreference(currentUser._id, value);
  }

  //see https://stackoverflow.com/questions/46746598/angular-material-how-to-refresh-a-data-source-mat-table
  refresh() {
    this.projectService.getProjects(this.userService.getCurrentUser()).subscribe((projects) => {
      this.changeDetectorRefs.detectChanges();
    });
  }
}
