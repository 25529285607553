<div class="content">
  <div
    *ngIf="{
    } as data"
  >
    <div *ngIf="showFilter">
      <mat-form-field class="table-filter">
        <mat-label>Search Projects</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search"
          aria-label="Type search criteria to filter results"
          matTooltip="Type search criteria to filter results"
          #tableFilter
        />
      </mat-form-field>
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="name"
      matSortDirection="asc"
      class="mat-elevation-z8"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Name</th>
        <td mat-cell *matCellDef="let projectOverview" style="cursor: pointer">
          <a
            aria-label="Click to view project"
            matTooltip="Click to view project"
            (click)="viewProject(projectOverview)"
            >{{ projectOverview.name }}</a
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="shipName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ship</th>
        <td mat-cell *matCellDef="let projectOverview" style="cursor: pointer">
          <a
            aria-label="Click to view project"
            matTooltip="Click to view project"
            (click)="viewProject(projectOverview)"
          >
            {{ projectOverview.shipName }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="vehicleName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle</th>
        <td mat-cell *matCellDef="let projectOverview" style="cursor: pointer">
          <a
            aria-label="Click to view project"
            matTooltip="Click to view project"
            (click)="viewProject(projectOverview)"
          >
            {{ projectOverview.vehicleName }}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="lastUpdatedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Modified By</th>
        <td mat-cell *matCellDef="let projectOverview" style="cursor: pointer">
          <a
            aria-label="Click to view project"
            matTooltip="Click to view project"
            (click)="viewProject(projectOverview)"
          >
            {{ projectOverview.lastUpdatedBy }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastUpdatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Modified</th>
        <td mat-cell *matCellDef="let projectOverview" style="cursor: pointer">
          <a
            aria-label="Click to view project"
            matTooltip="Click to view project"
            (click)="viewProject(projectOverview)"
          >
            {{ projectOverview.lastUpdatedAt | date: 'short' }}
          </a>
        </td>
      </ng-container>

      <!-- actions column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let projectOverview">
          <span>
            <button
              mat-icon-button
              aria-label="Open Project in Free Roam"
              matTooltip="Open Project in Free Roam"
              (click)="openProjectViewer(projectOverview)"
            >
              <img src="assets/img/UEViewer.png" alt="Open {{ unrealViewerName }}" />
            </button>
            <button
              mat-icon-button
              aria-label="View Project"
              matTooltip="View Project"
              (click)="viewProject(projectOverview)"
            >
              <img src="assets/img/Search.png" alt="View" />
            </button>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedProjectOverviewColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedProjectOverviewColumns"></tr>

      <!-- Row shown when there is no matching data -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">*** No matching projects ***</td>
      </tr>
    </table>

    <mat-paginator *ngIf="showPaginator">
      [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons aria-label="Select page of projects"
    </mat-paginator>
  </div>
</div>
