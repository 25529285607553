<div class="content">
  <div
    *ngIf="{
      modSafeUrl: modSafeUrl$ | async,
      currentUser: currentUser$ | async
    } as data"
  >
    <div *ngIf="showFilter">
      <mat-form-field class="table-filter">
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search"
          aria-label="Type search criteria to filter results"
          matTooltip="Type search criteria to filter results"
          #tableFilter
        />
      </mat-form-field>
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="modName"
      matSortDirection="asc"
      class="mat-elevation-z8 modTable"
    >
      <div *ngIf="isModsNeedingAdjustment; then needingAdjustmentBlock; else inProcessingBlock"></div>
      <ng-template #needingAdjustmentBlock>
        <!-- modName column -->
        <ng-container matColumnDef="modName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Name</th>
          <td mat-cell *matCellDef="let mod">{{ mod.name }}</td>
        </ng-container>

        <!-- model3dName column -->
        <ng-container matColumnDef="model3dName" *ngIf="allowVehicleModels3d">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>3D Model Name</th>
          <td mat-cell *matCellDef="let mod">{{ mod.model3dDetails ? mod.model3dDetails.name : '' }}</td>
        </ng-container>

        <!-- scanName column -->
        <ng-container matColumnDef="scanName" *ngIf="allowVehicleScans">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Scan Name</th>
          <td mat-cell *matCellDef="let mod">{{ mod.scanDetails ? mod.scanDetails.name : '' }}</td>
        </ng-container>

        <!-- vehicleName column -->
        <ng-container matColumnDef="vehicleName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Name</th>
          <td mat-cell *matCellDef="let mod">{{ mod.vehicleDetails ? mod.vehicleDetails.name : '' }}</td>
        </ng-container>

        <!-- vehicleDesignation column -->
        <ng-container matColumnDef="vehicleDesignation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Designation</th>
          <td mat-cell *matCellDef="let mod">{{ mod.vehicleDetails ? mod.vehicleDetails.designation : '' }}</td>
        </ng-container>

        <!-- actions column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let mod">
            <mat-toolbar class="actionButtonBar">
              <a
                mat-icon-button
                aria-label="Open in ViPr Viewer"
                matTooltip="Open in ViPr Viewer"
                (click)="openViewer(mod)"
              >
                <img src="assets/img/UEViewer.png" alt="Open {{ unrealViewerName }}" />
              </a>
            </mat-toolbar>
          </td>
        </ng-container>
      </ng-template>

      <ng-template #inProcessingBlock>
        <div *ngIf="isVehicleMods; then vehiclesNeedingProcessingBlock; else shipsNeedingProcessingBlock"></div>
        <ng-template #vehiclesNeedingProcessingBlock>
          <!-- modName column -->
          <ng-container matColumnDef="modName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Name</th>
            <td mat-cell *matCellDef="let mod">{{ mod.name }}</td>
          </ng-container>

          <!-- model3dName column -->
          <ng-container matColumnDef="model3dName" *ngIf="allowVehicleModels3d">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>3D Model Name</th>
            <td mat-cell *matCellDef="let mod">{{ mod.model3dDetails ? mod.model3dDetails.name : '' }}</td>
          </ng-container>

          <!-- scanName column -->
          <ng-container matColumnDef="scanName" *ngIf="allowVehicleScans">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Scan Name</th>
            <td mat-cell *matCellDef="let mod">{{ mod.scanDetails ? mod.scanDetails.name : '' }}</td>
          </ng-container>

          <!-- vehicleName column -->
          <ng-container matColumnDef="vehicleName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Name</th>
            <td mat-cell *matCellDef="let mod">{{ mod.vehicleDetails ? mod.vehicleDetails.name : '' }}</td>
          </ng-container>

          <!-- vehicleDesignation column -->
          <ng-container matColumnDef="vehicleDesignation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Designation</th>
            <td mat-cell *matCellDef="let mod">{{ mod.vehicleDetails ? mod.vehicleDetails.designation : '' }}</td>
          </ng-container>

          <!-- actions column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let mod">
              <mat-toolbar class="actionButtonBar">
                <a
                  mat-icon-button
                  color="primary"
                  aria-label="Download"
                  matTooltip="Download {{ mod.name }}"
                  (click)="download(mod, data.currentUser)"
                >
                  <img src="assets/img/Download.png" alt="Download" />
                </a>
                <a
                  mat-icon-button
                  color="secondary"
                  aria-label="Upload"
                  matTooltip="Upload Processed File for {{ mod.name }}"
                  (click)="upload(mod, data.currentUser)"
                >
                  <img src="assets/img/Upload.png" alt="View" />
                </a>
              </mat-toolbar>
            </td>
          </ng-container>
        </ng-template>
        <ng-template #shipsNeedingProcessingBlock>
          <!-- modName column -->
          <ng-container matColumnDef="modName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Name</th>
            <td mat-cell *matCellDef="let mod">{{ mod.name }}</td>
          </ng-container>

          <!-- model3dName column -->
          <ng-container matColumnDef="model3dName" *ngIf="allowShipModels3d">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>3D Model Name</th>
            <td mat-cell *matCellDef="let mod">{{ mod.model3dDetails ? mod.model3dDetails.name : '' }}</td>
          </ng-container>

          <!-- scanName column -->
          <ng-container matColumnDef="scanName" *ngIf="allowShipScans">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Scan Name</th>
            <td mat-cell *matCellDef="let mod">{{ mod.scanDetails ? mod.scanDetails.name : '' }}</td>
          </ng-container>

          <!-- shipName column -->
          <ng-container matColumnDef="shipName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ship Name</th>
            <td mat-cell *matCellDef="let mod">{{ mod.shipDetails ? mod.shipDetails.name : '' }}</td>
          </ng-container>

          <!-- shipDesignationWithHullNumber column -->
          <ng-container matColumnDef="shipDesignationWithHullNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ship Designation</th>
            <td mat-cell *matCellDef="let mod">
              {{ mod.shipDetails ? mod.shipDetails.shipDesignationWithHullNumber : '' }}
            </td>
          </ng-container>

          <!-- actions column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let mod">
              <mat-toolbar class="actionButtonBar">
                <a
                  mat-icon-button
                  color="primary"
                  aria-label="Download"
                  matTooltip="Download {{ mod.name }}"
                  (click)="download(mod, data.currentUser)"
                >
                  <img src="assets/img/Download.png" alt="Download" />
                </a>
                <a
                  mat-icon-button
                  color="secondary"
                  aria-label="Upload"
                  matTooltip="Upload Processed File for {{ mod.name }}"
                  (click)="upload(mod, data.currentUser)"
                >
                  <img src="assets/img/Upload.png" alt="View" />
                </a>
              </mat-toolbar>
            </td>
          </ng-container>
        </ng-template>
      </ng-template>

      <tr mat-header-row *matHeaderRowDef="displayedModColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedModColumns"></tr>

      <!-- Row shown when there is no matching data -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">*** No matching assets ***</td>
      </tr>
    </table>

    <mat-paginator *ngIf="showPaginator">
      [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons aria-label="Select page of assets"
    </mat-paginator>

    <iframe id="iframeForDownload" [src]="data.modSafeUrl" style="visibility: hidden"></iframe>
  </div>
</div>
