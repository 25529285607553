<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser">
  <ng-container
    *ngIf="{
      project: project$ | async,
      projectDocuments: projectDocuments$ | async,
      projectImages: projectImages$ | async,
      projectNotes: projectNotes$ | async,
      projectReports: projectReports$ | async,
      projectVideos: projectVideos$ | async,
      reportSections: reportSections$ | async,
      selectedShip: selectedShip$ | async,
      selectedShipMod: selectedShipMod$ | async,
      selectedShipMods: selectedShipMods$ | async,
      selectedShipModModel3d: selectedShipModModel3d$ | async,
      selectedShipModScan: selectedShipModScan$ | async,
      selectedShipModels3d: selectedShipModels3d$ | async,
      selectedShipScans: selectedShipScans$ | async,
      selectedVehicle: selectedVehicle$ | async,
      selectedVehicleMod: selectedVehicleMod$ | async,
      selectedVehicleModModel3d: selectedVehicleModModel3d$ | async,
      selectedVehicleModScan: selectedVehicleModScan$ | async,
      selectedVehicleMods: selectedVehicleMods$ | async,
      selectedVehicleModels3d: selectedVehicleModels3d$ | async,
      selectedVehicleScans: selectedVehicleScans$ | async,
      shipImages: selectedReportShipImages$ | async,
      ships: ships$ | async,
      vehicleImages: selectedReportVehicleImages$ | async,
      vehicles: vehicles$ | async
    } as data"
  >
    <div *ngIf="data.project">
      <h1 style="display: inline-block">{{ data.project.name }}</h1>
      <div class="toolbar-action-buttons mat-toolbar-single-row">
        <a mat-stroked-button aria-label="Expand all" matTooltip="Expand all" (click)="accordion.openAll()"
          >Expand All</a
        >
        <a mat-stroked-button aria-label="Collapse all" matTooltip="Collapse all" (click)="accordion.closeAll()"
          >Collapse All</a
        >
        <a
          mat-stroked-button
          aria-label="View / Edit Project"
          matTooltip="View / Edit Project"
          (click)="editProject(data.project, currentUser)"
        >
          <img src="assets/img/Edit.png" alt="Edit" />
          <span>&nbsp;&nbsp;Edit Project</span>
        </a>
        <a
          mat-stroked-button
          aria-label="Open Project in Free Roam"
          matTooltip="Open project in {{ unrealViewerName }}"
          (click)="openProjectViewer(data.project, false)"
        >
          <img src="assets/img/UEViewer.png" alt="Open {{ unrealViewerName }}" />
          <span>&nbsp;&nbsp;Open {{ unrealViewerName }}</span>
        </a>
        <div *ngIf="showViewerLite && data.project.hasValidPanoMod">
          <a
            mat-stroked-button
            aria-label="Open Project in Free Roam Lite"
            matTooltip="Open project in {{ unrealViewerNameLite }}"
            (click)="openProjectViewer(data.project, true)"
          >
            <img src="assets/img/UEViewerLite.png" alt="Open {{ unrealViewerNameLite }}" />
            <span>&nbsp;&nbsp;Open {{ unrealViewerNameLite }}</span>
          </a>
        </div>
        <div>
          <mat-checkbox
            class="example-margin"
            aria-label="Get Activity Notifications?"
            matTooltip="Get Activity Notifications?"
            [checked]="getIsGettingNotifications(data.project)"
            (change)="updateNotifications($event.checked, data.project)"
          >
            <span>&nbsp;&nbsp;Get Notifications?</span>
          </mat-checkbox>
        </div>
      </div>

      <div>
        <p *ngIf="errorMsg">{{ errorText }}</p>
      </div>

      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>PROJECT OVERVIEW</mat-panel-title>
            <mat-panel-description>Overview of project including ship and vehicle selections. </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="project-grid-container">
            <div>
              <table style="width: 100%">
                <tr>
                  <td style="width: 25%">Name</td>
                  <td style="width: 75%">{{ data.project.name }}</td>
                </tr>
                <tr>
                  <td style="width: 25%">Description</td>
                  <td style="width: 75%">{{ data.project.description }}</td>
                </tr>
                <tr>
                  <td style="width: 25%">Ship</td>
                  <td *ngIf="data.selectedShip" style="width: 75%">
                    {{ data.selectedShip.name }} | {{ data.selectedShip.designationWithHullNumber }}
                  </td>
                </tr>
                <tr *ngIf="data.selectedShipModModel3d">
                  <td style="width: 25%">Ship 3D Model</td>
                  <td style="width: 75%">
                    {{ data.selectedShipModModel3d.name }} | Modeled:
                    {{ data.selectedShipModModel3d.modelDate | date: 'short' }} by
                    {{ data.selectedShipModModel3d.nameOfPersonModeling }}
                  </td>
                </tr>
                <tr *ngIf="data.selectedShipModScan">
                  <td style="width: 25%">Ship Scan</td>
                  <td style="width: 75%">
                    {{ data.selectedShipModScan.name }} | Scanned:
                    {{ data.selectedShipModScan.scanDate | date: 'short' }} by
                    {{ data.selectedShipModScan.nameOfPersonScanning }}
                  </td>
                </tr>
                <tr *ngIf="data.project.isModelAnalysisProject">
                  <td style="width: 25%">Vehicle</td>
                  <td *ngIf="data.selectedVehicle" style="width: 75%">
                    {{ data.selectedVehicle.name }} | {{ data.selectedVehicle.modelName }}
                  </td>
                </tr>
                <tr *ngIf="data.project.isModelAnalysisProject && data.selectedVehicleModModel3d">
                  <td style="width: 25%">Vehicle 3D Model</td>
                  <td style="width: 75%">
                    {{ data.selectedVehicleModModel3d.name }} | Modeled:
                    {{ data.selectedVehicleModModel3d.modelDate | date: 'short' }} by
                    {{ data.selectedVehicleModModel3d.nameOfPersonModeling }}
                  </td>
                </tr>
                <tr *ngIf="data.project.isModelAnalysisProject && data.selectedVehicleModScan">
                  <td style="width: 25%">Vehicle Scan</td>
                  <td style="width: 75%">
                    {{ data.selectedVehicleModScan.name }} | Scanned:
                    {{ data.selectedVehicleModScan.scanDate | date: 'short' }} by
                    {{ data.selectedVehicleModScan.nameOfPersonScanning }}
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <img
                class="project-image"
                src="{{ data.project.displayThumbnailUrl || data.project.displayUrl || data.project.imageThumbnailUrl || data.project.imageUrl  }}"
                alt="{{ data.project.name }} image"
                onerror="this.onerror=null; this.src='../../../assets/img/SDAT.png';"
              />
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="data.project.isModelAnalysisProject">
          <mat-expansion-panel-header>
            <mat-panel-title>MINIMUM CLEARANCE</mat-panel-title>
            <mat-panel-description
              >The Minimum Clearance Distance determines the clearance the vehicle has on all sides when analyzing paths
              for collisions in the future steps.
            </mat-panel-description>
          </mat-expansion-panel-header>
          <table style="width: 100%">
            <tr>
              <td style="width: 25%">Longitudinal (Front)</td>
              <td style="width: 75%" *ngIf="data.project.minimumClearance.longitudinal.front">
                {{ data.project.minimumClearance.longitudinal.front.value }}
                {{ data.project.minimumClearance.longitudinal.front.units }}
              </td>
            </tr>
            <tr>
              <td style="width: 25%">Longitudinal (Back)</td>
              <td style="width: 75%" *ngIf="data.project.minimumClearance.longitudinal.back">
                {{ data.project.minimumClearance.longitudinal.back.value }}
                {{ data.project.minimumClearance.longitudinal.back.units }}
              </td>
            </tr>
            <tr>
              <td style="width: 25%">Lateral (Left)</td>
              <td style="width: 75%" *ngIf="data.project.minimumClearance.lateral.left">
                {{ data.project.minimumClearance.lateral.left.value }}
                {{ data.project.minimumClearance.lateral.left.units }}
              </td>
            </tr>
            <tr>
              <td style="width: 25%">Lateral (Right)</td>
              <td style="width: 75%" *ngIf="data.project.minimumClearance.lateral.right">
                {{ data.project.minimumClearance.lateral.right.value }}
                {{ data.project.minimumClearance.lateral.right.units }}
              </td>
            </tr>
            <tr>
              <td style="width: 25%">Vertical (Roof)</td>
              <td style="width: 75%" *ngIf="data.project.minimumClearance.vertical.roof">
                {{ data.project.minimumClearance.vertical.roof.value }}
                {{ data.project.minimumClearance.vertical.roof.units }}
              </td>
            </tr>
            <tr>
              <td style="width: 25%">Vertical (Ground)</td>
              <td style="width: 75%" *ngIf="data.project.minimumClearance.vertical.ground">
                {{ data.project.minimumClearance.vertical.ground.value }}
                {{ data.project.minimumClearance.vertical.ground.units }}
              </td>
            </tr>
          </table>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >NOTES ({{ getNumberOfItemsInArray(getEditableProjectNotes(data.projectNotes)) }})
            </mat-panel-title>
            <mat-panel-description
              >Notes added to the project that are not tied to reports, snapshots or videos by report section.
            </mat-panel-description>
          </mat-expansion-panel-header>

          <button mat-raised-button color="accent" class="right" (click)="addNote(data.project)">
            <mat-icon>library_add</mat-icon>
            <span>Add Note</span>
          </button>

          <div *ngIf="getEditableProjectNotes(data.projectNotes) as notes">
            <app-note-table
              [notes]="notes"
              [project]="data.project"
              [reportSections]="data.reportSections"
              [isSnapshotOrVideoNotes]="false"
            ></app-note-table>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >SNAPSHOTS ({{ getNumberOfItemsInArray(getSnapshotImages(data.projectImages)) }})
            </mat-panel-title>
            <mat-panel-description>Snapshots added to the project with notes by report section. </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="grid" *ngIf="getSnapshotImages(data.projectImages) as snapshotImages">
            <div *ngFor="let img of snapshotImages">
              <app-snapshot-card
                [img]="img"
                [notes]="getSnapshotNotes(data.projectNotes, img._id)"
                [project]="data.project"
                [reportSections]="data.reportSections"
              ></app-snapshot-card>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              >IMAGES ({{ getNumberOfItemsInArray(getNonSnapshotImages(data.projectImages)) }})
            </mat-panel-title>
            <mat-panel-description>Non-snapshot images added to the project.</mat-panel-description>
          </mat-expansion-panel-header>

          <span class="right">
            <button mat-raised-button color="accent" class="right" (click)="imageFileInput.click()">
              <mat-icon>library_add</mat-icon>
              <span>Add Image</span>
              <input
                #imageFileInput
                type="file"
                (change)="imageFileInputChange($event)"
                accept="image/*"
                style="display: none"
                multiple 
              />
            </button>
          </span>

          <div class="grid" *ngIf="getNonSnapshotImages(data.projectImages) as projectImages">
            <div *ngFor="let img of projectImages">
              <app-image-doc-card [img]="img" [project]="data.project"></app-image-doc-card>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>VIDEOS ({{ getNumberOfItemsInArray(data.projectVideos) }})</mat-panel-title>
            <mat-panel-description>Videos added to the project by report section.</mat-panel-description>
          </mat-expansion-panel-header>

          <span class="right">
            <button mat-raised-button color="accent" (click)="videoFileInput.click()">
              <mat-icon>library_add</mat-icon>
              <span>Add Video</span>
              <input
                #videoFileInput
                type="file"
                (change)="videoFileInputChange($event)"
                accept=".mp4"
                style="display: none" 
                multiple 
              />
            </button>
          </span>

          <div class="grid" *ngIf="data.projectVideos">
            <div *ngFor="let video of data.projectVideos">
              <app-video-card
              [video]="video"
              [notes]="getVideoNotes(data.projectNotes, video._id)"
              [project]="data.project"
            >
            </app-video-card>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>DOCUMENTS ({{ getNumberOfItemsInArray(data.projectDocuments) }}) </mat-panel-title>
            <mat-panel-description>Documents added to the project.</mat-panel-description>
          </mat-expansion-panel-header>

          <span class="right">
            <button mat-raised-button color="accent" (click)="documentFileInput.click()">
              <mat-icon>library_add</mat-icon>
              <span>Add Document</span>
              <input
                #documentFileInput
                type="file"
                (change)="documentFileInputChange($event)"
                accept="image/*,video/*,audio/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                style="display: none" 
                multiple 
              />
            </button>
          </span>

          <br><br>

          <div class="grid" *ngIf="data.projectDocuments">
            <div *ngFor="let doc of data.projectDocuments">
              <app-document-card [doc]="doc" [project]="data.project"></app-document-card>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="data.project.isModelAnalysisProject">
          <mat-expansion-panel-header>
            <mat-panel-title>REPORTS ({{ getNumberOfItemsInArray(data.projectReports) }})</mat-panel-title>
            <mat-panel-description>Reports created for the project.</mat-panel-description>
          </mat-expansion-panel-header>

          <button
            mat-raised-button
            color="accent"
            class="right"
            (click)="
              createReport(
                data.project,
                data.selectedShip,
                data.selectedShipMod,
                data.selectedShipModModel3d,
                data.selectedShipModScan,
                data.selectedVehicle,
                data.selectedVehicleMod,
                data.selectedVehicleModModel3d,
                data.selectedVehicleModScan
              )
            "
          >
            <mat-icon>library_add</mat-icon>
            <span>Create Report</span>
          </button>

          <div *ngIf="data.projectReports">
            <table mat-table [dataSource]="data.projectReports" class="mat-elevation-z8">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Report Name</th>
                <td mat-cell *matCellDef="let report">{{ report.name }}</td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let report">{{ report.description }}</td>
              </ng-container>

              <ng-container matColumnDef="finalizedDate">
                <th mat-header-cell *matHeaderCellDef>Finalized Date</th>
                <td mat-cell *matCellDef="let report">{{ report.finalized.finalizedDate | date: 'MM/dd/yyyy' }}</td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>Actions</th>
                <td mat-cell *matCellDef="let report">
                  <span>
                    <button
                      *ngIf="canDelete"
                      mat-icon-button
                      color="warn"
                      aria-label="Delete Report"
                      matTooltip="Delete Report"
                      (click)="deleteReport(report, data.project._id)"
                    >
                      <img src="assets/img/TrashGray.png" alt="Delete" />
                    </button>
                    <button
                      mat-icon-button
                      aria-label="Open Report"
                      matTooltip="Open Report"
                      (click)="
                        openReport(
                          data.project,
                          report,
                          data.selectedShip,
                          data.selectedShipMod,
                          data.selectedShipModModel3d,
                          data.selectedShipModScan,
                          data.selectedVehicle,
                          data.selectedVehicleMod,
                          data.selectedVehicleModModel3d,
                          data.selectedVehicleModScan
                        )
                      "
                    >
                      <img src="assets/img/Search.png" alt="Open Report" />
                    </button>
                  </span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedReportColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedReportColumns"></tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <canvas id="canvasForImageProcessing"></canvas>
    </div>
  </ng-container>
</div>
