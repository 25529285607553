import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of, zip } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';

import { Mod, User } from '@shared/models';
import { DbCollectionsEnum, ModStatesEnum, ScanDisplayTypesEnum } from '@shared/enums';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import { ErrorService, LogService, SettingsService, UserService } from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class ModService {
  private currentShipModSubject = new BehaviorSubject<Mod>(null);
  private currentShipModsSubject = new BehaviorSubject<Mod[]>([]);
  private currentVehicleModSubject = new BehaviorSubject<Mod>(null);
  private currentVehicleModsSubject = new BehaviorSubject<Mod[]>([]);
  private invalidModsCountSubject = new BehaviorSubject<number>(0);
  private modsSubject = new BehaviorSubject<Mod[]>([]);
  private shipModsInProcessingSubject = new BehaviorSubject<Mod[]>([]);
  private shipPointCloudModsInProcessingSubject = new BehaviorSubject<Mod[]>([]);
  private vehicleModsInProcessingSubject = new BehaviorSubject<Mod[]>([]);
  private vehicleModsNeedingAdjustmentSubject = new BehaviorSubject<Mod[]>([]);
  private modErrorSubject = new BehaviorSubject<string>(null);
  currentShipMod$: Observable<Mod> = this.currentShipModSubject.asObservable();
  currentShipMods$: Observable<Mod[]> = this.currentShipModsSubject.asObservable();
  currentVehicleMod$: Observable<Mod> = this.currentVehicleModSubject.asObservable();
  currentVehicleMods$: Observable<Mod[]> = this.currentVehicleModsSubject.asObservable();
  invalidModsCount$: Observable<number> = this.invalidModsCountSubject.asObservable();
  mods$: Observable<Mod[]> = this.modsSubject.asObservable();
  shipModsInProcessing$: Observable<Mod[]> = this.shipModsInProcessingSubject.asObservable();
  shipPointCloudModsInProcessing$: Observable<Mod[]> = this.shipPointCloudModsInProcessingSubject.asObservable();
  vehicleModsInProcessing$: Observable<Mod[]> = this.vehicleModsInProcessingSubject.asObservable();
  vehicleModsNeedingAdjustment$: Observable<Mod[]> = this.vehicleModsNeedingAdjustmentSubject.asObservable();
  modError$: Observable<string> = this.modErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) {
  }

  getMods(currentUser: User): Observable<Mod[]> {
    const modsHttp$ = createHttpObservable(`${environment.baseAPIUrl}mod?userId=${currentUser?._id}`, {}, true);

    modsHttp$
      .pipe(
        catchError((err) => {
          console.error(`Error getting mods: ${err}`);
          return of([]);
        })
      )
      .subscribe({
        next: (mods: Mod[]) => {
          this.modsSubject.next(mods);
          this.shipModsInProcessingSubject.next(
            mods.filter(
              (mod) => mod.modState === ModStatesEnum.IN_PROCESSING && mod.parent.collection === DbCollectionsEnum.SHIPS
            )
          );
          this.shipPointCloudModsInProcessingSubject.next(
            mods.filter(
              (mod) => mod.modState === ModStatesEnum.IN_PROCESSING && mod.parent.collection === DbCollectionsEnum.SHIPS
                && mod.scanDetails?.scanDisplayType === ScanDisplayTypesEnum.POINT_CLOUD
            )
          )
          this.vehicleModsInProcessingSubject.next(
            mods.filter(
              (mod) =>
                mod.modState === ModStatesEnum.IN_PROCESSING && mod.parent.collection === DbCollectionsEnum.VEHICLES
            )
          );
          this.vehicleModsNeedingAdjustmentSubject.next(
            mods.filter(
              (mod) =>
                mod.modState === ModStatesEnum.ADJUSTMENT_REQUIRED && mod.parent.collection === DbCollectionsEnum.VEHICLES
            )
          );
          this.invalidModsCountSubject.next(
            this.shipModsInProcessingSubject.getValue().length +
            this.vehicleModsInProcessingSubject.getValue().length +
            this.vehicleModsNeedingAdjustmentSubject.getValue().length
          );
        },
        error: (error) => {
          const errMessage = this.errorService.handleError(error);
          this.modErrorSubject.next(errMessage);
          this.modsSubject.next(null);
        },
        complete: () => { }

      });

    return modsHttp$;
  }

  async getModById(modId: string, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      let isValidParentCollection = true;

      if (parentCollection) {
        switch (parentCollection) {
          case DbCollectionsEnum.SHIPS:
          case DbCollectionsEnum.VEHICLES:
            break;
          default:
            isValidParentCollection = false;
            break;
        }

        if (isValidParentCollection) {
          let returnValue: Mod;
          const mods: Mod[] = _this.modsSubject.getValue();
          let shipMods: Mod[];
          let vehicleMods: Mod[];

          if (currentUser && modId) {
            switch (parentCollection) {
              case DbCollectionsEnum.SHIPS:
                if (parentId && mods && mods.length > 0) {
                  shipMods = mods.filter(
                    (mod) => mod.parent && mod.parent.collection === parentCollection && mod.parent._id === parentId
                  );
                }
                _this.currentShipModsSubject.next(shipMods);
    
                if (modId && shipMods && shipMods.length > 0) {
                  returnValue = shipMods.find((mod) => mod._id === modId);
                }
    
                _this.currentShipModSubject.next(returnValue);
                break;
              case DbCollectionsEnum.VEHICLES:
                if (parentId && mods && mods.length > 0) {
                  vehicleMods = mods.filter(
                    (mod) => mod.parent && mod.parent.collection === parentCollection && mod.parent._id === parentId
                  );
                }
                _this.currentVehicleModsSubject.next(vehicleMods);
    
                if (modId && vehicleMods && vehicleMods.length > 0) {
                  returnValue = vehicleMods.find((mod) => mod._id === modId);
                }
    
                _this.currentVehicleModSubject.next(returnValue);
                break;
            }
          } else {
            switch (parentCollection) {
              case DbCollectionsEnum.SHIPS:
                this.currentShipModSubject.next(returnValue);
                break;
              case DbCollectionsEnum.VEHICLES:
                this.currentVehicleModSubject.next(returnValue);
                break;
              default:
                break;
            }
          }

          resolve(returnValue);
        } else {
          const errMessage = _this.errorService.handleError(`${parentCollection} is not a valid mod parent collection`);
          _this.modErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `parentCollection is required to get a mod by id`;
        _this.errorService.handleError(errMessage);
        _this.modErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  /* Not used - jane 10/1/2024
  async getModByModSource(
    modSourceId: string,
    modSourceCollection: string,
    parentCollection: string,
    parentId: string,
    currentUser: User
  ): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && parentCollection) {
        const mods: Mod[] = _this.modsSubject.getValue();
        let shipMods: Mod[];
        let vehicleMods: Mod[];
        let isValidParentCollection = true;
        let returnValue: Mod;

        switch (parentCollection) {
          case DbCollectionsEnum.SHIPS:
            if (parentId && mods && mods.length > 0) {
              shipMods = mods.filter(
                (mod) => mod.parent && mod.parent.collection === parentCollection && mod.parent._id === parentId
              );
            }

            _this.currentShipModsSubject.next(shipMods);

            if (modSourceId && modSourceCollection && shipMods && shipMods.length > 0) {
              returnValue = shipMods.find(
                (mod) => mod.modSource._id === modSourceId && mod.modSource.collection === modSourceCollection
              );
            }

            _this.currentShipModSubject.next(returnValue);
            break;
          case DbCollectionsEnum.VEHICLES:
            if (parentId && mods && mods.length > 0) {
              vehicleMods = mods.filter(
                (mod) => mod.parent && mod.parent.collection === parentCollection && mod.parent._id === parentId
              );
            }

            _this.currentVehicleModsSubject.next(vehicleMods);

            if (modSourceId && modSourceCollection && vehicleMods && vehicleMods.length > 0) {
              returnValue = vehicleMods.find((mod) => mod.modSource._id === modSourceId);
            }

            _this.currentVehicleModSubject.next(returnValue);
            break;
          default:
            _this.currentShipModsSubject.next(shipMods);
            _this.currentVehicleModsSubject.next(vehicleMods);
            _this.currentShipModSubject.next(returnValue);
            _this.currentVehicleModSubject.next(returnValue);
            isValidParentCollection = false;
            break;
        }

        if (isValidParentCollection) {
          resolve(returnValue);
        } else {
          const errMessage = _this.errorService.handleError(`${parentCollection} is not a valid mod parent collection`);
          _this.modErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = _this.errorService.handleError(`parentCollection and user are required to get mod by mod source`);
        _this.modErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }
    */

  getCurrentShipMod() {
    return this.currentShipModSubject.getValue();
  }

  getCurrentShipMods() {
    return this.currentShipModsSubject.getValue();
  }

  getCurrentVehicleMod() {
    return this.currentVehicleModSubject.getValue();
  }

  getCurrentVehicleMods() {
    return this.currentVehicleModsSubject.getValue();
  }

  getShipModsInProcessing() {
    return this.shipModsInProcessingSubject.getValue();
  }

  getVehicleModsInProcessing() {
    return this.vehicleModsInProcessingSubject.getValue();
  }

  getVehicleModsNeedingAdjustment() {
    return this.vehicleModsNeedingAdjustmentSubject.getValue();
  }

  async refreshModsByParent(parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && parentCollection) {
        const mods = _this.modsSubject.getValue();
        let returnValue: Mod[];
        let shipMods: Mod[];
        let vehicleMods: Mod[];
        let isValidParentCollection = true;

        switch (parentCollection) {
          case DbCollectionsEnum.SHIPS:
            if (parentId && mods && mods.length > 0) {
              shipMods = mods.filter(
                (mod) => mod.parent && mod.parent.collection === parentCollection && mod.parent._id === parentId
              );
            }

            _this.currentShipModsSubject.next(shipMods);

            if (parentId && shipMods && shipMods.length > 0) {
              returnValue = shipMods.filter(
                (mod) => mod.parent && mod.parent.collection === parentCollection && mod.parent._id === parentId
              );
            }
            break;
          case DbCollectionsEnum.VEHICLES:
            if (parentId && mods && mods.length > 0) {
              vehicleMods = mods.filter(
                (mod) => mod.parent && mod.parent.collection === parentCollection && mod.parent._id === parentId
              );
            }

            _this.currentVehicleModsSubject.next(vehicleMods);

            if (parentId && vehicleMods && vehicleMods.length > 0) {
              returnValue = vehicleMods.filter(
                (mod) => mod.parent && mod.parent.collection === parentCollection && mod.parent._id === parentId
              );
            }
            break;
          default:
            _this.currentShipModsSubject.next(shipMods);
            _this.currentVehicleModsSubject.next(vehicleMods);
            isValidParentCollection = false;
            break;
        }

        if (isValidParentCollection) {
          resolve(returnValue);
        } else {
          const errMessage = _this.errorService.handleError(`${parentCollection} is not a valid mod parent collection`);
          _this.modErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `Parent collection and user are required refresh mods by parent`;
        _this.errorService.handleError(errMessage);
        _this.modErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveMod(modId: string, parentCollection: string, parentId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && modId && parentCollection && parentId) {
        const url = `${environment.baseAPIUrl}mod/${modId}?userId=${currentUser?._id}`;
        let returnValue: Mod;
        let mods: Mod[];
        let isValidParentCollection = true;

        switch (parentCollection) {
          case DbCollectionsEnum.SHIPS:
            mods = _this.currentShipModsSubject.getValue();
            break;
          case DbCollectionsEnum.VEHICLES:
            mods = _this.currentVehicleModsSubject.getValue();
            break;
          default:
            isValidParentCollection = false;
            break;
        }

        if (isValidParentCollection) {
          const modIndex = mods && mods.length > 0 ? mods.findIndex((mod) => mod._id === modId) : -1;
          const newMods = mods && mods.length > 0 ? mods.slice(0) : null;

          _this.httpClient
            .put(url, changes, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (updatedMod: Mod) => {
                returnValue = updatedMod;
                if (modIndex != -1) {
                  newMods[modIndex] = updatedMod;
                }

                switch (parentCollection) {
                  case DbCollectionsEnum.SHIPS:
                    _this.currentShipModSubject.next(returnValue);
                    _this.currentShipModsSubject.next(newMods);
                    break;
                  case DbCollectionsEnum.VEHICLES:
                    _this.currentVehicleModSubject.next(returnValue);
                    _this.currentVehicleModsSubject.next(newMods);
                    break;
                }

                resolve(returnValue);
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.modErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {

              }
            });
        } else {
          const errMessage = _this.errorService.handleError(
            `${parentCollection} is not a valid parent collection for mods`
          );
          _this.modErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `changes, modId, parent collection, parentId and user are required to update a mod`;
        _this.errorService.handleError(errMessage);
        _this.modErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }
}
