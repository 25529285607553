export enum ReportSectionIdsEnum {
  ANALYSIS = 'analysis',
  COLLISION = 'collision',
  MINIMUM_CLEARANCE = 'minimumClearance',
  PATH_TRAVELED = 'pathTraveled',
  PROJECT_INFO = 'projectInfo',
  PROPAGATED_ERROR = 'propagatedError',
  SHIP = 'ship',
  SNAPSHOT = 'snapshot',
  USER = 'user',
  VEHICLE = 'vehicle',
}
