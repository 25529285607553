import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, from, throwError, of, zip } from 'rxjs';
import { catchError, filter, map, switchMap, tap, toArray } from 'rxjs/operators';

import { DataSource, Mod, Model3d, User } from '@shared/models';
import { DbCollectionsEnum } from '@shared/enums';
import { getCustomHeaders } from '@shared/utils';

import { DataSourceService, ErrorService, LogService, ModService, SettingsService, UserService } from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class Model3dService {
  private currentShipModel3dSubject = new BehaviorSubject<Model3d>(null);
  private currentShipModel3dCreatorSubject = new BehaviorSubject<User>(null);
  private currentShipModel3dDataSourceSubject = new BehaviorSubject<DataSource>(null);
  private currentVehicleModel3dSubject = new BehaviorSubject<Model3d>(null);
  private currentVehicleModel3dCreatorSubject = new BehaviorSubject<User>(null);
  private currentVehicleModel3dDataSourceSubject = new BehaviorSubject<DataSource>(null);
  private models3dSubject = new BehaviorSubject<Model3d[]>([]);
  private model3dErrorSubject = new BehaviorSubject<string>(null);
  private currentShipModels3dSubject = new BehaviorSubject<Model3d[]>(null);
  private currentShipModels3dWithModsSubject = new BehaviorSubject<Model3d[]>(null);
  private currentVehicleModels3dSubject = new BehaviorSubject<Model3d[]>(null);
  private currentVehicleModels3dWithModsSubject = new BehaviorSubject<Model3d[]>(null);
  private modsSubject = new BehaviorSubject<Mod[]>(null);
  private updatedModel3dIdSubject = new BehaviorSubject<string>(null);
  currentShipModel3d$: Observable<Model3d> = this.currentShipModel3dSubject.asObservable();
  currentShipModel3dCreator$: Observable<User> = this.currentShipModel3dCreatorSubject.asObservable();
  currentShipModel3dDataSource$: Observable<DataSource> = this.currentShipModel3dDataSourceSubject.asObservable();
  currentVehicleModel3d$: Observable<Model3d> = this.currentVehicleModel3dSubject.asObservable();
  currentVehicleModel3dCreator$: Observable<User> = this.currentVehicleModel3dCreatorSubject.asObservable();
  currentVehicleModel3dDataSource$: Observable<DataSource> = this.currentVehicleModel3dDataSourceSubject.asObservable();
  models3d$: Observable<Model3d[]> = this.models3dSubject.asObservable();
  model3dError: Observable<string> = this.model3dErrorSubject.asObservable();
  mods$: Observable<Mod[]> = this.modsSubject.asObservable();
  currentShipModels3d$: Observable<Model3d[]> = this.currentShipModels3dSubject.asObservable();
  currentShipModels3dWithMods$: Observable<Model3d[]> = this.currentShipModels3dWithModsSubject.asObservable();
  currentVehicleModels3d$: Observable<Model3d[]> = this.currentVehicleModels3dSubject.asObservable();
  currentVehicleModels3dWithMods$: Observable<Model3d[]> = this.currentVehicleModels3dWithModsSubject.asObservable();
  updatedModel3dId$: Observable<string> = this.updatedModel3dIdSubject.asObservable();

  constructor(
    private dataSourceService: DataSourceService,
    private errorService: ErrorService,
    private logService: LogService,
    private modService: ModService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.mods$ = this.modService.mods$;
  }

  async createNewModel3d(model3dPayload: Model3d, modPayload: Mod, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && model3dPayload && modPayload && parentCollection && parentId) {
        try {
          const url = `${environment.baseAPIUrl}model3d/create?userId=${currentUser?._id}`;
          let models3d;
          let returnValue: Model3d;

          switch (parentCollection) {
            case DbCollectionsEnum.SHIPS:
              models3d = _this.currentShipModels3dSubject.getValue();
              break;
            case DbCollectionsEnum.VEHICLES:
              models3d = _this.currentVehicleModels3dSubject.getValue();
              break;
            default:
              const errMessage = `${parentCollection} is not a valid 3D model parent collection`;
              _this.errorService.handleError(errMessage);
              _this.model3dErrorSubject.next(errMessage);
              reject(errMessage);
              break;
          }

          const newModels3d = models3d ? models3d.slice(0) : [];

          const payload = {
            model3d: model3dPayload,
            mod: modPayload
          };

          _this.httpClient
            .post(url, payload, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (newModel3d: Model3d) => {
                returnValue = newModel3d;
                const promises = [];

                promises.push(_this.updateChildData(newModel3d, parentCollection, parentId));
                promises.push(_this.modService.refreshModsByParent(parentCollection, parentId, currentUser));

                Promise.allSettled(promises).then((results) => {
                  const childResults = results[0];
                  const modResults = results[1];

                  if (childResults.status === 'fulfilled') {
                    returnValue = childResults.value;
                    newModels3d.push(returnValue);
                    _this.model3dErrorSubject.next('');
                  } else {
                    const errMessage = _this.errorService.handleError(
                      `Error updating child data for 3d models ${parentCollection} id ${parentId}: ${childResults.reason}`
                    );
                    _this.model3dErrorSubject.next(errMessage);
                  }

                  if (modResults.status === 'rejected') {
                    const errMessage = _this.errorService.handleError(
                      `Error refreshing mods for 3d models parent ${parentCollection} id ${parentId}: ${modResults.reason}`
                    );
                  }

                  switch (parentCollection) {
                    case DbCollectionsEnum.SHIPS:
                      _this.currentShipModel3dSubject.next(returnValue);
                      _this.currentShipModels3dSubject.next(newModels3d);
                      break;
                    case DbCollectionsEnum.VEHICLES:
                      _this.currentVehicleModel3dSubject.next(returnValue);
                      _this.currentVehicleModels3dSubject.next(newModels3d);
                      break;
                  }

                  _this.updatedModel3dIdSubject.next(newModel3d._id);
                  resolve(returnValue);
                });
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.model3dErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => { }
            });
        } catch (ex) {
          const errMessage = `Error creating new 3D model for parent ${parentCollection} parentId ${parentId}: ${ex.message}`;
          _this.errorService.handleError(errMessage);
          _this.model3dErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `model3dPayload, modPayload, parentCollection, parentId and user are required to create a 3D model`;
        _this.errorService.handleError(errMessage);
        _this.model3dErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async deleteModel3d(model3dId: string, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      let returnValue: Model3d[];

      if (currentUser && model3dId && parentCollection && parentId) {
        try {
          let isValidParentCollection = true;

          switch (parentCollection) {
            case DbCollectionsEnum.SHIPS:
              returnValue = _this.currentShipModels3dSubject.getValue();
              break;
            case DbCollectionsEnum.VEHICLES:
              returnValue = _this.currentVehicleModels3dSubject.getValue();
              break;
            default:
              isValidParentCollection = false;
              break;
          }

          if (isValidParentCollection) {
            const url = `${environment.baseAPIUrl}model3d/${model3dId}?userId=${currentUser._id}`;

            _this.httpClient
              .delete(url, {
                headers: getCustomHeaders(true),
                responseType: 'json',
              })
              .subscribe({
                next: (deletedModel3d: Model3d) => {
                  const promises = [];
                  promises.push(_this.refreshModels3dByParent(parentCollection, parentId, currentUser));
                  promises.push(_this.modService.refreshModsByParent(parentCollection, parentId, currentUser));

                  Promise.allSettled(promises).then((results) => {
                    const modelResults = results[0];
                    if (modelResults.status === 'fulfilled') {
                      returnValue = modelResults.value;

                      switch (parentCollection) {
                        case DbCollectionsEnum.SHIPS:
                          _this.currentShipModels3dSubject.next(returnValue);
                          break;
                        case DbCollectionsEnum.VEHICLES:
                          _this.currentVehicleModels3dSubject.next(returnValue);
                          break;
                      }

                      _this.updatedModel3dIdSubject.next(`deleted-${model3dId}`);
                      resolve(returnValue);
                    } else {
                      const errMessage = _this.errorService.handleError(
                        `Error deleting model3dId ${model3dId}: ${modelResults.reason}`
                      );
                      _this.model3dErrorSubject.next(errMessage);
                      reject(errMessage);
                    }
                  });
                },
                error: (error: HttpErrorResponse) => {
                  const errMessage = _this.errorService.handleError(error);
                  _this.model3dErrorSubject.next(errMessage);
                  reject(error);
                },
                complete: () => {

                }
              });
          } else {
            const errMessage = `${parentCollection} is not a valid 3D model parent collection`;
            _this.errorService.handleError(errMessage);
            _this.model3dErrorSubject.next(errMessage);
            reject(errMessage);
          }
        } catch (ex) {
          const errMessage = `Error removing model3dId ${model3dId} from ${parentCollection} ${parentId}: ${ex.message}`;
          _this.errorService.handleError(errMessage);
          _this.model3dErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `model3dId, parentCollection and parentId are required to delete a 3D model`;
        _this.errorService.handleError(errMessage);
        _this.model3dErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getCurrentShipModel3d() {
    return this.currentShipModel3dSubject.getValue();
  }

  getCurrentShipModels3d() {
    return this.currentShipModels3dSubject.getValue();
  }

  getCurrentShipModel3dCreator() {
    return this.currentShipModel3dCreatorSubject.getValue();
  }

  getCurrentShipModel3dDataSource() {
    return this.currentShipModel3dDataSourceSubject.getValue();
  }

  getCurrentVehicleModel3d() {
    return this.currentVehicleModel3dSubject.getValue();
  }

  getCurrentVehicleModels3d() {
    return this.currentVehicleModels3dSubject.getValue();
  }

  getCurrentVehicleModel3dCreator() {
    return this.currentVehicleModel3dCreatorSubject.getValue();
  }

  getCurrentVehicleModel3dDataSource() {
    return this.currentVehicleModel3dDataSourceSubject.getValue();
  }

  async getModel3dById(model3dId: string, parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      let isValidParentCollection = true;

      if (parentCollection) {
        switch (parentCollection) {
          case DbCollectionsEnum.SHIPS:
          case DbCollectionsEnum.VEHICLES:
            break;
          default:
            isValidParentCollection = false;
            break;
        }

        if (isValidParentCollection) {
          _this.settingsService.setIsLoading(true);
          let models3d: Model3d[];
          let returnValue: Model3d;

          if (currentUser && parentId) {
            switch (parentCollection) {
              case DbCollectionsEnum.SHIPS:
                models3d = _this.currentShipModels3dSubject.getValue();
                if (model3dId && models3d && models3d.length > 0) {
                  returnValue = models3d.find((model3d) => model3d._id === model3dId);
                }
                _this.currentShipModel3dSubject.next(returnValue);
                break;
              case DbCollectionsEnum.VEHICLES:
                models3d = _this.currentVehicleModels3dSubject.getValue();
                if (model3dId && models3d && models3d.length > 0) {
                  returnValue = models3d.find((model3d) => model3d._id === model3dId);
                }
                _this.currentVehicleModel3dSubject.next(returnValue);
                break;
            }
          } else {
            switch (parentCollection) {
              case DbCollectionsEnum.SHIPS:
                _this.currentShipModel3dSubject.next(returnValue);
                break;
              case DbCollectionsEnum.VEHICLES:
                _this.currentVehicleModel3dSubject.next(returnValue);
                break;
            }
          }

          _this
          .updateChildData(returnValue, parentCollection, parentId)
          .then((updatedModel3d: Model3d) => {
            returnValue = updatedModel3d;
            _this.model3dErrorSubject.next('');
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error updating child data for 3D models ${parentCollection} id ${parentId}: ${error}`
            );
            _this.model3dErrorSubject.next(errMessage);
          })
          .finally(() => {
            switch (parentCollection) {
              case DbCollectionsEnum.SHIPS:
                _this.currentShipModel3dSubject.next(returnValue);
                break;
              case DbCollectionsEnum.VEHICLES:
                _this.currentVehicleModel3dSubject.next(returnValue);
                break;
            }

            _this.settingsService.setIsLoading(false);
            resolve(returnValue);
          });
        } else {
          const errMessage = _this.errorService.handleError(`${parentCollection} is not a valid 3d model parent collection`);
          _this.model3dErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `parentCollection is required to get a 3d model`;
        _this.errorService.handleError(errMessage);
        _this.model3dErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async refreshModels3dByParent(parentCollection: string, parentId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (parentCollection) {
        let returnValue: Model3d[];
        let modelsWithMods: Model3d[];
        let isValidParentCollection = true;

        switch (parentCollection) {
          case DbCollectionsEnum.SHIPS:
          case DbCollectionsEnum.VEHICLES:
            break;
          default:
            isValidParentCollection = false;
            break;
        }

        if (isValidParentCollection) {
          if (currentUser && parentId) {
            const url = `${environment.baseAPIUrl}model3d/${parentCollection}/${parentId}?userId=${currentUser?._id}`;

            _this.httpClient
              .get(url, {
                headers: getCustomHeaders(true),
                responseType: 'json',
              })
              .subscribe({
                next: (models3d: Model3d[]) => {
                  returnValue = models3d;
                  const promises = [];

                  promises.push(_this.updateChildData(null, parentCollection, parentId));
                  promises.push(_this.modService.refreshModsByParent(parentCollection, parentId, currentUser));

                  Promise.allSettled(promises).then((results) => {
                    const childResults = results[0];
                    const modResults = results[1];

                    if (childResults.status === 'fulfilled') {
                      _this.model3dErrorSubject.next('');
                    } else {
                      const errMessage = _this.errorService.handleError(
                        `Error updating child data for 3d models ${parentCollection} id ${parentId}: ${childResults.reason}`
                      );
                      _this.model3dErrorSubject.next(errMessage);
                    }

                    if (modResults.status === 'rejected') {
                      const errMessage = _this.errorService.handleError(
                        `Error refreshing mods for 3d models parent ${parentCollection} id ${parentId}: ${modResults.reason}`
                      );
                    }

                    switch (parentCollection) {
                      case DbCollectionsEnum.SHIPS:
                        _this.currentShipModels3dSubject.next(returnValue);

                        if (returnValue.length > 0) {
                          modelsWithMods = returnValue.filter(
                            (model3d) => model3d.modDetails && model3d.modDetails.url != null
                          );
                        }

                        _this.currentShipModels3dWithModsSubject.next(modelsWithMods);
                        break;
                      case DbCollectionsEnum.VEHICLES:
                        _this.currentVehicleModels3dSubject.next(returnValue);

                        if (returnValue.length > 0) {
                          modelsWithMods = returnValue.filter(
                            (model3d) => model3d.modDetails && model3d.modDetails.url != null
                          );
                        }

                        _this.currentVehicleModels3dWithModsSubject.next(modelsWithMods);
                        break;
                    }

                    resolve(returnValue);
                  });
                },
                error: (error: HttpErrorResponse) => {
                  const errMessage = _this.errorService.handleError(error);
                  _this.model3dErrorSubject.next(errMessage);
                },
                complete: () => {
                  _this.settingsService.setIsLoading(false);
                }
              });
          } else {
            switch (parentCollection) {
              case DbCollectionsEnum.SHIPS:
                _this.currentShipModels3dSubject.next(returnValue);
                break;
              case DbCollectionsEnum.VEHICLES:
                _this.currentVehicleModels3dSubject.next(returnValue);
                break;
            }

            _this.settingsService.setIsLoading(false);
            resolve(returnValue);
          }
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = `${parentCollection} is not a valid 3D model parent collection`;
          _this.errorService.handleError(errMessage);
          _this.model3dErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `parentCollection is required to refresh 3D models by parent`;
        _this.errorService.handleError(errMessage);
        _this.model3dErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveModel3d(model3dId: string, parentCollection: string, parentId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && model3dId && parentCollection && parentId) {
        let models3d: Model3d[];
        let validParentCollection = true;
        let returnValue: Model3d;
        let errMessage;

        switch (parentCollection) {
          case DbCollectionsEnum.SHIPS:
            models3d = _this.currentShipModels3dSubject.getValue();
            break;
          case DbCollectionsEnum.VEHICLES:
            models3d = _this.currentVehicleModels3dSubject.getValue();
            break;
          default:
            validParentCollection = false;
            errMessage = `${parentCollection} is not a valid 3D model parent collection`;
            _this.errorService.handleError(errMessage);
            _this.model3dErrorSubject.next(errMessage);
            break;
        }

        if (validParentCollection) {
          const model3dIndex = models3d.findIndex((model3d) => model3d._id === model3dId);
          const newModel3ds = models3d.slice(0);
          const url = `${environment.baseAPIUrl}model3d/${model3dId}?userId=${currentUser?._id}`;

          _this.httpClient
            .put(url, changes, {
              headers: getCustomHeaders(true),
              responseType: 'json',
            })
            .subscribe({
              next: (updatedModel3d: Model3d) => {
                returnValue = updatedModel3d;

                _this.updateChildData(updatedModel3d, parentCollection, parentId)
                  .then((model3dWithChildData) => {
                    returnValue = model3dWithChildData;
                  })
                  .catch((childError) => {
                    _this.model3dErrorSubject.next(childError.message);
                  })
                  .finally(() => {
                    newModel3ds[model3dIndex] = returnValue;

                    switch (parentCollection) {
                      case DbCollectionsEnum.SHIPS:
                        _this.currentShipModel3dSubject.next(returnValue);
                        _this.currentShipModels3dSubject.next(newModel3ds);
                        break;
                      case DbCollectionsEnum.VEHICLES:
                        _this.currentVehicleModel3dSubject.next(returnValue);
                        _this.currentVehicleModels3dSubject.next(newModel3ds);
                        break;
                    }

                    _this.updatedModel3dIdSubject.next(updatedModel3d._id);

                    resolve(returnValue);
                  });
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.model3dErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {

              }
            });
        } else {
          reject(errMessage);
        }
      } else {
        const errMessage = `changes, model3dId, parentCollection, parentId and user are required update a 3D model`;
        _this.errorService.handleError(errMessage);
        _this.model3dErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async updateChildData(model3d: Model3d, parentCollection: string, parentId: string): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      const _this = this;
      let returnValue: Model3d;

      zip(_this.mods$, _this.userService.users$, _this.dataSourceService.dataSources$)
        .pipe(
          map(([mods, users, dataSources]) => ({
            mods,
            users,
            dataSources,
          }))
        )
        .subscribe({
          next: ({ users, dataSources }) => {
            let user, dataSource;
            const promises = [];

            if (model3d) {
              if (model3d.creatorId) {
                user = users.find((user) => user._id === model3d.creatorId);
              }

              if (model3d.dataSourceId) {
                dataSource = dataSources.find((dataSource) => dataSource._id === model3d.dataSourceId);
              }

              model3d.modelUploader = user ? user.fullName : null;
              model3d.dataSourceName = dataSource ? dataSource.name : '';

              returnValue = model3d;

              switch (parentCollection) {
                case DbCollectionsEnum.SHIPS:
                  _this.currentShipModel3dSubject.next(returnValue);
                  _this.currentShipModel3dCreatorSubject.next(user);
                  _this.currentShipModel3dDataSourceSubject.next(dataSource);
                  break;
                case DbCollectionsEnum.VEHICLES:
                  _this.currentVehicleModel3dSubject.next(returnValue);
                  _this.currentVehicleModel3dCreatorSubject.next(user);
                  _this.currentVehicleModel3dDataSourceSubject.next(dataSource);
                  break;
              }
            } else {
              switch (parentCollection) {
                case DbCollectionsEnum.SHIPS:
                  _this.currentShipModel3dSubject.next(null);
                  _this.currentShipModel3dCreatorSubject.next(null);
                  _this.currentShipModel3dDataSourceSubject.next(null);
                  break;
                case DbCollectionsEnum.VEHICLES:
                  _this.currentVehicleModel3dSubject.next(null);
                  _this.currentVehicleModel3dCreatorSubject.next(null);
                  _this.currentVehicleModel3dDataSourceSubject.next(null);
                  break;
              }
            }

            resolve(returnValue);
          },
          error: (error) => {
            const errMessage = _this.errorService.handleError(
              `Error subscribing to users and datasources for 3d models: ${error.error}`
            );
            this.model3dErrorSubject.next(errMessage);
            resolve(model3d);
          },
          complete: () => { }
        });
    });
  }
}
