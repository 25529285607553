import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import axios from 'axios';
import { BehaviorSubject, Observable, from, throwError, of, zip, generate } from 'rxjs';
import { AbortMultipartUploadCommand, CompleteMultipartUploadCommand, CreateMultipartUploadCommand, PutObjectCommand, S3Client, UploadPartCommand } from '@aws-sdk/client-s3';

import { FileObjectTypesEnum, TagFileTypesEnum } from '@shared/enums';
import { User } from '@shared/models';
import { getCustomHeaders } from '@shared/utils';

import { ErrorService, LogService, SettingsService } from '@shared/services';

import { environment } from '@environment';

const ObjectID = require('bson-objectid');

// see https://medium.com/@mabdullah.se/upload-file-to-amazon-s3-bucket-using-aws-sdk-angular-nodejs-91f89722652a
// see https://medium.com/@mabdullah.se/upload-file-to-amazon-s3-bucket-using-presigned-url-5affc0beebdc
@Injectable({
  providedIn: 'root',
})
export class FileService {
  private fileErrorSubject = new BehaviorSubject<string>(null);
  fileError$: Observable<string> = this.fileErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private httpClient: HttpClient
  ) { }

  async createServerFile(fileObjectType: string, filePath: string, jsonData, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && filePath && jsonData) {
        _this.settingsService.setIsLoading(true);
        const url = `${environment.baseAPIUrl}file/createServerFile?userId=${currentUser?._id}`;
        let returnValue: string;

        this.httpClient
          .post(
            url,
            {
              fileObjectType: fileObjectType,
              filePath: filePath,
              jsonData: jsonData,
            },
            {
              headers: getCustomHeaders(true),
              responseType: 'json',
            }
          )
          .subscribe({
            next: (results: string) => {
              returnValue = results;
              _this.fileErrorSubject.next('');
              _this.settingsService.setIsLoading(false);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.fileErrorSubject.next(errMessage);
              _this.settingsService.setIsLoading(false);
              reject(error);
            },
            complete: () => {

            }
          });
      } else {
        const errMessage = `A filePath and jsonData are required to create a server file`;
        _this.errorService.handleError(errMessage);
        _this.fileErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async deleteServerFile(fileObjectType: string, filePath: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && fileObjectType && filePath) {
        const url = `${environment.baseAPIUrl}file/deleteServerFile?userId=${currentUser?._id}`;
        let returnValue: string;

        this.httpClient
          .post(
            url,
            {
              fileObjectType: fileObjectType,
              filePath: filePath,
            },
            {
              headers: getCustomHeaders(true),
              responseType: 'json',
            }
          )
          .subscribe({
            next: (results: string) => {
              returnValue = results;
              _this.fileErrorSubject.next('');
              resolve(returnValue);
            }, 
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.fileErrorSubject.next(errMessage);
              //don't reject even if file couldn't be deleted
              resolve(`Unable to delete server file ${filePath}: ${error}`);
            },
            complete: () => {

            }
          });
      } else {
        const errMessage = `A fileObjectType and filePath are required to delete a server file`;
        _this.errorService.handleError(errMessage);
        _this.fileErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async downloadFile(fileUrl: string, fileNameToUse: string, currentUser: User, viewInBrowser: boolean): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && fileUrl) {
        _this.settingsService.setIsLoading(true);
        const url = `${environment.baseAPIUrl}file/download?userId=${currentUser?._id}&ts=${new Date().valueOf()}&viewInBrowser=${viewInBrowser}`;
        let returnValue: any;

        this.httpClient
          .post(
            url,
            {
              fileNameToUse: fileNameToUse,
              url: fileUrl,
            },
            {
              headers: getCustomHeaders(true),
              responseType: 'json',
            }
          )
          .subscribe({
            next: (results: string) => {
              returnValue = results;
              _this.fileErrorSubject.next('');
              _this.settingsService.setIsLoading(false);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.fileErrorSubject.next(errMessage);
              _this.settingsService.setIsLoading(false);
              reject(error);
            },
            complete: () => {

            }
          });
      } else {
        const errMessage = `A url and userId are required to download a file`;
        _this.errorService.handleError(errMessage);
        _this.fileErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getFileContentType(fileExtension) {
    let returnValue;

    // see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    switch (fileExtension) {
      case 'aac':
        returnValue = 'audio/acc';
        break;
      case 'bin':
        returnValue = 'application/octet-stream';
        break;
      case 'bmp':
        returnValue = 'image/bmp';
        break;
      case 'bz':
        returnValue = 'application/x-bzip';
        break;
      case 'bz2':
        returnValue = 'application/x-bzip2';
        break;
      case 'csv':
        returnValue = 'text/csv';
        break;
      case 'doc':
        returnValue = 'application/msword';
        break;
      case 'docx':
        returnValue = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case 'gz':
        returnValue = 'application/gzip';
        break;
      case 'gif':
        returnValue = 'image/gif';
        break;
      case 'htm':
      case 'html':
        returnValue = 'text/html';
        break;
      case 'ico':
        returnValue = 'image/vnd.microsoft.icon';
        break;
      case 'jar':
        returnValue = 'application/java-archive';
        break;
      case 'jpeg':
      case 'jpg':
        returnValue = 'image/jpeg';
        break;
      case 'json':
        returnValue = 'application/json';
        break;
      case 'jsonld':
        returnValue = 'application/ld+json';
        break;
      case 'mid':
      case 'midi':
        returnValue = 'audio/midi';
        break;
      case 'mp3':
        returnValue = 'audio/mpeg';
        break;
      case 'mp4':
        returnValue = 'video/mp4';
        break;
      case 'mpeg':
        returnValue = 'video/mpeg';
        break;
      case 'odp':
        returnValue = 'application/vnd.oasis.opendocument.presentation';
        break;
      case 'ods':
        returnValue = 'application/vnd.oasis.opendocument.spreadsheet';
        break;
      case 'odt':
        returnValue = 'application/vnd.oasis.opendocument.text';
        break;
      case 'oga':
        returnValue = 'audio/ogg';
        break;
      case 'ogv':
        returnValue = 'video/ogg';
        break;
      case 'png':
        returnValue = 'image/png';
        break;
      case 'pdf':
        returnValue = 'application/pdf';
        break;
      case 'ppt':
        returnValue = 'application.vnd.ms-powerpoint';
        break;
      case 'pptx':
        returnValue = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        break;
      case 'rar':
        returnValue = 'application/vnd.rar';
        break;
      case 'rtf':
        returnValue = 'application/rtf';
        break;
      case 'svg':
        returnValue = 'image/svg+xml';
        break;
      case 'tar':
        returnValue = 'application/x-tar';
        break;
      case 'tif':
      case 'tiff':
        returnValue = 'image/tiff';
        break;
      case 'txt':
        returnValue = 'text/plain';
        break;
      case 'vsd':
        returnValue = 'application/vnd.visio';
        break;
      case 'wav':
        returnValue = 'audio/wav';
        break;
      case 'xls':
        returnValue = 'application/vnd.ms-excel';
        break;
      case 'xlxs':
        returnValue = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case 'xml':
        returnValue = 'application/xml';
        break;
      case 'zip':
        returnValue = 'application/zip';
        break;
      case '7z':
        returnValue = 'application/x-7z-compressed';
        break;
    }

    return returnValue;
  }

  async getPresignedUrl(fileUrl: string, fileName: string, currentUser: User, viewInBrowser: boolean) {
    if (fileUrl && currentUser) {
      try {
        const fileResults = await this.downloadFile(
          fileUrl,
          fileName,
          currentUser,
          viewInBrowser
        );
        return fileResults.signedUrl;
      } catch (ex) {
        //error already logged
        throw ex;
      }
    }
  }

  async uploadAppFileFromServer(filePath: string, fileObjectType: string, fileObjectId: string, documentType: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && filePath && fileObjectType && fileObjectId && documentType) {
        try {
          const url = `${environment.baseAPIUrl}file/uploadAppServerFile?userId=${currentUser?._id}`;
          let returnValue: any;

          this.httpClient
            .post(
              url,
              {
                filePath: filePath,
                fileObjectType: fileObjectType,
                fileObjectId: fileObjectId,
                documentType: documentType,
              },
              {
                headers: getCustomHeaders(true),
                responseType: 'json',
              }
            )
            .subscribe({
              next: (results: any) => {
                returnValue = results;
                _this.fileErrorSubject.next('');
                resolve(returnValue);
              },
              error: (error: HttpErrorResponse) => {
                const errMessage = _this.errorService.handleError(error);
                _this.fileErrorSubject.next(errMessage);
                reject(error);
              },
              complete: () => {

              }
            });
        } catch (ex) {
          const errMessage = _this.errorService.handleError(
            `Error uploading file ${filePath} from server: ${ex.message}`
          );
          reject(errMessage);
        }
      } else {
        const errMessage = _this.errorService.handleError(
          `filePath, fileObjectType, fileObjectId and documentType are required to upload a file from the server`
        );
        reject(errMessage);
      }
    });
  }

  async uploadFile(file, fileObjectType: string, fileObjectId: string, tags, documentType: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise(async (resolve, reject) => {
      if (currentUser && file && fileObjectType && fileObjectId && documentType) {
        try {
          const url = `${environment.baseAPIUrl}file/uploadFile?userId=${currentUser?._id}`;
          const form = new FormData();
          form.append('file', file);
          form.append('fileObjectType', fileObjectType);
          form.append('fileObjectId', fileObjectId);
          form.append('documentType', documentType);
          form.append('tags', tags);
          form.append('userId', currentUser._id);

          const headers = getCustomHeaders(true);
          //replace json type with form data 
          headers['content-type'] = 'multipart/form-data';
          let returnValue: any;

          const results = await axios({
            url: url,
            headers: headers,
            method: 'post',
            data: form
          });

          returnValue = results.data;
          _this.fileErrorSubject.next('');
          resolve(returnValue);
        } catch (ex) {
          const errMessage = _this.errorService.handleError(ex);
          reject(errMessage);
        }
      } else {
        const errMessage = _this.errorService.handleError(
          `filePath, fileObjectType, fileObjectId and documentType are required to upload a file`
        );
        reject(errMessage);
      }
    });
  }
}
