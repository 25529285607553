import { Component, Input, OnInit } from '@angular/core';
import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2/src/sweetalert2';

import { ImageDoc, Note, Project, ReportSection, Ship, User, Vehicle } from '@shared/models';
import {
  ErrorService,
  FileService,
  ImageDocService,
  LogService,
  ProjectService,
  SettingsService,
  ShipService,
  UserService,
  VehicleService,
} from '@shared/services';
import { DbCollectionsEnum, ReportSectionIdsEnum, UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

import { ReportItemDialogComponent } from '../report/report-item-dialog/report-item-dialog.component';

@UntilDestroy()
@Component({
  selector: 'app-snapshot-card',
  templateUrl: './snapshot-card.component.html',
  styleUrls: ['./snapshot-card.component.css'],
})
export class SnapshotCardComponent implements OnInit {
  @Input()
  img: ImageDoc;

  @Input()
  notes: Note[];

  @Input()
  project: Project;

  @Input()
  reportSections: ReportSection[];

  @Input()
  ship: Ship;

  @Input()
  vehicle: Vehicle;

  currentUser$: Observable<User>;
  private imageSafeUrlSubject = new BehaviorSubject<SafeResourceUrl>(null);
  imageSafeUrl$: Observable<SafeResourceUrl> = this.imageSafeUrlSubject.asObservable();

  constructor(
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private errorService: ErrorService,
    private fileService: FileService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private projectService: ProjectService,
    private settingsService: SettingsService,
    private shipService: ShipService,
    private userService: UserService,
    private vehicleService: VehicleService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
  }

  get canDelete(): boolean {
    const user = this.userService.getCurrentUser();
    let returnValue = false;

    if (user.role === UserRolesEnum.ADMIN || user._id === this.img.creatorId) {
      returnValue = true;
    }

    return returnValue;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  get safeImageUrl(): SafeResourceUrl {
    return this.imageSafeUrlSubject.getValue();
  }

  async addSnapshotNote() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: this.userService.getCurrentUser(),
      imageDoc: this.img,
      isNewNote: true,
      project: this.project,
      ship: this.ship,
      vehicle: this.vehicle,
    };

    const dialogRef = this.dialog.open(ReportItemDialogComponent, dialogConfig);
  }

  async deleteSnapshot(): Promise<any> {
    const _this = this;
    const currentUser = _this.userService.getCurrentUser();
    let title = `Do you want to delete the ${_this.img.name} snapshot and any related notes?`;

    if (currentUser) {
      Swal.fire({
        title: title,
        showCancelButton: true,
        confirmButtonText: 'Delete',
      }).then((result) => {
        if (result.isConfirmed) {
          _this.imageDocService
            .deleteImage(_this.img._id, _this.img.parent.collection, _this.img.parent._id, currentUser)
            .then(function (deleteResults) {
              console.log(`deleted imageId ${_this.img._id}`);
            })
            .catch(function (deleteError) {
              const errorTitle = 'Error Deleting Snapshot and Notes';
              _this.errorService.handleError(`${errorTitle}: ${deleteError}`);
              if (_this.settingsService.getShowPopupErrorMessages()) {
                Swal.fire(errorTitle, deleteError.message, 'error');
              }
            });
        }
      });
    } else {

    }
  }

  async download(img: ImageDoc, currentUser: User) {
    if (img?.url) {
      try {
        this.settingsService.setIsLoading(true);
        const downloadUrl = await this.fileService.getPresignedUrl(img.url, img.name, currentUser, false);
        this.imageSafeUrlSubject.next(this.domSanitizer.bypassSecurityTrustResourceUrl(downloadUrl));
        if (downloadUrl) {
          const element = document.getElementById('iframeForDownload') as HTMLElement;
          if (element) {
            element.click();
          }
        }
      } catch (ex) {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Downloading Snapshot ${this.img.name}`,
            `There was an error downloading the file.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      } finally {
        this.settingsService.setIsLoading(false);
      }
    } else {
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error',
          `No snapshot was provided to download.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
			}
    }
  }

  editSnapshot() {
    const currentUser = this.userService.getCurrentUser();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      currentUser: currentUser,
      imageDoc: this.img,
    };

    const dialogRef = this.dialog.open(ReportItemDialogComponent, dialogConfig);
  }

  getReportSectionTitleCase(reportSectionId: string) {
    let returnValue = '';
    let matching;

    if (Array.isArray(this.reportSections) && this.reportSections.length > 0 && reportSectionId) {
      matching = this.reportSections.find((reportSection) => reportSection._id === reportSectionId);
    }
  
    returnValue = matching ? matching.titleCase : '';
    return returnValue;
  }

  getSnapshotNotes() {
    if (this.notes && this.notes.length > 0 && this.img) {
      return this.notes.filter((note) => note.imageId === this.img._id);
    }
  }

  async viewSnapshot(img: ImageDoc, currentUser: User) {
    if (img?.url && currentUser) {
      try {
        this.settingsService.setIsLoading(true);
        const viewUrl = await this.fileService.getPresignedUrl(img.url, img.name, currentUser, true);
        if (viewUrl) {
          window.open(viewUrl, '_blank');
        }
      } catch (ex) {
        this.settingsService.setIsLoading(false);
        if (this.settingsService.getShowPopupErrorMessages()) {
          Swal.fire(
            `Error Viewing Snapshot ${this.img.name}`,
            `There was an error viewing the file.  Please email ${environment.techSupportEmail}.`,
            'error'
          );
        }
      } finally {
        this.settingsService.setIsLoading(false);
      }
    } else {
      if (this.settingsService.getShowPopupErrorMessages()) {
        Swal.fire(
          'Error',
          `No snapshot was provided to view.  Please email ${environment.techSupportEmail}.`,
          'error'
        );
			}
    }
  }
}
