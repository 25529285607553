enum VehicleSteeringTypesEnum {
  FRONT = 'front',
  REAR = 'rear',
  ALL = 'all',
  NOT_APPLICABLE = 'na',
  UNKNOWN = 'unknown',
}

interface VehicleSteeringType {
  _id: string;
  displayName: string;
}

export const FrontWheelDrive: VehicleSteeringType = {
  _id: VehicleSteeringTypesEnum.FRONT,
  displayName: 'Front',
};

export const RearWheelDrive: VehicleSteeringType = {
  _id: VehicleSteeringTypesEnum.REAR,
  displayName: 'Rear',
};

export const AllWheelDrive: VehicleSteeringType = {
  _id: VehicleSteeringTypesEnum.ALL,
  displayName: 'All',
};

export const NoWheelDrive: VehicleSteeringType = {
  _id: VehicleSteeringTypesEnum.NOT_APPLICABLE,
  displayName: 'Not Applicable',
};

export const UnknownSteeringType: VehicleSteeringType = {
  _id: VehicleSteeringTypesEnum.UNKNOWN,
  displayName: 'Unknown',
};
