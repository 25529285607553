import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { User } from '@shared/models';
import { ErrorService, LogService, ModService, UserService } from '@shared/services';
import { UserRolesEnum } from '@shared/enums';

import { environment } from '@environment';

@UntilDestroy()
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css'],
})
export class AdminDashboardComponent implements OnInit {
  currentUser$: Observable<User>;
  invalidModsCount$: Observable<number>;

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private modService: ModService,
    private userService: UserService,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.userService.currentUser$;
    this.invalidModsCount$ = this.modService.invalidModsCount$;
  }

  get isAdmin(): boolean {
    const currentUser = this.userService.getCurrentUser();
    return currentUser && currentUser.role === UserRolesEnum.ADMIN ? true : false;
  }

  goBack(): void {
    this.location.back();
  }

  goToInvalidMods(): void {
    this.router.navigate(['/assets']);
  }

  goToSettings(): void {
    this.router.navigate(['/settings']);
  }

  goToUserDashboard(): void {
    this.router.navigate(['/users']);
  }
}
