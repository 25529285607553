<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser">
  <h2 mat-dialog-title>{{ formTitle }}</h2>

  <mat-dialog-content>
    <form [formGroup]="form">
      <mat-horizontal-stepper [linear]="true" #stepper>
        <mat-step [stepControl]="shipInfoForm" errorMessage="Ship name, class and designation are required.">
          <ng-template matStepLabel>Ship Metadata</ng-template>
          <div [formGroup]="shipInfoForm">
            <h3>Ship Info</h3>
            <mat-form-field>
              <mat-label>Ship Name</mat-label>
              <input matInput placeholder="Ship Name" formControlName="name" />
            </mat-form-field>

            <br>

            <mat-form-field appearance="fill">
              <mat-label>Ship Class</mat-label>
              <mat-select formControlName="shipClassId" (selectionChange)="onShipClassChange($event.value)">
                <mat-option *ngFor="let shipClass of shipClasses$ | async as shipClasses" [value]="shipClass._id">
                  {{ shipClass.class }}
                </mat-option>
              </mat-select>
              <button matSuffix mat-icon-button aria-label="Add Ship Class" matTooltip="Add Ship Class" *ngIf="isAdmin"
                (click)="addShipClass()">
                <mat-icon>add</mat-icon>
              </button>
            </mat-form-field>

            <br>

            <table style="width: 100%">
              <tr>
                <td style="width: 65%">
                  <mat-form-field appearance="fill">
                    <mat-label>Ship Designation</mat-label>
                    <mat-select formControlName="shipDesignationId"
                      (selectionChange)="onShipDesignationChange($event.value)">
                      <mat-option *ngFor="let shipDesignation of shipClassDesignations$ | async as shipDesignations"
                        [value]="shipDesignation._id">
                        {{ shipDesignation.designation }}
                      </mat-option>
                    </mat-select>
                    <button matSuffix mat-icon-button aria-label="Add Ship Designation"
                      matTooltip="Add Ship Designation" *ngIf="isAdmin" (click)="addShipDesignation()">
                      <mat-icon>add</mat-icon>
                    </button>
                  </mat-form-field>
                </td>
                <td style="width: 35%">
                  <mat-form-field>
                    <mat-label>Hull Number</mat-label>
                    <input matInput type="text" pattern="[0-9]*" placeholder="Hull Number (numeric)"
                      title="Numeric Hull Number" formControlName="hullNumber" (change)="onHullNumberChange()" />
                  </mat-form-field>
                </td>
              </tr>
            </table>

            <br>
          </div>

          <div class="button-row">
            <button mat-raised-button aria-label="Close without saving" matTooltip="Close without saving"
              (click)="close()">
              Cancel
            </button>
            <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" matStepperNext>
              Next
            </button>
          </div>

          <div *ngIf="isDebugging$ | async">
            <pre>creatorId: {{ shipInfoForm.get('creatorId').valid }}</pre>
            <pre>designationWithHullNumber: {{ shipInfoForm.get('designationWithHullNumber').valid }}</pre>
            <pre>editorId: {{ shipInfoForm.get('editorId').valid }}</pre>
            <pre>hullNumber: {{ shipInfoForm.get('hullNumber').valid }}</pre>
            <pre>_id: {{ shipInfoForm.get('_id').valid }}</pre>
            <pre>name: {{ shipInfoForm.get('name').valid }}</pre>
            <pre>shipClassId: {{ shipInfoForm.get('shipClassId').valid }}</pre>
            <pre>shipDesignationId: {{ shipInfoForm.get('shipDesignationId').valid }}</pre>
            <pre>shipId: {{ shipInfoForm.get('shipId').valid }}</pre>
          </div>
        </mat-step>

        <mat-step [stepControl]="fileForm">
          <ng-template matStepLabel>Ship Files</ng-template>
          <div [formGroup]="fileForm">
            <h3>Ship Image</h3>
            <div>
              <span>
                <button (click)="imageFileInput.click()">
                  <mat-icon>library_add</mat-icon>
                  <span>Select Image File</span>
                  <input #imageFileInput type="file" (change)="imageFileInputChange($event)" accept="image/*"
                    style="display: none" />
                </button>

                <br><br>

                <!-- Image Preview -->
                <div class="imagePreview" *ngIf="imageURL && imageURL !== ''">
                  <img [src]="imageURL" alt="{{ imageFileName }}" style="width: 250px; height: auto"
                    onerror="this.onerror=null; this.src='../../../../assets/img/SDAT.png';" />
                </div>
              </span>
            </div>
            <br>

            <div *ngIf="isDebugging$ | async">
              <pre>imageFileName: {{ fileForm.get('imageFileName').valid }}</pre>
              <pre>imageUrl: {{ fileForm.get('imageUrl').valid }}</pre>
            </div>

            <div class="button-row">
              <button mat-raised-button aria-label="Close without saving" matTooltip="Close without saving"
                (click)="close()">
                Cancel
              </button>
              <button mat-raised-button aria-label="Back to previous step" matTooltip="Back to previous step"
                matStepperPrevious>
                Back
              </button>
              <button mat-raised-button color="primary" aria-label="Next step" matTooltip="Next step" matStepperNext>
                Next
              </button>
            </div>
          </div>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          {{ currentUser }}
          <div class="button-row">
            <button mat-raised-button aria-label="Close without saving" matTooltip="Close without saving"
              (click)="close()">
              Cancel
            </button>

            <button mat-raised-button color="primary" aria-label="Save and close" matTooltip="Save and close"
              #saveButton (click)="save(currentUser)" [disabled]="form.invalid" *ngIf="getShowShipSaveButton(currentUser.role)">
              {{ saveButtonText }}
            </button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>

      <br>
      <p *ngIf="errorMsg">{{ errorText }}</p>
    </form>
  </mat-dialog-content>
</div>