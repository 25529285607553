<div class="content" *ngIf="currentUser$ | async as currentUser">
  <div *ngIf="showFilter">
    <mat-form-field class="table-filter">
      <mat-label>Search Images</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Search"
        aria-label="Type search criteria to filter results"
        matTooltip="Type search criteria to filter results"
        #tableFilter
      />
    </mat-form-field>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="name"
    matSortDirection="asc"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Image Name</th>
      <td mat-cell *matCellDef="let img" style="cursor: pointer">
        <a aria-label="Click to view image" matTooltip="Click to view image" (click)="viewImage(img, currentUser)">{{
          img.name
        }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="isMainImage">
      <th mat-header-cell *matHeaderCellDef>Is Main Image</th>
      <td mat-cell *matCellDef="let img" style="cursor: pointer">
        <a aria-label="Click to view image" matTooltip="Click to view image" (click)="viewImage(img, currentUser)">{{
          getYesOrNo(img.isMainImage)
        }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="isSnapshot">
      <th mat-header-cell *matHeaderCellDef>Is Snapshot</th>
      <td mat-cell *matCellDef="let img" style="cursor: pointer">
        <a aria-label="Click to view image" matTooltip="Click to view image" (click)="viewImage(img, currentUser)">{{
          getYesOrNo(img.isSnapshot)
        }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Uploaded</th>
      <td mat-cell *matCellDef="let img" style="cursor: pointer">
        <a aria-label="Click to view image" matTooltip="Click to view image" (click)="viewImage(img, currentUser)">{{
          img.createdAt | date: 'MM/dd/yyyy'
        }}</a>
      </td>
    </ng-container>

    <!-- actions column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let img">
        <span>
          <button
            mat-icon-button
            color="primary"
            aria-label="View Image"
            matTooltip="View Image"
            (click)="viewImage(img, currentUser)"
          >
            <img src="assets/img/Search.png" alt="View" />
          </button>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedImageDocColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedImageDocColumns"></tr>

    <!-- Row shown when there is no matching data -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">*** No matching images ***</td>
    </tr>
  </table>

  <mat-paginator *ngIf="showPaginator">
    [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons aria-label="Select page of images"
  </mat-paginator>

  <iframe id="iframeForDownload" *ngIf="safeImageUrl" [src]="safeImageUrl" style="visibility: hidden"></iframe>
</div>
