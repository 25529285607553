import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { DbCollectionsEnum, ViewFormatsEnum } from '@shared/enums';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private emailErrorMessageSubject = new BehaviorSubject<boolean>(false);
  private isDebuggingSubject = new BehaviorSubject<boolean>(false);
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  private loadingIdSubject = new BehaviorSubject<string>(null);
  private showPopupErrorMessagesSubject = new BehaviorSubject<boolean>(false);
  private viewFormatSubject = new BehaviorSubject<string>('cards');
  emailErrorMessages$: Observable<boolean> = this.emailErrorMessageSubject.asObservable();
  isLoading$: Observable<boolean> = this.isLoadingSubject.asObservable();
  isDebugging$: Observable<boolean> = this.isDebuggingSubject.asObservable();
  loadingId$: Observable<string> = this.loadingIdSubject.asObservable();
  showPopupErrorMessages$: Observable<boolean> = this.showPopupErrorMessagesSubject.asObservable();
  viewFormat$: Observable<string> = this.viewFormatSubject.asObservable();

  allowedShipModSources = [DbCollectionsEnum.SCANS];
  allowedVehicleModSources = [DbCollectionsEnum.MODELS3D];

  constructor() { }

  getEmailErrorMessages(): boolean {
    return this.emailErrorMessageSubject.getValue();
  }

  getIsDebugging(): boolean {
    return this.isDebuggingSubject.getValue();
  }

  getIsViewAsCards(): boolean {
    if (this.viewFormatSubject.getValue() === ViewFormatsEnum.CARDS) {
      return true;
    } else {
      return false;
    }
  }

  getIsViewAsList(): boolean {
    if (this.viewFormatSubject.getValue() === ViewFormatsEnum.LIST) {
      return true;
    } else {
      return false;
    }
  }

  getIsShipModSourceAllowed(modSource: DbCollectionsEnum): boolean {
    const idx = this.allowedShipModSources.indexOf(modSource);

    if (idx === -1) {
      return false;
    } else {
      return true;
    }
  }

  getIsVehicleModSourceAllowed(modSource: DbCollectionsEnum): boolean {
    const idx = this.allowedVehicleModSources.indexOf(modSource);

    if (idx === -1) {
      return false;
    } else {
      return true;
    }
  }

  getShowPopupErrorMessages(): boolean {
    return this.showPopupErrorMessagesSubject.getValue();
  }

  getViewFormat(): string {
    return this.viewFormatSubject.getValue();
  }

  setEmailErrorMessages(sendEmail: boolean) {
    this.emailErrorMessageSubject.next(sendEmail);
  }

  setIsDebugging(isDebugging: boolean) {
    this.isDebuggingSubject.next(isDebugging);
  }

  setIsLoading(isLoading: boolean) {
    this.isLoadingSubject.next(isLoading);
  }

  setShowPopupErrorMessages(showPopup: boolean) {
    this.showPopupErrorMessagesSubject.next(showPopup);
  }

  setLoadingId(loadingId: string) {
    this.loadingIdSubject.next(loadingId);
  }

  setViewFormat(formatToUse: string) {
    this.viewFormatSubject.next(formatToUse);
  }
}
