<div class="content">
  <div
    *ngIf="{
    } as data"
  >
    <div *ngIf="showFilter">
      <mat-form-field class="table-filter">
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search"
          aria-label="Type search criteria to filter results"
          matTooltip="Type search criteria to filter results"
          #tableFilter
        />
      </mat-form-field>
    </div>

    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="name"
      matSortDirection="asc"
      class="mat-elevation-z8 projectTable"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Project Name</th>
        <td mat-cell *matCellDef="let project" style="cursor: pointer">
          <a aria-label="Click to view project" matTooltip="Click to view project" (click)="viewProject(project)">{{
            project.name
          }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Modified</th>
        <td mat-cell *matCellDef="let project" style="cursor: pointer">
          <a aria-label="Click to view project" matTooltip="Click to view project" (click)="viewProject(project)">
            {{ project.updatedAt | date: 'short' }}
          </a>
        </td>
      </ng-container>

      <!-- actions column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let project">
          <mat-toolbar class="actionButtonBar">
            <a
              mat-icon-button
              aria-label="Delete Project"
              matTooltip="Delete Project"
              (click)="deleteProject(project)"
              *ngIf="canDelete(project)"
            >
              <img src="assets/img/TrashGray.png" alt="Delete" />
            </a>
            <a
              mat-icon-button
              aria-label="Open Project in Free Roam"
              matTooltip="Open Project in Free Roam"
              (click)="openProjectViewer(project, false)"
            >
              <img src="assets/img/UEViewer.png" alt="Open {{ unrealViewerName }}" />
            </a>
            <div *ngIf="showViewerLite && project.hasValidPanoMod">
              <a
                mat-icon-button
                aria-label="Open Project in Free Roam Lite"
                matTooltip="Open Project in {{ unrealViewerNameLite }}"
                (click)="openProjectViewer(project, true)"
              >
                <img src="assets/img/UEViewerLite.png" alt="Open {{ unrealViewerNameLite }}" />
              </a>
            </div>
            <a mat-icon-button aria-label="View Project" matTooltip="View Project" (click)="viewProject(project)">
              <img src="assets/img/Search.png" alt="View" />
            </a>
          </mat-toolbar>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedProjectColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedProjectColumns"></tr>

      <!-- Row shown when there is no matching data -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">*** No matching projects ***</td>
      </tr>
    </table>

    <mat-paginator *ngIf="showPaginator">
      [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons aria-label="Select page of projects"
    </mat-paginator>
  </div>
</div>
