import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError, timer, Subject } from 'rxjs';
import { catchError, filter, map, retry, switchMap, share, takeUntil, tap } from 'rxjs/operators';

import { UnrealServer, User } from '@shared/models';
import { createHttpObservable, getCustomHeaders } from '../../utils';

import {
  ErrorService,
  LogService,
  SettingsService
} from '@shared/services';

import { environment } from '@environment';

const ObjectID = require('bson-objectid');

@Injectable({
  providedIn: 'root',
})
export class UnrealServerService implements OnDestroy {
  private currentUnrealServerSubject = new BehaviorSubject<UnrealServer>(null);
  private unrealServerErrorSubject = new BehaviorSubject<string>(null);
  private unrealServerInUseSubject = new BehaviorSubject<boolean>(false);
  private stopPolling = new Subject();
  currentUnrealServer$: Observable<UnrealServer> = this.currentUnrealServerSubject.asObservable();
  unrealServerError$: Observable<string> = this.unrealServerErrorSubject.asObservable();
  private unrealServerStatus$: Observable<boolean>;
  private unrealServerInUse$: Observable<boolean>;

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    //blog.angulartraining.com/how-to-do-polling-with-rxjs-and-angular-50d635574965
    https: this.unrealServerStatus$ = timer(1, 3000).pipe(
      switchMap(() => httpClient.get<boolean>(`${environment.baseAPIUrl}unreal/inUse`)),
      retry(),
      share(),
      takeUntil(this.stopPolling)
    );
  }

  ngOnDestroy() {
    this.stopPolling.next('true');
  }

  getIsUnrealServerInUse(): boolean {
    return this.unrealServerInUseSubject.getValue();
  }

  getUnrealServerInUse(): Observable<boolean> {
    return this.unrealServerStatus$.pipe(
      tap((serverStatus) => {
        this.unrealServerInUseSubject.next(serverStatus);
        if (this.settingsService.getIsDebugging()) {
          this.logService.logInfo(`Unreal server status ${serverStatus} sent to subscriber`);
        }
      })
    );
  }

  getUnrealServer() {
    return this.currentUnrealServerSubject.getValue();
  }

  async startUnrealServer(user: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);

      if (user) {
        const apiUrl = `${environment.baseAPIUrl}unreal/start?userId=${user?._id}`;
        let returnValue: UnrealServer = {
          _id: new ObjectID().toString(),
          viewerUserId: user._id,
          inUse: false,
          inUseDate: null,
        };

        const data = {
          userId: user._id,
          basePath: environment.unreal.baseAppPath,
          shortcut: environment.unreal.shortcut,
        };

        _this.httpClient
          .post(apiUrl, data, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (result: any) => {
              returnValue.inUse = true;
              returnValue.inUseDate = new Date();
              _this.currentUnrealServerSubject.next(returnValue);
              _this.unrealServerErrorSubject.next('');
              resolve(result);
            },
            error: (error: HttpErrorResponse) => {
              _this.settingsService.setLoadingId(null);
              _this.currentUnrealServerSubject.next(null);
              const errMessage = _this.errorService.handleError(error);
              _this.unrealServerErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {

            }
          });
      } else {
        _this.settingsService.setIsLoading(false);
        _this.settingsService.setLoadingId(null);
        _this.currentUnrealServerSubject.next(null);
        const errMessage = _this.errorService.handleError(
          `An API URL, user and source are required to load the Unreal Server viewer`
        );
        _this.unrealServerErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async stopUnrealServer(user: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      const unrealServerInUse = _this.unrealServerInUseSubject.getValue();

      if (unrealServerInUse) {
        const url = `${environment.baseAPIUrl}unreal/stop?userId=${user?._id}`;
        let data: any = {
          programName: `${environment.unreal.programName}`,
        };

        if (user) {
          data.userId = user._id;
        }

        _this.httpClient
          .post(url, data, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (result: any) => {
              _this.currentUnrealServerSubject.next(null);
              _this.unrealServerErrorSubject.next('');
              resolve(`Successfully stopped the ${environment.unreal.viewerName}`);
            },
            error: (error: HttpErrorResponse) => {
              //clear the server variables anyway...this will fail if the server was stopped another way
              _this.currentUnrealServerSubject.next(null);
              const errMessage = _this.errorService.handleError(error);
              _this.unrealServerErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        resolve(`There is no ${environment.unreal.viewerName} currently running`);
      }
    });
  }
}
