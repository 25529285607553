import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';

import { ShipDesignation, User } from '@shared/models';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import {
  ErrorService,
  LogService,
  SettingsService,
  UserService
} from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class ShipDesignationService {
  private currentShipDesignationSubject = new BehaviorSubject<ShipDesignation>(null);
  private shipDesignationsSubject = new BehaviorSubject<ShipDesignation[]>([]);
  private shipClassDesignationsSubject = new BehaviorSubject<ShipDesignation[]>([]);
  private shipDesignationErrorSubject = new BehaviorSubject<string>(null);
  currentShipDesignation$: Observable<ShipDesignation> = this.currentShipDesignationSubject.asObservable();
  shipDesignations$: Observable<ShipDesignation[]> = this.shipDesignationsSubject.asObservable();
  shipClassDesignations$: Observable<ShipDesignation[]> = this.shipClassDesignationsSubject.asObservable();
  shipDesignationError$: Observable<string> = this.shipDesignationErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) { 

  }

  async createNewShipDesignation(payload: ShipDesignation, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && payload) {
        _this.settingsService.setIsLoading(true);
        const shipDesignations = _this.shipDesignationsSubject.getValue();
        const newShipDesignations = shipDesignations.slice(0);
        const url = `${environment.baseAPIUrl}shipDesignation/create?userId=${currentUser?._id}`;
        let returnValue: ShipDesignation;
  
        _this.httpClient
          .post(url, payload, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (newShipDesignation: ShipDesignation) => {
              returnValue = newShipDesignation;
              _this.currentShipDesignationSubject.next(newShipDesignation);
              newShipDesignations.push(newShipDesignation);
              _this.shipDesignationsSubject.next(newShipDesignations);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.shipDesignationErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        const errMessage = `payload and user are required to create a ship designation`;
        _this.errorService.handleError(errMessage);
        _this.shipDesignationErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveShipDesignation(shipDesignationId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && shipDesignationId) {
        _this.settingsService.setIsLoading(true);
        const shipDesignations = _this.shipDesignationsSubject.getValue();
        const shipDesignationIndex = shipDesignations.findIndex(
          (shipDesignation) => shipDesignation._id === shipDesignationId
        );
        const newShipDesignations = shipDesignations.slice(0);
        const url = `${environment.baseAPIUrl}shipDesignation/${shipDesignationId}?userId=${currentUser?._id}`;
        let returnValue: ShipDesignation;
  
        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedShipDesignation: ShipDesignation) => {
              returnValue = updatedShipDesignation;
              _this.currentShipDesignationSubject.next(updatedShipDesignation);
              newShipDesignations[shipDesignationIndex] = updatedShipDesignation;
              _this.shipDesignationsSubject.next(newShipDesignations);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.shipDesignationErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        const errMessage = `changes, shipDesignationId and user are required to update a ship designation`;
        _this.errorService.handleError(errMessage);
        _this.shipDesignationErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getCurrentShipDesignation() {
    return this.currentShipDesignationSubject.getValue();
  }

  getShipDesignations(currentUser: User): Observable<ShipDesignation[]> {
    const shipDesignationsHttp$ = createHttpObservable(`${environment.baseAPIUrl}shipDesignation?userId=${currentUser?._id}`, {}, true);

    shipDesignationsHttp$
      .pipe(
        catchError((err) => {
          console.error(`Error getting ship designations: ${err}`);
          return of([]);
        })
      )
      .subscribe((shipDesignations: ShipDesignation[]) => this.shipDesignationsSubject.next(shipDesignations));

    return shipDesignationsHttp$;
  }

  getShipDesignationById(shipDesignationId: string, currentUser: User): any {
    if (currentUser && shipDesignationId) {
      return this.shipDesignations$
        .pipe(
          map((shipDesignations) =>
            shipDesignations.find((shipDesignation) => shipDesignation._id === shipDesignationId)
          ),
          filter((shipDesignation) => !!shipDesignation)
        )
        .subscribe((shipDesignation) => this.currentShipDesignationSubject.next(shipDesignation));
    } else {
      this.currentShipDesignationSubject.next(null);
    }
  }

  async refreshShipDesignationsByShipClass(shipClassId: string): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      let returnValue: ShipDesignation[];

      _this.shipDesignations$
        .pipe(
          map((shipDesignations) =>
            shipDesignations.filter((shipDesignation) => shipDesignation.shipClasses.indexOf(shipClassId) > -1)
          )
        )
        .subscribe((shipDesignations: ShipDesignation[]) => {
          returnValue = shipDesignations;
          _this.shipClassDesignationsSubject.next(returnValue);
          resolve(returnValue);
        }),
        (error) => {
          const errMessage = _this.errorService.handleError(
            `Error refreshing ship designations for shipClassId ${shipClassId}:  ${error.error}`
          );
          _this.shipDesignationErrorSubject.next(errMessage);
          _this.shipClassDesignationsSubject.next(returnValue);
          resolve(returnValue);
        };
    });
  }
}
