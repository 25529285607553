<div class="content" *ngIf="currentUser$ | async as currentUser">
  <div *ngIf="showFilter">
    <mat-form-field class="table-filter">
      <mat-label>Search Documents</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Search"
        aria-label="Type search criteria to filter results"
        matTooltip="Type search criteria to filter results"
        #tableFilter
      />
    </mat-form-field>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="name"
    matSortDirection="asc"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Document Name</th>
      <td mat-cell *matCellDef="let doc" style="cursor: pointer">
        <a aria-label="Click to view document" matTooltip="Click to view document" (click)="viewDocument(doc, currentUser)">{{
          doc.name
        }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="documentType">
      <th mat-header-cell *matHeaderCellDef>Document Type</th>
      <td mat-cell *matCellDef="let doc" style="cursor: pointer">
        <a aria-label="Click to view document" matTooltip="Click to view document" (click)="viewDocument(doc, currentUser)">{{
          doc.documentType
        }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Uploaded</th>
      <td mat-cell *matCellDef="let doc" style="cursor: pointer">
        <a aria-label="Click to view document" matTooltip="Click to view document" (click)="viewDocument(doc, currentUser)">{{
          doc.createdAt | date: 'MM/dd/yyyy'
        }}</a>
      </td>
    </ng-container>

    <!-- actions column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let doc">
        <mat-toolbar class="matButtonBar actionButtonBar">
          <a
          mat-icon-button
          color="warn"
          aria-label="Delete Document"
          matTooltip="Delete Document"
          (click)="deleteDocument(doc, currentUser)"
          *ngIf="canDelete(doc)" 
          >
          <img src="assets/img/TrashGray.png" alt="Delete" />
        </a>
        <a
              mat-icon-button
              color="primary"
              aria-label="View Document"
              matTooltip="View Document"
              (click)="viewDocument(doc, currentUser)"
            >
              <img src="assets/img/Search.png" alt="View" />
            </a>
        </mat-toolbar>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedDocumentColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedDocumentColumns"></tr>

    <!-- Row shown when there is no matching data -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">*** No matching documents ***</td>
    </tr>
  </table>

  <mat-paginator *ngIf="showPaginator">
    [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons aria-label="Select page of documents"
  </mat-paginator>

  <iframe id="iframeForDownload" *ngIf="safeDocumentUrl" [src]="safeDocumentUrl" style="visibility: hidden"></iframe>
</div>
