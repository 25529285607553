<div style="min-height: 100%; height: 100%" *ngIf="currentUser$ | async as currentUser">
  <div class="content">
    <ng-container
      *ngIf="{
        project: currentProject$ | async,
        projectNotes: currentProjectNotes$ | async,
        ship: currentProjectShip$ | async,
        shipMod: currentProjectShipMod$ | async,
        shipModel3d: currentProjectShipModModel3d$ | async,
        shipScan: currentProjectShipModScan$ | async,
        vehicle: currentProjectVehicle$ | async,
        vehicleMod: currentProjectVehicleMod$ | async,
        vehicleModel3d: currentProjectVehicleModModel3d$ | async,
        vehicleScan: currentProjectVehicleModScan$ | async,
        report: selectedReport$ | async,
        reportIFrameSrc: selectedReportIFrameSrc$ | async,
        reportSections: reportSections$ | async
      } as data"
    >
    <div class="report-grid-container">
      <div>
        <div class="report-viewer">
          <iframe
            id="reportViewer"
            class="e2e-iframe-trusted-src"
            frameborder="0"
            width="100%"
            height="100%"
            allowfullscreen 
            loading="lazy" 
            [src]="safeSrc"
          ></iframe>
        </div>
      </div>
      <div>
        <form [formGroup]="form">
          <h1>REPORT SETTINGS</h1>
          <div class="toolbar-action-buttons mat-toolbar-single-row">
            <button
              mat-stroked-button
              aria-label="Click to expand all report options"
              matTooltip="Click to expand all report options"
              (click)="accordion.openAll()"
            >
              Expand All
            </button>
            <button
              mat-stroked-button
              aria-label="Click to collapse all report options"
              matTooltip="Click to collapse all report options"
              (click)="accordion.closeAll()"
            >
              Collapse All
            </button>
            <button
              mat-stroked-button
              aria-label="Click to view project"
              matTooltip="Click to view project"
              (click)="viewProject()"
            >
              View Project
            </button>
            <button
              mat-stroked-button
              color="accent"
              aria-label="Click to save and refresh report"
              matTooltip="Click to save and refresh report"
              *ngIf="!isFinalizedReport && form.dirty"
              (click)="save(data.report._id, data.project, currentUser._id)"
              [disabled]="form.invalid"
            >
              Update Report
            </button>
            <button
              mat-stroked-button
              color="warn"
              aria-label="Click to finalize report"
              matTooltip="Click to finalize report"
              *ngIf="!isFinalizedReport && !form.dirty"
              (click)="finalize(data.report, data.project, currentUser._id)"
              [disabled]="form.invalid"
            >
              Finalize Report
            </button>
          </div>
          <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> ANALYSIS </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-slide-toggle
                (change)="onSelectAllAnalysisComponents($event)"
                [checked]="areAllAnalysisComponentsSelected"
                class="right"
                [disabled]="isFinalizedReport"
                >Select All</mat-slide-toggle
              >
              <form [formGroup]="analysisForm">
                <table style="width: 100%">
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="notes"  [disabled]="isFinalizedReport">Notes </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="snapshots" [disabled]="isFinalizedReport"> Snapshots</mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="videos" [disabled]="isFinalizedReport"> Videos</mat-checkbox>
                    </td>
                  </tr>
                </table>
              </form>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> COLLISION </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-slide-toggle
                (change)="onSelectAllCollisionComponents($event)"
                [checked]="areAllCollisionComponentsSelected"
                class="right"
                [disabled]="isFinalizedReport"
                >Select All</mat-slide-toggle
              >
              <form [formGroup]="collisionForm">
                <table style="width: 100%">
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="count"  [disabled]="isFinalizedReport">Collision Summary </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="notes" [disabled]="isFinalizedReport">Notes </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="snapshots" [disabled]="isFinalizedReport"> Snapshots</mat-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="videos" [disabled]="isFinalizedReport"> Videos</mat-checkbox>
                    </td>
                    <td style="width: 33%"></td>
                    <td style="width: 33%"></td>
                  </tr>
                </table>
              </form>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> MINIMUM CLEARANCE </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-slide-toggle
                (change)="onSelectAllMinimumClearanceComponents($event)"
                [checked]="areAllMinimumClearanceComponentsSelected"
                class="right"
                [disabled]="isFinalizedReport"
                >Select All</mat-slide-toggle
              >
              <form [formGroup]="minimumClearanceForm">
                <table style="width: 100%">
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="lateralLeft" [disabled]="isFinalizedReport">
                        Lateral (Left)</mat-checkbox
                      >
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="lateralRight" [disabled]="isFinalizedReport">
                        Lateral (Right)</mat-checkbox
                      >
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="longitudinalFront" [disabled]="isFinalizedReport">
                        Longitudinal (Front)
                      </mat-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="longitudinalBack" [disabled]="isFinalizedReport">
                        Longitudinal (Back)</mat-checkbox
                      >
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="verticalGround" [disabled]="isFinalizedReport">
                        Vertical (Ground)</mat-checkbox
                      >
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="verticalRoof" [disabled]="isFinalizedReport">
                        Vertical (Roof)</mat-checkbox
                      >
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="notes" [disabled]="isFinalizedReport"> Notes </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="snapshots" [disabled]="isFinalizedReport"> Snapshots</mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="videos" [disabled]="isFinalizedReport"> Videos</mat-checkbox>
                    </td>
                  </tr>
                </table>
              </form>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> PATH TRAVELED </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-slide-toggle
                (change)="onSelectAllPathTraveledComponents($event)"
                [checked]="areAllPathTraveledComponentsSelected"
                class="right"
                [disabled]="isFinalizedReport"
                >Select All</mat-slide-toggle
              >
              <form [formGroup]="pathTraveledForm">
                <table style="width: 100%">
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="notes" [disabled]="isFinalizedReport">Notes </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="snapshots" [disabled]="isFinalizedReport"> Snapshots</mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="videos" [disabled]="isFinalizedReport"> Videos</mat-checkbox>
                    </td>
                  </tr>
                </table>
              </form>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> PROJECT </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-slide-toggle
                (change)="onSelectAllProjectComponents($event)"
                [checked]="areAllProjectComponentsSelected"
                class="right"
                [disabled]="isFinalizedReport"
                >Select All</mat-slide-toggle
              >
              <form [formGroup]="projectForm">
                <table style="width: 100%">
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="propagatedError" [disabled]="isFinalizedReport">
                        Propagated Error
                      </mat-checkbox>
                    </td>
                    <td style="width: 33%"></td>
                    <td style="width: 33%"></td>
                  </tr>
                </table>
              </form>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> SHIP </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-slide-toggle
                (change)="onSelectAllShipComponents($event)"
                [checked]="areAllShipComponentsSelected"
                class="right"
                [disabled]="isFinalizedReport"
                >Select All</mat-slide-toggle
              >
              <form [formGroup]="shipForm">
                <table style="width: 100%" *ngIf="isShipModel3d">
                  <tr>
                    <td style="width: 33%" formGroupName="model3d">
                      <mat-checkbox class="example-margin" formControlName="dataSource" [disabled]="isFinalizedReport">Data Source </mat-checkbox>
                    </td>
                    <td style="width: 33%" formGroupName="model3d">
                      <mat-checkbox class="example-margin" formControlName="fidelity" [disabled]="isFinalizedReport">Fidelity </mat-checkbox>
                    </td>
                    <td style="width: 33%" formGroupName="model3d">
                      <mat-checkbox class="example-margin" formControlName="fileType" [disabled]="isFinalizedReport">File Type </mat-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="image" [disabled]="isFinalizedReport">Image </mat-checkbox>
                    </td>
                    <td style="width: 33%" formGroupName="model3d">
                      <mat-checkbox class="example-margin" formControlName="modelDate" [disabled]="isFinalizedReport">Model Date </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="notes" [disabled]="isFinalizedReport">Notes </mat-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="snapshots" [disabled]="isFinalizedReport"> Snapshots</mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="videos" [disabled]="isFinalizedReport"> Videos</mat-checkbox>
                    </td>
                    <td style="width: 33%"></td>
                  </tr>
                </table>
                <table style="width: 100%" *ngIf="isShipScan">
                  <tr>
                    <td style="width: 33%" formGroupName="scan">
                      <mat-checkbox class="example-margin" formControlName="dataSource" [disabled]="isFinalizedReport">Data Source </mat-checkbox>
                    </td>
                    <td style="width: 33%" formGroupName="scan">
                      <mat-checkbox class="example-margin" formControlName="fileType" [disabled]="isFinalizedReport">File Type </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="image" [disabled]="isFinalizedReport">Image </mat-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%" formGroupName="scan">
                      <mat-checkbox class="example-margin" formControlName="scanDate" [disabled]="isFinalizedReport">Scan Date </mat-checkbox>
                    </td>
                    <td style="width: 33%" formGroupName="scan">
                      <mat-checkbox class="example-margin" formControlName="scanType" [disabled]="isFinalizedReport">Scan Type </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="notes" [disabled]="isFinalizedReport">Notes </mat-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="snapshots" [disabled]="isFinalizedReport"> Snapshots</mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="videos" [disabled]="isFinalizedReport"> Videos</mat-checkbox>
                    </td>
                    <td style="width: 33%"></td>
                  </tr>
                </table>
              </form>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> VEHICLE </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-slide-toggle
                (change)="onSelectAllVehicleComponents($event)"
                [checked]="areAllVehicleComponentsSelected"
                class="right"
                [disabled]="isFinalizedReport"
                >Select All</mat-slide-toggle
              >
              <form [formGroup]="vehicleForm">
                <table *ngIf="isVehicleModel3d" style="width: 100%">
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="axles" [disabled]="isFinalizedReport">Axles </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="brakes" [disabled]="isFinalizedReport">Brakes </mat-checkbox>
                    </td>
                    <td style="width: 33%" formGroupName="model3d">
                      <mat-checkbox class="example-margin" formControlName="dataSource" [disabled]="isFinalizedReport">Data Source </mat-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="engine" [disabled]="isFinalizedReport">Engine </mat-checkbox>
                    </td>
                    <td style="width: 33%" formGroupName="model3d">
                      <mat-checkbox class="example-margin" formControlName="fidelity" [disabled]="isFinalizedReport">Fidelity </mat-checkbox>
                    </td>
                    <td style="width: 33%" formGroupName="model3d">
                      <mat-checkbox class="example-margin" formControlName="fileType" [disabled]="isFinalizedReport">File Type </mat-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="generalInfo" [disabled]="isFinalizedReport">
                        General Information
                      </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="image" [disabled]="isFinalizedReport">Image </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="maneuverability" [disabled]="isFinalizedReport">
                        Maneuverability</mat-checkbox
                      >
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%" formGroupName="model3d">
                      <mat-checkbox class="example-margin" formControlName="modelDate" [disabled]="isFinalizedReport">Model Date </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="steering" [disabled]="isFinalizedReport">Steering </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="notes" [disabled]="isFinalizedReport">Notes </mat-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="snapshots" [disabled]="isFinalizedReport"> Snapshots</mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="videos" [disabled]="isFinalizedReport"> Videos</mat-checkbox>
                    </td>
                    <td style="width: 33%"></td>
                  </tr>
                </table>
                <table *ngIf="isVehicleScan" style="width: 100%">
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="axles" [disabled]="isFinalizedReport">Axles </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="brakes" [disabled]="isFinalizedReport">Brakes </mat-checkbox>
                    </td>
                    <td style="width: 33%" formGroupName="scan">
                      <mat-checkbox class="example-margin" formControlName="dataSource" [disabled]="isFinalizedReport">Data Source </mat-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="engine" [disabled]="isFinalizedReport">Engine </mat-checkbox>
                    </td>
                    <td style="width: 33%" formGroupName="scan">
                      <mat-checkbox class="example-margin" formControlName="fileType" [disabled]="isFinalizedReport">File Type </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="generalInfo" [disabled]="isFinalizedReport">
                        General Information
                      </mat-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="image" [disabled]="isFinalizedReport">Image </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="maneuverability" [disabled]="isFinalizedReport">
                        Maneuverability</mat-checkbox
                      >
                    </td>
                    <td style="width: 33%" formGroupName="scan">
                      <mat-checkbox class="example-margin" formControlName="scanDate" [disabled]="isFinalizedReport">Scan Date </mat-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%" formGroupName="scan">
                      <mat-checkbox class="example-margin" formControlName="scanType" [disabled]="isFinalizedReport">Scan Type </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="steering" [disabled]="isFinalizedReport">Steering </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="notes" [disabled]="isFinalizedReport">Notes </mat-checkbox>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="snapshots" [disabled]="isFinalizedReport"> Snapshots</mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="videos" [disabled]="isFinalizedReport"> Videos</mat-checkbox>
                    </td>
                    <td style="width: 33%"></td>
                  </tr>
                </table>
              </form>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> SNAPSHOTS </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-slide-toggle
                (change)="onSelectAllSnapshotComponents($event)"
                [checked]="areAllSnapshotComponentsSelected"
                class="right"
                [disabled]="isFinalizedReport"
                >Select All</mat-slide-toggle
              >
              <form [formGroup]="snapshotForm">
                <table style="width: 100%">
                  <tr>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="notes" [disabled]="isFinalizedReport">Notes </mat-checkbox>
                    </td>
                    <td style="width: 33%">
                      <mat-checkbox class="example-margin" formControlName="snapshots" [disabled]="isFinalizedReport"> Snapshots</mat-checkbox>
                    </td>
                    <td style="width: 33%"></td>
                  </tr>
                </table>
              </form>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title
                  >REPORT LEVEL NOTES ({{
                    getNumberOfItemsInArray(getReportLevelNotes(data.projectNotes, data.report._id))
                  }})
                </mat-panel-title>
                <mat-panel-description>Notes added to the report by report section. </mat-panel-description>
              </mat-expansion-panel-header>

              <button mat-raised-button color="accent" class="right" (click)="addNote(data.report, data.project)">
                <mat-icon>library_add</mat-icon>
                <span>Add Note</span>
              </button>

              <div *ngIf="getReportLevelNotes(data.projectNotes, data.report._id) as reportNotes">
                <app-note-table
                  [notes]="reportNotes"
                  [project]="data.project"
                  [reportSections]="data.reportSections"
                  [isSnapshotOrVideoNotes]="false"
                >
                </app-note-table>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </form>
      </div>
    </div>
      <canvas id="canvasForImageProcessing"></canvas>
    </ng-container>
  </div>
</div>
