import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, from, throwError, of, zip } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';

import {
  DataSource,
  Document,
  ImageDoc,
  Manufacturer,
  Mod,
  Model3d,
  Note,
  Scan,
  Scanner,
  Ship,
  ShipClass,
  ShipDesignation,
  User,
  Video
} from '@shared/models';
import { DbCollectionsEnum, ModStatesEnum, ScanDisplayTypesEnum } from '@shared/enums';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import {
  DataSourceService,
  DocumentService,
  ErrorService,
  ImageDocService,
  LogService,
  ManufacturerService,
  ModService,
  Model3dService,
  NoteService,
  ScannerService,
  ScanService,
  SettingsService,
  ShipClassService,
  ShipDesignationService,
  UnrealServerService,
  UserService,
  VideoService
} from '@shared/services';

import { environment } from '@environment';

const ObjectID = require('bson-objectid');

@Injectable({
  providedIn: 'root',
})
export class ShipService {
  private allowShipModels3dSubject = new BehaviorSubject<boolean>(null);
  private allowShipScansSubject = new BehaviorSubject<boolean>(null);
  private currentShipSubject = new BehaviorSubject<Ship>(null);
  private currentShipClassSubject = new BehaviorSubject<ShipClass>(null);
  private currentShipDesignationSubject = new BehaviorSubject<ShipDesignation>(null);
  private currentShipImagesSubject = new BehaviorSubject<ImageDoc[]>(null);
  private currentShipModel3dSubject = new BehaviorSubject<Model3d>(null);
  private currentShipModels3dSubject = new BehaviorSubject<Model3d[]>(null);
  private currentShipModels3dWithModsSubject = new BehaviorSubject<Model3d[]>(null);
  private currentShipModels3dWithValidModsSubject = new BehaviorSubject<Model3d[]>(null);
  private currentShipNotesSubject = new BehaviorSubject<Note[]>(null);
  private currentShipScanSubject = new BehaviorSubject<Scan>(null);
  private currentShipPanoramicScansSubject = new BehaviorSubject<Scan[]>(null);
  private currentShipPointCloudScansSubject = new BehaviorSubject<Scan[]>(null);
  private currentShipScansSubject = new BehaviorSubject<Scan[]>(null);
  private currentShipScansWithModsSubject = new BehaviorSubject<Scan[]>(null);
  private currentShipScansWithValidModsSubject = new BehaviorSubject<Scan[]>(null);
  private currentShipVideosSubject = new BehaviorSubject<Video[]>(null);
  private dataSourcesSubject = new BehaviorSubject<DataSource[]>(null);
  private documentsSubject = new BehaviorSubject<Document[]>(null);
  private manufacturersSubject = new BehaviorSubject<Manufacturer[]>(null);
  private models3dSubject = new BehaviorSubject<Model3d[]>(null);
  private modsSubject = new BehaviorSubject<Mod[]>(null);
  private shipsSubject = new BehaviorSubject<Ship[]>(null);
  private shipsWithModsSubject = new BehaviorSubject<Ship[]>(null);
  private shipsWithValidModsSubject = new BehaviorSubject<Ship[]>(null);
  private scannersSubject = new BehaviorSubject<Scanner[]>(null);
  private scansSubject = new BehaviorSubject<Scan[]>(null);
  private shipClassesSubject = new BehaviorSubject<ShipClass[]>(null);
  private shipDesignationsSubject = new BehaviorSubject<ShipDesignation[]>(null);
  private usersSubject = new BehaviorSubject<User[]>(null);
  private shipErrorSubject = new BehaviorSubject<string>(null);
  allowShipModel3ds$: Observable<boolean> = this.allowShipModels3dSubject.asObservable();
  allowShipScans$: Observable<boolean> = this.allowShipScansSubject.asObservable();
  currentShip$: Observable<Ship> = this.currentShipSubject.asObservable();
  currentShipClass$: Observable<ShipClass> = this.currentShipClassSubject.asObservable();
  currentShipDesignation$: Observable<ShipDesignation> = this.currentShipDesignationSubject.asObservable();
  currentShipImages$: Observable<ImageDoc[]> = this.currentShipImagesSubject.asObservable();
  currentShipModel3d$: Observable<Model3d> = this.currentShipModel3dSubject.asObservable();
  currentShipModels3d$: Observable<Model3d[]> = this.currentShipModels3dSubject.asObservable();
  currentShipModels3dWithMods$: Observable<Model3d[]> = this.currentShipModels3dWithModsSubject.asObservable();
  currentShipModels3dWithValidMods$: Observable<Model3d[]> = this.currentShipModels3dWithValidModsSubject.asObservable();
  currentShipNotes$: Observable<Note[]> = this.currentShipNotesSubject.asObservable();
  currentShipScan$: Observable<Scan> = this.currentShipScanSubject.asObservable();
  currentShipPanoramicScans$: Observable<Scan[]> = this.currentShipPanoramicScansSubject.asObservable();
  currentShipPointCloudScans$: Observable<Scan[]> = this.currentShipPointCloudScansSubject.asObservable();
  currentShipScans$: Observable<Scan[]> = this.currentShipScansSubject.asObservable();
  currentShipScansWithMods$: Observable<Scan[]> = this.currentShipScansWithModsSubject.asObservable();
  currentShipScansWithValidMods$: Observable<Scan[]> = this.currentShipScansWithValidModsSubject.asObservable();
  currentShipVideos$: Observable<Video[]> = this.currentShipVideosSubject.asObservable();
  dataSources$: Observable<DataSource[]> = this.dataSourcesSubject.asObservable();
  documents$: Observable<Document[]> = this.documentsSubject.asObservable();
  manufacturers$: Observable<Manufacturer[]> = this.manufacturersSubject.asObservable();
  models3d$: Observable<Model3d[]> = this.models3dSubject.asObservable();
  mods$: Observable<Mod[]> = this.modsSubject.asObservable();
  ships$: Observable<Ship[]> = this.shipsSubject.asObservable();
  shipsWithMods$: Observable<Ship[]> = this.shipsWithModsSubject.asObservable();
  shipsWithValidMods$: Observable<Ship[]> = this.shipsWithValidModsSubject.asObservable();
  scanners$: Observable<Scanner[]> = this.scannersSubject.asObservable();
  scans$: Observable<Scan[]> = this.scansSubject.asObservable();
  shipClasses$: Observable<ShipClass[]> = this.shipClassesSubject.asObservable();
  shipDesignations$: Observable<ShipDesignation[]> = this.shipDesignationsSubject.asObservable();
  users$: Observable<User[]> = this.usersSubject.asObservable();
  shipError$: Observable<string> = this.shipErrorSubject.asObservable();

  constructor(
    private dataSourceService: DataSourceService,
    private documentService: DocumentService,
    private errorService: ErrorService,
    private imageDocService: ImageDocService,
    private logService: LogService,
    private manufacturerService: ManufacturerService,
    private modService: ModService,
    private model3dService: Model3dService,
    private noteService: NoteService,
    private scannerService: ScannerService,
    private scanService: ScanService,
    private settingsService: SettingsService,
    private shipClassService: ShipClassService,
    private shipDesignationService: ShipDesignationService,
    private unrealServerService: UnrealServerService,
    private userService: UserService,
    private videoService: VideoService,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.allowShipModels3dSubject.next(this.settingsService.getIsShipModSourceAllowed(DbCollectionsEnum.MODELS3D));
    this.allowShipScansSubject.next(this.settingsService.getIsShipModSourceAllowed(DbCollectionsEnum.SCANS));
    this.currentShipImages$ = this.imageDocService.currentShipImages$;
    this.currentShipModels3d$ = this.model3dService.currentShipModels3d$;
    this.currentShipModel3d$ = this.model3dService.currentShipModel3d$;
    this.currentShipNotes$ = this.noteService.currentShipNotes$;
    this.currentShipScans$ = this.scanService.currentShipScans$;
    this.currentShipScan$ = this.scanService.currentShipScan$;
    this.currentShipVideos$ = this.videoService.currentShipVideos$;
    this.dataSources$ = this.dataSourceService.dataSources$;
    this.documents$ = this.documentService.currentShipDocuments$;
    this.manufacturers$ = this.manufacturerService.manufacturers$;
    this.models3d$ = this.model3dService.models3d$;
    this.mods$ = this.modService.mods$;
    this.scanners$ = this.scannerService.scanners$;
    this.scans$ = this.scanService.scans$;
    this.shipClasses$ = this.shipClassService.shipClasses$;
    this.shipDesignations$ = this.shipDesignationService.shipDesignations$;
    this.users$ = this.userService.users$;

    combineLatest([this.currentShipModels3d$, this.currentShipScans$, this.mods$]).subscribe(
      ([models3d, scans, mods]) => {
        let models3dWithMods: Model3d[];
        let models3dWithValidMods: Model3d[];
        let panoramicScans: Scan[];
        let pointCloudScans: Scan[];
        let scansWithMods: Scan[];
        let scansWithValidMods: Scan[];

        if (models3d && models3d.length > 0) {
          models3dWithMods = models3d.filter((model3d) => model3d.modDetails && model3d.modDetails.url != null);
          models3dWithValidMods = models3d.filter((model3d) => model3d.modDetails && model3d.modDetails.modState === ModStatesEnum.VALID);
        }

        if (scans && scans.length > 0) {
          panoramicScans = scans.filter((scan) => scan.scanDisplayType === ScanDisplayTypesEnum.PANORAMIC_IMAGES);
          pointCloudScans = scans.filter((scan) => scan.scanDisplayType === ScanDisplayTypesEnum.POINT_CLOUD);
          scansWithMods = scans.filter((scan) => scan.modDetails && scan.modDetails.url != null);
          scansWithValidMods = scans.filter((scan) => scan.modDetails && scan.modDetails.modState === ModStatesEnum.VALID);
        }

        this.currentShipModels3dWithModsSubject.next(models3dWithMods);
        this.currentShipModels3dWithValidModsSubject.next(models3dWithValidMods);
        this.currentShipPanoramicScansSubject.next(panoramicScans);
        this.currentShipPointCloudScansSubject.next(pointCloudScans);
        this.currentShipScansWithModsSubject.next(scansWithMods);
        this.currentShipScansWithValidModsSubject.next(scansWithValidMods);
      }
    );
  }

  async addDocument(doc: Document, ship: Ship, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const ships = _this.shipsSubject.getValue();
      let returnValue: Document;

      if (currentUser && doc && ship && ships) {
        const shipIndex = ships.findIndex((ship) => ship._id === ship._id);
        const newShips = ships.slice(0);

        _this.documentService
          .createNewDocument(doc, DbCollectionsEnum.SHIPS, ship._id, currentUser)
          .then((newDoc: Document) => {
            returnValue = newDoc;
            resolve(returnValue);
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error creating document for shipId $${ship._id}: ${error.error}`
            );
            _this.shipErrorSubject.next(errMessage);
            reject(errMessage);
          })
          .finally(() => {
            _this.settingsService.setIsLoading(false);
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(
          `A Document object, ship and user arerequired to add a new document to the ship`
        );
        _this.shipErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async addImage(imageDoc: ImageDoc, ship: Ship, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const currentUser = _this.userService.getCurrentUser();
      const ships = _this.shipsSubject.getValue();
      let returnValue: ImageDoc;

      if (currentUser && imageDoc && ship) {
        //keep the array consistent, it will be refreshed in the api automatically
        const shipIndex = ships.findIndex((s) => s._id === ship._id);
        const newShips = ships.slice(0);

        _this.imageDocService
          .createNewImageDoc(imageDoc, DbCollectionsEnum.SHIPS, ship._id, currentUser)
          .then((newImage: ImageDoc) => {
            returnValue = newImage;
            newShips[shipIndex].editorId = currentUser._id;
            newShips[shipIndex].updatedAt = new Date();
            _this.shipsSubject.next(newShips);
            resolve(returnValue);
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error creating new image for shipId ${ship._id}: ${error.error}`
            );
            _this.shipErrorSubject.next(errMessage);
            reject(errMessage);
          })
          .finally(() => {
            _this.settingsService.setIsLoading(false);
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(
          `A Image Document object is required to add a new image to the ship`
        );
        _this.shipErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async addNote(note: Note, ship: Ship, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const ships = _this.shipsSubject.getValue();
      let returnValue: Note;

      if (currentUser && note && ship && ships) {
        //keep the array consistent, it will be refreshed in the api automatically
        const shipIndex = ships.findIndex((s) => s._id === ship._id);
        const newShips = ships.slice(0);

        _this.noteService
          .createNewNote(note, DbCollectionsEnum.SHIPS, ship._id, currentUser)
          .then((newNote: Note) => {
            returnValue = newNote;
            newShips[shipIndex].editorId = currentUser._id;
            newShips[shipIndex].updatedAt = new Date();
            _this.shipsSubject.next(newShips);
            resolve(returnValue);
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error creating new note for shipId ${ship._id}: ${error.error}`
            );
            _this.shipErrorSubject.next(errMessage);
            reject(errMessage);
          })
          .finally(() => {
            _this.settingsService.setIsLoading(false);
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(
          `A Note object, ship and user are required to add a new note to a ship`
        );
        _this.shipErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async addVideo(video: Video, ship: Ship, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const ships = _this.shipsSubject.getValue();
      let returnValue: Video;

      if (currentUser && video && ship) {
        //keep the array consistent, it will be refreshed in the api automatically
        const shipIndex = ships.findIndex((s) => s._id === ship._id);
        const newShips = ships.slice(0);

        _this.videoService
          .createNewVideo(video, DbCollectionsEnum.SHIPS, ship._id, currentUser)
          .then((newVideo: Video) => {
            returnValue = newVideo;
            newShips[shipIndex].editorId = currentUser._id;
            newShips[shipIndex].updatedAt = new Date();
            _this.shipsSubject.next(newShips);
            resolve(returnValue);
          })
          .catch((error) => {
            const errMessage = _this.errorService.handleError(
              `Error creating video ${JSON.stringify(video)} for shipId ${ship._id}: ${error.error}`
            );
            _this.shipErrorSubject.next(errMessage);
            reject(errMessage);
          })
          .finally(() => {
            _this.settingsService.setIsLoading(false);
          });
      } else {
        _this.settingsService.setIsLoading(false);
        const errMessage = _this.errorService.handleError(
          `A video object and a ship are required to add a new video to the ship`
        );
        _this.shipErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async createNewShip(payload: Ship, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && payload) {
        _this.settingsService.setIsLoading(true);
        const ships = _this.shipsSubject.getValue();
        const newShips = ships.slice(0);
        const url = `${environment.baseAPIUrl}ship/create?userId=${currentUser?._id}`;
        const promises = [];
        let returnValue: Ship;

        _this.httpClient
          .post(url, payload, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (newShip: Ship) => {
              returnValue = newShip;
              _this.currentShipSubject.next(newShip);
              newShips.push(newShip);
              _this.shipsSubject.next(newShips);

              promises.push(
                _this.documentService.refreshDocumentsByParent(DbCollectionsEnum.SHIPS, newShip ? newShip._id : null, currentUser)
              );

              promises.push(
                _this.imageDocService.refreshImagesByParent(DbCollectionsEnum.SHIPS, newShip ? newShip._id : null, false, currentUser)
              );
              promises.push(
                _this.model3dService.refreshModels3dByParent(DbCollectionsEnum.SHIPS, newShip ? newShip._id : null, currentUser)
              );
              promises.push(_this.noteService.refreshNotesByParent(DbCollectionsEnum.SHIPS, newShip ? newShip._id : null, currentUser));
              promises.push(_this.scanService.refreshScansByParent(DbCollectionsEnum.SHIPS, newShip ? newShip._id : null, currentUser));
              promises.push(_this.updateChildData(newShip, currentUser));

              Promise.allSettled(promises).then((results) => {
                _this.settingsService.setIsLoading(false);
                resolve(returnValue);
              });
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.shipErrorSubject.next(errMessage);
              _this.currentShipSubject.next(null);
              promises.push(_this.documentService.refreshDocumentsByParent(DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(_this.imageDocService.refreshImagesByParent(DbCollectionsEnum.SHIPS, null, false, currentUser));
              promises.push(_this.model3dService.refreshModels3dByParent(DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(_this.noteService.refreshNotesByParent(DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(_this.scanService.refreshScansByParent(DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(_this.updateChildData(null, currentUser));

              Promise.allSettled(promises).then((results) => {
                _this.settingsService.setIsLoading(false);
                reject(error);
              });
            },
            complete: () => {

            }
          });
      } else {
        const errMessage = `payload and user are required to create a ship`;
        this.errorService.handleError(errMessage);
        this.shipErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async deleteShip(shipId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      const currentShip = _this.currentShipSubject.getValue();
      const ships = _this.shipsSubject.getValue();
      let returnValue: Ship[];

      if (currentUser && ships && currentShip) {
        const shipIndex = ships.findIndex((ship) => ship._id === currentShip._id);
        ships.splice(shipIndex, 1);
        const url = `${environment.baseAPIUrl}ship/${currentShip._id}?userId=${currentUser._id}`;

        _this.httpClient
          .delete(url, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedShip: Ship) => {
              _this.currentShipSubject.next(null);
              _this.shipsSubject.next(ships);
              const promises = [];

              promises.push(_this.documentService.refreshDocumentsByParent(DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(_this.imageDocService.refreshImagesByParent(DbCollectionsEnum.SHIPS, null, false, currentUser));
              promises.push(_this.model3dService.refreshModels3dByParent(DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(_this.noteService.refreshNotesByParent(DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(_this.scanService.refreshScansByParent(DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(_this.updateChildData(null, currentUser));

              Promise.allSettled(promises).then((results) => {
                _this.settingsService.setIsLoading(false);
                returnValue = ships;
                _this.shipsSubject.next(returnValue);
                resolve(returnValue);
              });
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.shipErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        this.settingsService.setIsLoading(false);
        const errMessage = this.errorService.handleError(`A ship and user are required to delete the current ship`);
        this.shipErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveShip(shipId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && shipId) {
        _this.settingsService.setIsLoading(true);
        const ships = _this.shipsSubject.getValue();
        const shipIndex = ships.findIndex((ship) => ship._id === shipId);
        const newShips = ships.slice(0);
        const url = `${environment.baseAPIUrl}ship/${shipId}?userId=${currentUser?._id}`;
        let returnValue: Ship;

        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedShip: Ship) => {
              returnValue = updatedShip;
              newShips[shipIndex] = updatedShip;
              _this.shipsSubject.next(newShips);
              _this.currentShipSubject.next(updatedShip);

              const promises = [];
              promises.push(
                _this.documentService.refreshDocumentsByParent(
                  DbCollectionsEnum.SHIPS,
                  updatedShip ? updatedShip._id : null,
                  currentUser
                )
              );
              promises.push(
                _this.imageDocService.refreshImagesByParent(
                  DbCollectionsEnum.SHIPS,
                  updatedShip ? updatedShip._id : null,
                  false,
                  currentUser
                )
              );
              promises.push(
                _this.model3dService.refreshModels3dByParent(DbCollectionsEnum.SHIPS, updatedShip ? updatedShip._id : null, currentUser)
              );
              promises.push(
                _this.noteService.refreshNotesByParent(DbCollectionsEnum.SHIPS, updatedShip ? updatedShip._id : null, currentUser)
              );
              promises.push(
                _this.scanService.refreshScansByParent(DbCollectionsEnum.SHIPS, updatedShip ? updatedShip._id : null, currentUser)
              );
              promises.push(_this.updateChildData(updatedShip, currentUser));

              Promise.allSettled(promises).then((results) => {
                _this.settingsService.setIsLoading(false);
                resolve(returnValue);
              });
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.shipErrorSubject.next(errMessage);
              _this.currentShipSubject.next(returnValue);

              const promises = [];
              promises.push(_this.documentService.refreshDocumentsByParent(DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(_this.imageDocService.refreshImagesByParent(DbCollectionsEnum.SHIPS, null, false, currentUser));
              promises.push(_this.model3dService.refreshModels3dByParent(DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(_this.noteService.refreshNotesByParent(DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(_this.scanService.refreshScansByParent(DbCollectionsEnum.SHIPS, null, currentUser));
              promises.push(_this.updateChildData(null, currentUser));

              Promise.allSettled(promises).then((results) => {
                _this.settingsService.setIsLoading(false);
                reject(error);
              });
            },
            complete: () => {

            }
          });
      } else {
        const errMessage = `changes, shipId and user are required to update a ship`;
        this.errorService.handleError(errMessage);
        this.shipErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getAllowShipModels3d() {
    return this.allowShipModels3dSubject.getValue();
  }

  getAllowShipScans() {
    return this.allowShipScansSubject.getValue();
  }

  getShips(currentUser: User): Observable<Ship[]> {
    const shipsHttp$ = createHttpObservable(`${environment.baseAPIUrl}ship?userId=${currentUser?._id}`, {}, true);

    shipsHttp$
      .pipe(
        catchError((err) => {
          console.error(`Error getting ships: ${err}`);
          return of([]);
        })
      )
      .subscribe((ships: Ship[]) => {
        this.shipsSubject.next(ships);
        let shipsWithMods: Ship[];
        let shipsWithValidMods: Ship[];

        if (ships && ships.length > 0) {
          shipsWithMods = ships.filter((ship) => ship.hasMod === true);
          shipsWithValidMods = ships.filter((ship) => ship.hasValidMod === true)
        }

        this.shipsWithModsSubject.next(shipsWithMods);
        this.shipsWithValidModsSubject.next(shipsWithValidMods);
      });

    return shipsHttp$;
  }

  getCurrentShip() {
    return this.currentShipSubject.getValue();
  }

  getCurrentShipClass() {
    return this.currentShipClassSubject.getValue();
  }

  getCurrentShipImages() {
    return this.currentShipImagesSubject.getValue();
  }

  getCurrentShipModel3d() {
    return this.currentShipModel3dSubject.getValue();
  }

  getCurrentShipModels3d() {
    return this.currentShipModels3dSubject.getValue();
  }

  getCurrentShipNotes() {
    return this.currentShipNotesSubject.getValue();
  }

  getCurrentShipScan() {
    return this.currentShipScanSubject.getValue();
  }

  getCurrentShipPanoramicScans() {
    return this.currentShipPanoramicScansSubject.getValue();
  }

  getCurrentShipPointCloudScans() {
    return this.currentShipPointCloudScansSubject.getValue();
  }

  getCurrentShipScans() {
    return this.currentShipScansSubject.getValue();
  }

  getCurrentShipVideos() {
    return this.currentShipVideosSubject.getValue();
  }

  getCurrentScanner() {
    return this.currentShipScanSubject.getValue();
  }

  async getShipById(shipId: string, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue: Ship;
      const promises = [];

      if (currentUser && shipId) {
        _this.ships$
          .pipe(
            map((ships) => ships.find((ship) => ship._id === shipId)),
            filter((ship) => !!ship)
          )
          .subscribe({
            next: (ship) => {
              returnValue = ship;
              _this.currentShipSubject.next(ship);

              promises.push(
                _this.documentService.refreshDocumentsByParent(DbCollectionsEnum.SHIPS, ship ? ship._id : null, currentUser)
              );

              promises.push(
                _this.imageDocService.refreshImagesByParent(DbCollectionsEnum.SHIPS, ship ? ship._id : null, false, currentUser)
              );
              promises.push(_this.model3dService.refreshModels3dByParent(DbCollectionsEnum.SHIPS, ship ? ship._id : null, currentUser));
              promises.push(_this.noteService.refreshNotesByParent(DbCollectionsEnum.SHIPS, ship ? ship._id : null, currentUser));
              promises.push(_this.scanService.refreshScansByParent(DbCollectionsEnum.SHIPS, ship ? ship._id : null, currentUser));
              promises.push(
                _this.videoService.refreshVideosByParent(DbCollectionsEnum.SHIPS, ship ? ship._id : null, currentUser)
              );
              promises.push(_this.updateChildData(ship, currentUser));

              Promise.allSettled(promises).then((results) => {
                const shipResults = results[results.length - 1];
                if (shipResults.status === 'fulfilled') {
                  returnValue = shipResults.value;
                  _this.currentShipSubject.next(returnValue);
                }

                _this.settingsService.setIsLoading(false);
                resolve(returnValue);
              });
            },
            error: (error) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = _this.errorService.handleError(
                `Error getting shipId ${shipId} and it's related data: ${error.error}`
              );
              _this.shipErrorSubject.next(errMessage);
              reject(errMessage);
            },
            complete: () => {

            }
          });
      } else {
        _this.currentShipSubject.next(null);

        promises.push(_this.documentService.refreshDocumentsByParent(DbCollectionsEnum.SHIPS, null, currentUser));
        promises.push(_this.imageDocService.refreshImagesByParent(DbCollectionsEnum.SHIPS, null, false, currentUser));
        promises.push(_this.model3dService.refreshModels3dByParent(DbCollectionsEnum.SHIPS, null, currentUser));
        promises.push(_this.noteService.refreshNotesByParent(DbCollectionsEnum.SHIPS, null, currentUser));
        promises.push(_this.scanService.refreshScansByParent(DbCollectionsEnum.SHIPS, null, currentUser));
        promises.push(_this.videoService.refreshVideosByParent(DbCollectionsEnum.SHIPS, null, currentUser));
        promises.push(_this.updateChildData(null, currentUser));

        Promise.allSettled(promises).then((results) => {
          const shipResults = results[results.length - 1];
          if (shipResults.status === 'fulfilled') {
            returnValue = shipResults.value;
            _this.currentShipSubject.next(returnValue);
          }

          _this.settingsService.setIsLoading(false);
          resolve(returnValue);
        });
      }
    });
  }

  async saveNewShipModel3d(model3dPayload, modPayload, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && model3dPayload && modPayload) {
        _this.settingsService.setIsLoading(true);
        let returnValue: Ship = _this.currentShipSubject.getValue();

        if (returnValue) {
          _this.model3dService
            .createNewModel3d(model3dPayload, modPayload, DbCollectionsEnum.SHIPS, returnValue._id, currentUser)
            .then((newModel3d: Model3d) => {
              const modSources = returnValue.modSources
                ? returnValue.modSources
                : {
                  models3d: [],
                  scans: [],
                };

              modSources.models3d.push(newModel3d._id);

              const shipChanges = {
                modSources: modSources,
                editorId: currentUser._id,
              };

              _this.saveShip(returnValue._id, shipChanges, currentUser)
                .then((updatedShip: Ship) => {
                  returnValue = updatedShip;
                  _this.currentShipSubject.next(updatedShip);
                  _this.shipErrorSubject.next('');
                })
                .catch((shipError) => {
                  _this.shipErrorSubject.next(shipError.message);
                })
                .finally(() => {
                  _this.settingsService.setIsLoading(false);
                  resolve(returnValue);
                });
            })
            .catch((error) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = _this.errorService.handleError(
                `Error creating new 3d model for shipId ${returnValue._id}: ${error.error}`
              );
              _this.shipErrorSubject.next(errMessage);
              reject(errMessage);
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(`ship and changes are required to save ship 3d model changes`);
          _this.shipErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `model3dPayload, modPayload and user are required to create a ship 3D model`;
        _this.errorService.handleError(errMessage);
        _this.shipErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveNewShipScan(scanPayload, modPayload, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && scanPayload && modPayload) {
        _this.settingsService.setIsLoading(true);
        let returnValue: Ship = _this.currentShipSubject.getValue();

        if (returnValue) {
          _this.scanService
            .createNewScan(scanPayload, modPayload, DbCollectionsEnum.SHIPS, returnValue._id, currentUser)
            .then((newScan: Scan) => {
              const modSources = returnValue.modSources
                ? returnValue.modSources
                : {
                  models3d: [],
                  scans: [],
                };

              modSources.scans.push(newScan._id);

              const shipChanges = {
                modSources: modSources,
                editorId: currentUser._id,
              };

              _this.saveShip(returnValue._id, shipChanges, currentUser)
                .then((updatedShip: Ship) => {
                  returnValue = updatedShip;
                  _this.currentShipSubject.next(updatedShip);
                  _this.shipErrorSubject.next(null);
                })
                .catch((shipError) => {
                  _this.shipErrorSubject.next(shipError.message);
                })
                .finally(() => {
                  _this.settingsService.setIsLoading(false);
                  resolve(returnValue);
                });
            })
            .catch((scanError) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = _this.errorService.handleError(
                `Error creating new scan ${scanPayload} and mod ${modPayload} for shipId ${returnValue._id}: ${scanError.message}`
              );
              _this.shipErrorSubject.next(errMessage);
              reject(errMessage);
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(`ship and scan are required to save a new ship scan`);
          _this.shipErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `scanPayload, modPayload and user are required to create a ship scan`;
        _this.errorService.handleError(errMessage);
        _this.shipErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveShipModel3d(model3dId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && model3dId) {
        _this.settingsService.setIsLoading(true);
        const currentShip = _this.currentShipSubject.getValue();
        const currentModel3d = _this.currentShipModel3dSubject.getValue();
        let returnValue: Model3d;

        if (currentShip && currentUser && model3dId && changes) {
          _this.model3dService
            .saveModel3d(model3dId, DbCollectionsEnum.SHIPS, currentShip._id, changes, currentUser)
            .then((updated3dModel: Model3d) => {
              returnValue = updated3dModel;

              _this.setShipModel3d(currentShip, updated3dModel, currentUser)
                .then((updatedShip: Ship) => {
                  _this.shipErrorSubject.next('');
                })
                .catch((shipError) => {
                  _this.shipErrorSubject.next(shipError.message);
                })
                .finally(() => {
                  _this.settingsService.setIsLoading(false);
                  resolve(returnValue);
                });
            })
            .catch((error) => {
              const errMessage = _this.errorService.handleError(
                `Error updating model3dId ${model3dId} for shipId ${currentShip._id}: ${error.error}`
              );
              _this.shipErrorSubject.next(errMessage);
              reject(errMessage);
            })
            .finally(() => {
              _this.settingsService.setIsLoading(false);
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(
            `ship, model3dId and changes are required to save changes to a ship 3d model`
          );
          _this.shipErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `changes, model3dId and user are required to update a ship 3D model`;
        _this.errorService.handleError(errMessage);
        _this.shipErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });;
  }

  async saveShipScan(scanId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && scanId) {
        _this.settingsService.setIsLoading(true);
        const currentShip = _this.currentShipSubject.getValue();
        const currentScan = _this.currentShipScanSubject.getValue();
        let returnValue: Scan;

        if (currentShip) {
          _this.scanService
            .saveScan(scanId, DbCollectionsEnum.SHIPS, currentShip._id, changes, currentUser)
            .then((updatedScan: Scan) => {
              returnValue = updatedScan;

              _this.setShipScan(currentShip, updatedScan, currentUser)
                .then((updatedShip: Ship) => {
                  _this.shipErrorSubject.next('');
                })
                .catch((error) => {
                  _this.shipErrorSubject.next(error.error);
                })
                .finally(() => {
                  _this.settingsService.setIsLoading(false);
                  resolve(returnValue);
                });
            })
            .catch((updateError) => {
              _this.settingsService.setIsLoading(false);
              const errMessage = _this.errorService.handleError(`Error updating scanId ${scanId}: ${updateError.message}`);
              _this.shipErrorSubject.next(errMessage);
              reject(errMessage);
            });
        } else {
          _this.settingsService.setIsLoading(false);
          const errMessage = _this.errorService.handleError(`ship, scanId and changes are required to save a ship scan.`);
          _this.shipErrorSubject.next(errMessage);
          reject(errMessage);
        }
      } else {
        const errMessage = `changes, scanId and user are required to update a ship scan`;
        _this.errorService.handleError(errMessage);
        _this.shipErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async setShipModel3d(ship: Ship, model3d: Model3d, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.settingsService.setIsLoading(true);
      let returnValue = ship;

      _this.currentShipModel3dSubject.next(model3d);

      _this.updateChildData(ship, currentUser)
        .then((updatedShip: Ship) => {
          returnValue = updatedShip;
        })
        .catch((error) => {
          _this.shipErrorSubject.next(error.error);
        })
        .finally(() => {
          _this.settingsService.setIsLoading(false);
          resolve(returnValue);
        });
    });
  }

  async setShipScan(ship: Ship, scan: Scan, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      _this.currentShipScanSubject.next(scan);
      let returnValue = ship;

      _this.updateChildData(ship, currentUser)
        .then((shipWithChildData: Ship) => {
          returnValue = shipWithChildData;
        })
        .catch((error) => {
          _this.shipErrorSubject.next(error.error);
        })
        .finally(() => {
          _this.settingsService.setIsLoading(false);
          resolve(returnValue);
        });
    });
  }

  async updateChildData(ship: Ship, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      zip(
        _this.dataSources$,
        _this.manufacturers$,
        _this.mods$,
        _this.scanners$,
        _this.scans$,
        _this.shipClasses$,
        _this.shipDesignations$,
        _this.users$
      )
        .pipe(
          map(([dataSources, manufacturers, mods, scanners, scans, shipClasses, shipDesignations, users]) => ({
            dataSources,
            manufacturers,
            mods,
            scanners,
            scans,
            shipClasses,
            shipDesignations,
            users,
          }))
        )
        .subscribe(
          {
            next: ({ dataSources, manufacturers, mods, scanners, scans, shipClasses, shipDesignations, users }) => {
              if (ship) {
                _this.shipClassService.getShipClassById(ship.shipClassId, currentUser);
                _this.shipDesignationService.getShipDesignationById(ship.shipDesignationId, currentUser);

                if (
                  ship.modSources &&
                  ship.modSources.scans &&
                  Array.isArray(ship.modSources.scans) &&
                  ship.modSources.scans.length > 0
                ) {
                  const shipScans = scans.filter(function (scan) {
                    const idx = ship.modSources.scans.indexOf(scan._id);
                    if (idx != -1) {
                      return true;
                    }
                  });

                  if (shipScans.length > 0) {
                    shipScans.map(function (scan) {
                      if (scan.dataSourceId && dataSources.length > 0) {
                        const ds = dataSources.find((dataSource) => dataSource._id === scan.dataSourceId);
                        if (ds) {
                          scan.dataSourceName = ds.name;
                        }
                      }

                      if (scan.creatorId && users.length > 0) {
                        const creator = users.find((user) => user._id === scan.creatorId);
                        if (creator) {
                          scan.scanUploader = creator.fullName;
                        }
                      }

                      if (scan.scannerId && scanners.length > 0) {
                        const scanner = scanners.find((scanner) => scanner._id === scan.scannerId);
                        if (scanner) {
                          scan.scannerName = scanner.name;

                          if (scanner.manufacturerId && manufacturers.length > 0) {
                            const manu = manufacturers.find((manu) => manu._id === scanner.manufacturerId);
                            if (manu) {
                              scan.scannerManufacturerName = manu.name;
                            }
                          }
                        }
                      }
                    });
                  }

                  _this.currentShipScansSubject.next(shipScans);
                } else {
                  _this.currentShipScansSubject.next(null);
                }

                if (ship.shipClassId) {
                  const shipClass = shipClasses.find((shipClass) => shipClass._id === ship.shipClassId);
                  _this.currentShipClassSubject.next(shipClass);
                } else {
                  _this.currentShipClassSubject.next(null);
                }

                if (ship.shipDesignationId) {
                  const shipDesignation = shipDesignations.find(
                    (shipDesignation) => shipDesignation._id === ship.shipDesignationId
                  );
                  _this.currentShipDesignationSubject.next(shipDesignation);
                } else {
                  _this.currentShipDesignationSubject.next(null);
                }
              } else {
                _this.modService.getModById(null, DbCollectionsEnum.SHIPS, null, currentUser);
                _this.currentShipModels3dSubject.next(null);
                _this.currentShipScanSubject.next(null);
                _this.currentShipScansSubject.next(null);
                _this.currentShipClassSubject.next(null);
                _this.currentShipDesignationSubject.next(null);
                _this.shipClassService.getShipClassById(null, currentUser);
                _this.shipDesignationService.getShipDesignationById(null, currentUser);
              }

              resolve(ship);
            },
            error: (error) => {
              const errMessage = _this.errorService.handleError(`Error updating ship child data: ${error.error}`);
              _this.shipErrorSubject.next(errMessage);
              reject(errMessage);
            },
            complete: () => {}
          }
        );
    });
  }
}
