<div class="parentContainer" *ngIf="currentUser$ | async as currentUser">
  <button class="image" id="btnBack" (click)="goBack()" aria-label="Go Back" natTooltip="Go Back">
    <span class="btnBack"></span></button>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <button class="image" id="btnManageUsers" (click)="goToUserDashboard()" aria-label="Go To User Dashboard"
      matTooltip="Go to User Dashboard">
      <span class="btnManageUsers"></span></button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <!-- need new image -->
    <div *ngIf="invalidModsCount$ | async as invalidModsCount">
      <button class="image" id="btnInvalidMods" *ngIf="invalidModsCount > 0" (click)="goToInvalidMods()"
        aria-label="Go to Invalid Mods " matTooltip="Go to Invalid Mods ">
        <span class="btnSettings"></span>
      </button>
    </div>
    <!-- put back in when we have settings
              </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button mat-button class="image " id="btnSettings " (click)="goToSettings() " aria-label="Go to Settings " matTooltip="Go to Settings ">
      <span class="btnSettings "></span>
    </button>-->
</div>