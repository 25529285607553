import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, throwError, of } from 'rxjs';
import { catchError, filter, map, tap, toArray } from 'rxjs/operators';

import { VehicleModel, User } from '@shared/models';
import { createHttpObservable, getCustomHeaders } from '@shared/utils';

import {
  ErrorService,
  LogService,
  SettingsService,
  UserService
} from '@shared/services';

import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class VehicleModelService {
  private currentVehicleModelSubject = new BehaviorSubject<VehicleModel>(null);
  private vehicleModelsSubject = new BehaviorSubject<VehicleModel[]>([]);
  private vehicleModelErrorSubject = new BehaviorSubject<string>(null);
  currentVehicleModel$: Observable<VehicleModel> = this.currentVehicleModelSubject.asObservable();
  vehicleModels$: Observable<VehicleModel[]> = this.vehicleModelsSubject.asObservable();
  vehicleModelError$: Observable<string> = this.vehicleModelErrorSubject.asObservable();

  constructor(
    private errorService: ErrorService,
    private logService: LogService,
    private settingsService: SettingsService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router
  ) { 
  }

  async createNewVehicleModel(payload: VehicleModel, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (currentUser && payload) {
        _this.settingsService.setIsLoading(true);
        const vehicleModels = _this.vehicleModelsSubject.getValue();
        const newVehicleModels = vehicleModels.slice(0);
        const url = `${environment.baseAPIUrl}vehicleModel/create?userId=${currentUser?._id}`;
        let returnValue: VehicleModel;
  
        _this.httpClient
          .post(url, payload, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (newVehicleModel: VehicleModel) => {
              returnValue = newVehicleModel;
              _this.currentVehicleModelSubject.next(newVehicleModel);
              newVehicleModels.push(newVehicleModel);
              _this.vehicleModelsSubject.next(newVehicleModels);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.vehicleModelErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        const errMessage = `payload and user are required to create a vehicle model`;
        _this.errorService.handleError(errMessage);
        _this.vehicleModelErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  async saveVehicleModel(vehicleModelId: string, changes, currentUser: User): Promise<any> {
    const _this = this;

    return new Promise((resolve, reject) => {
      if (changes && currentUser && vehicleModelId) {
        _this.settingsService.setIsLoading(true);
        const vehicleModels = _this.vehicleModelsSubject.getValue();
        const vehicleModelIndex = vehicleModels.findIndex((vehicleModel) => vehicleModel._id === vehicleModelId);
        const newVehicleModels = vehicleModels.slice(0);
        const url = `${environment.baseAPIUrl}vehicleModel/${vehicleModelId}?userId=${currentUser?._id}`;
        let returnValue: VehicleModel;
  
        _this.httpClient
          .put(url, changes, {
            headers: getCustomHeaders(true),
            responseType: 'json',
          })
          .subscribe({
            next: (updatedVehicleModel: VehicleModel) => {
              returnValue = updatedVehicleModel;
              newVehicleModels[vehicleModelIndex] = updatedVehicleModel;
              _this.currentVehicleModelSubject.next(updatedVehicleModel);
              _this.vehicleModelsSubject.next(newVehicleModels);
              resolve(returnValue);
            },
            error: (error: HttpErrorResponse) => {
              const errMessage = _this.errorService.handleError(error);
              _this.vehicleModelErrorSubject.next(errMessage);
              reject(error);
            },
            complete: () => {
              _this.settingsService.setIsLoading(false);
            }
          });
      } else {
        const errMessage = `changes, vehicleModelId and user are required to update a vehicle model`;
        _this.errorService.handleError(errMessage);
        _this.vehicleModelErrorSubject.next(errMessage);
        reject(errMessage);
      }
    });
  }

  getCurrentVehicleModel() {
    return this.currentVehicleModelSubject.getValue();
  }

  getVehicleModels(currentUser: User): Observable<VehicleModel[]> {
    const vehicleModelsHttp$ = createHttpObservable(`${environment.baseAPIUrl}vehicleModel?userId=${currentUser?._id}`, {}, true);

    vehicleModelsHttp$
      .pipe(
        catchError((err) => {
          console.error(`Error getting vehicle models: ${err}`);
          return of([]);
        })
      )
      .subscribe((vehicleModels: VehicleModel[]) => this.vehicleModelsSubject.next(vehicleModels));

    return vehicleModelsHttp$;
  }

  getVehicleModelById(vehicleModelId: string): any {
    if (vehicleModelId) {
      return this.vehicleModels$
        .pipe(
          map((vehicleModels) => vehicleModels.find((vehicleModel) => vehicleModel._id === vehicleModelId)),
          filter((vehicleModel) => !!vehicleModel)
        )
        .subscribe((vehicleModel) => this.currentVehicleModelSubject.next(vehicleModel));
    } else {
      this.currentVehicleModelSubject.next(null);
    }
  }
}
